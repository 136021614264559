import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'objectKeys' })
export class ObjectKeysPipe implements PipeTransform {
  transform(input: Object): Array<any> {
    let keys = [];
    for (let key in input) {
      keys.push({key: key, value: input[key]});
    }
    return keys;
  }
}
