import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AerialButtonWithIcon } from '../../aerial-button/aerialButtonWithIcon.model';

@Component({
  selector: 'combo-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <button type="button" [ngClass]="classes" [disabled]="disabled">
      <span class="combo-text btn-text">
        <ng-content></ng-content>
      </span>
      <i class="combo-icon btn-icon {{ iconClass }}"></i>
    </button>
  `,
  styleUrls: ['./combo-button.styles.scss']
})
export class ComboButtonComponent extends AerialButtonWithIcon {
  otherClasses = ['combo-btn', 'combo-element', 'with-separator', 'small'];
}
