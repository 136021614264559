import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'aerial-switch',
  templateUrl: './aerial-switch.template.html',
  styleUrls: ['./aerial-switch.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AerialSwitchComponent {
  @Input()
  size: 'xsmall' | 'small' | 'medium' = 'medium';

  @Input()
  checked = false;

  @Input()
  disabled = false;

  @Output()
  toggle = new EventEmitter<void>();

  @Output()
  check = new EventEmitter<void>();

  @Output()
  uncheck = new EventEmitter<void>();

  onToggle(): void {
    this.checked = !this.checked;
    this.toggle.emit();
    this[this.checked ? 'check' : 'uncheck'].emit();
  }
}
