import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './fields.template.html',
  styleUrls: ['./fields.styles.scss']
})
export class FieldsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
