export class DateRange {
  public start: Date;
  public end: Date;

  /**
   * The number of miliseconds a day lasts.
   */
  private _dayTime: number = 1000 * 60 * 60 * 24;

  /**
   * Construct a new DateRange.
   *
   * @param  Date  start
   * @param  Date  end
   * @return void
   */
  constructor(start: Date, end: Date) {
    if (!this._isValidDate(start) || !this._isValidDate(end)) {
      throw new Error('Arguments should be valid dates.');
    }

    // We dont want time in our date range, because it will make calculation so much
    // more inaccurate. Besides, this is a 'date' range, not 'date-time'. This also
    // creates new dates to prevent references from outside.
    start = this._stripTime(start);

    end = this._stripTime(end);

    if (start >= end) {
      throw new Error('End date must be bigger then start date.');
    }

    this.start = start;

    this.end = end;
  }

  /**
   * Get the number of miliseconds in the range.
   *
   * @return number
   */
  getTime() {
    return this.end.getTime() - this.start.getTime();
  }

  /**
   * Get the number of days in the range.
   *
   * @return number
   */
  countDays() {
    return this.getTime() / this._dayTime + 1;
  }

  /**
   * Get array of dates for the beginning of each year in the range
   *
   * @return array
   */
  getYears(): Date[] {
    const startYear = this.start.getFullYear();

    const nYears = this.end.getFullYear() - startYear + 1;

    return this._times(nYears, index => {
      return new Date(startYear + index, 0, 1);
    });
  }

  /**
   * Get array of dates for the beginning of each month in the range
   *
   * @return array
   */
  getMonths(): Date[] {
    const startYear = this.start.getFullYear();
    const endYear = this.end.getFullYear();
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const startMonth = i === startYear ? this.start.getMonth() : 0;
      const endMonth = i === endYear ? this.end.getMonth() : 11;

      for (
        let j = startMonth;
        j <= endMonth;
        j = j > 12 ? j % 12 || 11 : j + 1
      ) {
        dates.push(new Date(i, j));
      }
    }
    return dates;
  }

  /**
   * Get an array of dates for the beginning of each day in the range
   *
   * @return array
   */
  getDays(): Date[] {
    const dates: Date[] = [];
    const current = this._incrementDate(this._cloneDate(this.start), -1);
    while (current < this.end) {
      dates.push(this._cloneDate(this._incrementDate(current)));
    }
    return dates;
  }

  /**
   * Determine if a date is in the range.
   *
   * @param  Date|DateRange  date
   * @return boolean
   */
  contains(date: Date | DateRange) {
    if (date instanceof DateRange) {
      return date.start >= this.start && date.end <= this.end;
    } else {
      return this._isValidDate(date) && date >= this.start && date <= this.end;
    }
  }

  /**
   * Get the index of a date in the range.
   *
   * @param  Date  value
   * @return number
   */
  indexOf(date: Date) {
    if (!this.contains(date)) return -1;

    return (date.getTime() - this.start.getTime()) / this._dayTime;
  }

  /**
   * Get the intersection between this and another DateRange.
   *
   * @param  DateRange  range
   * @return DateRange|boolean
   */
  intersection(range: DateRange) {
    if (!this._isDateRange(range)) return false;

    var start = this._max([this.start, range.start]);

    var end = this._min([this.end, range.end]);

    if (start >= end) return false;

    return new DateRange(start, end);
  }

  /**
   * Determine if a range intersects this range.
   *
   * @param  DateRange  range
   * @return boolean
   */
  doesIntersect(range: DateRange) {
    return (
      this._isDateRange(range) &&
      range.start <= this.end &&
      range.end >= this.start
    );
  }

  /**
   * Clone a date, without the time portion.
   *
   * @param  Date  date
   * @return Date
   */
  private _stripTime(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  /**
   * Determine if a value is a valid Date.
   *
   * @param  mixed  value
   * @return boolean
   */
  private _isValidDate(value: Date) {
    return value instanceof Date;
  }

  /**
   * Determine if a value is a DateRange.
   *
   * @param  mixed  value
   * @return boolean
   */
  private _isDateRange(value: DateRange) {
    return value instanceof DateRange;
  }

  /**
   * Do a thing n times.
   *
   * @param  number  n
   * @param  function  predicate
   * @return array
   */
  private _times(n: number, predicate: (index: number) => any) {
    var array = [];

    for (var i = 0; i < n; i++) {
      array.push(predicate(i));
    }

    return array;
  }

  /**
   * Get the lowest value from a list.
   *
   * @param  array  values
   * @return mixed
   */
  private _min(values: any[]) {
    return values.reduce((lowest, value) => {
      return lowest && value < lowest ? value : lowest;
    });
  }

  /**
   * Get the highest value from a list.
   *
   * @param  array  values
   * @return mixed
   */
  private _max(values: any[]) {
    return values.reduce((highest, value) => {
      return highest && value > highest ? value : highest;
    });
  }

  /**
   * returns a cloned date object
   *
   * @param Date date to clone
   * @return Date
   */
  private _cloneDate(date: Date) {
    return new Date(date.valueOf());
  }

  /**
   * adds a day to the date, or the specified number of days if passed
   *
   * @param Date date to increment
   * @param number optional amount of days to increment
   * @return the incremented date
   */
  private _incrementDate(date: Date, amount?: number): Date {
    date.setDate(date.getDate() + (amount || 1));
    return date;
  }
}
