import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MapService } from '../map.service';
import { AerialMapService } from './aerial-map.service';

export interface AerialMapOptions {
  disableOneAtlas?: boolean; // Pass true to not add One Atlas layers
  mouseWheelZoom?: boolean; // Pass false to remove mouse/trackpad scroll zoom
}

@Component({
  selector: 'aerial-map',
  templateUrl: './aerial-map.template.html',
  styleUrls: ['./aerial-map.styles.scss']
})
export class AerialMapComponent implements OnInit, OnDestroy {
  @Input()
  center: [number, number];

  @Input()
  zoom: number;

  @Input()
  options: AerialMapOptions;

  @Input()
  mgrsMode = false;

  @Input()
  imperialUnits = false;

  constructor(
    private _mapService: MapService,
    private _aerialMapService: AerialMapService
  ) {}

  ngOnInit() {
    this._mapService.initMap('map', this.center, this.zoom, this.options);
    this._mapService.onInit(() => this._aerialMapService.onMapInit());
  }

  ngOnDestroy() {
    this._aerialMapService.onMapDestroy();
  }
}
