import { BaseModel } from './base.model';

export enum ByodTemplateName {
  TwoDee = 'Imported Data (2D)',
  ThreeDee = 'Imported Data (3D)',
  Photos = 'Imported Data (Photos)'
}

export interface DataProductConfig {
  dataProduct: string;
  selected: boolean;
  configuration: any;
}

export interface OrderRequirement {
  minArea: number;
  maxArea: number;
  areaUnits: string;
}

export interface MissionWorkItemTask {
  name: string;
  reqId: number;
  taskType: string;
}

export interface MissionWorkItem {
  type: string;
  reqId: number;
  tasks: MissionWorkItemTask[];
}

export interface ProcessWorkItemConfig {
  output: string[];
  productType: string;
  expectedDataProductCount: number;
}

export interface ProcessWorkItem {
  type: string;
  dagId: string;
  reqId: number;
  config: ProcessWorkItemConfig;
  upstreamReqIds: number[];
}

export class OrderTemplate extends BaseModel {
  id: string;
  created: number;
  modified: number;
  name: string;
  customerId: string; // Is this used?
  thumbnailUrl: string;
  outputProduct: string;
  description: string;
  dataProductConfigs: DataProductConfig[];
  orderRequirements: OrderRequirement[];
  statementOfWork: (MissionWorkItem | ProcessWorkItem)[];
  deleted: boolean;
  active: boolean;

  get outputProductTypes(): string[] {
    return this.statementOfWork
      ? this.statementOfWork
          .filter(sow => sow.type === 'PROCESS')
          .map((sow: ProcessWorkItem) => sow.config)
          .filter(sow => sow.expectedDataProductCount > 0)
          .map(sow => sow.productType)
      : [];
  }

  toJSON(): object {
    return this;
  }
}
