import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialButtonWithIcon } from '../aerialButtonWithIcon.model';

@Component({
  selector: 'loader-button',
  template: `
    <!-- prettier-ignore -->
    <button type="button" [ngClass]="allClasses" [disabled]="true">
      <span class="combo-text btn-text">
        <ng-content></ng-content>
      </span>
      <loader-guy class="combo-icon btn-icon"
        [inline]="true"
        [hideText]="true"
        [size]="size"
        [color]="loaderColor">
      </loader-guy>
    </button>
  `,
  styleUrls: ['./loader-button.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderButtonComponent extends AerialButtonWithIcon {
  @Input()
  set loaderColor(loaderColor: 'dark' | 'light' | 'neutral') {
    this._loaderColor = loaderColor;
  }

  get loaderColor(): 'dark' | 'light' | 'neutral' {
    if (this._loaderColor) {
      return this._loaderColor;
    }
    switch (this.color) {
      case 'tertiary':
        return 'neutral';
      case 'secondary':
      case 'subordinate':
        return 'dark';
      default:
        return 'light';
    }
  }

  otherClasses = ['loader-btn', 'combo-element'];

  private _loaderColor: 'dark' | 'light' | 'neutral';
}
