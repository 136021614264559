import { Injectable } from '@angular/core';
import { Geometry } from 'projects/api/src/public_api';
import AMap from 'amap';
import { environment } from 'environments/environment';
import { toLonLat } from 'ol/proj';

/**
 * Interface for handling initialization AMap
 */
@Injectable({
  providedIn: 'root'
})
export class AMapService {
  /**
   * Instance of AMap
   */
  private _amap: any;

  /**
   * Array of callbacks that other components can register to be called when the map is initialized.
   */
  private _initCallbacks: Function[] = [];

  constructor() {}

  /**
    @param options An object with options to configure the map
    @param options.target The id of the HTML element
    @param options.onLoad Callback function to be executed after initialization
    @param options.disableZoom Disable mouse zoom
    @param options.showSatelliteLayer Whether to show the satellite layer on first load
    @param options.initialBounds Set the initial bounds of the map
  */
  initMap({
    target,
    onLoad,
    disableZoom = false,
    showSatelliteLayer = true,
    initialBounds = null
  }: {
    target: string;
    onLoad: Function;
    disableZoom?: boolean;
    showSatelliteLayer?: boolean;
    initialBounds?: Array<Array<number>>;
  }) {
    this._amap = new AMap({
      appId: environment.AMAP_APP_ID,
      apiHost: 'map-api.airbusutm.com',
      container: target,
      zoom: 11,
      center: [-122.41942, 37.77493],
      bounds: initialBounds,
      fitBoundsOptions: {
        animate: false,
        duration: 0,
        padding: 20
      },
      onLoad: (map: any) => {
        map.showSatelliteLayer(showSatelliteLayer);
        map.addControl(
          new AMap.mapboxgl.NavigationControl({
            showCompass: false,
            showZoom: true
          }),
          'bottom-right'
        );
        if (disableZoom) {
          map.map.scrollZoom.disable();
        }
        // run registered callbacks
        this._initCallbacks.forEach(callback => callback());
        this._initCallbacks = [];
        onLoad(map);
      }
    });
  }

  geometryCollectionToGeojson(geometry: any) {
    const toLngLat = (coord: [number, number]) =>
      toLonLat([coord[0], coord[1]]);

    const convertToLngLat = (geom: Geometry) => {
      // note: Geometry type doesn't type coordinates correctly for the given types
      let coordinates;
      if (geom.type === 'Point') {
        coordinates = toLngLat((geom.coordinates as unknown) as [
          number,
          number
        ]);
      } else if (geom.type === 'Polygon') {
        coordinates = ((geom.coordinates as unknown) as number[][][]).map(
          linearRing => linearRing.map(toLngLat)
        );
      } else {
        throw new Error('Unsupported geometry type');
      }
      return {
        ...geom,
        coordinates
      };
    };

    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'GeometryCollection',
        geometries: geometry.geometries.map(convertToLngLat)
      }
    };
  }

  /**
    Allows for callback functions to be registered to then be called
    after the map object is initialized.
    ```typescript
    this._amapService.onInit(() => {
      // Do stuff here only after the map is created
    })
    ```
  */
  onInit(callback: Function) {
    this._initCallbacks.push(callback);
  }

  /**
   * Get initialized map
   * @returns Instance of AMap
   */
  getMap(): any {
    return this._amap;
  }
}
