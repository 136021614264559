import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Dialog, ConfirmDialog } from './dialog.interface';

@Component({
  template: `
    <!-- prettier-ignore -->
    <div class="dialog-body" [innerHTML]="body"></div>
    <!-- prettier-ignore -->
    <div class="dialog-actions btn-group flush-wide">
      <aerial-button classes="block" color="tertiary" (click)="deny()">
        {{ denyBtnText }}
      </aerial-button>
      <aerial-button classes="block"
        [color]="confirmBtnColor"
        (click)="confirm()">
        {{ confirmBtnText }}
      </aerial-button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements Dialog, ConfirmDialog {
  body: string;
  confirmBtnText: string;
  denyBtnText: string;
  successCallback: Function;
  errorCallback?: Function;
  close: Function;
  confirmBtnColor: string;

  deny(): void {
    // needs to return 'false'
    if (this.errorCallback) {
      this.errorCallback();
    }
    this.close();
  }

  confirm(): void {
    // needs to return 'true'
    this.successCallback();
    this.close();
  }
}
