import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';

import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export abstract class BaseService {
  protected readonly rootUrl: string;
  protected readonly authUrl: string;
  protected readonly usersUrl: string;
  protected readonly customersUrl: string;
  protected readonly brokerRootUrl: string;
  protected readonly agRootUrl: string;
  protected abstract endpointUrl: string;
  protected defaultSize = 500;

  constructor(protected httpClient: HttpClient) {
    this.rootUrl = `${environment.API_ROOT}`;
    this.authUrl = `${environment.AUTH_API_ROOT}`;
    this.usersUrl = `${environment.USERS_API_ROOT}`;
    this.customersUrl = `${environment.CUSTOMERS_API_ROOT}`;
    this.brokerRootUrl = `${environment.BROKER_ROOT}`;
    this.agRootUrl = `${environment.AG_API_ROOT}`;
    // `endpointUrl` should be set in the inherited class' constructor
  }

  protected buildParams(params: {
    [param: string]: string | string[];
  }): HttpParams {
    let res = new HttpParams();

    Object.keys(params).forEach(paramKey => {
      const paramVal = params[paramKey];

      if (Array.isArray(paramVal)) {
        paramVal.forEach(pVal => (res = res.append(paramKey, pVal)));
      } else {
        res = res.set(paramKey, <string>paramVal);
      }
    });

    return res;
  }

  protected buildPagedParams(
    baseParams: BaseParams = {},
    otherParams: HttpParams | { [param: string]: string | string[] } = {}
  ): HttpParams {
    const size = baseParams.size ? `${baseParams.size}` : `${this.defaultSize}`,
      sortOrder = baseParams.sortOrder ? baseParams.sortOrder : 'desc';

    let params: HttpParams;
    if (otherParams instanceof HttpParams) {
      params = otherParams;
    } else {
      params = this.buildParams(otherParams);
    }

    if (!isNil(baseParams.page)) {
      params = params.set('page', `${baseParams.page}`);
    }

    if (baseParams.sortBy) {
      const sortBy = Array.isArray(baseParams.sortBy)
        ? baseParams.sortBy.join()
        : baseParams.sortBy;
      params = params.set('sort', `${sortBy},${sortOrder}`);
    }

    return params.set('size', size);
  }

  protected buildWildcardSearchParam(
    term: string,
    column = 'name'
  ): HttpParams {
    return this.buildParams({ search: `${column}=="*${term}*"` });
  }

  protected buildHeaders(headers: {
    [header: string]: string | string[];
  }): HttpHeaders {
    let res = new HttpHeaders();

    Object.keys(headers).forEach(header => {
      res = res.set(header, headers[header]);
    });

    return res;
  }

  protected buildAuthHeader(token: string): HttpHeaders {
    // this is a temp solution until I can add the ability to specify a token on all requests
    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }

  protected get<T>(
    requestUrl: string,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<T> {
    return this.httpClient.get<T>(requestUrl, {
      ...options
    });
  }

  protected getWithResponse<T>(
    requestUrl: string,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<HttpResponse<T>> {
    return this.httpClient.get<T>(requestUrl, {
      ...options,
      observe: 'response'
    });
  }

  protected getWithPagedResponse<T>(
    requestUrl: string,
    mapper: (respBody: Array<T>) => Array<T>,
    baseParams: BaseParams = {},
    options: {
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    } = {}
  ): Observable<PagedResponse<T>> {
    let headers: HttpHeaders;
    if (options.headers instanceof HttpHeaders) {
      headers = options.headers;
    } else {
      headers = new HttpHeaders(options.headers);
    }

    const pagedOptions = {
      params: this.buildPagedParams(baseParams, options.params),
      headers
    };

    return this.getWithResponse<T[]>(requestUrl, pagedOptions).pipe(
      map(response => {
        return {
          response: response,
          pagination: {
            totalCount: toNumber(
              response.headers.get('pagination-total-count')
            ),
            totalPages: toNumber(
              response.headers.get('pagination-total-pages')
            ),
            pageSize: toNumber(response.headers.get('pagination-page-size')),
            currentPage: toNumber(
              response.headers.get('pagination-current-page')
            )
          },
          data: mapper(response.body)
        };
      })
    );
  }

  protected createWithResponse<T>(
    requestUrl: string,
    body: any | null,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<HttpResponse<T>> {
    return this.httpClient.post<T>(requestUrl, body, {
      ...options,
      observe: 'response'
    });
  }

  protected createWithResponseAsUrl(
    requestUrl: string,
    body: any | null,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<string> {
    return this.createWithResponse<object>(requestUrl, body, options).pipe(
      map(resp => resp.headers.get('Location'))
    );
  }

  // alias for `createWithResponse<T>()`
  protected postWithResponse<T>(
    requestUrl: string,
    body: any | null,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<HttpResponse<T>> {
    return this.createWithResponse<T>(requestUrl, body, options);
  }

  // alias for `createWithResponseAsUrl()`
  protected postWithResponseAsUrl(
    requestUrl: string,
    body: any | null,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<string> {
    return this.createWithResponseAsUrl(requestUrl, body, options);
  }

  protected putWithResponse<T>(
    requestUrl: string,
    body: object,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<HttpResponse<T>> {
    return this.httpClient.put<T>(requestUrl, body, {
      ...options,
      observe: 'response'
    });
  }

  protected patchWithResponse<T>(
    requestUrl: string,
    body: object,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<HttpResponse<T>> {
    return this.httpClient.patch<T>(requestUrl, body, {
      ...options,
      observe: 'response'
    });
  }

  protected delete(
    requestUrl: string,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<object> {
    return this.httpClient.delete(requestUrl, {
      ...options
    });
  }

  protected deleteWithResponse(
    requestUrl: string,
    options: {
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {}
  ): Observable<HttpResponse<object>> {
    return this.httpClient.delete(requestUrl, {
      ...options,
      observe: 'response'
    });
  }
}
