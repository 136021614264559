import { Pipe, PipeTransform } from '@angular/core';
import isFinite from 'lodash/isFinite';
import { numberWithCommas } from './helpers';

@Pipe({ name: 'centimeters' })

// Convert centimeters number into an appropriate metric unit
// After conversion to correct scale, the precision is set
// parseFloat will remove any trailing zeros after the decimal point
// commas are added for large numbers
export class CentimetersPipe implements PipeTransform {
  transform(input: number, precision?: number): string {
    if (isFinite(input)) {
      if (input > 100000) {
        return (
          numberWithCommas(
            parseFloat((input / 100000).toFixed(precision || 2))
          ) + ' km'
        );
      } else if (input > 100) {
        return parseFloat((input / 100).toFixed(precision || 2)) + ' m';
      } else if (input < 1) {
        return parseFloat((input * 10).toFixed(precision || 2)) + ' mm';
      } else {
        return parseFloat(input.toFixed(precision || 2)) + ' cm';
      }
    } else {
      return 'NaN';
    }
  }
}
