import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialButtonWithIcon } from '../aerialButtonWithIcon.model';

@Component({
  selector: 'disclosure-button',
  template: `
    <!-- prettier-ignore -->
    <texticon-button
      [classes]="classes"
      [color]="color"
      [size]="size"
      [type]="type"
      [disabled]="disabled"
      [iconPosition]="iconPosition"
      iconClass="icon-disclosure_{{ arrowDirection }}">
      <ng-content></ng-content>
    </texticon-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclosureButtonComponent extends AerialButtonWithIcon {
  @Input()
  arrowDirection: 'up' | 'down' | 'left' | 'right' = 'right';

  otherClasses: string[] = [];
}
