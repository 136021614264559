import { BaseModel } from './base.model';

import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';

export class Asset extends BaseModel {
  pointFeature: Feature;
  extentFeature: Feature;

  id: string;
  name: string;
  address: string;
  description: string;
  customerId: string;
  projectId: string;
  layerId: string;
  pitneyBowesKey: string;
  created: number;
  modified: number;
  index?: number;

  attributes: any;
  classifications: any;

  toJSON(): object {
    let obj = {} as any;
    Object.keys(this).map(key => {
      obj[key] = this[key];
    });

    let geo = new GeoJSON();
    this.pointFeature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    this.extentFeature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
    const pointJSON = JSON.parse(geo.writeFeature(this.pointFeature));
    const extentJSON = JSON.parse(geo.writeFeature(this.extentFeature));

    if (!this.attributes) {
      this.attributes = {
        aerialStatus: 'NOT SET',
        source: 'NOT SET'
      };
    }

    obj.feature = {
      attributes: this.attributes,
      classifications: this.classifications,
      geom: extentJSON['geometry']
    };

    obj.point = pointJSON['geometry'];

    delete obj.pointFeature;
    delete obj.extentFeature;
    return obj;
  }

  static fromJSON(jsonAsset: any): Asset {
    let geom = new GeoJSON();
    let pointFeature = geom.readFeature(jsonAsset.point);
    pointFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    pointFeature.setId(jsonAsset.id);
    pointFeature.setProperties({
      name: jsonAsset.name,
      class: 'asset-feature'
    });
    jsonAsset.pointFeature = pointFeature;

    let extentFeature = geom.readFeature(jsonAsset.feature.geom);
    extentFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    extentFeature.setId(jsonAsset.id);
    extentFeature.setProperties({
      name: jsonAsset.name,
      class: 'asset-feature'
    });
    jsonAsset.attributes = jsonAsset.feature.attributes;
    jsonAsset.classifications = jsonAsset.feature.classifications;
    jsonAsset.extentFeature = extentFeature;
    jsonAsset.featureId = jsonAsset.feature.id;

    delete jsonAsset.point;
    delete jsonAsset.feature;

    return new Asset({
      ...jsonAsset
    });
  }
}
