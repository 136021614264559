import { BaseModel } from './base.model';
import { environment } from 'environments/environment';

export class Stac extends BaseModel {
  stacVersion: string;
  stacExtensions?: string[];

  set stac_version(stac_version: string) {
    this.stacVersion = stac_version;
  }

  set stac_extensions(stac_extensions: string[]) {
    this.stacExtensions = stac_extensions;
  }
}

export class StacCollection extends Stac {
  // https://github.com/radiantearth/stac-spec/blob/master/collection-spec/collection-spec.md
  id: string;
  title?: string;
  description: string;
  keywords?: string[];
  license: string;
  providers?: StacProvider[];
  extent: StacExtent;
  summaries?: {
    [fieldName: string]: StacStats | any[];
  };
  links: StacLink[];
}

export class StacCollections extends BaseModel {
  links: StacLink[];

  set collections(collections: StacCollection[]) {
    this._collections = collections.map(collection =>
      collection instanceof StacCollection
        ? collection
        : new StacCollection(collection)
    );
  }

  get collections(): StacCollection[] {
    return this._collections;
  }

  private _collections: StacCollection[];
}

export interface StacProvider {
  // https://github.com/radiantearth/stac-spec/blob/master/collection-spec/collection-spec.md#provider-object
  name: string;
  description?: string;
  roles?: Array<'licensor' | 'producer' | 'processor' | 'host'>;
  url?: string;
}

export interface StacExtent {
  // https://github.com/radiantearth/stac-spec/blob/master/collection-spec/collection-spec.md#extent-object
  spatial: StacExtentSpatial;
  temporal: StacExtentTemporal;
}

export interface StacExtentSpatial {
  bbox: [number[]];
}

export interface StacExtentTemporal {
  interval: [[string | null, string | null]];
}

export interface StacStats {
  // https://github.com/radiantearth/stac-spec/blob/master/collection-spec/collection-spec.md#stats-object
  min: string | number;
  max: string | number;
  [fieldName: string]: any;
}

export interface StacLink {
  // https://github.com/radiantearth/stac-spec/blob/master/collection-spec/collection-spec.md#link-object
  href: string;
  rel: 'self' | 'root' | 'parent' | 'collection' | string;
  type?: string;
  title?: string;
}

export interface StacNextLink {
  // https://github.com/radiantearth/stac-spec/blob/v0.9.0/api-spec/api-spec.md#paging-extension
  href: string;
  rel: 'next';
  type?: string;
  title?: string;
  method?: 'GET' | 'POST' | string;
  headers?: object;
  body?: object;
  merge?: boolean;
}

export class StacItem extends Stac {
  // https://github.com/radiantearth/stac-spec/blob/master/item-spec/item-spec.md
  id: string;
  type: 'Feature';
  geometry:
    | PointGeometry
    | MultiPointGeometry
    | LineStringGeometry
    | MultiLineStringGeometry
    | PolygonGeometry
    | MultiPolygonGeometry
    | GeometryCollection;
  bbox: StacBbox;
  properties: {
    datetime: string;
    'eo:gsd'?: number;
    'eo:cloud_cover'?: number;
    platform?: string;
    instruments?: string[];
    'airbus:id'?: string;
    'view:incidence_angle'?: number;
    'airbus:mimeType'?: string;
    'sat:orbit_state'?: string;
    'sar:polarizations'?: string | string[];
    'sar:instrument_mode'?: string;
    'sar:observation_direction'?: string;
    [fieldName: string]: any;
  };
  links: StacLink[];
  assets: {
    // Not worrying about automatically converting to camel case here because these
    // are considered unique key names (in this case, defined by us) and not
    // defined-by-stac-standard field names.

    // Developers are encouraged to expose/access these via getters using camelCase
    // see: `streamingWMSAsset` as an example

    // Our streaming asset keywords:
    // https://aerial.atlassian.net/wiki/spaces/PD/pages/1101004819/STAC+Catalog+Streaming+Asset+Keywords

    streaming_wms?: StacAsset;
    streaming_wmts?: StacAsset;
    streaming_wcs?: StacAsset;
    streaming_image_server?: StacAsset;
    streaming_tilejson?: StacAsset;
    streaming_xyz?: StacAsset;

    // Thumbnail Size Options
    browse_small?: StacAsset;
    browse_medium?: StacAsset;
    browse_large?: StacAsset;

    [uniqueKey: string]: StacAsset;
  };
  collection?: string;

  get hasStreamingAssets(): boolean {
    return (
      !!this.streamingWMSAsset ||
      !!this.streamingWMTSAsset ||
      !!this.streamingWCSAsset ||
      !!this.streamingImageServerAsset ||
      !!this.streamingTilejsonAsset ||
      !!this.streamingXYZAsset
    );
  }

  get streamingWMSAsset(): StacAsset {
    return this.assets.streaming_wms;
  }

  get streamingWMTSAsset(): StacAsset {
    return this.assets.streaming_wmts;
  }

  get streamingWCSAsset(): StacAsset {
    return this.assets.streaming_wcs;
  }

  get streamingImageServerAsset(): StacAsset {
    return this.assets.streaming_image_server;
  }

  get streamingTilejsonAsset(): StacAsset {
    return this.assets.streaming_tilejson;
  }

  get streamingXYZAsset(): StacAsset {
    return this.assets.streaming_xyz;
  }

  get overlayGeographicAsset(): StacAsset {
    return this.assets.overlay_geographic;
  }

  get overlayMercatorAsset(): StacAsset {
    return this.assets.overlay_mercator;
  }

  get secureThumbnailAsset(): StacAsset {
    return Object.values(this.assets).find(
      asset =>
        asset.roles &&
        asset.roles.includes('thumbnail') &&
        // Some assets are hosted publicly on CDN
        !asset.href.includes(environment.CDN_ROOT)
    );
  }

  get thumbnailAsset(): StacAsset {
    return Object.values(this.assets).find(
      asset => asset.roles && asset.roles.includes('thumbnail')
    );
  }

  get smallThumbAsset(): StacAsset {
    return this.assets.browse_small;
  }

  get mediumThumbAsset(): StacAsset {
    return this.assets.browse_medium;
  }

  get largeThumbAsset(): StacAsset {
    return this.assets.browse_large;
  }
}

export type StacBbox =
  | [number, number, number, number]
  | [number, number, number, number, number, number]; // limiting to 2D and 3D

export interface StacAsset {
  // https://github.com/radiantearth/stac-spec/blob/master/item-spec/item-spec.md#asset-object
  href: string;
  title?: string;
  description?: string;
  type?: string;
  roles?: string[];
  [fieldName: string]: any;
}

export class StacItemCollection extends Stac {
  // https://github.com/radiantearth/stac-spec/blob/master/item-spec/itemcollection-spec.md
  type: 'FeatureCollection';
  context?: StacContext;

  set features(features: StacItem[]) {
    this._features = features.map(feature =>
      feature instanceof StacItem ? feature : new StacItem(feature)
    );
  }

  get features(): StacItem[] {
    return this._features;
  }

  set links(links: Array<StacLink | StacNextLink>) {
    this._links = links.map(link => {
      if (link.rel === 'next') {
        this._next = link as StacNextLink;
        return link as StacNextLink;
      } else {
        return link as StacLink;
      }
    });
  }

  get links(): Array<StacLink | StacNextLink> {
    return this._links;
  }

  get next(): StacNextLink {
    return this._next;
  }

  private _features: StacItem[];
  private _links: Array<StacLink | StacNextLink>;
  private _next: StacNextLink;
}

export interface StacContext {
  returned: number;
  limit?: number | null;
  matched?: number;
}

export type StacQueryOperator =
  | 'eq'
  | 'neq'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'startsWith'
  | 'endsWith'
  | 'contains'
  | 'in';

export type StacQueryClause = {
  [operator in StacQueryOperator]?: any;
};

export interface StacQuery {
  [propertyName: string]: StacQueryClause;
}

export interface StacSort {
  field: string;
  direction: 'asc' | 'desc';
}

export interface StacSearchParams {
  bbox?: StacBbox;
  intersects?: StacGeometry;
  datetime?: string;
  limit?: number;
  ids?: string[];
  collections?: string[];
  query?: StacQuery;
  sortby?: StacSort[];
  fields?: {
    include?: string[];
    exclude?: string[];
  };
  text?: string[];
}

// GeoJSON Geometry Object: https://tools.ietf.org/html/rfc7946#section-3.1
export type GeometryPosition = [number, number] | [number, number, number];
export type GeometryType =
  | 'Point'
  | 'MultiPoint'
  | 'LineString'
  | 'MultiLineString'
  | 'Polygon'
  | 'MultiPolygon'
  | 'GeometryCollection';
export type GeoJSONType = 'Feature' | 'FeatureCollection' | GeometryType;

export abstract class StacGeometry {
  // Prefixing with `Stac` to avoid conflict with existing `Geometry` interface.
  // Ideally, these should be the same model, but I don't want to risk breaking
  // something by replacing our existing `Geometry` interface.
  type:
    | 'Point'
    | 'MultiPoint'
    | 'LineString'
    | 'MultiLineString'
    | 'Polygon'
    | 'MultiPolygon';
  abstract coordinates:
    | GeometryPosition
    | GeometryPosition[]
    | Array<GeometryPosition[]>
    | Array<Array<GeometryPosition[]>>;
}

export class PointGeometry extends StacGeometry {
  coordinates: GeometryPosition; // single position
}

export class MultiPointGeometry extends StacGeometry {
  coordinates: GeometryPosition[]; // array of positions
}

export class LineStringGeometry extends StacGeometry {
  coordinates: GeometryPosition[]; // array of two or more positions
}

export class MultiLineStringGeometry extends StacGeometry {
  coordinates: Array<GeometryPosition[]>; // array of LineString coordinate arrays
}

export class PolygonGeometry extends StacGeometry {
  coordinates: Array<GeometryPosition[]>; // https://tools.ietf.org/html/rfc7946#section-3.1.6
}

export class MultiPolygonGeometry extends StacGeometry {
  coordinates: Array<Array<GeometryPosition[]>>; // array of Polygon coordinate arrays
}

export interface GeometryCollection {
  type: 'GeometryCollection';
  geometries: Array<
    | PointGeometry
    | MultiPointGeometry
    | LineStringGeometry
    | MultiLineStringGeometry
    | PolygonGeometry
    | MultiPolygonGeometry
  >;
}
