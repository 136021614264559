import { MapLayer, BasemapOptions } from 'projects/map/src/public_api';
import { WorkspaceLayer } from './workspaceLayer.model';

export interface WorkspaceState {
  name: string;
  baseMap: BasemapOptions;
  streetsOverlayEnabled: boolean;
  mapMarkersEnabled: boolean;
  layers: MapLayer[];
  projectId?: string;
}

export class Workspace {
  id: string;
  name: string;
  projectId: string;
  baseMap: BasemapOptions;
  streetsOverlayEnabled: boolean;
  mapMarkersEnabled: boolean;
  created: number;
  modified: number;

  protected workspaceLayers: WorkspaceLayer[];

  get layers(): object[] {
    return this.workspaceLayers.sort((a, b) => {
      // sort descending
      if (a.layerOrder > b.layerOrder) return -1;
      if (b.layerOrder > a.layerOrder) return 1;
      return 0;
    });
  }

  set layers(layers: object[]) {
    this.workspaceLayers = layers.map((layer: object) => {
      const wsLayer = new WorkspaceLayer(layer);
      if (!wsLayer.workspaceId) wsLayer.workspaceId = this.id;
      return wsLayer;
    });
  }

  get toObject(): object {
    return {
      name: this.name,
      projectId: this.projectId,
      baseMap: this.baseMap,
      streetsOverlayEnabled: this.streetsOverlayEnabled,
      mapMarkersEnabled: this.mapMarkersEnabled,
      workspaceLayers: this.workspaceLayers
    };
  }

  constructor(workspace = {}) {
    Object.keys(workspace).forEach(key => {
      if (key === 'workspaceLayers') {
        this.layers = workspace[key];
      } else {
        this[key] = workspace[key];
      }
    });
  }

  saveState({
    name,
    baseMap,
    streetsOverlayEnabled,
    mapMarkersEnabled,
    layers,
    projectId
  }: WorkspaceState): void {
    this.name = name;
    this.baseMap = baseMap;
    this.streetsOverlayEnabled = streetsOverlayEnabled;
    this.mapMarkersEnabled = mapMarkersEnabled;
    this.layers = layers;
    if (projectId) this.projectId = projectId;
  }

  isStateEqualTo(otherState: WorkspaceState): boolean {
    return (
      this.name === otherState.name &&
      this.baseMap === otherState.baseMap &&
      this.streetsOverlayEnabled === otherState.streetsOverlayEnabled &&
      this.mapMarkersEnabled === otherState.mapMarkersEnabled &&
      this.layers.length === otherState.layers.length &&
      this.areWorkspaceLayersEqualTo(otherState.layers) &&
      (this.projectId === otherState.projectId || !otherState.projectId)
    );
  }

  areWorkspaceLayersEqualTo(mapLayers: MapLayer[]): boolean {
    return (this.layers as WorkspaceLayer[]).every(
      (wsLayer, idx) => mapLayers[idx] && wsLayer.is(mapLayers[idx])
    );
  }
}
