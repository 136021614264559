import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'link-tabs',
  templateUrl: './link-tabs.template.html',
  styleUrls: ['./link-tabs.styles.scss']
})
export class LinkTabsComponent {
  @Input() tabs: { name: string; routePath: any[] };
  @Input() size: 'small' | 'large' = 'large';
}
