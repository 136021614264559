import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { environment } from 'environments/environment';
import { AuthService } from '../auth.service';
import { APP_BASE_HREF } from '@angular/common';

@Component({
  templateUrl: './logIn.template.html',
  styleUrls: ['./logIn.styles.scss']
})
export class LogInComponent implements OnInit {
  message: string;

  // TODO: temp hack
  userName: string;

  environment = environment;

  constructor(
    @Inject(APP_BASE_HREF) private _baseHref: string,
    private _router: Router,
    private _authService: AuthService,
    private _formBuilder: FormBuilder
  ) {}

  // FormBuilder eliminates using formcontrols for each input
  form = this._formBuilder.group({
    login: this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    }),
    rememberMe: this._formBuilder.group({
      checkbox: false
    })
  });

  onSubmit() {
    this._authService
      .logIn(this.form.value.login.email, this.form.value.login.password)
      .then(_user => {
        if (this.form.value.rememberMe.checkbox) {
          localStorage.setItem('username', this.form.value.login.email);
        } else {
          localStorage.removeItem('username');
        }

        const baseHref = this._baseHref;
        const deniedPath = localStorage.getItem('deniedPath');
        if (deniedPath && deniedPath.startsWith(baseHref)) {
          localStorage.removeItem('deniedPath');
          this._router.navigate(['/' + deniedPath.substring(baseHref.length)]);
        } else if (deniedPath) {
          localStorage.removeItem('deniedPath');
          this._router.navigate([deniedPath]);
        } else {
          this._router.navigate(['/']);
        }
      })
      .catch(_error => {
        if (_error.status === 403) {
          this.message =
            'Too many failed login attempts, your account has been locked';
        } else {
          this.message = 'Invalid Credentials';
        }
      });
  }

  ngOnInit() {
    this.userName = localStorage.getItem('username');

    if (this.userName !== 'null') {
      this.form.setValue({
        login: {
          email: this.userName,
          password: ''
        },
        rememberMe: {
          checkbox: true
        }
      });
    }
  }
}
