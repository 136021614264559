import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UtilsModule } from 'projects/utils/src/public_api';
import { MyDatePickerModule } from 'mydatepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { HighlightModule, HighlightOptions } from 'ngx-highlightjs';
import { ColorPickerModule } from 'ngx-color-picker';
import { TextMaskModule } from 'angular2-text-mask';

// Buttons
import { AerialButtonComponent } from './aerial-button/aerial-button.component';
import { IconButtonComponent } from './aerial-button/icon-button/icon-button.component';
import { TexticonButtonComponent } from './aerial-button/texticon-button/texticon-button.component';
import { DisclosureButtonComponent } from './aerial-button/disclosure-button/disclosure-button.component';
import { SubmitButtonComponent } from './aerial-button/submit-button/submit-button.component';
import { CancelButtonComponent } from './aerial-button/cancel-button/cancel-button.component';
import { LogoButtonComponent } from './aerial-button/logo-button/logo-button.component';
import { LoaderButtonComponent } from './aerial-button/loader-button/loader-button.component';
import { ContextButtonComponent } from './aerial-button/context-button/context-button.component';

import { AerialCodeComponent } from './aerial-code/aerial-code.component';
import { AerialHeroComponent } from './aerial-hero/aerial-hero.component';

// Badges
import { ProductTypeBadgeComponent } from './badges/product-type-badge/product-type-badge.component';
import { StatusBadgeComponent } from './badges/status-badge/status-badge.component';
import { TagBadgeComponent } from './badges/tag-badge/tag-badge.component';

// Combo Elements
import { ComboBadgeComponent } from './combo-elements/combo-badge/combo-badge.component';
import { ComboButtonComponent } from './combo-elements/combo-button/combo-button.component';
import { ComboLinkComponent } from './combo-elements/combo-link/combo-link.component';
import { DisclosureLinkComponent } from './combo-elements/combo-link/disclosure-link.component';

// Dialogs
import { DialogService } from './dialogs/dialog.service';
import { DialogHostDirective } from './dialogs/dialog-host.directive';
import { DialogOutletComponent } from './dialogs/dialog-outlet.component';
import { DialogComponent } from './dialogs/dialog.component';
import { AlertDialogComponent } from './dialogs/alert-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog.component';

import { SearchBoxComponent } from './search-box/search-box.component';
import { FilterBoxComponent } from './filter-box/filter-box.component';
import { FilterService } from './filter-box/filter-box.service';

// Form Stuff
import { AerialCheckboxComponent } from './faux-elements/aerial-checkbox.component';
import { AerialRadioButtonComponent } from './faux-elements/aerial-radio-button.component';
import { NumberPickerComponent } from './faux-elements/number-picker/number-picker.component';
import { AddressBlockComponent } from './form-components/addressBlock/addressBlock.component'; // deprecated
import { AddressGroupComponent } from './form-components/address-group/address-group.component';
import { ContactBlockComponent } from './form-components/contactBlock/contactBlock.component'; // deprecated
import { ContactGroupComponent } from './form-components/contact-group/contact-group.component';
import { MultiCheckboxComponent } from './form-components/multi-checkbox/multi-checkbox.component';
import { PasswordChecklistComponent } from './form-components/password/checklist/passwordChecklist.component';
import { CreatePasswordComponent } from './form-components/password/createPassword/createPassword.component';
import { DatePickerComponent } from './form-components/date-picker/date-picker.component';
import { DatePickerDirective } from './form-components/date-picker/date-picker.directive';
import { DatePickerControlComponent } from './form-components/date-picker-control/date-picker-control.component';
import { CheckboxControlComponent } from './form-components/checkbox-control/checkbox-control.component';
import { RadioControlComponent } from './form-components/radio-control/radio-control.component';
import { InputControlComponent } from './form-components/input-control/input-control.component';
import { AerialInputDirective } from './form-components/input-control/aerial-input.directive';
import { StateSelectComponent } from './form-components/state-select/state-select.component';
import { CountrySelectComponent } from './form-components/country-select/country-select.component';
import { PasswordControlComponent } from './form-components/password-control/password-control.component';
import { PasswordGroupComponent } from './form-components/password-group/password-group.component';
import { PasswordRequirementsComponent } from './form-components/password-requirements/password-requirements.component';
import { UserInfoGroupComponent } from './form-components/user-info-group/user-info-group.component';
import { NumberSliderComponent } from './form-components/number-slider/number-slider.component';
import { RadioControlGroupComponent } from './form-components/radio-control-group/radio-control-group.component';
import { LoginGroupComponent } from './form-components/login-group/login-group.component';
import { CopyInputComponent } from './form-components/copy-input/copy-input.component';

import { FauxMultiSelectComponent } from './multiselect/fauxMultiSelect.component';
import { FauxMultiSelectOptionComponent } from './multiselect/fauxMultiSelectOption.component';

// Modals
import { ModalOverlayComponent } from './modals/modal-overlay.component';
import { ModalService } from './modals/modal.service';

// Toasts
import { ToasterOutletComponent } from './toaster/toaster-outlet.component';
import { ToasterService } from './toaster/toaster.service';
import { ToastyComponent } from './toaster/toasty.component';

// Tooltips
import { TooltipDirective } from './tooltips/tooltip.directive';
import { TooltipService } from './tooltips/tooltip.service';
import { TooltipOutletComponent } from './tooltips/tooltip-outlet.component';

// Tabs
import { TabComponent } from './tabs/tab/tab.component';
import { ClickTabsComponent } from './tabs/click-tabs/click-tabs.component';
import { LinkTabsComponent } from './tabs/link-tabs/link-tabs.component';

// App Header
import { AerialHeaderComponent } from './aerial-header/aerial-header.component';
import { AerialHeaderMenuComponent } from './aerial-header/aerial-header-menu/aerial-header-menu.component';
import { HelpMenuComponent } from './aerial-header/help-menu/help-menu.component';
import { HelpMenuContentComponent } from './aerial-header/help-menu-content/help-menu-content.component';
import { UserMenuComponent } from './aerial-header/user-menu/user-menu.component';
import { UserMenuContentComponent } from './aerial-header/user-menu-content/user-menu-content.component';
// import { HamberderComponent } from './app-header/hamberder/hamberder.component';
// import { NotificationsMenuComponent } from './app-header/notifications-menu/notifications-menu.component';
import { UsersnapDirective } from './aerial-header/usersnap.directive';

import { LoaderGuyComponent } from './loader-guy/loader-guy.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SortersComponent } from './sort/sorters.component';
import { SelectSorterComponent } from './select-sorter/select-sorter.component';
import { ColorSwatchComponent } from './color-swatch/color-swatch.component';
import { AccordionHeadingComponent } from './accordion-heading/accordion-heading.component';
import { ViewSwitcherComponent } from './view-switcher/view-switcher.component';
import { HeaderColumnComponent } from './header-column/header-column.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MapTogglerComponent } from './map-toggler/map-toggler.component';
import { SimpleSelectDropdownComponent } from './simple-select-dropdown/simple-select-dropdown.component';
import { SimpleSelectDropdownService } from './simple-select-dropdown/simple-select-dropdown.service';
import { NextPrevControlsComponent } from './next-prev-controls/next-prev-controls.component';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { AerialSwitchComponent } from './aerial-switch/aerial-switch.component';
import { AerialAccordionComponent } from './aerial-accordion/aerial-accordion.component';
import { FiltersListComponent } from './filters-list/filters-list.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { UserSelectComponent } from './form-components/user-select/user-select.component';
import { OverflowMenuComponent } from './overflow-menu/overflow-menu.component';

export const hljsOptions: HighlightOptions = {
  theme: 'github-gist',
  path: './assets/lib/hljs'
};
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    ClipboardModule,
    HighlightModule.forRoot(hljsOptions),
    ColorPickerModule,
    TextMaskModule,
    UtilsModule
  ],
  declarations: [
    DialogHostDirective,
    DialogOutletComponent,
    DialogComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ToasterOutletComponent,
    ToastyComponent,
    FilterBoxComponent,
    LoaderGuyComponent,
    FauxMultiSelectComponent,
    FauxMultiSelectOptionComponent,
    PaginationComponent,
    SortersComponent,
    AerialButtonComponent,
    IconButtonComponent,
    TexticonButtonComponent,
    ComboBadgeComponent,
    ComboButtonComponent,
    AddressBlockComponent,
    ContactBlockComponent,
    MultiCheckboxComponent,
    CreatePasswordComponent,
    PasswordChecklistComponent,
    TooltipDirective,
    TooltipOutletComponent,
    DatePickerComponent,
    NumberPickerComponent,
    AerialHeroComponent,
    SectionTitleComponent,
    AerialCodeComponent,
    StatusBadgeComponent,
    TagBadgeComponent,
    ProductTypeBadgeComponent,
    DisclosureButtonComponent,
    SubmitButtonComponent,
    CancelButtonComponent,
    SelectSorterComponent,
    AerialCheckboxComponent,
    AerialRadioButtonComponent,
    CheckboxControlComponent,
    RadioControlComponent,
    ColorSwatchComponent,
    InputControlComponent,
    AerialInputDirective,
    StateSelectComponent,
    CountrySelectComponent,
    UserSelectComponent,
    PasswordControlComponent,
    LogoButtonComponent,
    AccordionHeadingComponent,
    ViewSwitcherComponent,
    SearchBoxComponent,
    HeaderColumnComponent,
    ProgressBarComponent,
    ModalOverlayComponent,
    MapTogglerComponent,
    AddressGroupComponent,
    ContactGroupComponent,
    UserInfoGroupComponent,
    PasswordGroupComponent,
    PasswordRequirementsComponent,
    NumberSliderComponent,
    RadioControlGroupComponent,
    DatePickerDirective,
    LoaderButtonComponent,
    SimpleSelectDropdownComponent,
    NextPrevControlsComponent,
    TabComponent,
    ClickTabsComponent,
    LinkTabsComponent,
    InlineEditComponent,
    DisclosureLinkComponent,
    ComboLinkComponent,
    ContextButtonComponent,
    AerialSwitchComponent,
    AerialAccordionComponent,
    AerialHeaderComponent,
    AerialHeaderMenuComponent,
    // HamberderComponent,
    // NotificationsMenuComponent,
    LoginGroupComponent,
    HelpMenuComponent,
    UserMenuComponent,
    TagListComponent,
    FiltersListComponent,
    DateFilterComponent,
    OverflowMenuComponent,
    UserMenuContentComponent,
    UsersnapDirective,
    CopyInputComponent,
    HelpMenuContentComponent,
    DatePickerControlComponent
  ],
  exports: [
    MyDatePickerModule,
    DialogHostDirective,
    DialogOutletComponent,
    DialogComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ToasterOutletComponent,
    ToastyComponent,
    FilterBoxComponent,
    LoaderGuyComponent,
    FauxMultiSelectComponent,
    FauxMultiSelectOptionComponent,
    PaginationComponent,
    SortersComponent,
    AerialButtonComponent,
    IconButtonComponent,
    TexticonButtonComponent,
    ComboBadgeComponent,
    ComboButtonComponent,
    AddressBlockComponent,
    ContactBlockComponent,
    MultiCheckboxComponent,
    CreatePasswordComponent,
    PasswordChecklistComponent,
    TooltipDirective,
    TooltipOutletComponent,
    DatePickerComponent,
    NumberPickerComponent,
    AerialHeroComponent,
    SectionTitleComponent,
    AerialCodeComponent,
    StatusBadgeComponent,
    TagBadgeComponent,
    ProductTypeBadgeComponent,
    DisclosureButtonComponent,
    SubmitButtonComponent,
    CancelButtonComponent,
    SelectSorterComponent,
    AerialCheckboxComponent,
    AerialRadioButtonComponent,
    CheckboxControlComponent,
    RadioControlComponent,
    ColorSwatchComponent,
    InputControlComponent,
    AerialInputDirective,
    StateSelectComponent,
    CountrySelectComponent,
    UserSelectComponent,
    PasswordControlComponent,
    LogoButtonComponent,
    AccordionHeadingComponent,
    ViewSwitcherComponent,
    SearchBoxComponent,
    HeaderColumnComponent,
    ProgressBarComponent,
    ModalOverlayComponent,
    MapTogglerComponent,
    AddressGroupComponent,
    ContactGroupComponent,
    UserInfoGroupComponent,
    PasswordGroupComponent,
    PasswordRequirementsComponent,
    NumberSliderComponent,
    RadioControlGroupComponent,
    DatePickerDirective,
    LoaderButtonComponent,
    SimpleSelectDropdownComponent,
    NextPrevControlsComponent,
    TabComponent,
    ClickTabsComponent,
    LinkTabsComponent,
    InlineEditComponent,
    DisclosureLinkComponent,
    ComboLinkComponent,
    ContextButtonComponent,
    AerialSwitchComponent,
    AerialAccordionComponent,
    AerialHeaderComponent,
    AerialHeaderMenuComponent,
    // HamberderComponent,
    // NotificationsMenuComponent,
    LoginGroupComponent,
    HelpMenuComponent,
    UserMenuComponent,
    TagListComponent,
    FiltersListComponent,
    DateFilterComponent,
    OverflowMenuComponent,
    UserMenuContentComponent,
    UsersnapDirective,
    CopyInputComponent,
    HelpMenuContentComponent,
    DatePickerControlComponent
  ],
  entryComponents: [AlertDialogComponent, ConfirmDialogComponent]
})
export class UIModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UIModule,
      providers: [
        TitleCasePipe,
        DialogService,
        ToasterService,
        FilterService,
        TooltipService,
        ModalService,
        SimpleSelectDropdownService
      ]
    };
  }
}
