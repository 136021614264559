import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import uniq from 'lodash/uniq';
import { AerialAccordion } from '../aerial-accordion/aerial-accordion.model';

@Component({
  selector: 'filters-list',
  templateUrl: './filters-list.template.html',
  styleUrls: ['./filters-list.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersListComponent extends AerialAccordion
  implements OnChanges, OnInit, OnDestroy {
  // TODO: implement ControlValueAccessor!
  @Input()
  groupName: string;

  @Input() // should there be an input where a user can filter the filters?
  isFilterable = true;

  @Input()
  displayRawText = false;

  @Input()
  allFilters: string[];

  @Input()
  selectedFilters: string[];

  @Output()
  filtersChange = new EventEmitter<string[]>();

  @Output()
  clearFilters = new EventEmitter<void>();

  get checkedFilters(): string[] {
    return Object.keys(this.filtersGrp.value).filter(
      filterName => this.filtersGrp.value[filterName] === true
    );
  }

  protected filterCtrl = new FormControl('');
  protected filtersGrp = new FormGroup({});
  protected filteredFilters: string[];

  private _clearingFilters = false;
  private _sub = new Subscription();

  protected onClear(): void {
    this.clearFilters.emit();
    this._clearingFilters = true;
    Object.values(this.filtersGrp.controls).forEach(ctrl => {
      if (ctrl.value) {
        ctrl.setValue(false, { onlySelf: true, emitEvent: false });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allFilters && changes.allFilters.firstChange) {
      this.allFilters = uniq(this.allFilters);
      this.filteredFilters = this.allFilters;
    }
    if (changes.allFilters || changes.selectedFilters) {
      this.filteredFilters.forEach(filterName => {
        const ctrl = this.filtersGrp.controls[filterName] as FormControl,
          checked = this.selectedFilters.includes(filterName);
        if (!ctrl) {
          this.filtersGrp.registerControl(filterName, new FormControl(checked));
        } else if (ctrl.value !== checked) {
          ctrl.setValue(checked, { onlySelf: true, emitEvent: false });
        }
      });
    }
  }

  ngOnInit() {
    const filtersGrpSub = this.filtersGrp.valueChanges.subscribe(val => {
      if (!this._clearingFilters) {
        this.filtersChange.emit(this.checkedFilters);
      } else if (this.checkedFilters.length === 0) {
        this._clearingFilters = false;
        this.filtersChange.emit([]);
      }
    });
    const filterCtrlSub = this.filterCtrl.valueChanges.subscribe(val => {
      if (val) {
        this.filteredFilters = this.allFilters.filter(filterName =>
          filterName.toLowerCase().includes(val.toLowerCase())
        );
      } else {
        this.filteredFilters = this.allFilters;
      }
    });
    this._sub.add(filtersGrpSub).add(filterCtrlSub);
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
