import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'create-password',
  template: `
    <div [formGroup]="parent">
      <div class="form-group">
        <label for="inputPassword">New Password</label>
        <input type="password" id="inputPassword"
          [class.invalid]="parent.get('pwd').dirty && parent.get('pwd').invalid"
          placeholder="Password" formControlName="pwd">
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password</label>
        <input type="password" id="confirmPassword"
          [class.invalid]="parent.get('confirmPwd').dirty && parent.get('confirmPwd').invalid"
          placeholder="Password" formControlName="confirmPwd">
      </div>
    </div>
  `
})
export class CreatePasswordComponent implements OnInit {
  @Input() parent: FormGroup;
  constructor() {}

  ngOnInit() {}
}
