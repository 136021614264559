import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Dialog, AlertDialog } from './dialog.interface';

@Component({
  template: `
    <div class="dialog-body" [innerHTML]="body"></div>
    <div class="dialog-actions btn-group align-right">
      <!-- prettier-ignore -->
      <aerial-button (click)="acknowledge()">{{ okBtnText }}</aerial-button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertDialogComponent implements Dialog, AlertDialog {
  body: string;
  okBtnText: string;
  successCallback: Function;
  close: Function;

  acknowledge(): void {
    this.successCallback();
    this.close();
  }
}
