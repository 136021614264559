import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// DEPRECATED! USE contact-group INSTEAD!
@Component({
  selector: 'contact-block',
  template: `
    <div [formGroup]="parent">
      <div class="form__input-group" formGroupName="contact">
        <div class="multiple-fields with-label">
          <div class="multiple-fields-group">
            <div>
              <label>Email</label>
              <input type="email" formControlName="email" />
            </div>
            <label>Home Phone</label>
            <input type="text" formControlName="homePhone" />
          </div>
          <div class="multiple-fields-group">
            <div>
              <label>Work Phone</label>
              <input type="text" formControlName="workPhone" />
            </div>
            <label>Mobile Phone</label>
            <input type="text" formControlName="mobilePhone" />
          </div>
        </div>
      </div>
    </div>
  `
})
export class ContactBlockComponent {
  @Input() parent: FormGroup;
}
