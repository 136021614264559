import { Injectable } from '@angular/core';
import {
  DataProduct,
  DataProductService,
  BrokerService
} from 'projects/api/src/public_api';

import { MapFeaturesService, VectorStyleDef } from './mapFeatures.service';

import { MapLayer } from './mapLayer.model';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WMS_TILEGRID_RESOLUTIONS } from './wmsTilegridResolutions';

import max from 'lodash/max';
import map from 'lodash/map';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import { AuthService } from 'projects/auth/src/public_api';
import { environment } from 'environments/environment';

import { Collection, ImageTile, Image } from 'ol';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import { getCenter } from 'ol/extent';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { Extent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { StyleFunction } from 'ol/style/Style';
import Cluster from 'ol/source/Cluster';
import Projection from 'ol/proj/Projection';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import VectorTile from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import EsriJSON from 'ol/format/EsriJSON';
import { tile } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import TileWMS from 'ol/source/TileWMS';
import TileGrid from 'ol/tilegrid/TileGrid';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import TileState from 'ol/TileState';
import { reject } from 'core-js/fn/promise';

export enum BasemapOptions {
  OneAtlas = 'ONE_ATLAS',
  OneAtlasMetaData = 'ONE_ATLAS_WITH_METADATA',
  MapboxVector = 'STREETS',
  MapboxSatellite = 'SATELLITE_STREETS',
  AlternateBasemap = 'ALTERNATE_BASEMAP'
}

/**
 * return true if force-cache should be enabled for getCapabilities that route
 */
function _forceGetCapsCache(url: string) {
  return !(url.includes('/tilerv2/s2/') || url.includes('/authored/'));
}

/**
 * return true if force-cache should be enabled for tile requests for that route
 */
function _forceTileCache(url: string) {
  return url.includes('/oneatlasv2/') ||
    url.includes('/wmtsv2/') ||
    url.includes('/nwp/') ||
    url.includes('/ned/') ||
    url.includes('/srtm/') ||
    url.includes('/gebco/') ||
    url.includes('/etopo/') ||
    url.includes('/livinglibrary/') ||
    url.includes('/tilerv2/') ||
    url.includes('/oneatlas/') ||
    url.includes('/mapbox/');
}

/**
 * return true if the route probably only supports jwt bearer tokens in header
 */
function _jwtOnlyRoute(url: string) {
  return (
    url.includes(environment.DISCOVERY_API_ROOT)
  );
}

/**
 * return true if provided url matches a pattern: assets*.airbusaerial.com
 * and is not a route that requires accessToken*/
function _needsBrokerApiKey(url: string) {
  return (
    url.includes('assets') &&
    url.includes('.airbusaerial.com') &&
    url.indexOf('assets') < url.indexOf('airbusaerial.com') &&
    !_jwtOnlyRoute(url)
  ) || (
    url.includes('/broker/')
  );
}

/**
 * The interface for dealing with map layers.
 *
 * There are 5 default layers; Three for sat basemap imagery, and two layers
 * to render ol.Features. One clusters the features, and one just renders the features directly.
 *
 * This is possibly too big and might need to be broken into more components.
 */
@Injectable({
  providedIn: 'root'
})
export class MapLayersService {
  private _initialized: boolean;
  private _noOneAtlas: boolean;
  private _mapboxSatLayer =
    `${environment['BROKER_ROOT']}/mapbox/satellite-streets-v10/{z}/{x}/{y}`;
  private _mapboxVectorLayer =
    `${environment['BROKER_ROOT']}/mapbox/streets-v10/{z}/{x}/{y}`;
  private _mapboxStreetsLayer =
    `${environment['BROKER_ROOT']}/mapbox/custom-streets-overlays/{z}/{x}/{y}`;
  private _alternateBaseMapURL = environment.ALTERNATE_BASEMAP;

  /**
   * Internal collection of {@link MapLayer}
   */
  private _layers: Collection<MapLayer>;

  /**
   * a layer for arbitrary non-clustered features.
   */
  private _annotationsLayer: Layer;

  /**
   * the layer for clustered features. (Assets and DataProducts)
   */
  private _featuresLayer: Layer;

  /**
   * mapbox streets layer
   */
  private _streetsLayer: Layer;

  /**
   * the One Atlas sat baseemap.
   */
  private _oneAtlasLayer: Layer;

  /**
   * the metadata for One Atlas sat images.
   */
  private _oneAtlasMetaLayer: Layer;

  /**
   * the alternate basemap layer
   */
  private _alternateBaseLayer: Layer;


  /**
   * the alternate basemap overlay layer
   */
  private _alternateOverlayLayer: Layer;

  /**
   * the mapbox sat base layer
   */
  private _baseSatLayer: Layer;

  /**
   * the mapbox sat base layer
   */
  private _baseVectorLayer: Layer;

  /**
   * Subject for listening for changes to the `_layers` collection. Use by {@link MapService}.
   */
  onLayerChange: BehaviorSubject<MapLayer[]>;

  // TEMP until we make it observable
  public currentBasemap: BasemapOptions;

  /**
   * A tile load function that smartly adds authentication,
   * smartly forces the browser to cache the tiles, and
   * handles errors.
   */
  private tileLoadWithAuth = async (tile: ImageTile, src: string) => {
    let fetchTileHeaders = {};
    if (_needsBrokerApiKey(src)) {
      const newUrl = new URL(src);
      // key expires so need to make sure we have unexpired key
      const newBrokerApiKey = await this._brokerService.getApiKey().toPromise();
      newUrl.searchParams.set('apiKey', newBrokerApiKey);
      src = newUrl.toString();
    } else if (_jwtOnlyRoute(src)) {
      // key expires so need to make sure we have unexpired key
      const brokerTileJwtToken = await this._authService.jwtTokenGetter();
      fetchTileHeaders = { Authorization: 'Bearer ' + brokerTileJwtToken };
    }
    fetch(src, {
      method: 'GET',
      headers: fetchTileHeaders ? fetchTileHeaders : undefined,
      cache: _forceTileCache(src) ? 'force-cache' : 'default'
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.blob();
    })
    .then(blob => {
      const img = tile.getImage() as HTMLImageElement;
      img.src = URL.createObjectURL(blob);
    })
    .catch(function (error) {
      tile.setState(TileState.ERROR);
      console.error(error);
    });
  }

  get streetsLayer() {
    return this._streetsLayer;
  }

  get featuresLayer() {
    return this._featuresLayer;
  }

  get annotationsLayer() {
    return this._annotationsLayer;
  }

  get featuresLayerVisible(): boolean {
    return this._featuresLayer.getVisible();
  }

  get annotationsLayerVisible(): boolean {
    return this._annotationsLayer.getVisible();
  }

  get streetsLayerVisible(): boolean {
    return this._streetsLayer.getVisible();
  }

  /**
   * returns all basemap layers
   */
  get baseLayers(): Layer[] {
    if (this._alternateBaseMapURL !== '') {
      if (this.alternateOverlayURL() !== '') {
        return [this._alternateOverlayLayer, this._alternateBaseLayer];
      }
      return [this._alternateBaseLayer]
    }

    let layers = [this._baseVectorLayer, this._baseSatLayer];

    if (!this._noOneAtlas) {
      layers = layers.concat([this._oneAtlasLayer, this._oneAtlasMetaLayer]);
    }

    return layers;
  }

  /**
   * Returns all workspace layers
   */
  get layers(): MapLayer[] {
    return this._layers.getArray();
  }

  /**
   * Returns the Overlay URL string if it exist in the environment
   */
  alternateOverlayURL(): string {
    let alternateOverlayURL = ''
    try {
      alternateOverlayURL = environment['ALTERNATE_OVERLAY'];
    } catch (error) {
      reject(error)
    }
    return alternateOverlayURL
  }

  constructor(
    private _featuresService: MapFeaturesService,
    private _brokerService: BrokerService,
    private _authService: AuthService
  ) {
    this._layers = new Collection<MapLayer>();

    this.onLayerChange = new BehaviorSubject<MapLayer[]>([]);

    const handler = () => this._layerChangeEmit();

    this._layers.on('add', handler);
    this._layers.on('remove', handler);
  }

  /**
   * Initializes the default layers
   * Called from {@link MapService}
   * Takes the [MapFeaturesService.featuresSource]{@link MapFeaturesService#featuresSource}
   * and puts it into an ol cluster source for feature clustering.
   * See [ol.source.Cluster]{@link https://openlayers.org/en/latest/apidoc/ol.source.Cluster.html}
   *
   * @param disableOneAtlas pass true to not load One Atlas layers
   */
  init(disableOneAtlas?: boolean): Promise<boolean> {
    if (this._initialized) {
      return Promise.resolve(true);
    }

    if (this._alternateBaseMapURL !== '') {
      disableOneAtlas = true;
    }

    // The number of zoom levels to preload
    // of the area in view. If on zoom level 20,
    // a value of 1 will preload zoom level 19,
    // a value of 2 will preload zoom level 18.
    // Higher takes more computer resoueces.
    // 0 is no preload. Infinity is full preload
    const basemapPreload = 3;
    const mapboxPreload = 0; //disabling for mapbox to avoid excess costs

    return new Promise<boolean>((resolve) => {
      this._initialized = true;

      const alternateBaseLayer = new TileLayer({
        preload: basemapPreload,
        source: new XYZ({
          url: this._alternateBaseMapURL,
          tileLoadFunction: this.tileLoadWithAuth
          // crossOrigin: 'anonymous'
        }),
        zIndex: -3
      } as any);

      const alternateOverlayLayer = new TileLayer({
        preload: basemapPreload,
        source: new XYZ({
          url: this.alternateOverlayURL(),
          tileLoadFunction: this.tileLoadWithAuth
          // crossOrigin: 'anonymous'
        }),
        zIndex: -2
      } as any);

      // create ol.layers
      const baseSatLayer = new TileLayer({
        preload: mapboxPreload,
        source: new XYZ({
          url: this._mapboxSatLayer,
          tileLoadFunction: this.tileLoadWithAuth
          // crossOrigin: 'anonymous'
        }),
        zIndex: -2
      } as any);

      const baseVectorLayer = new TileLayer({
        preload: mapboxPreload,
        source: new XYZ({
          url: this._mapboxVectorLayer,
          tileLoadFunction: this.tileLoadWithAuth
          // crossOrigin: 'anonymous'
        }),
        zIndex: -1
      } as any);

      const streetsOlLayer = new TileLayer({
        preload: mapboxPreload,
        source: new XYZ({
          url: this._mapboxStreetsLayer,
          tileLoadFunction: this.tileLoadWithAuth
          // crossOrigin: 'anonymous'
        }),
        zIndex: 9999
      } as any);

      const clusterSource = new Cluster({
        distance: 50,
        source: this._featuresService.featuresSource,
        geometryFunction: feature => {
          if (feature.getGeometry().getType() === 'Polygon') {
            return new Point(getCenter(feature.getGeometry().getExtent()));
          } else {
            return feature.getGeometry() as Point;
          }
        }
      });

      const featuresOlLayer = new VectorLayer({
        source: clusterSource,
        style: MapFeaturesService.clusteredFeatureStyleFunction,
        zIndex: 9997
      } as any);
      // this attr is set so the hover style function find this layer
      featuresOlLayer.set('clusterGuy', 'true');

      // TEMP
      this._featuresService.annotationsLayer.setZIndex(9998);

      // create custom MapLayers
      this._alternateBaseLayer = alternateBaseLayer;
      this._alternateOverlayLayer = alternateOverlayLayer
      this._baseSatLayer = baseSatLayer;
      this._baseVectorLayer = baseVectorLayer;
      this._streetsLayer = streetsOlLayer;
      this._featuresLayer = featuresOlLayer;
      this._annotationsLayer = this._featuresService.annotationsLayer;

      // default streets layer to be off
      this._streetsLayer.setVisible(false);

      // TODO: this maybe shouldnt be here
      if (!disableOneAtlas) {
        const promises: Promise<any>[] = [];

        promises.push(
          this._getWMTSLayer({
            brokerUrl: `${environment['BROKER_ROOT']}/oneatlasv2/metadata/wmts`,
            name: 'Satellite Metadata'
          } as DataProduct,
          basemapPreload).then(([newLayer]) => {
            // TODO the MapLayer here is to support old stuff
            newLayer.olLayer.setZIndex(0);
            this._oneAtlasMetaLayer = newLayer.olLayer;
          })
        );

        promises.push(
          this._getWMTSLayer({
            brokerUrl: `${environment['BROKER_ROOT']}/oneatlasv2/wmts`,
            name: 'Satellite Imagery'
          } as DataProduct,
          basemapPreload).then(([newLayer]) => {
            // TODO the MapLayer here is to support old stuff
            newLayer.olLayer.setZIndex(-1);
            this._oneAtlasLayer = newLayer.olLayer;
          })
        );

        Promise.all(promises).then(
          () => {
            this._noOneAtlas = false;
            this.setBasemap(BasemapOptions.OneAtlas);
            resolve(true);
          },
          () => {
            // TODO: how to properly hand errors
            this._noOneAtlas = true;
            this.setBasemap(BasemapOptions.MapboxSatellite);
            resolve(true);
          }
        );
      } else {
        this._noOneAtlas = true;
        this._alternateBaseMapURL !== ''
          ? this.setBasemap(BasemapOptions.AlternateBasemap)
          : this.setBasemap(BasemapOptions.MapboxSatellite);
        return resolve(true);
      }
    });
  }

  /**
   * Method to hide or show the features layer
   *
   * @param on boolean to set the feature layer to be on or off
   */
  toggleFeaturesLayer(on?: boolean) {
    let isOn: boolean;
    switch (on) {
      case true:
        isOn = true;
        break;
      case false:
        isOn = false;
        break;
      default:
        isOn = !this.featuresLayerVisible;
    }
    this._featuresLayer.setVisible(isOn);
  }

  /**
   * Method to hide or show the annotations layer
   *
   * @param on boolean to set the annotations layer to be on or off
   */
  toggleAnnotationsLayer(on?: boolean) {
    let isOn: boolean;
    switch (on) {
      case true:
        isOn = true;
        break;
      case false:
        isOn = false;
        break;
      default:
        isOn = !this.annotationsLayerVisible;
    }
    this._annotationsLayer.setVisible(isOn);
  }

  /**
   * Method to hide or show the streets overlay layer
   *
   * @param on boolean to set the streets layer to be on or off
   */
  toggleStreetsLayer(on?: boolean) {
    let isOn: boolean;
    switch (on) {
      case true:
        isOn = true;
        break;
      case false:
        isOn = false;
        break;
      default:
        isOn = !this.streetsLayerVisible;
    }
    this._streetsLayer.setVisible(isOn);
  }

  /**
   * setBaseLayer - select the base "layer" to show
   * This is just an interface to better manage the 4 base _layers
   * The mapboxSat layers will technically always show, its jarring otherwise
   *
   * @param basemap basemap option to show
   */
  setBasemap(basemap: BasemapOptions) {
    this.currentBasemap = basemap;
    switch (basemap) {
      case BasemapOptions.OneAtlas:
        this._baseVectorLayer.setVisible(false);
        this._oneAtlasMetaLayer.setVisible(false);
        this._baseSatLayer.setVisible(false);
        this._oneAtlasLayer.setVisible(true);
        this._alternateBaseLayer.setVisible(false);
        break;
      case BasemapOptions.OneAtlasMetaData:
        this._baseVectorLayer.setVisible(false);
        this._baseSatLayer.setVisible(false);
        this._oneAtlasLayer.setVisible(true);
        this._oneAtlasMetaLayer.setVisible(true);
        this._alternateBaseLayer.setVisible(false);
        break;
      case BasemapOptions.MapboxVector:
        if (!this._noOneAtlas) {
          this._oneAtlasLayer.setVisible(false);
          this._oneAtlasMetaLayer.setVisible(false);
        }
        this._baseSatLayer.setVisible(false);
        this._baseVectorLayer.setVisible(true);
        this._alternateBaseLayer.setVisible(false);
        break;
      case BasemapOptions.AlternateBasemap:
        if (!this._noOneAtlas) {
          this._oneAtlasLayer.setVisible(false);
          this._oneAtlasMetaLayer.setVisible(false);
        }
        this._baseVectorLayer.setVisible(false);
        this._baseSatLayer.setVisible(false);
        this._alternateBaseLayer.setVisible(true);
        break;
      case BasemapOptions.MapboxSatellite:
      default:
        if (!this._noOneAtlas) {
          this._oneAtlasLayer.setVisible(false);
          this._oneAtlasMetaLayer.setVisible(false);
        }
        this._baseVectorLayer.setVisible(false);
        this._baseSatLayer.setVisible(true);
        this._alternateBaseLayer.setVisible(false);
        break;
    }
  }

  /**
   * Method to get a basemap layer
   *
   * @param basemap the enum identifier for the basemap to get
   */
  getBasemapLayer(basemap: BasemapOptions): Layer {
    const basemapmap = {
      [BasemapOptions.OneAtlas]: this._oneAtlasLayer,
      [BasemapOptions.OneAtlasMetaData]: this._oneAtlasMetaLayer,
      [BasemapOptions.MapboxVector]: this._baseVectorLayer,
      [BasemapOptions.MapboxSatellite]: this._baseSatLayer,
      [BasemapOptions.AlternateBasemap]: this._alternateBaseLayer
    };

    return basemapmap[basemap];
  }

  /**
   * Takes a DataProduct and routes it to the proper getLayer function based on the
   * dataProduct's dataType.
   * @param dataProduct the DataProduct to get layers for
   */
  getMapLayers(dataProduct: DataProduct): Promise<MapLayer[]> {
    let extent: Extent;
    if (dataProduct.extent && dataProduct.dataType !== 'STATIC') {
      const format = new GeoJSON();
      const geometry = format.readGeometry(dataProduct.extent, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      extent = geometry ? geometry.getExtent() : null;
    }

    switch (dataProduct.dataType) {
      case 'STATIC':
        return this._getStaticLayer(dataProduct, extent);
      case 'FEATURES':
        return this._getFeaturesLayer(dataProduct, extent);
      case 'GEOJSON':
        return this._getGeoJSONLayer(dataProduct, extent);
      case 'ESRIJSON':
        return this._getEsriJSONLayer(dataProduct);
      case 'MVT':
        return this._getMVTLayer(dataProduct, extent);
      case 'XYZ':
        return this._getXYZLayer(dataProduct, extent);
      case 'WMTS':
        return dataProduct.brokerUrl.includes('oneatlas') &&
          !dataProduct.brokerUrl.includes('oneatlasv2')
          ? this._getAirbusWMTSLayer(dataProduct)
          : this._getWMTSLayer(dataProduct);
      case 'WMS':
      default:
        return this._getWMSLayer(dataProduct);
    }
  }

  async _getStaticLayer(dataProduct: DataProduct, extent: Extent) {
    const styleDef = dataProduct.style,
      url = dataProduct.brokerUrl;
    let styleFn: StyleFunction = MapFeaturesService.MVTLayerStyle;
    if (styleDef) {
      styleFn = MapFeaturesService.GenerateMVTStyleFunction(styleDef);
    }

    const imageLoadWithHeader = async (image: Image, src: string) => {
      let fetchTileHeaders = {};
      if (_needsBrokerApiKey(src)) {
        const newUrl = new URL(src);
        // key expires so need to make sure we have unexpired key
        const newBrokerApiKey = await this._brokerService.getApiKey().toPromise();
        newUrl.searchParams.set('apiKey', newBrokerApiKey);
        src = newUrl.toString();
      } else if (_jwtOnlyRoute(src)) {
        // key expires so need to make sure we have unexpired key
        const brokerTileJwtToken = await this._authService.jwtTokenGetter();
        fetchTileHeaders = { Authorization: 'Bearer ' + brokerTileJwtToken };
      }
      fetch(src, {
        method: 'GET',
        headers: fetchTileHeaders ? fetchTileHeaders : undefined
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.blob();
      })
      .then(blob => {
        const img = image.getImage() as HTMLImageElement;
        img.src = URL.createObjectURL(blob);
      })
      .catch(function (error) {
        console.error(error);
      });
    };

    const dataProductExtent =
      Array.isArray((dataProduct as any).extent)
        ? (dataProduct as any).extent
        : new GeoJSON().readGeometry((dataProduct as any).extent).getExtent();

    const projection = new Projection({
      code: 'EPSG:4326',
      extent: dataProductExtent
    });
    return new Promise<MapLayer[]>(resolve => {
      const newLayer = new ImageLayer({
        source: new Static({
          url: url,
          projection: projection,
          imageExtent: dataProductExtent,
          imageLoadFunction: imageLoadWithHeader
        })
      });

      const newMapLayer = new MapLayer(
        newLayer,
        dataProduct,
        dataProductExtent
      );

      resolve([newMapLayer]);
    });
  }
  /**
   * Adds a MapLayer to the collection. Mostly used internally.
   * Sets a zIndex to be above all other layers
   *
   * @param layer MapLayer to add
   */
  addLayer(layer: MapLayer) {
    if (!layer.olLayer.getZIndex()) {
      // only set the zIndex if it isn't already set
      const maxZIndex = max(
        this._layers.getArray().map(highestlayer => highestlayer.olLayer.getZIndex())
      );
      const zIndex = maxZIndex ? maxZIndex + 1 : this._layers.getLength() + 1;
      layer.olLayer.setZIndex(zIndex);
    }
    this._layers.push(layer);
  }

  /**
   * Sets the zindex of the provided layer so that it is higher than all the
   * other layers in the collection
   * TODO: if the lowest zindex is not zero, all the zindexes could be
   *       adjusted down. But...seems that zindex can be a big number
   *
   * @param layer MapLayer to adjust
   */
  moveLayerToTop(layer: MapLayer) {
    const currentZIndex = layer.olLayer.getZIndex();
    const arrayOfZIndexes = this._layers
      .getArray()
      .map(layerItr => layerItr.olLayer.getZIndex());
    const maxZIndex = max(arrayOfZIndexes);
    const zIndex = maxZIndex ? maxZIndex + 1 : this._layers.getLength() + 1;

    if (maxZIndex === currentZIndex) {
      const numOccurences = arrayOfZIndexes.filter(
        index => index === currentZIndex
      ).length;
      if (numOccurences <= 1) {
        return;
      } // else more than one layer has the maxZIndex, need to bump up
    }
    layer.olLayer.setZIndex(zIndex);
  }

  /**
   * Hides all layers in this._layers (user layers)
   */
  hideAllUserLayers() {
    this._layers.getArray().forEach((layer: MapLayer) => {
      layer.visible = false;
    });
  }

  /**
   * Removes a map layer from the collection
   *
   * @param layer MapLayer to removed.
   */
  removeLayer(layer: MapLayer) {
    this._layers.remove(layer);
  }

  /**
   * emits the current collection of MapLayers to the ReplaySubject.
   * Used internally after manipulating to notify subscribers of the change.
   */
  private _layerChangeEmit() {
    this.onLayerChange.next(this._layers.getArray());
  }

  /**
   * Handles creating the ol.layer with a predefined collection of Features.
   * Wraps the ol.layer in the MapLayer class and returns that.
   * @param dataProduct the data product to load layers for
   * @param extent the bounding extent for the layer
   * @returns A promise that resolves with the MapLayers.
   */
  private async _getFeaturesLayer(
    dataProduct: DataProduct,
    extent: Extent
  ): Promise<MapLayer[]> {
    const features = (dataProduct as any).features;
    const parser = new GeoJSON();

    let styleFn: StyleFunction = MapFeaturesService.MVTLayerStyle;
    const styleDef = dataProduct.style;

    if (styleDef) {
      styleFn = MapFeaturesService.GenerateMVTStyleFunction(styleDef);
    }

    return new Promise<MapLayer[]>(resolve => {
      const newLayer = new VectorLayer({
        source: new VectorSource({
          features: parser.readFeatures(features, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          })
        }),
        renderOrder: null,
        style: styleFn
      });

      const newMapLayer = new MapLayer(newLayer, dataProduct, extent);

      resolve([newMapLayer]);
    });
  }

  /**
   * Handles creating the ol.layer for an GeoJSON file url.
   * Wraps the ol.layer in the MapLayer class and returns that.
   * @param dataProduct the data product to load layers for
   * @param extent the bounding extent for the layer
   * @returns A promise that resolves with the MapLayers.
   */
  private async _getGeoJSONLayer(
    dataProduct: DataProduct,
    extent: Extent
  ): Promise<MapLayer[]> {
    const url = dataProduct.brokerUrl;

    // NOTE: this is only being used for loading JSON from GCS signed url
    // let queryDelimiter = url.includes('?') ? '&' : '?';
    // let brokerApiKey = await this._brokerService.getApiKey().toPromise();
    // url += queryDelimiter + 'apiKey=' + brokerApiKey;
    let styleFn: StyleFunction = MapFeaturesService.MVTLayerStyle;
    const styleDef = dataProduct.style;

    if (styleDef) {
      styleFn = MapFeaturesService.GenerateMVTStyleFunction(styleDef);
    }

    return new Promise<MapLayer[]>(resolve => {
      const newLayer = new VectorLayer({
        source: new VectorSource({
          format: new GeoJSON(),
          url: url
        }),
        renderOrder: null,
        style: styleFn
      });

      const newMapLayer = new MapLayer(newLayer, dataProduct, extent);

      resolve([newMapLayer]);
    });
  }

  /**
   * Handles creating the ol.layer for an MVT type service url.
   * Wraps the ol.layer in the MapLayer class and returns that.
   * Styles are hard-coded here based on a `class` attribute on
   * the features that come from the service.
   * @param dataProduct the data product to load layers for
   * @param extent the bounding extent for the layer
   * @returns A promise that resolves with the MapLayers.
   */
  private async _getMVTLayer(
    dataProduct: DataProduct,
    extent: Extent
  ): Promise<MapLayer[]> {
    let url = dataProduct.brokerUrl;

    if (_needsBrokerApiKey(url)) {
      const brokerApiKey = await this._brokerService.getApiKey().toPromise();
      const queryDelimiter = url.includes('?') ? '&' : '?';
      url += queryDelimiter + 'apiKey=' + brokerApiKey;
    }

    let styleFn: StyleFunction = MapFeaturesService.MVTLayerStyle;
    const  styleDef = dataProduct.style,
      name = dataProduct.name;

    if (styleDef) {
      styleFn = MapFeaturesService.GenerateMVTStyleFunction(styleDef);
    }

    return new Promise<MapLayer[]>(resolve => {
      const newLayer = new VectorTileLayer({
        source: new VectorTile({
          format: new MVT(),
          projection: 'EPSG:3857',
          url: url
        }),
        renderOrder: null,
        renderMode:
          // https://github.com/openlayers/openlayers/blob/master/changelog/upgrade-notes.md#removal-of-the-vector-render-mode-for-vector-tile-layers
          dataProduct.productType === 'Geotagged' ? 'hybrid' : 'image',
        style: styleFn
      });

      const newMapLayer = new MapLayer(newLayer, dataProduct, extent);
      // newMapLayer.toggle();
      // this._layers.insertAt(this._layers.getLength() - 1, newMapLayer);

      resolve([newMapLayer]);
    });
  }

  /**
   * Handles creating the ol.layer for an XYZ type service url.
   * Wraps the ol.layer in the MapLayer class and returns that.
   *
   * @param dataProduct the dataProduct to load layers for
   * @param extent the bounding extent for the layer
   * @returns A promise that resolves with the MapLayers.
   */
  private async _getXYZLayer(
    dataProduct: DataProduct,
    extent: Extent
  ): Promise<MapLayer[]> {
    let url = dataProduct.brokerUrl;

    if (_needsBrokerApiKey(url)) {
      const brokerApiKey = await this._brokerService.getApiKey().toPromise();
      const queryDelimiter = url.includes('?') ? '&' : '?';
      url += queryDelimiter + 'apiKey=' + brokerApiKey;
    }

    return new Promise<MapLayer[]>(resolve => {
      const xyzSource = new XYZ({
        url: url,
        crossOrigin: 'anonymous',
        tileLoadFunction: this.tileLoadWithAuth
      });
      const newLayer = new TileLayer({
        extent: extent,
        source: xyzSource
      });
      if (url.includes('maps/manned')) {
        newLayer.setMaxResolution(0.298);
        newLayer.setMinResolution(0.009);
      }

      const newMapLayer = new MapLayer(newLayer, dataProduct, extent);
      // newMapLayer.toggle();
      // this._layers.insertAt(this._layers.getLength() - 1, newMapLayer);

      resolve([newMapLayer]);
    });
  }

  /**
   * Handles creating the ol.layer for an EsriJSON type service url.
   * Wraps the ol.layer in the MapLayer class and returns that.
   * openlayers won't load these by default, so this has to specificy
   * a custom `loader` function to figure out how to load image tiles.
   *
   * @param dataProduct the dataProduct to load layers for
   * @returns A promise that resolves with the MapLayers.
   */
  private _getEsriJSONLayer(dataProduct: DataProduct): Promise<MapLayer[]> {
    // TODO: this is pretty hardcoded for an ArcGIS mapserver. Not sure if it's agnostic at all
    // THis also assumes the service uses EPSG:3857
    const url = dataProduct.dataUrl;
    return new Promise<MapLayer[]>((resolve, reject) => {
      fetch(url + '?f=json')
        .then(resp => resp.json())
        .then(serviceJSON => {
          const esrijsonFormat = new EsriJSON();
          const extent: Extent = [
            serviceJSON.extent.xmin,
            serviceJSON.extent.ymin,
            serviceJSON.extent.xmax,
            serviceJSON.extent.ymax
          ];

          const vectorSource = new VectorSource({
            loader: (bbox, resolution, projection) => {
              const queryUrl =
                url +
                '/query/?f=json&' +
                'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
                encodeURIComponent(
                  '{"xmin":' +
                    bbox[0] +
                    ',"ymin":' +
                    bbox[1] +
                    ',"xmax":' +
                    bbox[2] +
                    ',"ymax":' +
                    bbox[3] +
                    ',"spatialReference":{"wkid":3857}}'
                ) +
                '&geometryType=esriGeometryEnvelope&inSR=3857&outFields=*' +
                '&outSR=3857';
              fetch(queryUrl)
                .then(resp => resp.json())
                .then((response: any) => {
                  const features = esrijsonFormat.readFeatures(response);
                  if (features.length > 0) {
                    vectorSource.addFeatures(features);
                  }
                });
            },
            strategy: tile(
              createXYZ({
                tileSize: 256
              })
            )
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              fill: new Fill({
                color: 'rgba(255, 0, 0, 255)'
              }),
              stroke: new Stroke({
                color: 'rgba(200, 255, 200, 255)',
                width: 3
              })
            })
          });

          const newMapLayer = new MapLayer(vectorLayer, dataProduct, extent);
          resolve([newMapLayer]);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Handles creating the ol.layer for an WMS type service url.
   * Wraps the ol.layer in the MapLayer class and returns that.
   *
   * @param dataProduct the dataProduct to load layers for
   * @returns A promise that resolves with the MapLayers.
   */
  private async _getWMSLayer(dataProduct: DataProduct): Promise<MapLayer[]> {
    const specificLayers = map(dataProduct.layers, 'name');
    let url = dataProduct.brokerUrl,
      queryDelimiter = url.includes('?') ? '&' : '?',
      fetchHeaders = {};

    if (_needsBrokerApiKey(url)) {
      const brokerApiKey = await this._brokerService.getApiKey().toPromise();
      url += queryDelimiter + 'apiKey=' + brokerApiKey;
      queryDelimiter = '&';
    } else if (_jwtOnlyRoute(url)) {
      const brokerJwtToken = await this._authService.jwtTokenGetter();
      fetchHeaders = { Authorization: 'Bearer ' + brokerJwtToken };
    }

    return new Promise<MapLayer[]>((resolve, reject) => {
      const mapLayers: MapLayer[] = [];

      fetch(
        `${url}${queryDelimiter}SERVICE=WMS&VERSION=1.1.1&REQUEST=GetCapabilities`,
        {
          method: 'GET',
          cache: _forceGetCapsCache(url) ? 'force-cache' : 'no-store',
          headers: fetchHeaders
        }
      )
        .then(response => response.text())
        .then(text => {
          const xmlDoc = new DOMParser().parseFromString(text, 'text/xml');

          const layergroups = Array.from(xmlDoc.getElementsByTagName('Layer'));

          layergroups.forEach((layergroup: any) => {
            // check if layer group is undefined
            if (!layergroup) { return; }

            const bbox = layergroup.getElementsByTagName('LatLonBoundingBox')[0];
            let extent: Extent;
            try {
              extent = concat(
                fromLonLat([
                  parseFloat(bbox.getAttribute('minx')),
                  parseFloat(bbox.getAttribute('miny'))
                ]),
                fromLonLat([
                  parseFloat(bbox.getAttribute('maxx')),
                  parseFloat(bbox.getAttribute('maxy'))
                ])
              ) as Extent;
            } catch (e) {
              console.log(
                'MapLayersService: Layer ' + url + ' has no LatLonBoundingBox'
              );
            }

            const layers = Array.from(layergroup.getElementsByTagName('Layer'));

            layers.forEach((layer: any) => {
              // check if layer is undefined
              if (!layer) { return; }

              const layerName = layer.getElementsByTagName('Name')[0].textContent;
              const layerTitle = layer.getElementsByTagName('Title')[0]
                .textContent;

              if (
                !isEmpty(specificLayers) &&
                !includes(specificLayers, layerName)
              ) { return; }

              let layerExtent: Extent;
              try {
                const layerBbox = layer.getElementsByTagName('LatLonBoundingBox')[0];
                layerExtent = concat(
                  fromLonLat([
                    parseFloat(layerBbox.getAttribute('minx')),
                    parseFloat(layerBbox.getAttribute('miny'))
                  ]),
                  fromLonLat([
                    parseFloat(layerBbox.getAttribute('maxx')),
                    parseFloat(layerBbox.getAttribute('maxy'))
                  ])
                ) as Extent;
              } catch (e) {
                console.log(
                  'MapLayersService: Layer ' +
                    name +
                    ' ' +
                    layerTitle +
                    ' has no LatLonBoundingBox'
                );
              }

              const wmsSource = new TileWMS({
                url: url,
                crossOrigin: 'anonymous',
                params: {
                  LAYERS: layerName,
                  VERSION: '1.1.1'
                },
                projection: new Projection({
                  code: 'EPSG:3857'
                }),
                tileGrid: new TileGrid({
                  extent: layerExtent ? layerExtent : extent,
                  resolutions: WMS_TILEGRID_RESOLUTIONS
                }),
                tileLoadFunction: this.tileLoadWithAuth
              });

              const wmsLayer = new TileLayer({
                extent: layerExtent ? layerExtent : extent,
                source: wmsSource
              });

              const newMapLayer = new MapLayer(
                wmsLayer,
                dataProduct,
                layerExtent,
                layers.length > 1
                  ? {
                      layerName: layerName,
                      layerTitle: layerTitle
                    }
                  : null
              );

              // newMapLayer.toggle();
              // this._layers.insertAt(this._layers.getLength() - 1, newMapLayer);
              mapLayers.push(newMapLayer);
            });
          });

          resolve(mapLayers);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Handles creating the ol.layer for a WMTS type service url.
   * Wraps the ol.layer in the MapLayer class and returns that.
   *
   * @param dataProduct the dataProduct to load layers for
   * @returns A promise that resolves with the MapLayers.
   */
  private async _getWMTSLayer(dataProduct: DataProduct, tilePreload = 0): Promise<MapLayer[]> {
    const specificLayers = map(dataProduct.layers, 'name');
    let url = dataProduct.brokerUrl,
      queryDelimiter = url.includes('?') ? '&' : '?',
      fetchHeaders = {};

    if (_needsBrokerApiKey(url)) {
      const brokerApiKey = await this._brokerService.getApiKey().toPromise();
      url += queryDelimiter + 'apiKey=' + brokerApiKey;
      queryDelimiter = '&';
    } else if (_jwtOnlyRoute(url)) {
      const brokerJwtToken = await this._authService.jwtTokenGetter();
      fetchHeaders = { Authorization: 'Bearer ' + brokerJwtToken };
    }
    return new Promise<MapLayer[]>((resolve, reject) => {
      const parser = new WMTSCapabilities();

      fetch(`${url}${queryDelimiter}request=GetCapabilities`, {
        cache: _forceGetCapsCache(url) ? 'force-cache' : 'no-store',
        headers: fetchHeaders
      })
        .then(response => response.text())
        .then(text => {
          const result: any = parser.read(text);
          const mapLayers: MapLayer[] = [];
          if (!result) { return; }
          if (!result.Contents) { return; }

          result.Contents.Layer.forEach((layer: any) => {
            if (
              !isEmpty(specificLayers) &&
              !includes(specificLayers, layer.Identifier)
            ) { return; }

            const wmtsOpts = optionsFromCapabilities(result, {
              layer: layer.Identifier,
              crossOrigin: 'anonymous'
            });

            const wmtsSource = new WMTS(wmtsOpts);
            wmtsSource.setTileLoadFunction(this.tileLoadWithAuth);

            const wmtsLayer = new TileLayer({
              preload: tilePreload,
              opacity: 1,
              source: wmtsSource
            });
            const layerExtent = concat(
              fromLonLat([
                layer.WGS84BoundingBox[0],
                layer.WGS84BoundingBox[1]
              ]),
              fromLonLat([layer.WGS84BoundingBox[2], layer.WGS84BoundingBox[3]])
            ) as [number, number, number, number];

            const newMapLayer = new MapLayer(
              wmtsLayer,
              dataProduct,
              layerExtent,
              result.Contents.Layer.length > 1
                ? {
                    layerName: layer.Identifier,
                    layerTitle: layer.Title
                  }
                : null
            );

            // Determines min zoom level the WMTS service wants to serve
            // then prevent OpenLayers from showing the layer at that zoom level
            // else CPU usage will go way up when zooming out.
            const tileRangeZero = wmtsOpts.tileGrid.getFullTileRange(0);
            const minZoomX = Math.max(0, Math.round(Math.log(tileRangeZero['maxX']) / Math.LN2));
            const minZoomY = Math.max(0, Math.round(Math.log(tileRangeZero['maxY']) / Math.LN2));
            newMapLayer.olLayer.setMinZoom(Math.min(minZoomX, minZoomY));

            // newMapLayer.toggle();
            // this._layers.insertAt(this._layers.getLength() - 1, newMapLayer);

            mapLayers.push(newMapLayer);
          });

          resolve(mapLayers);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Used for the One Atlas layers. The API token is hardcoded here. It shouldn't be.
   * This is really old, and probably is not coded well.
   *
   * @param capUrl the Capabilities url for the service
   * @param name the display name for the MapLayer
   * @param dataProduct the dataProduct for this map layer
   * @returns a promise that resolves with the new MapLayer
   */
  private _getAirbusWMTSLayer(dataProduct: DataProduct): Promise<MapLayer[]> {
    let url = dataProduct.brokerUrl;

    return new Promise<MapLayer[]>(async (resolve, reject) => {
      const parser = new WMTSCapabilities();
      const queryDelimiter = url.includes('?') ? '&' : '?';
      const init = {
        method: 'GET'
      };
      const brokerApiKey = await this._brokerService.getApiKey().toPromise();
      if (_needsBrokerApiKey(url)) {
        url += queryDelimiter + 'apiKey=' + brokerApiKey;
      } else {
        // NOTE: this shouldn't happen anymore
        console.error('Attempting to load OneAtlas layer outside the Broker');
      }

      fetch(url, init)
        .then(response => response.text())
        .then(text => {
          const result: any = parser.read(text);
          const wmtsOpts = optionsFromCapabilities(result, {
            layer: result.Contents.Layer[0].Identifier,
            crossOrigin: 'anonymous'
          });

          wmtsOpts.attributions = `Includes material &copy; AIRBUS DS ${new Date().getFullYear()},
            Copernicus Emergency Management Service (&copy; European Union, 2012-${new Date().getFullYear()}),
            National Oceanic and Atmospheric Administration (NOAA)`;
            const wmtsSource = new WMTS(wmtsOpts);
          wmtsSource.setTileLoadFunction(this.tileLoadWithAuth);

          const wmtsLayer = new TileLayer({
            opacity: 1,
            source: wmtsSource
          });

          const layerExtent = concat(
            fromLonLat([
              result.Contents.Layer[0].WGS84BoundingBox[0],
              result.Contents.Layer[0].WGS84BoundingBox[1]
            ]),
            fromLonLat([
              result.Contents.Layer[0].WGS84BoundingBox[2],
              result.Contents.Layer[0].WGS84BoundingBox[3]
            ])
          ) as [number, number, number, number];

          const newMapLayer = new MapLayer(wmtsLayer, dataProduct, layerExtent);

          resolve([newMapLayer]);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
