import { BaseModel } from './base.model';

export enum AttachmentStatus {
  Uploaded = 'UPLOADED',
  Pending = 'PENDING'
}

export interface CsvColumn {
  name: string;
  index: number;
}

export interface CsvColumnsConfig {
  columns: CsvColumn[];
  latIndex: number;
  lonIndex: number;
}

export class Attachment extends BaseModel {
  readonly id: string;
  ownerId: string; // uuid
  referenceId: string; // uuid
  path: string;
  signedUrl: string;
  uploadUrl: string;
  userId: string; // uuid
  status: AttachmentStatus;
  labels: object;
  attributes: object;
  readonly created: number;
  readonly modified: number;
}

export interface SignedUrl {
  expiration: number;
  gsUrl: string;
  signedUrl: string;
}

export interface SignedUrlResponse {
  urls: SignedUrl[];
}
