import { Component, OnInit, OnDestroy } from '@angular/core';
import { Toasty } from './toasty.model';
import { Toast } from './toast.model';
import { Alert } from './alert.model';
import { ToasterService } from './toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'toaster-outlet',
  template: `
    <!-- prettier-ignore -->
    <div class="toaster-outlet toast-outlet">
      <toasty *ngFor="let toast of toasts"
        [toasty]="toast"
        (onClose)="removeToasty($event)">
      </toasty>
    </div>
    <!-- prettier-ignore -->
    <div class="toaster-outlet alert-outlet">
      <toasty *ngFor="let alert of alerts"
        [toasty]="alert"
        (onClose)="removeToasty($event)">
      </toasty>
    </div>
  `,
  styleUrls: ['./toaster-outlet.styles.scss']
})
export class ToasterOutletComponent implements OnInit, OnDestroy {
  protected toasts: Toast[] = [];
  protected alerts: Alert[] = [];

  private _toastSub: Subscription;
  private _alertSub: Subscription;

  constructor(private _toaster: ToasterService) {}

  protected removeToasty(toasty: Toasty): void {
    toasty.hide();
    setTimeout(() => {
      if (toasty instanceof Alert) {
        this.alerts = this.alerts.filter(alert => alert !== toasty);
      } else {
        this.toasts = this.toasts.filter(toast => toast !== toasty);
      }
    }, 160);
  }

  ngOnInit() {
    this._toastSub = this._toaster.toast$.subscribe(toast => {
      if (!toast) {
        this.toasts = [];
      } else {
        toast.show();
        this.toasts.unshift(toast);
        setTimeout(() => this.removeToasty(toast), 5000);
      }
    });
    this._alertSub = this._toaster.alert$.subscribe(alert => {
      if (!alert) {
        this.alerts = [];
      } else {
        alert.show();
        this.alerts.unshift(alert);
        setTimeout(() => this.removeToasty(alert), 5000);
      }
    });
  }

  ngOnDestroy() {
    this._toastSub.unsubscribe();
    this._alertSub.unsubscribe();
  }
}
