import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { titleize } from 'projects/utils/src/public_api';

@Component({
  selector: 'radio-control-group',
  templateUrl: './radio-control-group.template.html',
  styleUrls: ['./radio-control-group.styles.scss']
})
export class RadioControlGroupComponent {
  @Input()
  control: FormControl;

  @Input()
  size: 'small' | 'medium' | 'large' = 'small';

  @Input()
  set values(values: (string | boolean | { value: string; label?: string })[]) {
    this.radioControls = values.map(val => {
      if (typeof val === 'object') {
        return {
          value: val.value,
          label: val.label ? val.label : titleize(val.value)
        };
      } else {
        return {
          value: val,
          label: titleize(val.toString())
        };
      }
    });
  }

  @Input()
  disableHover = false;

  radioControls: { value: string | boolean; label: string }[];
}
