import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public isOpen$: Observable<boolean>;

  set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
    this._subject.next(this._isOpen);
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  private _isOpen = false;
  private _subject = new BehaviorSubject<boolean>(this._isOpen);

  constructor() {
    this.isOpen$ = this._subject.asObservable();
  }
}
