import { Pipe, PipeTransform } from '@angular/core';
import isFinite from 'lodash/isFinite';
import { numberWithCommas } from './helpers';

@Pipe({ name: 'centimetersSqr' })

// Convert square centimeters number into an appropriate metric unit
//
// After conversion to correct scale, the precision is set
//
// parseFloat will remove any trailing zeros after the decimal point
//
// Commas are added for large numbers.
//
// Some strange math in here. I tried to comment below.
//
// globalPrecision = true is mainly so MGRS grid areas can who one significant
// digit using centimetersSqr:0:true. This rounds the area to 1 sqkm, 100 sqkm, etc
// even if the calculated area is not quite those numbers due to weird rounding issues.
export class CentimetersSqrPipe implements PipeTransform {
  transform(input: number, precision?: number, globalPrecision?: boolean): string {
    if (isFinite(input)) {
      const digits = Math.max(0, precision) || 2;
      // Changeover depends on precision requested
      if (input > (Math.pow(10, 11 - digits))) {
          const output = input / 1e10;
          // If magnitude of number is large, keep all digits but use toFixed
          // to "trim" trailing decimals.
          const outputStr = (output >= Math.pow(10, digits) && !globalPrecision)
            ? numberWithCommas(parseFloat(output.toFixed(0)))
            : parseFloat(output.toPrecision(digits));
          return `${outputStr} km²`;
      } else if (input > (Math.pow(10, 5 - digits))) {
        const output = input / 1e4;
        const outputStr = (output >= Math.pow(10, digits) && !globalPrecision)
          ? numberWithCommas(parseFloat(output.toFixed(0)))
          : parseFloat(output.toPrecision(digits));
        return `${outputStr} m²`;
      } else {
        const outputStr = (input >= (Math.pow(10, digits)) && !globalPrecision)
          ? numberWithCommas(parseFloat(input.toFixed(0)))
          : parseFloat(input.toPrecision(digits));
          return `${outputStr} cm²`;
      }
    } else {
      return 'NaN';
    }
  }
}
