import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.template.html',
  styleUrls: ['./side-panel.styles.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidePanelComponent implements OnInit {

  @Input() floatingPanel: boolean; // defaults to docked panel
  @Input() alignRight: boolean; // defaults to alignLeft
  @Input() bgColor: string; // defaults to white bg
  @Input() panelWidth: string; // defaults to 424px per comp
  @Input() autoHeight: string; // defaults to true to fill height

  constructor() { }

  ngOnInit() { }
}