import { BaseModel } from './base.model';
import { DataProductType } from '../interfaces/dataProductType.interface';
import { titleize } from 'projects/utils/src/public_api';

export class DataProcess extends BaseModel {
  dagId: string;
  serviceType: string;
  productType: DataProductType;

  get text(): string {
    switch (this.dagId) {
      case 'gsf_flood_water_finder':
        return 'GSF Flood Water Finder';
      case 'gsf_flood_water_finder_different_converter':
        // TODO: what does this do?
        return 'GSF Flood Water Finder Different Converter';
      case 'pdf_reports':
        return 'PDF Reports';
      case 'process_gsf_images':
        return 'Process GSF Images';
      case 'process_rasterfoundry':
        return 'Process Raster Foundry';
      case 'raster-vision-training':
        return 'Raster Vision Training';
      case 'veg_finder':
        return 'Vegetation Finder';
      default:
        return titleize(this.dagId);
    }
  }
}
