import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './plantings.template.html',
  styleUrls: ['./plantings.styles.scss']
})
export class PlantingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
