import {
  Component,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Renderer2,
  Input
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from './modal.service';

@Component({
  selector: 'modal-overlay',
  exportAs: 'modalOverlay',
  template: `
    <!-- prettier-ignore -->
    <div class="modal-overlay overlay-{{ overlayColor }}">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalOverlayComponent implements AfterViewInit, OnDestroy {
  @Input()
  protected overlayColor: 'blue' | 'grey' = 'blue';

  constructor(
    private _renderer: Renderer2,
    private _router: Router,
    private _route: ActivatedRoute,
    private _modalService: ModalService
  ) {
    this._modalService.isOpen = true;
  }

  public close(modalOutletName = 'modal'): void {
    this._router.navigate(
      [
        '.',
        {
          outlets: {
            [modalOutletName]: null
          }
        }
      ],
      { relativeTo: this._route.parent }
    );
  }

  ngAfterViewInit() {
    this._renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this._renderer.removeClass(document.body, 'modal-open');
    this._modalService.isOpen = false;
  }
}
