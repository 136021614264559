import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef
} from '@angular/core';
import { AerialButtonWithIcon } from '../aerialButtonWithIcon.model';
import { TooltipPosition } from '../../tooltips/tooltip.model';
import isEqual from 'lodash/isEqual';

export interface ButtonContext {
  id?: string;
  iconClass: string;
  tipText?: string;
  isActive?: boolean;
  hide?: boolean;
}

@Component({
  selector: 'context-button',
  templateUrl: './context-button.template.html',
  styleUrls: ['./context-button.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextButtonComponent extends AerialButtonWithIcon {
  @Input()
  contexts: ButtonContext[];

  @Input()
  tipPlacement: TooltipPosition = 'right';

  @Input()
  closeOnClickOut = false;

  @Output()
  contextChange = new EventEmitter<ButtonContext>();

  get activeContext(): ButtonContext {
    return this.contexts.find(ctx => ctx.isActive);
  }

  set activeContext(activeContext: ButtonContext) {
    this.activeContext.isActive = false;
    this.contexts.find(ctx => isEqual(ctx, activeContext)).isActive = true;
    this.contextChange.emit(activeContext);
  }

  get otherContexts(): ButtonContext[] {
    return this.contexts.filter(ctx => !ctx.isActive && !ctx.hide);
  }

  otherClasses: string[] = ['square'];
  protected dropdownOpen = false;

  constructor(private _el: ElementRef) {
    super();
  }

  @HostListener('document:click', ['$event'])
  protected onClickout(event: Event) {
    if (
      this.closeOnClickOut &&
      !this._el.nativeElement.contains(event.target)
    ) {
      this.dropdownOpen = false;
    }
  }
}
