
import { MapService } from './map.service';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Layer from 'ol/layer/Layer';
import GeoJSON from 'ol/format/GeoJSON';

export class MapCropLayer {

  private _mapCropGeometry: Geometry;

  constructor(
    private _mapService: MapService,
    private _olLayer: Layer,
    private _cropGeometry: object,
    private _geometryProjection?: string
  ) {
    this._mapCropGeometry = new GeoJSON()
      .readFeature(this._cropGeometry, {
      dataProjection: this._geometryProjection ? this._geometryProjection : 'EPSG:4326',
      featureProjection: 'EPSG:3857' // TODO: access via `_mapService.map.getView().getProjection()`
      })
      .getGeometry();
  }

  cropLayer() {
    switch (this._mapCropGeometry.getType()) {
      case 'Polygon':
        this._olLayer.on('prerender', this.onPolygonPrecompose);
        break;
      case 'MultiPolygon':
        this._olLayer.on('prerender', this.onMultiPolygonPrecompose);
        break;
      default:
        return;
    }
    this._olLayer.on('postrender', this.onPostcompose);
  }

  uncropLayer() {
    switch (this._mapCropGeometry.getType()) {
      case 'Polygon':
        this._olLayer.un('prerender', this.onPolygonPrecompose);
        break;
      case 'MultiPolygon':
        this._olLayer.un('prerender', this.onMultiPolygonPrecompose);
        break;
      default:
        return;
    }
    this._olLayer.un('postrender', this.onPostcompose);
  }

  private onPolygonPrecompose = (event: any) => {
    const ctx = event.context;
    const mapServiceMap = this._mapService.getMap();
    ctx.save();
    const pixelRatio = event.frameState.pixelRatio;
    ctx.scale(1 * pixelRatio, 1 * pixelRatio);
    let pix: any;
    let first = true;
    ctx.beginPath();
    (this._mapCropGeometry as Polygon).getCoordinates()
      .forEach(
        (coord: any[]) => {
          pix = mapServiceMap.getPixelFromCoordinate(coord[0]);
          if (pix) {
            ctx.moveTo(pix[0], pix[1]);
          }
          first = true;
          coord.forEach((point: any[]) => {
            if (first) {
              first = false;
              return;
            }
            pix = mapServiceMap
            .getPixelFromCoordinate(point as [number, number]);
            if (pix) {
              ctx.lineTo(pix[0], pix[1]);
            }
          });
        }
    );
    ctx.clip();
    ctx.scale(1 / 1 / pixelRatio, 1 / 1 / pixelRatio);
    ctx.stroke();
  }

  private onMultiPolygonPrecompose = (event: any) => {
    const ctx = event.context;
    const mapServiceMap = this._mapService.getMap();
    ctx.save();
    const pixelRatio = event.frameState.pixelRatio;
    ctx.scale(1 * pixelRatio, 1 * pixelRatio);
    let pix: any;
    let first = true;
    ctx.beginPath();
    (this._mapCropGeometry as MultiPolygon).getCoordinates()
      .forEach(
        (coord: any[]) => {
          coord.forEach((points: any[]) => {
            pix = mapServiceMap.getPixelFromCoordinate(points[0]);
            if (pix) {
              ctx.moveTo(pix[0], pix[1]);
            }
            first = true;
            points.forEach((point: any[]) => {
              if (first) {
                first = false;
                return;
              }
              pix = mapServiceMap
                .getPixelFromCoordinate(point as [number, number]);
              if (pix) {
                ctx.lineTo(pix[0], pix[1]);
              }
            });
          });
        }
    );
    ctx.clip();
    ctx.scale(1 / 1 / pixelRatio, 1 / 1 / pixelRatio);
    ctx.stroke();
  }

  private onPostcompose = (event: any) => {
    event.context.restore();
  }
}
