import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Aoi } from '../models/aoi.model';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map } from 'rxjs/operators';

@Injectable()
export class AoiService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/aois`;
  }

  protected mapJsonToCollection(respBody: Aoi[]): Aoi[] {
    return respBody.map(json => Aoi.fromJSON(json));
  }

  getAois(
    baseParams?: BaseParams,
    searchTerm?: string
  ): Promise<PagedResponse<Aoi>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Aoi>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getAoisForProject(
    projectId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Promise<PagedResponse<Aoi>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Aoi>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getAoisForCustomer(
    customerId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Aoi>> {
    return this.getWithPagedResponse<Aoi>(
      `${this.endpointUrl}/project/${customerId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getAoi(aoiId: string): Promise<Aoi> {
    return this.httpClient
      .get<Aoi>(`${this.endpointUrl}/${aoiId}`)
      .pipe(map(resp => Aoi.fromJSON(resp)))
      .toPromise();
  }

  updateAoi(aoi: Aoi): Promise<HttpResponse<Aoi>> {
    return this.putWithResponse<Aoi>(
      `${this.endpointUrl}/${aoi.id}`,
      aoi.toJSON()
    ).toPromise();
  }

  patchAoi(aoiId: string, params: object): Promise<HttpResponse<Aoi>> {
    return this.patchWithResponse<Aoi>(
      `${this.endpointUrl}/${aoiId}`,
      params
    ).toPromise();
  }

  createAoi(aoi: Aoi): Promise<string> {
    return this.createWithResponseAsUrl(
      this.endpointUrl,
      aoi.toJSON()
    ).toPromise();
  }

  saveAoi(aoi: Aoi): Promise<string> {
    return this.createAoi(aoi);
  }

  deleteAoi(aoi: Aoi): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${aoi.id}`).toPromise();
  }
}
