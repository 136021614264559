import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToasterService } from 'projects/ui/src/public_api';
import { AuthService } from '../auth.service';

@Component({
  templateUrl: './forgotPassword.template.html',
  styleUrls: ['../logIn/logIn.styles.scss']
})
export class ForgotPasswordComponent {
  email = new FormControl('', [Validators.required, Validators.email]);
  emailSent = false;

  constructor(
    private _authService: AuthService,
    private _toaster: ToasterService
  ) {}

  sendResetEmail() {
    this._authService.sendResetEmail(this.email.value).then(
      _ => (this.emailSent = true),
      error => {
        console.error('Forgot Password Error', error);
        this._toaster.toast(
          'error',
          'Something went wrong. Please check your email address and try again.'
        );
      }
    );
  }
}
