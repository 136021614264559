import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BaseStateService<T extends object> {
  private _state$: BehaviorSubject<T>;
  // protected defaultState: T;

  constructor(protected defaultState: T) {
    this._state$ = new BehaviorSubject<T>(this.defaultState);
  }

  public get state$(): Observable<T> {
    return this._state$.asObservable();
  }

  protected getCurrentState(): T {
    return this._state$.getValue();
  }

  protected setState(newState: T) {
    this._state$.next(newState);
  }

  protected patchState(newState: Partial<T>) {
    const currState = this.getCurrentState();

    // I don't like this
    this._state$.next({
      ...(currState as object),
      ...(newState as object)
    } as T);
  }
}
