import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  HostListener
} from '@angular/core';
import { Sort } from '../sort/sort.interface';

@Component({
  selector: 'select-sorter',
  templateUrl: './select-sorter.template.html',
  styleUrls: ['./select-sorter.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSorterComponent {
  protected sortBy: string;
  protected sortOrder: 'asc' | 'desc';
  protected dropdownOpen = false;

  /**
   * The array of attributes you want to be able to sort by
   */
  @Input()
  sortOptions: string[] = [];

  /**
   * ALlow additional classes to be passed in, like 'invisible'
   */
  @Input()
  classes: string;

  /**
   * Allow alliging the option list along the left or right side of the dropdown
   */
  @Input()
  dropdownAlignment: 'left' | 'right' = 'left';

  /**
   * The current sort values.
   */
  @Input()
  set currentSort({ by, order }: Sort) {
    this.sortBy = by;
    if (order.toLowerCase() === 'asc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }

  get currentSort(): Sort {
    return { by: this.sortBy, order: this.sortOrder };
  }

  @Output()
  sort: EventEmitter<Sort> = new EventEmitter<Sort>();

  openDropdown(event: Event) {
    event.stopPropagation();
    this.dropdownOpen = true;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown() {
    this.dropdownOpen = false;
  }

  changeSortBy(newSortBy: string, event: Event) {
    event.stopPropagation();
    this.sortBy = newSortBy;
    this.sort.emit(this.currentSort);
    this.closeDropdown();
  }

  changeSort(sort: Sort) {
    this.currentSort = sort;
    this.sort.emit(this.currentSort);
  }
}
