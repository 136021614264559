import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseService } from './base.service';
import { AirmapAdvisories } from '../models/advisory.model';

@Injectable()
export class AirmapService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/integrations/airmap/advisories`;
  }

  getAdvisoriesByMissionId(
    missionId: string,
    token?: string
  ): Promise<AirmapAdvisories> {
    const headers = token ? { headers: this.buildAuthHeader(token) } : {};
    return this.httpClient
      .get(`${this.endpointUrl}/${missionId}/all`, headers)
      .pipe(map(resp => resp as AirmapAdvisories))
      .toPromise();
  }
}
