import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'aerial-checkbox',
  template: `
    <!-- prettier-ignore -->
    <button type="button"
      class="aerial-checkbox check-btn square {{ size }} {{ color }}"
      [ngClass]="{
        active: checked,
        indeterminate: indeterminate,
        'alt-style': altStyle,
        invalid: invalid
      }"
      [disabled]="disabled"
      (click)="toggle()">
      <i class="btn-icon icon-{{ indeterminate ? 'dash' : 'check' }}"></i>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AerialCheckboxComponent {
  @Input()
  size: 'small' | 'medium' | 'large' = 'small';

  @Input()
  color: 'dark' | 'light' | 'inverted' = 'dark';

  @Input()
  altStyle = false; // if true, the bgcolor of an unchecked checkbox will be white instead of grey

  @Input()
  checked = false;

  @Input()
  indeterminate = false; // not fully implemented

  @Input()
  disabled = false;

  @Input()
  invalid = false;

  @Output()
  onChange = new EventEmitter<void>();

  @Output()
  onCheck = new EventEmitter<void>();

  @Output()
  onUncheck = new EventEmitter<void>();

  protected toggle(): void {
    this.checked = !this.checked;
    this.onChange.emit();
    this[this.checked ? 'onCheck' : 'onUncheck'].emit();
  }
}
