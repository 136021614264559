import { Pipe, PipeTransform } from '@angular/core';

import sortBy from 'lodash/sortBy';

@Pipe({ name: 'sort' })

// Used to reverse the contents of an Array
export class SortPipe implements PipeTransform {
  transform(
    input: Array<any> | undefined,
    sort: string | string[]
  ): Array<any> {
    if (input instanceof Array) {
      return sortBy(input, sort);
    } else if (input === undefined) {
      return [];
    } else throw 'SortPipe: input needs to be an Array';
  }
}
