import {
  Directive,
  HostListener,
  Renderer2,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { TooltipService } from './tooltip.service';
import { Tooltip, TooltipPosition } from './tooltip.model';

@Directive({
  selector: '[title]'
})
export class TooltipDirective implements OnChanges {
  @Input('title') text: string;
  @Input() placement: TooltipPosition = 'right';

  private _visible = false;
  private _element: HTMLElement;

  get tooltip(): Tooltip {
    return new Tooltip({
      text: this.text,
      placement: this.placement,
      visible: this._visible,
      actorElement: this._el
    });
  }

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _tooltipService: TooltipService
  ) {
    this._element = this._el.nativeElement;
  }

  @HostListener('mouseover') onMouseOver() {
    this._visible = true;
    this._renderer.removeAttribute(this._element, 'title');
    this._tooltipService.load(this.tooltip);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._removeTT();
  }

  @HostListener('blur') onBlur() {
    this._removeTT();
  }

  private _removeTT() {
    this._visible = false;
    this._tooltipService.hide();
    this._renderer.setAttribute(this._element, 'title', this.text);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.text && !changes.text.firstChange) {
      this._tooltipService.load(this.tooltip);
    }
  }
}
