import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlantingsStateService } from '../plantings-state.service';
import {
  Planting,
  Field,
  Crop,
  CropService,
  AgSubscription,
  AgSubscriptionService
} from 'api/src/public_api';
import { FieldsStateService } from '../../fields/fields-state.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { MapFeaturesService, MapService } from 'map/src/public_api';
import { skipWhile } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { PlantingDataService } from '../../../shared/services/planting-data.service';

@Component({
  templateUrl: './edit-planting.template.html',
  styleUrls: ['./edit-planting.styles.scss']
})
export class EditPlantingComponent implements OnInit, OnDestroy {
  private partialPlanting: Partial<Planting>;
  field: Field;
  fieldSub: Subscription;
  planting: Planting;
  plantingSub: Subscription;
  plantingCompleted: boolean = false;

  _plantingNameSub: Subscription;
  plantingNameCtrl = new FormControl('');
  plantingNameChosen: string;

  cropList: any;
  cropTypeCtrl = new FormControl({});

  fieldSelectCtrl = new FormControl({});

  startDateCtrl = new FormControl();
  endDateCtrl = new FormControl();

  requiredFieldsMet = true;
  plantingNamed: boolean;
  startDate: number;
  endDate: number;
  subData: Partial<AgSubscription>[];

  showEndDateInput = true;

  get minEndDate() {
    // Min growing cycle length: 4 weeks
    if (!this.startDate) {
      return undefined;
    }
    const startDate = new Date(this.startDate);
    return startDate.setDate(startDate.getDate() + 4 * 7);
  }

  get maxEndDate() {
    // Maximum growing cycle length: 52 weeks
    if (!this.startDate) {
      return undefined;
    }
    const startDate = new Date(this.startDate);
    return startDate.setDate(startDate.getDate() + 52 * 7);
  }

  constructor(
    private _plantingDataService: PlantingDataService,
    private _plantingsStateService: PlantingsStateService,
    private _fieldsStateService: FieldsStateService,
    private _mapFeatureService: MapFeaturesService,
    private _mapService: MapService,
    private _cropService: CropService,
    private _agSubscriptionService: AgSubscriptionService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit() {

    this.fieldSub = this._route.params
      .pipe(
        filter((params: Params) => {
          return params['fieldId'];
        }),
        this._fieldsStateService.loadEntityOpr('fieldId')
      )
      .subscribe((field: Field) => {
        this.field = field;
        this._mapFeatureService.addAnnotation(this.field.geometry);
        this._mapService.animateToBounds(
          (this.field.geometry.getGeometry() as any).getExtent(),
          [0, 0, 0, 410]
        );

        // set incoming Field as selected
        this.setField();
      });

    this.plantingSub = this._route.params
      .pipe(
        filter((params: Params) => {
          return params['plantingId'];
        })
      )
      .subscribe((params: Params) => {
        this._plantingDataService.plantingId = params['plantingId'];
      });

    this.plantingSub = this._plantingDataService.planting$.subscribe(
      (planting: Planting) => {
        this.planting = planting;
        this.setPlanting();
      }
    );


    this._plantingNameSub = this.plantingNameCtrl.valueChanges.subscribe(
      val => {
        this.plantingNameChosen = val;
        this.plantingNamed = val ? true : false;
        this.checkStepOneReqs();
      }
    );

    this._cropService
      .list({
        size: '1000'
      })
      .pipe(
        map((resp: HttpResponse<Crop>) => {
          return resp.body;
        })
      )
      .subscribe(resp => {
        this.cropList = resp;
      });

    this.cropTypeCtrl.valueChanges.subscribe(res => {
      this.checkStepOneReqs();
    });
  }

  setField() {
    this.fieldSelectCtrl.setValue({
      name: this.field.name,
      id: this.field.id
    });
  }

  setPlanting() {
    this.cropTypeCtrl.setValue({
      name: this.planting.cropName,
      id: this.planting.cropId
    })

    this.startDate = this.planting.growingCycleStart;
    this.endDate = this.planting.growingCycleEnd;

    this.startDateCtrl.setValue(this.planting.growingCycleStart); 
    this.endDateCtrl.setValue(this.planting.growingCycleEnd);  
    this.plantingNameCtrl.setValue(this.planting.name);
  }

  currentStep(step: number) {
    switch (step) {
      case 1:
        this.checkStepOneReqs();
        break;
    }
  }

  checkStepOneReqs() {
    this.requiredFieldsMet = true;
  }

  stepperCompleted() {
    this.plantingCompleted = true;
    this.createPlanting();
  }

  startDatePicked(date: number) {
    this.showEndDateInput = true;
    this.startDate = date;
    this.checkStepOneReqs();
  }

  endDatePicked(date: number) {
    this.endDate = date;
    this.checkStepOneReqs();
  }

  subscriptionData(data: Partial<AgSubscription>[]) {
    this.subData = data;
  }

  createPlanting() {
    const fieldJSON = this.field.toJSON();
    const plantingObject = {
      ...this.partialPlanting,
      id: this.planting.id,
      name: this.plantingNameChosen || this.cropTypeCtrl.value.name,
      geometry: fieldJSON['geometry'],
      cropId: this.cropTypeCtrl.value.id,
      growingCycleStart: this.startDate,
      growingCycleEnd: this.endDate,
    };

    this._plantingsStateService
      .$patch(new Planting(plantingObject))
      .subscribe((planting: Planting) => {
        // no-op
      });
  }

  ngOnDestroy() {
    this.fieldSub.unsubscribe();
    this.plantingSub.unsubscribe();
    this._plantingNameSub.unsubscribe();
    this._mapFeatureService.clearAnnotations();
  }
}
