// Determines if a default should be used or not
export function envPicker(variable: string, defaultVal: any) {
  const val =
    window['env'] &&
    window['env'][variable] &&
    window['env'][variable] !== `\${${variable}}`
      ? window['env'][variable]
      : defaultVal;
  if (
    typeof val === 'string' &&
    (val.toLowerCase() === 'true' || val.toLowerCase() === 'false')
  ) {
    // convert bool strings to boolean
    return val.toLowerCase() === 'true';
  }
  return val;
}
