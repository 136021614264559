import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';

import { Observable } from 'rxjs';
import { Crop } from '../interfaces/crop.interface';

@Injectable()
export class CropService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.agRootUrl}/ag/crops/`;
  }

  public list(options: {
    [param: string]: string | string[];
  }): Observable<HttpResponse<Crop>> {
    return this.getWithResponse<Crop>(`${this.endpointUrl}`, {
      params: this.buildParams(options)
    });
  }
}
