import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';

import { Observable } from 'rxjs';
import { AgSubscriptionType } from '../interfaces/ag-subscription.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { BaseParams } from '../interfaces/baseParams.interface';

@Injectable()
export class AgSubscriptionTypeService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.agRootUrl}/ag/subscriptiontypes`;
  }

  public list(
    baseParams?: BaseParams
  ): Observable<PagedResponse<AgSubscriptionType>> {
    return this.getWithPagedResponse<AgSubscriptionType>(
      `${this.endpointUrl}/`,
      (respBody: AgSubscriptionType[]): AgSubscriptionType[] => {
        return respBody.map(json => json as AgSubscriptionType);
      },
      baseParams
    );
  }

  public getByPlantingId(
    plantingId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<AgSubscriptionType>> {
    return this.getWithPagedResponse<AgSubscriptionType>(
      `${this.endpointUrl}/planting/${plantingId}/`,
      (respBody: AgSubscriptionType[]): AgSubscriptionType[] => {
        return respBody.map(json => json as AgSubscriptionType);
      },
      baseParams
    );
  }
}
