import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Modal } from 'projects/ui/src/public_api';
import { AgNeoOnboardService } from 'projects/ag/src/app/shared/services/agneo-onboarding.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'name-field-overlay', // until modal is hooked up
  templateUrl: './name-field-overlay.template.html',
  styleUrls: ['./name-field-overlay.styles.scss']
})
export class NameFieldOverlayComponent extends Modal
  implements OnInit, OnDestroy {
  @Output() modalData: EventEmitter<{}>;
  fieldNameSub: Subscription;
  selectedOption: string = 'Select column to map';

  constructor(
    public agNeoOnboardService: AgNeoOnboardService,
    private _agneoOnboardingService: AgNeoOnboardService
  ) {
    super();
    this.modalData = new EventEmitter<{
      showModal: false;
      payload: null;
    }>();
  }

  ngOnInit() {
    this.fieldNameSub = this._agneoOnboardingService.fieldNameCol$.subscribe(
      (name: string) => {
        this.selectedOption = name;
      }
    );
  }

  ngOnDestroy() {
    this.fieldNameSub.unsubscribe();
  }

  save() {
    this.modalData.emit({
      showModal: false,
      payload: this.selectedOption
    });
  }

  invokeCloseModal() {
    this.modalData.emit({
      showModal: false,
      payload: this.selectedOption
    });
  }
}
