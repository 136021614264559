import { Output, EventEmitter } from '@angular/core';
import { DatePicker } from '../date-picker/datePicker.model';

export abstract class DatePickerControl extends DatePicker {
  @Output()
  protected openPicker = new EventEmitter<void>();

  @Output()
  protected closePicker = new EventEmitter<void>();

  get isSingleMode(): boolean {
    return this.config.mode === 'single';
  }

  get isMultiMode(): boolean {
    return this.config.mode === 'multiple';
  }

  get isRangeMode(): boolean {
    return this.config.mode === 'range';
  }

  get isTimestampFormat(): boolean {
    return this.config.dateFormat === 'U';
  }

  get isBtnStyle(): boolean {
    return this.config.style === 'button';
  }

  get isIconBtnStyle(): boolean {
    return this.config.style === 'icon-button';
  }

  get isInputStyle(): boolean {
    return this.config.style === 'input';
  }

  get isDropdownStyle(): boolean {
    return this.config.style === 'dropdown';
  }

  get hasValue(): boolean {
    return this._flatpickr.selectedDates.length > 0;
  }

  protected abstract isPickerOpen: boolean;
  protected _flatpickr: any;

  open(): void {
    this._flatpickr.open();
  }

  clear(): void {
    this._flatpickr.close();
    this._flatpickr.clear();
  }
}
