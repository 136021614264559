import { BaseModel } from './base.model';

import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Geometry from 'ol/geom/Geometry';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Polygon from 'ol/geom/Polygon';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import * as olSphere from 'ol/sphere';
import Circle from 'ol/geom/Circle';
import { circular } from 'ol/geom/Polygon';
import SimpleGeometry from 'ol/geom/SimpleGeometry';

export abstract class ObjectWithFeature extends BaseModel {
  public feature: Feature;

  // TODO: these attr names should probably be geometry specific
  get type() {
    return this.geometry.getType();
  }

  get coordinates(): Coordinate | null {
    switch (this.type) {
      case 'Polygon':
        return (this.geometry as Polygon).getInteriorPoint().getCoordinates();
      case 'Circle':
        const circle = this.geometry as Circle;
        const polyCircle = circular(
          circle.getCenter(),
          circle.getRadius(),
          64
          // new olSphere(6378137), https://openlayers.org/en/latest/apidoc/module-ol_geom_Polygon.html#.circular
        );
        return (polyCircle as Polygon).getInteriorPoint().getCoordinates();
      case 'Point':
        return (this.geometry as Point).getCoordinates();
      case 'GeometryCollection':
        return ((this
          .geometry as GeometryCollection).getGeometries()[0] as SimpleGeometry).getFirstCoordinate();
      default:
        return null;
    }
  }

  get area(): number {
    if (this.type === 'Circle') {
      // ... i have no idea if this is accurate
      const geo = this.geometry as Circle;
      return Math.PI * Math.pow(geo.getRadius(), 2) * 3.861e-7;
    }
    return (olSphere as any).getArea(this.feature.getGeometry()) * 3.861e-7;
  }

  get extent(): Extent {
    return this.feature.getGeometry().getExtent();
  }

  get geometry(): Geometry {
    return this.feature.getGeometry();
  }
}
