import { BaseModel } from './base.model';
import { DataProductType } from '../interfaces/dataProductType.interface';

import { Feature } from 'ol';

export enum DataProductCategory {
  Product = 'PRODUCT',
  // created as a result of an order (imagry or analytics)
  UserProvided = 'USER_PROVIDED',
  // created via a user upload (PIF/CSV data, SHP files, etc)
  Utility = 'UTILITY',
  // external GIS layers we make available for customers
  // (NOAA layers, fire lines, parcel data, etc)

  // ANALYTICS, INTERNAL, EXTERNAL are supported for backwards compatability but
  // are deprecated should not be used going forward
  Analytics = 'ANALYTICS',
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export enum DataProductStatus {
  Created = 'CREATED',
  Pending = 'PENDING'
}

export const dataProductTypes: DataProductType[] = [
  {
    name: 'Satellite RGB',
    iconClass: 'icon-satellite',
    fillClass: 'fill-dark-blue'
  },
  {
    name: 'Satellite CIR',
    iconClass: 'icon-satellite',
    fillClass: 'fill-blue'
  },
  {
    name: 'Drone',
    iconClass: 'icon-drone',
    fillClass: 'fill-magenta'
  },
  {
    name: 'Rooftop Measurements',
    iconClass: 'fa fa-home',
    fillClass: 'fill-pink'
  },
  {
    name: 'Pointivo Measurements',
    displayName: 'Rooftop Measurements',
    iconClass: 'fa fa-home',
    fillClass: 'fill-pink'
  },
  {
    name: 'Water Finder',
    iconClass: 'icon-water_drop',
    fillClass: 'fill-teal'
  },
  {
    name: 'Vegetation Finder',
    iconClass: 'icon-vegetation',
    fillClass: 'fill-green'
  },
  {
    name: 'Damage Heatmap',
    iconClass: 'fa fa-thermometer-half',
    fillClass: 'fill-red'
  },
  {
    name: 'Burn Area Analysis',
    iconClass: 'icon-flame',
    fillClass: 'fill-orange'
  },
  {
    name: 'Green Pool Finder',
    iconClass: 'icon-water',
    fillClass: 'fill-black'
  },
  {
    name: '3D Model',
    iconClass: 'icon-3d',
    fillClass: 'fill-purple'
  },
  {
    name: 'Manned RGB',
    iconClass: 'icon-airplane',
    fillClass: 'fill-dark-grey'
  },
  {
    name: 'Manned CIR',
    iconClass: 'icon-airplane',
    fillClass: 'fill-grey'
  },
  {
    name: 'Radar',
    iconClass: 'icon-radar',
    fillClass: 'fill-light-blue'
  },
  {
    name: 'Geotagged',
    displayName: 'Photos',
    iconClass: 'fa fa-image',
    fillClass: 'fill-sky-blue'
  }
];

export class DataProduct extends BaseModel {
  static dataTypes: string[] = [
    'WMS',
    'WMTS',
    'ESRIJSON',
    'XYZ',
    'MVT',
    'GEOJSON'
  ];

  id: string;
  orderId: string;
  projectId: string;
  category: string | DataProductCategory;
  status: string | DataProductStatus;
  productType: string;
  gsd: number;
  name: string;
  acquired: number;
  created: number;
  modified: number;
  dataUrl: string;
  brokerUrl: string;
  admId: string;
  globallyAvailable: boolean;
  dataType: string;
  groupName: string;
  layers: any[];
  mapLayers: any[];
  style: any;
  extent: object;
  maxZ: number;
  minZ: number;
  attributes: { [key: string]: any };
  added?: boolean;
  sourceKey: string;

  get assetUrl(): string {
    return this.brokerUrl ? this.brokerUrl : this.dataUrl;
  }

  get group(): string {
    return this.groupName || 'Default Group';
  }

  get productTypeDisplay(): string {
    const prodType = dataProductTypes.find(
      type => type.name === this.productType
    );
    return prodType
      ? prodType.displayName
        ? prodType.displayName
        : prodType.name
      : undefined;
  }
}

// fake workspace data products
export class NearmapDataProduct extends DataProduct {}
export class OneAtlasDataProduct extends DataProduct {
  feature: Feature;
}
