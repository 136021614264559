import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AgBaseService } from './agBase.service';
import { Field } from '../models/field.model';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { Observable } from 'rxjs';
import { BaseParams } from '../interfaces/baseParams.interface';

@Injectable()
export class FieldService extends AgBaseService<Field> {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super('/ag/fields', httpClient);
  }

  marshall(json: object): Field {
    return new Field(json);
  }

  public getFieldsForFarm(
    farmId: string,
    baseParams: BaseParams = {},
    options: {
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    } = {}
  ): Observable<PagedResponse<Field>> {
    return this.getWithPagedResponse<Field>(
      `${this.endpointUrl}/farm/${farmId}/`,
      (respBody: Field[]): Field[] => {
        return respBody.map(json => new Field(json));
      },
      baseParams,
      options
    );
  }
}
