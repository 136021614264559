import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PlantingsComponent } from './plantings.component';
import { PlantingSingleComponent } from './planting-single/planting-single.component';
import { CreatePlantingComponent } from './create-planting/create-planting.component';
import { ManagementZoneComponent } from './management-zone/management-zone.component';
import { VrtMapComponent } from './vrt-map/vrt-map.component';
import { EditPlantingComponent } from './edit-planting/edit-planting.component';

const routes: Routes = [
  {
    path: '',
    component: PlantingsComponent
  },
  // {
  //   path: 'create-planting',
  //   component: CreatePlantingComponent,
  //   outlet: 'floating-panel'
  // },
  {
    path: 'create',
    component: CreatePlantingComponent
  },
  {
    path: ':plantingId/edit',
    component: EditPlantingComponent
  },
  {
    path: ':plantingId/management-zone',
    component: ManagementZoneComponent
  },
  {
    path: ':plantingId/vrt-map/:zoneId',
    component: VrtMapComponent
  },
  {
    path: ':plantingId',
    component: PlantingSingleComponent,
    data: { show_timeline: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlantingsRoutingModule { }
