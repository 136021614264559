export class ExternalApiKey {
  id: string;
  customerId: string;
  expirationDate: number;
  expired: boolean;
  active: boolean;
  valid: boolean;
  created: number;
  modified: number;
}
