import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AerialValidation } from '../../util/aerial-validation';

@Component({
  selector: 'user-info-group',
  templateUrl: './user-info-group.template.html',
  styleUrls: ['./user-info-group.styles.scss']
})
export class UserInfoGroupComponent {
  mask = AerialValidation.legacyPhoneMask;
  samplePhoneFormat = AerialValidation.samplePhoneFormat;

  @Input()
  parent: FormGroup;

  @Input()
  readonly = false;

  @Input()
  showHomePhone = false;

  @Input()
  showWorkPhone = false;

  @Input()
  validateBeforeTouch = false;

  @Input()
  validateMobilePhoneFormat = false;

  get userInfoGrp(): FormGroup {
    return this.parent.get('userInfo') as FormGroup;
  }

  get firstNameCtrl(): FormControl {
    return this.parent.get('firstName') as FormControl;
  }

  get lastNameCtrl(): FormControl {
    return this.parent.get('lastName') as FormControl;
  }

  get emailCtrl(): FormControl {
    return this.parent.get('email') as FormControl;
  }

  get mobilePhoneCtrl(): FormControl {
    return (this.userInfoGrp
      ? this.userInfoGrp.get('mobilePhone')
      : this.parent.get('mobilePhone')) as FormControl;
  }

  get isFirstNameInvalid(): boolean {
    return this.validateBeforeTouch
      ? this.firstNameCtrl.invalid
      : this.firstNameCtrl.touched && this.firstNameCtrl.invalid;
  }

  get isLastNameInvalid(): boolean {
    return this.validateBeforeTouch
      ? this.lastNameCtrl.invalid
      : this.lastNameCtrl.touched && this.lastNameCtrl.invalid;
  }

  get isEmailInvalid(): boolean {
    return this.validateBeforeTouch
      ? this.emailCtrl.invalid
      : this.emailCtrl.touched && this.emailCtrl.invalid;
  }

  get isMobilePhoneInvalid(): boolean {
    return this.validateBeforeTouch
      ? this.mobilePhoneCtrl.invalid
      : this.mobilePhoneCtrl.touched && this.mobilePhoneCtrl.invalid;
  }
}
