import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  of,
  forkJoin,
  from,
  EMPTY
} from 'rxjs';
import {
  switchMap,
  shareReplay,
  map,
  tap,
  filter,
  take,
  mergeMap,
  catchError
} from 'rxjs/operators';
import {
  Field,
  Grower,
  CropService,
  Farm,
  Planting,
  Crop
} from 'api/src/public_api';

import * as shapefile from 'shapefile';
import * as JSZip from 'jszip';
import { produce } from 'immer';
import { GrowersStateService } from '../../explorer/growers/growers-state.service';
import { FarmsStateService } from '../../explorer/farms/farms-state.service';
import { FieldsStateService } from '../../explorer/fields/fields-state.service';
import { PlantingsStateService } from '../../explorer/plantings/plantings-state.service';
import { MockDataService } from './mock-data.service';
import { HttpResponse } from '@angular/common/http';

import GeoJSON from 'ol/format/GeoJSON';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';

export const FileUploadErrors = {
  NOSHP: 'No shapefile provided',
  NODBF: 'No dbf file provided',
  NOPRJ: 'No prj file provided'
};

export type AgFeatureCollection = any;
export type AgFeature = any;

export interface AgProperties {
  id: string;
  name: string;
  growerId: string;
  farmId: string;
  fieldId: string;
  geometry: object;
  cropId: string;
  growingCycleStart: number;
  growingCycleEnd: number;
}
export interface DatabaseNode {
  type: 'GROWER' | 'FARM' | 'FIELD' | 'PLANTING';
  parentName: string;
  children?: DatabaseMap;
  properties: Partial<AgProperties>;
}

export interface DatabaseMap {
  [key: string]: DatabaseNode;
}

export interface CropMap {
  [key: string]: string;
}

export interface AgOnBoardingMutation {
  mutationId: string;
  targetId: string;
  targetColumn?: string;
  type: 'DELETE' | 'UPDATE';
  properties?: object;
}

export interface AgImportStatistics {
  nbGrowersComplete: number;
  nbGrowersFailed: number;
  nbFarmsComplete: number;
  nbFarmsFailed: number;
  nbFieldsComplete: number;
  nbFieldsFailed: number;
  nbPlantingsComplete: number;
  nbPlantingsFailed: number;
}

@Injectable({
  providedIn: 'root'
})
export class AgNeoOnboardService {
  private _zipController: JSZip = new JSZip();
  private _shapeFileSubject = new BehaviorSubject<AgFeatureCollection>(null);
  private _plantingNameColSubject = new BehaviorSubject<string>(null);
  private _fieldNameColSubject = new BehaviorSubject<string>(null);
  private _farmNameColSubject = new BehaviorSubject<string>(null);
  private _growerNameColSubject = new BehaviorSubject<string>(null);
  private _currentFarmIdSubject = new BehaviorSubject<string>(null);
  private _currentGrowerIdSubject = new BehaviorSubject<string>(null);
  private _startDateColSubject = new BehaviorSubject<string>(null);
  private _endDateColSubject = new BehaviorSubject<string>(null);
  private _cropNameColSubject = new BehaviorSubject<string>(null);
  private _includePlantingSubject = new BehaviorSubject<boolean>(false);
  private _invalidPlantings = new BehaviorSubject<any[]>([]);
  private _importInProgressSubject = new BehaviorSubject<boolean>(false);
  private _agImportStatisticsSubject = new BehaviorSubject<AgImportStatistics>({
    nbGrowersComplete: 0,
    nbGrowersFailed: 0,
    nbFarmsComplete: 0,
    nbFarmsFailed: 0,
    nbFieldsComplete: 0,
    nbFieldsFailed: 0,
    nbPlantingsComplete: 0,
    nbPlantingsFailed: 0
  });
  private _cropMap: CropMap = {};

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  private findFileNameByExtension = (
    files: { [key: string]: JSZip.JSZipObject },
    extension: string
  ): string => {
    return Object.keys(files).find((key: string) => {
      return key.split('.').pop() === extension;
    });
  };

  private readShapefile = (
    bundle: [ArrayBuffer, ArrayBuffer]
  ): Promise<AgFeatureCollection> => {
    return bundle === null
      ? Promise.resolve({ features: [] })
      : shapefile.read(bundle[0], bundle[1]);
  };

  private applyUniqueIdsToFeaturesInCollection = (
    collection: AgFeatureCollection
  ) => {
    return produce(collection, draftCollection => {
      draftCollection.features.forEach((feature: AgFeature) => {
        feature.properties._uploadId = this.uuidv4();
      });
    });
  };

  private applyMutationsToCollection = ([collection, mutations]: [
    AgFeatureCollection,
    AgOnBoardingMutation[]
  ]) => {
    return produce(collection, draftCollection => {
      mutations.forEach((mutation: AgOnBoardingMutation) => {
        const feature = draftCollection.features.find((feature: AgFeature) => {
          return feature.properties._uploadId === mutation.targetId;
        });
        if (feature) {
          feature.properties = {
            ...feature.properties,
            ...mutation.properties
          };
        }
      });
    });
  };

  private getUnique = (name: string, index: number, self: any[]) => {
    return self.indexOf(name) === index;
  };

  private getUniqueRowsByColumnName = ([collection, targetColName]: [
    AgFeatureCollection,
    string
  ]) => {
    return collection.features
      .map((feature: AgFeature) => {
        return feature.properties[targetColName];
      })
      .filter(this.getUnique);
  };

  private filterNull = <T>(value: T): boolean => {
    return value !== null;
  };

  private getGrowersFromDatabase = (db: DatabaseMap): Grower[] => {
    return Object.keys(db).map((name: string) => {
      return new Grower(db[name].properties);
    });
  };

  private getChildrenNames = (db: DatabaseMap): string[] => {
    return Object.keys(db);
  };

  private getNbEntities = (db: DatabaseMap): number => {
    return this.getChildrenNames(db).length;
  };

  private getNbGrowers = (db: DatabaseMap): number => {
    return this.getNbEntities(db);
  };

  private accumulateEntities = (prev: number, curr: number) => {
    return prev + curr;
  };

  private getNbFarms = (db: DatabaseMap): number => {
    return this.getChildrenNames(db)
      .map((name: string) => {
        return this.getNbEntities(db[name].children);
      })
      .reduce(this.accumulateEntities, 0);
  };

  private getNbFields = (db: DatabaseMap): number => {
    return this.getChildrenNames(db)
      .map((growerName: string) => {
        return this.getChildrenNames(db[growerName].children)
          .map((farmName: string) => {
            return this.getNbEntities(
              db[growerName].children[farmName].children
            );
          })
          .reduce(this.accumulateEntities, 0);
      })
      .reduce(this.accumulateEntities, 0);
  };

  private getNbPlantings = (db: DatabaseMap): number => {
    return this.getChildrenNames(db)
      .map((growerName: string) => {
        return this.getChildrenNames(db[growerName].children)
          .map((farmName: string) => {
            return this.getChildrenNames(
              db[growerName].children[farmName].children
            )
              .map((fieldName: string) => {
                return this.getNbEntities(
                  db[growerName].children[farmName].children[fieldName].children
                );
              })
              .reduce(this.accumulateEntities, 0);
          })
          .reduce(this.accumulateEntities, 0);
      })
      .reduce(this.accumulateEntities, 0);
  };

  private convertToMultiPolygonGeoJSON = (geometryJSON: object): object => {
    const geoJSONController = new GeoJSON();
    const geometry = geoJSONController.readGeometry(geometryJSON);
    const geomType = geometry.getType();

    if (geomType === 'MultiPolygon') {
      return geometryJSON;
    } else if (geomType === 'Polygon') {
      const newMultiPolygon = new MultiPolygon([geometry as Polygon]);
      return JSON.parse(geoJSONController.writeGeometry(newMultiPolygon));
    }

    return geometryJSON;
  };

  private getFieldsFromCollection = ([collection, farmId, growerId, colName]: [
    AgFeatureCollection,
    string,
    string,
    string
  ]): Field[] => {
    return collection.features.map((feature: AgFeature) => {
      return new Field({
        farmId: farmId,
        growerId: growerId,
        name: feature.properties[colName],
        geometry: this.convertToMultiPolygonGeoJSON(feature.geometry),
        _uploadId: feature.properties._uploadId,
        deleted:
          feature.properties.deleted === undefined
            ? false
            : feature.properties.deleted,
        selected:
          feature.properties.selected === undefined
            ? false
            : feature.properties.selected
      });
    });
  };

  private getUniqueColumnNames = (
    collection: AgFeatureCollection
  ): string[] => {
    return []
      .concat(
        ...(collection.features as AgFeature[]).map((feature: AgFeature) => {
          return Object.keys(feature.properties);
        })
      )
      .filter((value: string, index: number, self: any) => {
        return self.indexOf(value) === index && value !== '_uploadId';
      });
  };

  private getRawFileFromZip = (
    bundle: JSZip,
    type: 'shp' | 'dbf'
  ): Promise<ArrayBuffer> => {
    return this._zipController
      .file(this.findFileNameByExtension(bundle.files, type))
      .async('arraybuffer') as Promise<ArrayBuffer>;
  };

  private getShapeFileFromZip = (bundle: JSZip): Promise<ArrayBuffer> => {
    return this.getRawFileFromZip(bundle, 'shp');
  };

  private getDatabaseFileFromZip = (bundle: JSZip): Promise<ArrayBuffer> => {
    return this.getRawFileFromZip(bundle, 'dbf');
  };

  set plantingNameCol(colName: string) {
    this._plantingNameColSubject.next(colName);
  }
  set includePlanting(val: boolean) {
    this._includePlantingSubject.next(val);
  }
  set fieldNameCol(colName: string) {
    this._fieldNameColSubject.next(colName);
  }
  set farmNameCol(colName: string) {
    this._farmNameColSubject.next(colName);
  }
  set growerNameCol(colName: string) {
    this._growerNameColSubject.next(colName);
  }
  set startDateCol(colName: string) {
    this._startDateColSubject.next(colName);
  }
  set endDateCol(colName: string) {
    this._endDateColSubject.next(colName);
  }
  set cropNameCol(colName: string) {
    this._cropNameColSubject.next(colName);
  }
  set currentFarmId(id: string) {
    this._currentFarmIdSubject.next(id);
  }
  set currentGrowerId(id: string) {
    this._currentGrowerIdSubject.next(id);
  }

  constructor(
    private _growersStateService: GrowersStateService,
    private _farmsStateService: FarmsStateService,
    private _fieldsStateService: FieldsStateService,
    private _plantingsStateService: PlantingsStateService,
    // private _growersStateService: MockDataService<Grower>,
    // private _farmsStateService: MockDataService<Farm>,
    // private _fieldsStateService: MockDataService<Field>,
    // private _plantingsStateService: MockDataService<Planting>,
    private _cropService: CropService
  ) {
    this._cropService
      .list({
        size: '1000'
      })
      .pipe(
        take(1),
        map((resp: HttpResponse<Crop>) => {
          return resp.body;
        })
      )
      .subscribe((resp: any) => {
        this._cropMap = resp.reduce((acc: CropMap, curr: Crop) => {
          return {
            ...acc,
            [curr.name]: curr.id
          };
        }, {});
      });
  }

  /** Start Mutations */
  private _mutations = new BehaviorSubject<AgOnBoardingMutation[]>([]);

  private setMutations(newMutations: AgOnBoardingMutation[]) {
    this._mutations.next(newMutations);
  }

  private addMutation(newMutation: AgOnBoardingMutation) {
    this._mutations.next(this._mutations.getValue().concat([newMutation]));
  }

  private removeMutation(mutationId: string) {
    this._mutations.next(
      this._mutations.getValue().filter((mutation: AgOnBoardingMutation) => {
        return mutation.mutationId !== mutationId;
      })
    );
  }

  public renameField(uploadId: string, newName: string): void {
    this.addMutation({
      mutationId: this.uuidv4(),
      type: 'UPDATE',
      targetId: uploadId,
      properties: {
        [this._fieldNameColSubject.getValue()]: newName
      }
    });
  }

  public softDeleteField(uploadId: string): void {
    this.addMutation({
      mutationId: this.uuidv4(),
      type: 'UPDATE',
      targetId: uploadId,
      properties: {
        deleted: true
      }
    });
  }

  public reinstateField(uploadId: string): void {
    this.removeMutation(
      this._mutations.getValue().find((mutation: AgOnBoardingMutation) => {
        return (
          (mutation.targetId === uploadId && mutation.type === 'DELETE') ||
          (mutation.targetId === uploadId &&
            (mutation.properties as any).deleted) === true
        );
      }).mutationId
    );
  }

  public selectField(uploadId: string): void {
    this.setMutations(
      this._mutations
        .getValue()
        .filter((mutation: AgOnBoardingMutation) => {
          return (mutation.properties as any).selected !== true;
        })
        .concat([
          {
            mutationId: this.uuidv4(),
            type: 'UPDATE',
            targetId: uploadId,
            properties: {
              selected: true
            }
          }
        ])
    );
  }

  public deselectAllFields(): void {
    this.setMutations(
      this._mutations.getValue().filter((mutation: AgOnBoardingMutation) => {
        return (mutation.properties as any).selected !== true;
      })
    );
  }

  /** End Mutations */
  public importStatistics$: Observable<
    AgImportStatistics
  > = this._agImportStatisticsSubject.asObservable().pipe(shareReplay(1));

  public importInProgress$: Observable<
    boolean
  > = this._importInProgressSubject.asObservable().pipe(shareReplay(1));

  public includePlanting$: Observable<
    boolean
  > = this._includePlantingSubject.asObservable().pipe(shareReplay(1));

  public invalidPlantings$: Observable<
    any[]
  > = this._invalidPlantings.asObservable().pipe(shareReplay(1));

  public plantingNameCol$: Observable<
    string
  > = this._plantingNameColSubject.asObservable().pipe(shareReplay(1));

  public cropNameCol$: Observable<
    string
  > = this._cropNameColSubject.asObservable().pipe(shareReplay(1));

  public startDateCol$: Observable<
    string
  > = this._startDateColSubject.asObservable().pipe(shareReplay(1));

  public endDateCol$: Observable<
    string
  > = this._endDateColSubject.asObservable().pipe(shareReplay(1));

  public fieldNameCol$: Observable<
    string
  > = this._fieldNameColSubject.asObservable().pipe(shareReplay(1));

  public farmNameCol$: Observable<
    string
  > = this._farmNameColSubject.asObservable().pipe(shareReplay(1));

  public growerNameCol$: Observable<
    string
  > = this._growerNameColSubject.asObservable().pipe(shareReplay(1));

  public upload(files: FileList): void {
    this._zipController.forEach(path => {
      this._zipController.remove(path);
    });
    this._zipController.loadAsync(files[0]).then((zip: JSZip) => {
      Promise.all([
        this.getShapeFileFromZip(zip),
        this.getDatabaseFileFromZip(zip)
      ]).then(bundle => this._shapeFileSubject.next(bundle));
    });
  }

  private rawCollection$: Observable<
    AgFeatureCollection
  > = this._shapeFileSubject.asObservable().pipe(
    switchMap(this.readShapefile),
    map(this.applyUniqueIdsToFeaturesInCollection),
    shareReplay(1)
  );

  private mutatedCollection$: Observable<AgFeatureCollection> = combineLatest([
    this.rawCollection$,
    this._mutations.asObservable()
  ]).pipe(map(this.applyMutationsToCollection));

  public database$: Observable<DatabaseMap> = combineLatest([
    this.mutatedCollection$,
    this.growerNameCol$.pipe(filter(this.filterNull)),
    this.farmNameCol$.pipe(filter(this.filterNull)),
    this.fieldNameCol$.pipe(filter(this.filterNull)),
    this.plantingNameCol$,
    this.cropNameCol$,
    this.startDateCol$,
    this.endDateCol$,
    this.includePlanting$
  ]).pipe(
    tap(_ => {
      this._invalidPlantings.next([]);
    }),
    map(
      ([
        collection,
        growerNameCol,
        farmNameCol,
        fieldNameCol,
        plantingNameCol,
        cropNameCol,
        startDateCol,
        endDateCol,
        includePlanting
      ]: [
        AgFeatureCollection,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        boolean
      ]) => {
        return collection.features.reduce(
          (database: DatabaseMap, feature: AgFeature) => {
            const growerName: string = feature.properties[growerNameCol];
            const farmName: string = feature.properties[farmNameCol];
            const fieldName: string = feature.properties[fieldNameCol];
            const geometry = this.convertToMultiPolygonGeoJSON(
              feature.geometry
            );

            let plantingName: string;
            let currPlanting: DatabaseNode;

            const currGrower: DatabaseNode =
              database[growerName] === undefined
                ? {
                    type: 'GROWER',
                    parentName: null,
                    children: {} as DatabaseMap,
                    properties: {
                      name: growerName
                    }
                  }
                : database[growerName];

            const currFarm: DatabaseNode =
              currGrower.children[farmName] === undefined
                ? {
                    type: 'FARM',
                    parentName: growerName,
                    children: {} as DatabaseMap,
                    properties: {
                      name: farmName,
                      growerId: undefined
                    }
                  }
                : currGrower.children[farmName];

            const currField: DatabaseNode =
              currFarm.children[fieldName] === undefined
                ? {
                    type: 'FIELD',
                    parentName: farmName,
                    children: {} as DatabaseMap,
                    properties: {
                      name: fieldName,
                      growerId: undefined,
                      farmId: undefined,
                      geometry: geometry
                    }
                  }
                : currFarm.children[fieldName];

            if (includePlanting) {
              plantingName = feature.properties[plantingNameCol];
              const cropName: string = feature.properties[cropNameCol];
              const startDate: number = new Date(
                feature.properties[startDateCol]
              ).getTime();
              const endDate: number = new Date(
                feature.properties[endDateCol]
              ).getTime();
              const cropId = this._cropMap[cropName];
              currPlanting =
                currField.children[plantingName] === undefined
                  ? {
                      type: 'PLANTING',
                      parentName: fieldName,
                      children: {} as DatabaseMap,
                      properties: {
                        // TODO
                        name: plantingName,
                        growerId: undefined,
                        farmId: undefined,
                        fieldId: undefined,
                        cropId: cropId,
                        growingCycleStart: startDate,
                        growingCycleEnd: endDate,
                        geometry: geometry
                      }
                    }
                  : currField.children[plantingName];

              if (!cropId) {
                this._invalidPlantings.next(
                  this._invalidPlantings.getValue().concat([currPlanting])
                );
              }
            }

            return {
              ...database,
              [growerName]: {
                ...currGrower,
                children: {
                  ...currGrower.children,
                  [farmName]: {
                    ...currFarm,
                    children: {
                      ...currFarm.children,
                      [fieldName]: {
                        ...currField,
                        children: {
                          ...currField.children,
                          ...(includePlanting && {
                            [plantingName]: {
                              ...currPlanting,
                              children: { ...currPlanting.children }
                            }
                          })
                        }
                      }
                    }
                  }
                }
              }
            };
          },
          {} as DatabaseMap
        );
      }
    ),
    shareReplay(1)
  );

  public nbGrowers$: Observable<number> = this.database$.pipe(
    map(this.getNbGrowers),
    shareReplay(1)
  );
  public nbFarms$: Observable<number> = this.database$.pipe(
    map(this.getNbFarms),
    shareReplay(1)
  );
  public nbFields$: Observable<number> = this.database$.pipe(
    map(this.getNbFields),
    shareReplay(1)
  );
  public nbPlantings$: Observable<number> = combineLatest([
    this.database$,
    this.plantingNameCol$
  ]).pipe(
    switchMap(([db, plantingColName]: [DatabaseMap, string]) => {
      if (plantingColName) {
        return of(this.getNbPlantings(db));
      } else {
        return of(0);
      }
    }),
    shareReplay(1)
  );

  public importComplete$: Observable<boolean> = combineLatest([
    this.nbGrowers$,
    this.nbFarms$,
    this.nbFields$,
    this.nbPlantings$,
    this._agImportStatisticsSubject.asObservable()
  ]).pipe(
    map(
      ([nbGrowers, nbFarms, nbFields, nbPlantings, stats]: [
        number,
        number,
        number,
        number,
        AgImportStatistics
      ]) => {
        return (
          stats.nbGrowersComplete +
            stats.nbGrowersFailed +
            stats.nbFarmsComplete +
            stats.nbFarmsFailed +
            stats.nbFieldsComplete +
            stats.nbFieldsFailed +
            stats.nbPlantingsComplete +
            stats.nbPlantingsFailed ===
          nbGrowers + nbFarms + nbFields + nbPlantings
        );
      }
    ),
    shareReplay(1)
  );

  private incrementStatistic(stat: keyof AgImportStatistics): void {
    const currState = this._agImportStatisticsSubject.getValue();
    this._agImportStatisticsSubject.next({
      ...currState,
      [stat]: currState[stat] + 1
    });
  }

  public newGrowers$: Observable<Grower[]> = this.database$.pipe(
    map(this.getGrowersFromDatabase),
    shareReplay(1)
  );

  public isValidMap$: Observable<boolean> = combineLatest([
    this._growerNameColSubject.asObservable(),
    this._farmNameColSubject.asObservable(),
    this._fieldNameColSubject.asObservable(),
    this._plantingNameColSubject.asObservable(),
    this._cropNameColSubject.asObservable(),
    this._startDateColSubject.asObservable(),
    this._endDateColSubject.asObservable(),
    this._includePlantingSubject.asObservable()
  ]).pipe(
    map(
      ([grower, farm, field, planting, crop, start, end, includePlanting]: [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        boolean
      ]) => {
        return includePlanting
          ? grower !== null &&
              farm !== null &&
              field !== null &&
              planting !== null &&
              crop !== null &&
              start !== null &&
              end !== null
          : grower !== null && farm !== null && field !== null;
      }
    ),
    shareReplay(1)
  );

  public bulkImport$(): Observable<any> {
    /* 
    // stay tuned for this to be refactored
    * This currently walks the grower->farm->field->planting tree
    * to create the requests
    **/
    const concurrency = 4;
    return this.database$.pipe(
      tap(_ => this._importInProgressSubject.next(true)),
      switchMap((db: DatabaseMap) => {
        return from(
          Object.values(db).map((growerNode: DatabaseNode) => {
            return new Grower({ ...growerNode.properties } as Grower);
          })
        ).pipe(
          mergeMap(
            (item: Grower) => {
              return this._growersStateService.$create(item).pipe(
                catchError(_ => {
                  this.incrementStatistic('nbGrowersFailed');
                  return EMPTY;
                }),
                tap(_ => {
                  this.incrementStatistic('nbGrowersComplete');
                }),
                switchMap((grower: Grower) => {
                  return from(
                    Object.values(db[grower.name].children).map(
                      (farmNode: DatabaseNode) => {
                        return new Farm({
                          ...farmNode.properties,
                          growerId: grower.id
                        } as Farm);
                      }
                    )
                  ).pipe(
                    mergeMap(
                      (item: Farm) => {
                        return this._farmsStateService.$create(item).pipe(
                          catchError(_ => {
                            this.incrementStatistic('nbFarmsFailed');
                            return EMPTY;
                          }),
                          tap(_ => {
                            this.incrementStatistic('nbFarmsComplete');
                          }),
                          switchMap((farm: Farm) => {
                            return from(
                              Object.values(
                                db[grower.name].children[farm.name].children
                              ).map((fieldNode: DatabaseNode) => {
                                return new Field({
                                  ...fieldNode.properties,
                                  farmId: farm.id,
                                  growerId: grower.id
                                } as Field);
                              })
                            ).pipe(
                              mergeMap(
                                (item: Field) => {
                                  return this._fieldsStateService
                                    .$create(item)
                                    .pipe(
                                      catchError(_ => {
                                        this.incrementStatistic(
                                          'nbFieldsFailed'
                                        );
                                        return EMPTY;
                                      }),
                                      tap(_ => {
                                        this.incrementStatistic(
                                          'nbFieldsComplete'
                                        );
                                      }),
                                      switchMap((field: Field) => {
                                        return from(
                                          Object.values(
                                            db[grower.name].children[farm.name]
                                              .children[field.name].children
                                          ).map(
                                            (plantingNode: DatabaseNode) => {
                                              return new Planting({
                                                ...plantingNode.properties,
                                                fieldId: field.id,
                                                farmId: farm.id,
                                                growerId: grower.id
                                              });
                                            }
                                          )
                                        ).pipe(
                                          mergeMap(
                                            (item: Planting) => {
                                              return this._plantingsStateService
                                                .$create(item)
                                                .pipe(
                                                  catchError(_ => {
                                                    this.incrementStatistic(
                                                      'nbPlantingsFailed'
                                                    );
                                                    return EMPTY;
                                                  }),
                                                  tap(_ => {
                                                    this.incrementStatistic(
                                                      'nbPlantingsComplete'
                                                    );
                                                  })
                                                );
                                            },
                                            undefined,
                                            concurrency
                                          )
                                        );
                                      })
                                    );
                                },
                                undefined,
                                concurrency
                              )
                            );
                          })
                        );
                      },
                      undefined,
                      concurrency
                    )
                  );
                })
              );
            },
            undefined,
            concurrency
          )
        );
      })
      // tap(_ => this._importInProgressSubject.next(false))
    );
  }

  public newFields$: Observable<Field[]> = combineLatest([
    this.mutatedCollection$,
    this._currentFarmIdSubject.asObservable(),
    this._currentGrowerIdSubject.asObservable(),
    this._fieldNameColSubject.asObservable()
  ]).pipe(
    map(this.getFieldsFromCollection),
    shareReplay(1)
  );

  public columnNames$: Observable<string[]> = this.rawCollection$.pipe(
    map(this.getUniqueColumnNames),
    shareReplay(1)
  );

  public reset(): void {
    this._shapeFileSubject.next(null);
    this.plantingNameCol = null;
    this.fieldNameCol = null;
    this.farmNameCol = null;
    this.growerNameCol = null;
    this.currentFarmId = null;
    this.currentGrowerId = null;
    this.setMutations([]);
  }
}
