import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import { getTransform } from 'ol/proj';
import { fromLonLat } from 'ol/proj';
import { MapService } from '../map.service';
import { BrokerService } from 'projects/api/src/public_api';
import { environment } from 'environments/environment';

const API_URL = `${environment['BROKER_ROOT']}/geocode`;

export interface GeocoderResponse {
  results: GeocoderResult[];
  status: string;
}

export interface GeocoderResult {
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
    transformedLocation: [number, number];
    transformedViewport: [number, number, number, number];
  };
}

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {
  // ref: https://developers.google.com/maps/documentation/geocoding/start
  private _mapExtent: Extent;

  constructor(
    private _mapService: MapService,
    private _brokerService: BrokerService
  ) {
    this._mapService.onInit(() => {
      this._mapExtent = this._mapService.getMapExtent(true);

      this._mapService.map.on('moveend', () => {
        this._mapExtent = this._mapService.getMapExtent(true);
      });
    });
  }

  public search(inputString: string): Observable<GeocoderResult[]> {
    const bbox = `${this._mapExtent[1]},${this._mapExtent[0]}|${this._mapExtent[3]},${this._mapExtent[2]}`;
    return this._brokerService
      .getApiKey()
      .pipe(
        switchMap(brokerApiKey => {
          return from(
            fetch(
              `${API_URL}?${this._convertToQuery(
                inputString
              )}&bounds=${bbox}&apiKey=${brokerApiKey}`,
              {
                method: 'GET',
                cache: 'force-cache'
              })
              .then(resp => resp.json() as Promise<GeocoderResponse>)
          ).pipe(
            // prettier-ignore
            map(json => json.results.map(this._transformCoordinates))
          );
        })
      );
  }

  public reverseGeocode(coords: Coordinate): Observable<string[]> {
    // Transform from web mercator to EPSG:4326
    const transFn = getTransform('EPSG:3857', 'EPSG:4326'),
      transCoords = transFn(coords);
    return this._brokerService
      .getApiKey()
      .pipe(
        switchMap(brokerApiKey => {
          return from(
            fetch(
              `${API_URL}?latlng=${transCoords[1]},${transCoords[0]}&apiKey=${brokerApiKey}`,
              {
                method: 'GET',
                cache: 'force-cache'
              })
              .then(resp => resp.json() as Promise<GeocoderResponse>)
          ).pipe(
            // prettier-ignore
            map(json => json.results.map(result => result.formatted_address))
          );
        })
      );
  }

  private _convertToQuery(searchString: string): string {
    const regex = /^-?\d+(\.\d+)?°?\s?[NSns]?,\s?-?\d+(\.\d+)?°?\s?[EWew]?$/g;

    if (regex.test(searchString)) {
      // Then its a lat long string
      let [lat, long] = searchString.replace(/\s?°?/g, '').split(',');

      const nsTest = /(n|s)$/i.exec(lat);
      if (nsTest) {
        lat = (/s/i.test(nsTest[0]) ? '-' : '') + lat.replace(nsTest[0], '');
      }

      const ewTest = /(e|w)$/i.exec(long);
      if (ewTest) {
        long = (/w/i.test(ewTest[0]) ? '-' : '') + long.replace(ewTest[0], '');
      }

      return `latlng=${lat},${long}`;
    } else {
      return `address=${searchString}`;
    }
  }

  // Google returns coords in EPSG:4326,
  // this converts them to Web Mercator
  private _transformCoordinates(result: GeocoderResult): GeocoderResult {
    const geometry = result.geometry,
      location = geometry.location,
      viewport = geometry.viewport;
    geometry.transformedLocation = fromLonLat([location.lng, location.lat]) as [
      number,
      number
    ];

    if (viewport) {
      // bbox can sometimes have the west longitude be greater than east longitude
      // in order to avoid a longitude greater than 180 or less than -180 degrees.
      // Openlayers zoomto/goto viewport accepts
      // longitudes greater than 180 or less than -180 but requires the
      // west longitude be less than the east longitude.
      if (viewport.southwest.lng > viewport.northeast.lng) {
        // If the user is zoomed out, do we move the map left to new zealand or
        // right to new zealand. The answer depends on how much of new zealand
        // is on the east side of the international date line or the west side.
        if (
          Math.abs(viewport.southwest.lng) > Math.abs(viewport.northeast.lng)
        ) {
          viewport.southwest.lng -= 360;
        } else {
          viewport.northeast.lng += 360;
        }
      }
      geometry.transformedViewport = [
        ...fromLonLat([viewport.southwest.lng, viewport.southwest.lat]),
        ...fromLonLat([viewport.northeast.lng, viewport.northeast.lat])
      ] as [number, number, number, number];
    }

    // TODO: if we need to use the viewport attrs of the result,
    // need to transform them here too
    result.geometry = geometry;
    return result;
  }
}
