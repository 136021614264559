import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { AgBase } from './agBase.model';

export class Planting extends AgBase {
  public fieldId: string;
  public farmId: string;
  public growerId: string;
  public geometry: string | Feature | any;
  public subscriptionCount?: number;
  public readonly cropId: string;
  public readonly cropName: string;
  public readonly growingCycleStart: number;
  public readonly growingCycleEnd: number;
  public readonly weakFieldThreshold: number;

  constructor(opts: Partial<Planting>) {
    let geom1 = opts.geometry;
    delete opts.geometry;
    super(opts);

    let geom = new GeoJSON();
    let feature = geom.readFeature(geom1);
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    feature.setId(opts.id);
    feature.setProperties({
      name: opts.name,
      class: 'asset-feature'
    });

    this.geometry = feature;
  }

  public toJSON(): object {
    let geo = new GeoJSON();
    (this.geometry as Feature)
      .getGeometry()
      .transform('EPSG:3857', 'EPSG:4326');
    const pointJSON = JSON.parse(geo.writeFeature(this.geometry as Feature));

    delete this.geometry;
    this.geometry = pointJSON.geometry;
    return this;
  }
}
