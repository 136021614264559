import { Injectable } from '@angular/core';
import { BaseDataService } from '../../shared/services/base-data.service';
import { Farm, FarmService } from 'api/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class FarmsStateService extends BaseDataService<Farm> {
  constructor(private service: FarmService) {
    super({ selected: null, cached: {} }, service);
  }
}
