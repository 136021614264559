import { Injectable } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  protected valuesCache = new Map();
  protected TTL = 3 * 60 * 1000; // Expire cache in 3 minutes
  protected DELAY_TIME = 300; // Delay for loading effect

  get(url: string, params?: any, delayResponse?: number): Observable<any> {
    const key = this._createKeyCacheMap(url, params);
    const cache = this.valuesCache.get(key);
    if (cache) {
      // Validate TTL from value
      if (cache.expire > Date.now()) {
        // Add delay response
        if (delayResponse) {
          return of(cache.value).pipe(delay(delayResponse || this.DELAY_TIME));
        }
        return of(cache.value);
      } else {
        // Remove expired value from cache Map
        this.valuesCache.delete(key);
      }
    }
    return;
  }

  set(url: string, value: any, params?: any, expire?: number): void {
    const key = this._createKeyCacheMap(url, params);
    this.valuesCache.set(key, { value: value, expire: Date.now() + (expire || this.TTL) });
  }

  delete(url: string, params?: any): void {
    const key = this._createKeyCacheMap(url, params);
    this.valuesCache.delete(key);
  }

  clearAllCache(): void {
    this.valuesCache.clear();
  }

  private _createKeyCacheMap(url: string, params?: any): string {
    if (params) {
      url += JSON.stringify(omitBy(params, isEmpty));
    }
    return url;
  }
}
