import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Annotation, AnnotationEntityType } from '../models/annotation.model';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { forkJoin, from } from 'rxjs';
import { map, flatMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrderTemplate } from '../models/orderTemplate.model';
import { OrderTemplateSubscription } from '../models/orderTemplateSubscription.model';

@Injectable()
export class OrderTemplateService extends BaseService {
  protected endpointUrl: string;
  protected endpointUrlSub: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/orderTemplates`;
    this.endpointUrlSub = `${this.rootUrl}/orderTemplateSubscriptions`;
  }

  protected mapJsonToCollection(respBody: OrderTemplate[]): OrderTemplate[] {
    return respBody.map(json => new OrderTemplate(json));
  }

  protected mapJsonToCollectionSub(
    respBody: OrderTemplateSubscription[]
  ): OrderTemplateSubscription[] {
    return respBody.map(json => new OrderTemplateSubscription(json));
  }

  /**
   * @return Observable<OrderTemplate[]>
   */
  getOrderTemplates(
    baseParams?: BaseParams
  ): Observable<PagedResponse<OrderTemplate>> {
    return this.getWithPagedResponse<OrderTemplate>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getOrderTemplatesForBYOD(): Promise<OrderTemplate[]> {
    return this.httpClient
      .get<OrderTemplate[]>(this.endpointUrl, {
        params: {
          search: 'templateType==BYOD'
        }
      })
      .pipe(
        map(json => this.mapJsonToCollection(json)),
        flatMap(templates =>
          forkJoin(
            templates.map(template =>
              from(this.getOrderTemplateById(template.id))
            )
          )
        ),
        take(1)
      )
      .toPromise();
  }

  createOrderTemplate(orderTemplate: OrderTemplate): Observable<string> {
    return this.createWithResponseAsUrl(
      this.endpointUrl,
      orderTemplate.toJSON()
    );
  }

  getOrderTemplateByUrl(url: string): Observable<OrderTemplate> {
    return this.httpClient
      .get<OrderTemplate>(url)
      .pipe(map(resp => new OrderTemplate(resp)));
  }

  getOrderTemplateById(id: string): Observable<OrderTemplate> {
    return this.httpClient
      .get<OrderTemplate>(`${this.endpointUrl}/${id}`)
      .pipe(map(resp => new OrderTemplate(resp)));
  }

  updateOrderTemplate(
    orderTemplate: OrderTemplate
  ): Observable<HttpResponse<OrderTemplate>> {
    return this.putWithResponse<OrderTemplate>(
      `${this.endpointUrl}/${orderTemplate.id}`,
      orderTemplate.toJSON()
    );
  }

  patchOrderTemplate(
    id: string,
    params: object
  ): Observable<HttpResponse<OrderTemplate>> {
    return this.patchWithResponse<OrderTemplate>(
      `${this.endpointUrl}/${id}`,
      params
    );
  }

  deleteOrderTemplate(
    orderTemplateId: string
  ): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${orderTemplateId}`);
  }

  getOrderTemplateSubscriptions(
    baseParams?: BaseParams
  ): Observable<PagedResponse<OrderTemplateSubscription>> {
    return this.getWithPagedResponse<OrderTemplateSubscription>(
      this.endpointUrl,
      this.mapJsonToCollectionSub,
      baseParams
    );
  }

  getOrderTemplateSubscriptionsById(
    id: string
  ): Observable<OrderTemplateSubscription[]> {
    return this.httpClient
      .get<OrderTemplateSubscription[]>(
        `${this.endpointUrlSub}/orderTemplate/${id}`
      )
      .pipe(
        map((resp: any) => {
          return resp.map((sub: OrderTemplateSubscription) => {
            return new OrderTemplateSubscription(sub);
          });
        })
      );
  }

  getOrderTemplateSubscriptionsByCustomerId(
    customerId: string
  ): Observable<OrderTemplateSubscription[]> {
    return this.httpClient
      .get<OrderTemplateSubscription[]>(
        `${this.endpointUrlSub}/customer/${customerId}`
      )
      .pipe(
        map((resp: any) => {
          return resp.map((sub: any) => {
            return new OrderTemplateSubscription(sub);
          });
        })
      );
  }

  createOrderTemplateSubscription(
    templateId: string,
    customerId: string
  ): Promise<string> {
    const body = {
      orderTemplateId: templateId,
      customerId: customerId
    };
    return this.httpClient
      .post<any>(this.endpointUrlSub, body)
      .pipe(map(resp => resp))
      .toPromise();
  }

  deleteOrderTemplateSubscription(subId: string): Promise<string> {
    return this.httpClient
      .delete<any>(`${this.endpointUrlSub}/${subId}`)
      .pipe(map(resp => resp))
      .toPromise();
  }
}
