import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UsState, usStates } from '../../util/usStates';
import { countries, Country } from '../../util/countries';

// DEPRECATED! USE address-group INSTEAD!
@Component({
  selector: 'address-block',
  template: `
    <!-- prettier-ignore -->
    <div [formGroup]="parent">
      <div class="form__input-group" formGroupName="address">
        <div class="multiple-fields with-label">
          <div class="multiple-fields-group">
            <label>Address line 1</label>
            <input type="text" formControlName="line1" />
            <div class="error-message" *ngIf="required('line1')">
              Please input a street address
            </div>
          </div>
          <div class="multiple-fields-group">
            <label>Address line 2 (optional)</label>
            <input type="text" formControlName="line2" />
          </div>
        </div>
        <div class="multiple-fields with-label three-fields">
          <div class="multiple-fields-group__city">
            <label>City</label>
            <input type="text" formControlName="city" />
            <div class="form__error-message" *ngIf="required('city')">
              Please input a city
            </div>
          </div>
          <div class="multiple-fields-group__zip">
            <label>Zip</label>
            <input type="text" formControlName="postalCode" />
            <div class="form__error-message" *ngIf="required('postalCode')">
              Please input a valid postal code
            </div>
          </div>
          <div class="multiple-fields-group__select">
            <div class="select-wrapper">
              <select name="customers" formControlName="stateProvince">
                <option value="">State</option>
                <option *ngFor="let state of states" [value]="state.abbreviation">{{state.abbreviation}}</option>
              </select>
            </div>
            <div class="form__error-message" *ngIf="required('stateProvince')">
              Please input a state
            </div>
          </div>
        </div>
        <div class="select-wrapper">
          <select name="country" formControlName="country">
            <option value="">Country</option>
            <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
          </select>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./addressBlock.styles.scss']
})
export class AddressBlockComponent implements OnInit {
  @Input()
  parent: FormGroup;

  states: Array<UsState> = [];
  countries: Array<Country> = [];

  required(name: string) {
    if (name === 'postalCode') {
      return (
        !this.parent.get(`address.${name}`).valid &&
        this.parent.get(`address.${name}`).touched
      );
    } else {
      return (
        this.parent.get(`address.${name}`).value === '' &&
        this.parent.get(`address.${name}`).touched
      );
    }
  }

  ngOnInit() {
    this.states = usStates;
    this.countries = countries;
  }
}
