import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  Validators
} from '@angular/forms';
import { AerialInputDirective } from '../input-control/aerial-input.directive';
import { AerialPassword } from '../../util/aerialPassword';
import { handleBooleanProperty } from '../../util/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'password-control',
  template: `
    <!-- prettier-ignore -->
    <input-control>
      <input #pwdInput
        aerialInput
        [formControl]="pwdInputCtrl"
        type="{{ type }}"
        [attr.type]="type"
        placeholder="{{ placeholder }}"
        [attr.placeholder]="placeholder"
        [attr.minlength]="minlength"
        class="prefixed"
        [class.invalid]="required && control.touched && control.invalid"
        id="{{ id }}"
        [attr.id]="id"
        [required]="required"
        (blur)="onTouched()">
      <i class="toggler icon-visibility_visible input-prefix"></i>
    </input-control>
  `,
  styleUrls: ['./password-control.styles.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordControlComponent),
      multi: true
    }
  ]
})
export class PasswordControlComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  isConfirmField = false;

  @Input()
  isPasswordVisible = false;

  @Input()
  id: string;

  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  get placeholder(): string {
    return this._placeholder
      ? this._placeholder
      : this.isConfirmField
      ? 'Confirm Password'
      : 'Password';
  }

  @Input()
  set minlength(minlength: number) {
    this._minlength = Number(minlength);
  }

  get minlength(): number {
    return this._minlength;
  }

  // @Input()
  // set value(value: string) {
  //   this.writeValue(value);
  // }

  // get value(): string {
  //   return this.pwdInputCtrl.value;
  // }

  @Input()
  set required(required: boolean) {
    this._required = handleBooleanProperty(required);
    if (this._required) {
      this.pwdInputCtrl.setValidators(Validators.required);
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set disabled(disabled: boolean) {
    disabled = handleBooleanProperty(disabled);
    this.setDisabledState(disabled);
  }

  get disabled(): boolean {
    return this.pwdInputCtrl.disabled;
  }

  @Output()
  change = new EventEmitter<string>();

  @HostBinding('class.form-control')
  hasFormCtrlClass = true;

  @HostBinding('class.input-control')
  hasInputClass = true;

  @HostBinding('class.password-control')
  hasIdentClass = true;

  get control(): FormControl {
    return this.pwdInputCtrl;
  }

  get element(): HTMLInputElement {
    return this._pwdInput.nativeElement;
  }

  get type(): string {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  protected pwdInputCtrl = new FormControl(''); // default password validators

  @ViewChild('pwdInput')
  private _pwdInput: ElementRef<HTMLInputElement>;

  private readonly _defaultMinLength = AerialPassword.minlength;
  private _placeholder: string;
  private _minlength = this._defaultMinLength;
  private _required = false;
  private _disabled = false;
  private _changesSub: Subscription;

  protected onTouched = () => {};
  private _onChange = (value: string) => {};

  constructor(
    public el: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  writeValue(value: string): void {
    console.log('write value', value);
    this.pwdInputCtrl.setValue(value);
    // if (this.rangeCtrl) {
    //   this.rangeCtrl.setValue(value);
    // } else {
    //   this.rangeCtrl = new FormControl(value);
    //   // this._changesSub = this.rangeCtrl.valueChanges.subscribe(val => {
    //   //   console.log('range val changes', val);
    //   // });
    // }
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.pwdInputCtrl.disable();
    }
  }

  ngOnInit() {
    this._changesSub = this.pwdInputCtrl.valueChanges.subscribe(value => {
      console.log('pw val changes', value);
      // this._onChange(value);
      // this.change.emit(value);
    });
  }

  ngOnDestroy() {
    this._changesSub.unsubscribe();
  }
}
