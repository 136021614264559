import { Injectable } from '@angular/core';

import { AlertDialog, ConfirmDialog } from './dialog.interface';
import { DialogIntent } from './dialogIntent.interface';
import { AlertDialogComponent } from './alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DialogService {
  // Callback to call when dialog is opened
  _dialogs = new BehaviorSubject<DialogIntent>(null);

  get dialogs$(): Observable<DialogIntent> {
    return this._dialogs.asObservable();
  }

  clearDialog() {
    this._dialogs.next(null);
  }

  createDialog(intent: DialogIntent) {
    this._dialogs.next(intent);
  }

  alert(
    body: string,
    callback: Function,
    title = 'Alert',
    okBtnText = 'Okay',
    width?: string
  ) {
    const dlgData: AlertDialog = {
      body,
      okBtnText
    };
    this._dialogs.next({
      title,
      component: AlertDialogComponent,
      data: {
        successCallback: callback,
        ...dlgData
      },
      type: 'alert',
      size: 'small',
      width,
      hideCloseBtn: true
    });
  }

  confirm(
    body: string,
    onConfirm: Function,
    title = 'Confirm',
    onDeny?: Function,
    confirmBtnText = 'Okay',
    denyBtnText = 'Cancel',
    width?: string,
    titleIcon?: string,
    className?: string
  ) {
    const dlgData: ConfirmDialog = {
      body,
      confirmBtnText,
      denyBtnText
    };
    this._dialogs.next({
      title,
      titleIcon,
      component: ConfirmDialogComponent,
      data: {
        confirmBtnColor: className,
        successCallback: onConfirm,
        errorCallback: onDeny,
        ...dlgData
      },
      type: 'confirm',
      size: 'small',
      width,
      className,
      hideCloseBtn: true
    });
  }
}
