import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'precision' })

export class PrecisionPipe implements PipeTransform {
  transform(input: number, precision: number): string {
    if(typeof input === 'number') {
      if(input > 99)
        return input.toFixed(2);
      else
        return input.toPrecision(precision);
    } else if(input == null) {
      return '';
    } else {
      throw('PrecisionPipe: timestamp input needs to be a number');
    }
  }
}
