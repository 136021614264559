import {
  Validators,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup
} from '@angular/forms';

export class AerialValidation {
  static readonly phoneMask = [
    '+',
    '1',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  static readonly phoneRegex = /^\+1 \([1-9]\d\d\) \d\d\d\-\d\d\d\d$/;
  static readonly samplePhoneFormat = '+1 (000) 867-5309';

  static readonly mobilePhoneValidators = [
    Validators.required,
    Validators.pattern(AerialValidation.phoneRegex)
  ];

  static readonly zipRegex = '^[0-9]{5}(?:-[0-9]{4})?$';

  static legacyPhoneMask(rawValue: string): any[] {
    // This is for legacy phone fields since the format hasn't always been required
    const checkValue = AerialValidation.stripPhone(rawValue);
    if (checkValue.length > 10) {
      // Some legacy phone numbers have > 10 digits. Regular mask will hide the
      // digits, making the error message confusing. This just adds the extra
      // digits to the masked value. Validator will still catch & report the error.
      return AerialValidation.phoneMask.concat(
        Array(checkValue.length - 10).fill(/\d/)
      );
    } else {
      return AerialValidation.phoneMask;
    }
  }

  static legacyPhoneValidator(control: FormControl): ValidationErrors | null {
    // This is for legacy phone fields since the format hasn't always been required
    const checkValue = AerialValidation.stripPhone(control.value || '');
    if (checkValue.length !== 10) {
      return { ...control.errors, invalidPhone: true } as ValidationErrors;
    }
    return null;
  }

  static stripPhone(phone: string): string {
    return phone.replace(/^(\+)?1/, '').replace(/\D/g, '');
  }

  static requiredCheckboxValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      return !Object.values(group.value).includes(true)
        ? { requiredCheckbox: true }
        : null;
    };
  }

  static readonly dateRangeValidator = (
    startDateCtrlName = 'startDate',
    endDateCtrlName = 'endDate'
  ): ValidatorFn => (group: FormGroup): ValidationErrors | null => {
    const startDateVal = group.get(startDateCtrlName).value,
      endDateVal = group.get(endDateCtrlName).value;
    if (!startDateVal || !endDateVal) {
      return null;
    }
    const startDate = new Date(startDateVal),
      endDate = new Date(endDateVal);
    const invalidDateRange = {
      startDateVal,
      startDate,
      endDateVal,
      endDate
    };
    return startDate.getTime() > endDate.getTime()
      ? { invalidDateRange }
      : null;
  };
}
