import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe, timer } from 'rxjs';
import {
  skipWhile,
  map,
  combineLatest,
  switchMap,
  debounce,
  tap,
  shareReplay,
  share,
  distinctUntilChanged,
  filter,
  take
} from 'rxjs/operators';
import { PlantingsStateService } from '../../explorer/plantings/plantings-state.service';
import {
  Planting,
  AgSearchService,
  AgSubscription,
  AgSubscriptionService,
  PagedResponse,
  ADMAsset,
  ManagementZone, ManagementZoneService
} from 'api/src/public_api';
import { HttpResponse } from '@angular/common/http';

export type HumanReadableProductType =
  | 'All'
  | 'MSAVI'
  | 'MSAVIC'
  | 'CHL'
  | 'FCOVER'
  | 'FNPV'
  | 'FSOIL'
  | 'AMSAVICLL'
  | 'LAI'
  | 'NDVI';

export const ProductTypeDisplayColor: {
  type: HumanReadableProductType;
  color: string;
}[] = [
  {
    type: 'All',
    color: '#000000'
  },
  {
    type: 'MSAVI',
    color: '#0d21a1'
  },
  {
    type: 'AMSAVICLL',
    color: '#0d21a1' // need color for this from design
  },
  {
    type: 'CHL',
    color: '#ff0022'
  },
  {
    type: 'FCOVER',
    color: '#fe5000'
  },
  {
    type: 'FNPV',
    color: '#2eb582'
  },
  {
    type: 'FSOIL',
    color: '#009ffd'
  },
  {
    type: 'LAI',
    color: '#7768ae'
  },
  {
    type: 'NDVI',
    color: '#da1884'
  }
];

const tuple = <T extends string[]>(...args: T) => args;
const usableProductTypes = tuple(
  // 'natural color JP2',
  // 'natural color KMZ',
  // 'color infrared JP2',
  // 'color infrared KMZ',
  // 'dashboard report PDF',
  'colorized msavi JP2',
  // 'colorized msavi KMZ',
  'raw msavi TIF',
  // 'colorized msavi change JP2',
  // 'colorized msavi change KMZ',
  // 'raw msavi change TIF',
  // 'colorized significant areas JP2',
  // 'colorized significant areas KMZ',
  // 'colorized significant areas change JP2',
  // 'colorized significant areas change KMZ',
  // 'Verde FIELDIMAGE DISPLAY JPG',
  'Verde CHL FULLCYCLE PNG',
  'Verde CHL TIF',
  'Verde FCOVER FULLCYCLE PNG',
  'Verde FCOVER TIF',
  'Verde FNPV FULLCYCLE PNG',
  'Verde FNPV TIF',
  'Verde FSOIL FULLCYCLE PNG',
  'Verde FSOIL TIF',
  'Verde LAI EARLYSTAGE PNG',
  'Verde LAI FULLCYCLE PNG',
  'Verde LAI TIF',
  'Verde NDVI FULLCYCLE PNG',
  'Verde NDVI TIF'
);
type ComputerReadableProductType = typeof usableProductTypes[number];

export type AnalyticMap = {
  [key in HumanReadableProductType]: {
    raw: ADMAsset[];
    color: ADMAsset[];
  };
};

export interface ProductMap {
  humanName: HumanReadableProductType;
  type: 'color' | 'raw';
}

export type ProductToHumanNameMapType = {
  [key in ComputerReadableProductType]?: ProductMap;
};

// ... yep. AFAIK there does not currently exist a programatic way to make this association
export const ProductToHumanNameMap: ProductToHumanNameMapType = {
  'colorized msavi JP2': {
    humanName: 'MSAVI',
    type: 'color'
  },
  'raw msavi TIF': {
    humanName: 'MSAVI',
    type: 'raw'
  },
  // 'colorized msavi change JP2': {
  //   humanName: 'MSAVIC',
  //   type: 'color'
  // },
  // 'raw msavi change TIF': {
  //   humanName: 'MSAVIC',
  //   type: 'raw'
  // },
  'Verde CHL FULLCYCLE PNG': {
    humanName: 'CHL',
    type: 'color'
  },
  'Verde CHL TIF': {
    humanName: 'CHL',
    type: 'raw'
  },
  'Verde FCOVER FULLCYCLE PNG': {
    humanName: 'FCOVER',
    type: 'color'
  },
  'Verde FCOVER TIF': {
    humanName: 'FCOVER',
    type: 'raw'
  },
  'Verde FNPV FULLCYCLE PNG': {
    humanName: 'FNPV',
    type: 'color'
  },
  'Verde FNPV TIF': {
    humanName: 'FNPV',
    type: 'raw'
  },
  'Verde FSOIL FULLCYCLE PNG': {
    humanName: 'FSOIL',
    type: 'color'
  },
  'Verde FSOIL TIF': {
    humanName: 'FSOIL',
    type: 'raw'
  },
  'Verde LAI EARLYSTAGE PNG': {
    humanName: 'LAI',
    type: 'color'
  },
  'Verde LAI FULLCYCLE PNG': {
    humanName: 'LAI',
    type: 'color'
  },
  'Verde LAI TIF': {
    humanName: 'LAI',
    type: 'raw'
  },
  'Verde NDVI FULLCYCLE PNG': {
    humanName: 'NDVI',
    type: 'color'
  },
  'Verde NDVI TIF': {
    humanName: 'NDVI',
    type: 'raw'
  }
};

@Injectable({
  providedIn: 'root'
})
export class PlantingDataService {
  private _plantingIdSubject = new BehaviorSubject<string>('');
  private _visibleDateRangeSubject = new BehaviorSubject<[number, number]>([
    0,
    0
  ]);
  private _selectedDateSubject = new BehaviorSubject<number>(
    new Date().getTime()
  );
  private _selectedDerivativeSubject = new BehaviorSubject<ADMAsset>(null);
  private _selectedDerivativeTypeSubject = new BehaviorSubject<
    HumanReadableProductType
  >('All');

  public productTypeDisplayColor = ProductTypeDisplayColor;
  public selectedDerivativeTypeColor = '#000000'; // default to black for now

  set plantingId(id: string) {
    this._plantingIdSubject.next(id);
  }

  set visibleDateRange(range: [number, number]) {
    this._visibleDateRangeSubject.next(range);
  }

  set selectedDate(date: number) {
    this._selectedDateSubject.next(date);
  }

  set selectedDerivative(derivative: ADMAsset) {
    this._selectedDerivativeSubject.next(derivative);
  }

  set selectedDerivativeType(type: HumanReadableProductType) {
    this.selectedDerivativeTypeColor = ProductTypeDisplayColor.find(
      productMatch => {
        return productMatch.type === type;
      }
    ).color;
    this._selectedDerivativeTypeSubject.next(type);
  }

  constructor(
    private _plantingsStateService: PlantingsStateService,
    private _agSearchService: AgSearchService,
    private _agSubscriptionService: AgSubscriptionService,
    private _managementZoneService: ManagementZoneService
  ) {}

  private convertEpochToUTC(epoch: number): string {
    return new Date(epoch).toISOString();
  }

  private responseMap = () =>
    pipe(
      map((res: HttpResponse<any[]>) => {
        return (res.body as any).features.map((feature: ADMAsset) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              ...(feature.properties.acquisitionDate && {
                acquisitionDate: Date.parse(<any>(
                  feature.properties.acquisitionDate
                ))
              })
            }
          };
        });
      })
    );

  public plantingId$: Observable<
    string
  > = this._plantingIdSubject.asObservable().pipe(
    distinctUntilChanged(),
    share()
  );

  public selectedDate$: Observable<
    number
  > = this._selectedDateSubject.asObservable().pipe(
    distinctUntilChanged(),
    shareReplay()
  );

  public selectedDerivativeType$: Observable<
    HumanReadableProductType
  > = this._selectedDerivativeTypeSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public visibleDateRangeObs$: Observable<
    [number, number]
  > = this._visibleDateRangeSubject.asObservable().pipe(
    filter((range: [number, number]) => {
      return range[0] !== 0 && range[1] !== 0;
    }),
    distinctUntilChanged(),
    share()
  );

  public selectedDerivative$: Observable<
    ADMAsset
  > = this._selectedDerivativeSubject.asObservable().pipe(
    distinctUntilChanged(),
    share()
  );

  public visibleDateRange$: Observable<
    [string, string]
  > = this.visibleDateRangeObs$.pipe(
    map((range: [number, number]) => {
      return <[string, string]>[
        this.convertEpochToUTC(range[0]),
        this.convertEpochToUTC(range[1])
      ];
    }),
    distinctUntilChanged((prev: [string, string], curr: [string, string]) => {
      return prev[0] === curr[0] && prev[1] === curr[1];
    }),
    share()
  );

  public planting$: Observable<Planting> = this.plantingId$.pipe(
    map((id: string) => {
      return {
        plantingId: id
      };
    }),
    this._plantingsStateService.loadEntityOpr('plantingId'),
    tap((planting: Planting) => {
      this.visibleDateRange = [
        planting.growingCycleStart,
        planting.growingCycleEnd
      ];
    }),
    distinctUntilChanged((prev: Planting, curr: Planting) => {
      return prev.id === curr.id;
    }),
    shareReplay()
  );

  public reports$: Observable<ADMAsset[]> = this.planting$.pipe(
    combineLatest(this.visibleDateRange$),
    switchMap(([planting, visibleDateRange]: [Planting, [string, string]]) => {
      return this._agSearchService.getAssets({
        plantingId: planting.id,
        product: 'dashboard report PDF',
        ...(visibleDateRange[0] !== '1970-01-01T00:00:00.000Z' && {
          acquisitionDate: `[${visibleDateRange[0]},${visibleDateRange[1]}]`
        })
      });
    }),
    this.responseMap(),
    combineLatest(this.selectedDate$),
    map(([reports, selectedDate]: [ADMAsset[], number]) => {
      return reports.filter((report: ADMAsset) => {
        return report.properties.acquisitionDate === selectedDate;
      });
    }),
    shareReplay()
  );

  public zones$: Observable<ManagementZone[]> = this.planting$.pipe(
    switchMap( (planting: Planting) => {
        return this._managementZoneService.getByPlantingId(planting.id);
      }),
    map((resp: PagedResponse<ManagementZone>) => {
      return resp.data;
    }),
    shareReplay(),
  );

  public allDerivatives$: Observable<any> = this.planting$.pipe(
    combineLatest(this.visibleDateRange$),
    switchMap(([planting, visibleDateRange]: [Planting, [string, string]]) => {
      return this._agSearchService.getAssets({
        plantingId: planting.id,
        product: `{${usableProductTypes}}`,
        ...(visibleDateRange[0] !== '1970-01-01T00:00:00.000Z' && {
          acquisitionDate: `[${visibleDateRange[0]},${visibleDateRange[1]}]`
        }),
        count: '1000'
      });
    }),
    this.responseMap()
  );

  public createDerivativeMap = (
    derivatives: ADMAsset[],
    initial: AnalyticMap
  ): AnalyticMap => {
    return derivatives.reduce((acc: AnalyticMap, derivative: ADMAsset) => {
      const productMap = ProductToHumanNameMap[derivative.properties.product];
      return {
        ...acc,
        All: {
          ...acc.All,
          [productMap.type]: acc.All[productMap.type].concat([derivative])
        },
        [productMap.humanName]:
          acc[productMap.humanName] === undefined
            ? {
                [productMap.type]: [derivative]
              }
            : {
                ...acc[productMap.humanName],
                [productMap.type]:
                  acc[productMap.humanName][productMap.type] === undefined
                    ? [derivative]
                    : acc[productMap.humanName][productMap.type].concat([
                        derivative
                      ])
              }
      };
    }, initial);
  };

  public allMappedDerivatives$: Observable<
    AnalyticMap
  > = this.allDerivatives$.pipe(
    map((derivatives: ADMAsset[]) => {
      return this.createDerivativeMap(derivatives, {
        All: { raw: [], color: [] }
      } as AnalyticMap);
    }),
    shareReplay()
  );

  public filteredMappedDerivatives$: Observable<
    AnalyticMap
  > = this.allMappedDerivatives$.pipe(
    combineLatest(this.selectedDerivativeType$),
    map(
      ([analyticMap, selectedDerivativeType]: [
        AnalyticMap,
        HumanReadableProductType
      ]) => {
        return {
          [selectedDerivativeType]: analyticMap[selectedDerivativeType]
        } as AnalyticMap;
      }
    )
  );

  public analyticsOnDay$: Observable<
    AnalyticMap
  > = this.allMappedDerivatives$.pipe(
    combineLatest(this.selectedDate$),
    map(([mappedDerivatives, selectedDate]: [AnalyticMap, number]) => {
      return Object.keys(mappedDerivatives)
        .filter((key: HumanReadableProductType) => {
          return key !== 'All';
        })
        .reduce(
          (acc: AnalyticMap, curr: string) => {
            return {
              ...acc,
              [curr]: {
                raw: mappedDerivatives[curr].raw.filter(
                  (derivative: ADMAsset) => {
                    return (
                      derivative.properties.acquisitionDate === selectedDate
                    );
                  }
                ),
                color: mappedDerivatives[curr].color.filter(
                  (derivative: ADMAsset) => {
                    return (
                      derivative.properties.acquisitionDate === selectedDate
                    );
                  }
                )
              }
            };
          },
          {} as AnalyticMap
        );
    }),
    shareReplay()
  );

  public sortedAvailabledDates$: Observable<
    number[]
  > = this.filteredMappedDerivatives$.pipe(
    map((analyticMap: AnalyticMap) => {
      return Object.values(analyticMap)[0]
        .raw.map((derivative: ADMAsset) => {
          return derivative.properties.acquisitionDate;
        })
        .sort();
    })
  );

  public nextDayAvailable$: Observable<
    boolean
  > = this.sortedAvailabledDates$.pipe(
    combineLatest(this.selectedDate$),
    map(([sortedDates, selectedDate]: [number[], number]) => {
      return sortedDates.indexOf(selectedDate) < sortedDates.length;
    }),
    shareReplay()
  );

  public nextDay() {
    this.sortedAvailabledDates$.pipe(take(1)).subscribe((dates: number[]) => {
      this.selectedDate =
        dates[dates.indexOf(this._selectedDateSubject.value) + 1];
    });
  }

  public prevDayAvailable$: Observable<
    boolean
  > = this.sortedAvailabledDates$.pipe(
    combineLatest(this.selectedDate$),
    map(([sortedDates, selectedDate]: [number[], number]) => {
      return sortedDates.indexOf(selectedDate) > 0;
    }),
    shareReplay()
  );

  public prevDay() {
    this.sortedAvailabledDates$.pipe(take(1)).subscribe((dates: number[]) => {
      this.selectedDate =
        dates[dates.indexOf(this._selectedDateSubject.value) - 1];
    });
  }

  public selectedDerivativeMetric$: Observable<
    number
  > = this.filteredMappedDerivatives$.pipe(
    combineLatest(this.selectedDate$),
    map(([analyticMap, selectedDate]: [AnalyticMap, number]) => {
      const entity = Object.values(analyticMap)[0].raw.find(
        (derivative: ADMAsset) => {
          return derivative.properties.acquisitionDate === selectedDate;
        }
      );
      return entity === undefined ? 0 : entity.properties.mean;
    })
  );

  public subscriptions$: Observable<AgSubscription[]> = this.planting$.pipe(
    switchMap((planting: Planting) => {
      return this._agSubscriptionService.getByPlantingId(planting.id);
    }),
    map((resp: PagedResponse<AgSubscription>) => {
      return resp.data;
    })
  );
}
