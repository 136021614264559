import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  transform(
    dateRange: [number, number],
    format = 'mediumDate',
    timezone?: string,
    locale = 'en-US'
  ): string {
    return dateRange[0] === dateRange[1]
      ? formatDate(dateRange[0], format, locale, timezone)
      : `${formatDate(dateRange[0], format, locale, timezone)} - ${formatDate(
          dateRange[1],
          format,
          locale,
          timezone
        )}`;
  }
}
