import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { Analytic } from '../models/analytic.model';
import { AnalyticRun } from '../models/analytic-run.model';
import { AnalyticPreset } from '../models/analytic-preset.model';
import { Observable } from 'rxjs';

export interface AnalyticRunCreatePayload {
  name: string;
  projectId: string;
  analyticId: string;
  presetId: string;
  inputDataProductIds: string[];
  aoi: any; // TODO: Need geojson inferfaces
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/analytics`;
  }

  getAnalytic(analyticId: string): Observable<Analytic> {
    return this.get<Analytic>(`${this.endpointUrl}/${analyticId}`);
  }

  getAnalytics(baseParams?: BaseParams): Observable<PagedResponse<Analytic>> {
    return this.getWithPagedResponse<Analytic>(
      this.endpointUrl,
      this._mapJsonToCollection,
      baseParams
    );
  }

  getPresetsForAnalytic(
    analyticId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<AnalyticPreset>> {
    return this.getWithPagedResponse<AnalyticPreset>(
      `${this.endpointUrl}/${analyticId}/presets`,
      respBody => respBody.map(json => new AnalyticPreset(json)),
      baseParams
    );
  }

  getPreset(analyticPresetId: string): Observable<AnalyticPreset> {
    return this.get<AnalyticPreset>(
      `${this.endpointUrl}/presets/${analyticPresetId}`
    );
  }

  createPreset(
    analyticId: string,
    presetPayload: Partial<AnalyticPreset>
  ): Observable<string> {
    return this.createWithResponseAsUrl(
      `${this.endpointUrl}/${analyticId}/presets`,
      presetPayload
    );
  }

  deletePreset(analyticPresetId: string): Observable<any> {
    return this.delete(`${this.endpointUrl}/presets/${analyticPresetId}`);
  }

  createAnalyticRun(payload: AnalyticRunCreatePayload): Observable<string> {
    return this.createWithResponseAsUrl(`${this.endpointUrl}/runs`, payload);
  }

  getAnalyticRun(analyticRunId: string): Observable<AnalyticRun> {
    return this.get<AnalyticRun>(`${this.endpointUrl}/runs/${analyticRunId}`);
  }

  getAnalyticRunsForProject(
    projectId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<any>> {
    return this.getWithPagedResponse<any>(
      `${this.endpointUrl}/runs/project/${projectId}`,
      respBody => respBody.map(json => new AnalyticRun(json)),
      baseParams
    );
  }

  patchAnalyticRun(analyticRunId: string, params: Partial<AnalyticRun>) {
    return this.patchWithResponse(
      `${this.endpointUrl}/runs/${analyticRunId}`,
      params
    );
  }

  acceptAnalyticRun(analyticRunId: string) {
    return this.postWithResponse(
      `${this.endpointUrl}/runs/${analyticRunId}/accepted`,
      {}
    );
  }

  rejectAnalyticRun(analyticRunId: string) {
    return this.postWithResponse(
      `${this.endpointUrl}/runs/${analyticRunId}/declined`,
      {}
    );
  }

  private _mapJsonToCollection(respBody: Analytic[]): Analytic[] {
    return respBody.map(json => new Analytic(json));
  }
}
