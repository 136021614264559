import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AerialPassword } from '../../util/aerialPassword';

@Component({
  selector: 'password-requirements',
  templateUrl: './password-requirements.template.html',
  styleUrls: ['./password-requirements.styles.scss']
})
export class PasswordRequirementsComponent {
  @Input() parent: FormGroup;

  get pwdCtrl(): FormControl {
    return this.parent.get('pwd') as FormControl;
  }

  get confirmPwdCtrl(): FormControl {
    return this.parent.get('confirmPwd') as FormControl;
  }

  get isFormReset(): boolean {
    return !this.pwdCtrl.value && !this.confirmPwdCtrl.value;
  }

  get minlengthReqState(): string {
    return this.pwdCtrl.pristine || this.isFormReset
      ? 'neutral'
      : !this.pwdCtrl.value || this.pwdCtrl.hasError('minlength')
      ? 'invalid'
      : 'valid';
  }

  get minlengthReqIcon(): string {
    return this._reqIcons[this.minlengthReqState];
  }

  get patternReqState(): string {
    return this.pwdCtrl.pristine || this.isFormReset
      ? 'neutral'
      : !this.pwdCtrl.value || this.pwdCtrl.hasError('pattern')
      ? 'invalid'
      : 'valid';
  }

  get patternReqIcon(): string {
    return this._reqIcons[this.patternReqState];
  }

  get matchReqState(): string {
    return this.parent.pristine || this.isFormReset
      ? 'neutral'
      : this.parent.hasError('passwordMismatch')
      ? 'invalid'
      : 'valid';
  }

  get matchReqIcon(): string {
    return this._reqIcons[this.matchReqState];
  }

  protected minlength = AerialPassword.minlength;
  protected validSpecialChars = AerialPassword.validSpecialChars;

  private readonly _reqIcons = {
    neutral: 'icon-check',
    valid: 'icon-check',
    invalid: 'icon-close'
  };
}
