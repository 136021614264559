import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from 'projects/utils/src/public_api';

// Map UI
import { AerialMapComponent } from './aerial-map/aerial-map.component';
import { BasemapSelectorComponent } from './aerial-map/basemap-selector/basemap-selector.component';
import { MapContextComponent } from './aerial-map/map-context/map-context.component';
import { AerialStaticMapComponent } from './aerial-map/aerial-static-map/aerial-static-map.component';

@NgModule({
  imports: [CommonModule, UtilsModule],
  declarations: [
    AerialMapComponent,
    BasemapSelectorComponent,
    MapContextComponent,
    AerialStaticMapComponent
  ],
  exports: [
    AerialMapComponent,
    BasemapSelectorComponent,
    MapContextComponent,
    AerialStaticMapComponent
  ]
})
export class MapModule {}
