import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { Observable } from 'rxjs';
import { AgBase } from '../models/agBase.model';

export interface AgBaseParams {
  customerId?: string;
}

@Injectable()
export abstract class AgBaseService<T extends AgBase> extends BaseService {
  protected endpointUrl: string;

  constructor(path: string, protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.agRootUrl}${path}`;
  }

  public abstract marshall(json: T): T;

  public list(
    baseParams?: BaseParams & AgBaseParams
  ): Observable<PagedResponse<T>> {
    return this.getWithPagedResponse<T>(
      `${this.endpointUrl}/`,
      (respBody: T[]): T[] => {
        return respBody.map(json => this.marshall(json));
      },
      baseParams
    );
  }

  public create(item: Partial<T>): Observable<HttpResponse<T>> {
    return this.createWithResponse<T>(`${this.endpointUrl}/`, item);
  }

  public getById(id: string): Observable<HttpResponse<T>> {
    return this.getWithResponse<T>(`${this.endpointUrl}/${id}/`);
  }

  public update(item: T): Observable<HttpResponse<T>> {
    return this.putWithResponse<T>(`${this.endpointUrl}/${item.id}/`, item);
  }

  public patch(id: string, params: Partial<T>): Observable<HttpResponse<T>> {
    return this.patchWithResponse<T>(`${this.endpointUrl}/${id}/`, params);
  }

  public delete(id: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${id}/`);
  }
}
