import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map } from 'rxjs/operators';
import { Asset } from '../models/asset.model';
import { Observable } from 'rxjs';
import { ManagementZone } from '../models/management-zone.model';
import { VRT } from '../models/vrt.model';

@Injectable()
export class ManagementZoneService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    //this.endpointUrl = `${this.rootUrl}/features/zones`;
    this.endpointUrl = `${this.agRootUrl}/ag/zones`;
  }

  public create(
    item: Partial<ManagementZone>
  ): Observable<HttpResponse<ManagementZone>> {
    return this.createWithResponse<ManagementZone>(
      `${this.endpointUrl}/`,
      item
    );
  }

  public getByPlantingId(
    id: string,
    baseParams?: BaseParams,
    options: {
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    } = {}): Observable<PagedResponse<ManagementZone>> {
    return this.getWithPagedResponse<ManagementZone>(
      `${this.endpointUrl}/planting/${id}`,
      (respBody: ManagementZone[]): ManagementZone[] => {
        return respBody.map(json => new ManagementZone(json));
      },
      baseParams,
      options
    );
  }

  // these are options but will need to be handled as paged like above
  /* public getAllZones(): Observable<HttpResponse<ManagementZone>> {
    return this.getWithResponse<ManagementZone>(`${this.endpointUrl}/`);
  } */

  /* public getByFieldId(id: string): Observable<HttpResponse<ManagementZone>> {
    return this.getWithResponse<ManagementZone>(`${this.endpointUrl}/field/${id}`);
  } */

  /* public getByUserId(id: string): Observable<HttpResponse<ManagementZone>> {
    return this.getWithResponse<ManagementZone>(`${this.endpointUrl}/user/${id}`);
  } */

  public getById(id: string): Observable<HttpResponse<ManagementZone>> {
    return this.getWithResponse<ManagementZone>(`${this.endpointUrl}/${id}`);
  }

  public patch(
    id: string,
    params: Partial<ManagementZone>
  ): Observable<HttpResponse<ManagementZone>> {
    return this.patchWithResponse<ManagementZone>(
      `${this.endpointUrl}/${id}`,
      params
    );
  }

  public delete(id: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${id}`);
  }

  public download(zoneId: string): Observable<Blob> {
    const url = `${this.endpointUrl}/${zoneId}/download`;
    const options = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  // VRT endpoints are also based off of management zones

  public getVRTByZoneId(
    zoneId: string,
    baseParams: BaseParams = {},
    options: {
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    } = {}
  ): Observable<PagedResponse<VRT>> {
    return this.getWithPagedResponse<VRT>(
      `${this.endpointUrl}/${zoneId}/vrt/`,
      (respBody: VRT[]): VRT[] => {
        return respBody.map(json => new VRT(json));
      },
      baseParams,
      options
    );
  }

  public getVRTById(zoneId: string, vrtId: string): Observable<HttpResponse<VRT>> {
    return this.getWithResponse<VRT>(`${this.endpointUrl}/${zoneId}/vrt/${vrtId}`);
  }

  public downloadVRT(zoneId: string, vrtId: string): Observable<Blob> {
    const url = `${this.endpointUrl}/${zoneId}/vrt/${vrtId}/download`;
    const options = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  public deleteVRT(zoneId: string, vrtId: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${zoneId}/vrt/${vrtId}`);
  }

  public createVRT(
    zoneId: string,
    vrtPayload: Partial<VRT>
  ): Observable<HttpResponse<VRT>> {
    return this.createWithResponse<VRT>(
      `${this.endpointUrl}/${zoneId}/vrt/`,
      vrtPayload
    );
  }

}
