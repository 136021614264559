import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Toast } from './toast.model';
import { Alert } from './alert.model';

@Injectable()
export class ToasterService {
  private _toastSubj = new Subject<Toast>();
  private _alertSubj = new Subject<Alert>();

  get toast$(): Observable<Toast> {
    return this._toastSubj.asObservable();
  }

  get alert$(): Observable<Alert> {
    return this._alertSubj.asObservable();
  }

  toast(
    contextType: string,
    message: string,
    customIconClass?: string,
    hideIcon = false
  ): void {
    const toast = new Toast(contextType, message, customIconClass, hideIcon);
    this._toastSubj.next(toast);
  }

  alert(
    contextType: string,
    message: string,
    customIconClass?: string,
    hideIcon = false
  ): void {
    const alert = new Alert(contextType, message, customIconClass, hideIcon);
    this._alertSubj.next(alert);
  }

  clearAlerts(): void {
    this._alertSubj.next();
  }

  clearToasts(): void {
    this._toastSubj.next();
  }
}
