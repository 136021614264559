import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// Local Files
import { BaseService } from './base.service';
import { Fulfillment } from '../models/fulfillment.model';
import { Requirement } from '../interfaces/requirement.interface';

// RXJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CoordinatorService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/coordinator`;
  }

  /**
   * @returns Promise<Fulfillment>
   */
  getFullfillmentsByOrderId(orderId: string): Observable<Fulfillment> {
    return this.httpClient.get<Fulfillment>(
      `${this.endpointUrl}/fulfillments/order/${orderId}`
    );
  }

  /**
   * @returns Observable<Fulfillment>
   */
  getFullfillmentsByMissionId(missionId: string): Observable<Fulfillment> {
    return this.httpClient.get<Fulfillment>(
      `${this.endpointUrl}/fulfillments/mission/${missionId}`
    );
  }

  getSemephore(id: string): Promise<Requirement> {
    return this.httpClient
      .get<Requirement>(`${this.endpointUrl}/semaphores/${id}`)
      .toPromise();
  }

  getSemaphoreByReferenceId(referenceId: string): Promise<Requirement> {
    return this.httpClient
      .get<Requirement>(
        `${this.endpointUrl}/semaphores/referenceId/${referenceId}`
      )
      .toPromise();
  }

  getSemaphoreForFulfillment(fulfillmentId: string): Promise<Requirement> {
    return this.httpClient
      .get<Requirement>(
        `${this.endpointUrl}/fulfillments/${fulfillmentId}/semaphores`
      )
      .toPromise();
  }

  markSemaphoreAsComplete(id: string): Promise<HttpResponse<Requirement>> {
    return this.postWithResponse<Requirement>(
      `${this.endpointUrl}/semaphores/${id}/complete`,
      null
    ).toPromise();
  }

  markSemaphoreAsFailed(id: string): Promise<HttpResponse<Requirement>> {
    return this.postWithResponse<Requirement>(
      `${this.endpointUrl}/semaphores/${id}/fail`,
      null
    ).toPromise();
  }

  publishEventToTopic(
    topic: string,
    body: any
  ): Promise<HttpResponse<Requirement>> {
    return this.postWithResponse<any>(
      `${this.endpointUrl}/events/publish/${topic}`,
      body
    ).toPromise();
  }
}
