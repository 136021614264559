import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'number-picker',
  template: `
    <!-- prettier-ignore -->
    <div class="number-picker" [ngClass]="{'alt-style': altStyle}">
      <button
        type="button"
        class="number-picker-ctrl secondary small"
        [class.active]="isActive"
        (click)="decreaseValue()"
        [disabled]="disabled">
        <i [class]="incrementDownIcon"></i>
      </button>
      <input
        type="number"
        [formControl]="numberPicker"
        class="number-picker-input"
        [min]="min"
        [max]="max"
        (keyup.enter)="enterValue()"
        (focus)="isActive = true"
        (blur)="isActive = false"
        [tabindex]="index + 1">
      <button
        type="button"
        class="number-picker-ctrl secondary small"
        [class.active]="isActive"
        (click)="increaseValue()"
        [disabled]="disabled">
        <i [class]="incrementUpIcon"></i>
      </button>
    </div>
  `,
  styleUrls: ['./number-picker.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberPickerComponent implements OnInit, OnDestroy {
  @Input()
  min = 0;
  @Input()
  max = 100;
  @Input()
  step = 1;
  @Input()
  precision = 1;
  @Input()
  disabled = false;
  @Input()
  startingValue = 0;
  @Input()
  altStyle = false;
  @Input()
  index = 1;
  @Output()
  onChange: EventEmitter<number> = new EventEmitter();

  protected numberPicker: FormControl;
  protected isActive = false;
  incrementUpIcon: string;
  incrementDownIcon: string;

  numPickerSub: Subscription;

  ngOnInit() {
    this.numberPicker = new FormControl({
      value: this.startingValue,
      disabled: this.disabled
    });
    /* this.numberPicker.registerOnChange(() => {
      this.onChange.emit(this.numberPicker.value);
    }); */
    // ^ keeping for now but below version is needed for ag and appears to solve above too
    this.numPickerSub = this.numberPicker.valueChanges.subscribe(() => {
      this.onChange.emit(this.numberPicker.value);
    });
    this.incrementUpIcon = this.altStyle ? 'icon-plus' : 'icon-disclosure_up';
    this.incrementDownIcon = this.altStyle ? 'icon-dash' : 'icon-disclosure_down';
  }

  increaseValue(): void {
    let currentValue = this.numberPicker.value;
    if (currentValue < this.max) {
      currentValue = currentValue + this.step;
      if (this.precision != null) {
        currentValue = this.round(currentValue, this.precision);
      }
      this.numberPicker.setValue(currentValue);
    }
  }

  enterValue(): void {
    const currentValue = this.numberPicker.value;
    this.numberPicker.setValue(currentValue);
  }

  decreaseValue(): void {
    let currentValue = this.numberPicker.value;
    if (currentValue > this.min) {
      currentValue = currentValue - this.step;
      if (this.precision != null) {
        currentValue = this.round(currentValue, this.precision);
      }
      this.numberPicker.setValue(currentValue);
    }
  }

  round(value: number, precision: number): number {
    const multiplier: number = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getValue(): number {
    return this.numberPicker.value;
  }

  ngOnDestroy() {
    if (this.numPickerSub) {
      this.numPickerSub.unsubscribe();
    }
  }
}
