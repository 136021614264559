import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

export interface NextPrevOptions {
  size?: 'small' | 'medium' | 'large';
  arrowStyle?: 'caret' | 'chevron';
  theme?: 'dark' | 'neutral'; // TODO: add 'light' theme
  currentPage?: number;
  totalPages?: number;
}

export enum NextPrevAction {
  Next = 'Next',
  Prev = 'Prev'
}

@Component({
  selector: 'next-prev-controls',
  templateUrl: './next-prev-controls.template.html',
  styleUrls: ['./next-prev-controls.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextPrevControlsComponent {
  @Input()
  set size(size: 'small' | 'medium' | 'large') {
    this._options.size = size;
  }

  @Input()
  set arrowStyle(arrowStyle: 'caret' | 'chevron') {
    this._options.arrowStyle = arrowStyle;
  }

  @Input()
  set theme(theme: 'dark' | 'neutral') {
    this._options.theme = theme;
  }

  @Input()
  set currentPage(currentPage: number) {
    this._options.currentPage = currentPage;
  }

  @Input()
  set totalPages(totalPages: number) {
    this._options.totalPages = totalPages;
  }

  @Input()
  set options(options: NextPrevOptions) {
    this._options = options;
  }

  @Output()
  change: EventEmitter<NextPrevAction>;

  @Output()
  pageChange = new EventEmitter<number>();

  @Output()
  next = new EventEmitter<void>();

  @Output()
  previous = new EventEmitter<void>();

  get size(): 'small' | 'medium' | 'large' {
    return this._options.size ? this._options.size : 'small';
  }

  get arrowStyle(): 'caret' | 'chevron' {
    return this._options.arrowStyle ? this._options.arrowStyle : 'caret';
  }

  get theme(): 'dark' | 'neutral' {
    return this._options.theme ? this._options.theme : 'neutral';
  }

  get currentPage(): number {
    return this._options.currentPage ? this._options.currentPage : 0;
  }

  get totalPages(): number {
    return this._options.totalPages ? this._options.totalPages : 1;
  }

  get classes(): string[] {
    return [
      'next-prev-ctrl',
      'square',
      this.size,
      `${this.arrowStyle}-icon`,
      'secondary',
      this.theme === 'dark' ? '' : this.theme
    ];
  }

  get icon(): 'disclosure' | 'triangle' {
    return this.arrowStyle === 'chevron' ? 'disclosure' : 'triangle';
  }

  NextPrevAction = NextPrevAction;
  private _options: NextPrevOptions = {};

  constructor() {
    this.change = new EventEmitter<NextPrevAction>(null);
  }

  protected fire(action: NextPrevAction) {
    this.currentPage =
      action === NextPrevAction.Next
        ? this.currentPage + 1
        : this.currentPage - 1;
    this.change.emit(action);
    this[action === NextPrevAction.Next ? 'next' : 'previous'].emit();
    this.pageChange.emit(this.currentPage);
  }
}
