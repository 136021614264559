import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  forwardRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { DialogIntent, DialogService } from 'projects/ui/src/public_api';
import { AgListDialogComponent } from '../../dialogs/ag-list-dialog/ag-list-dialog.component';

@Component({
  selector: 'input-list-selector',
  templateUrl: './input-list-selector.template.html',
  styleUrls: ['./input-list-selector.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputListSelectorComponent),
      multi: true
    }
  ]
})

export class InputListSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() listInput = []; // incoming list from parent that is fed to dialog for selection
  @Input() dialogTitle: string; // optional- name dialog
  @Input() displayField: string; // optional - chose data filed to display (default 'name')
  @Input() displayOnly: boolean; // if wanting to show result but not allow selection
  @Input() chicletLetter: string; // if design calls for chiclet, provide letter
  @Input() chicletColor: string; // if design calls for chiclet, provide color
  @Input() searchFilter = true; // defaulting to showing a search/filter for list

  private formController = new FormControl();
  private selectedItem: object;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialog: DialogService
  ) { }

  protected onTouched = () => { };
  private _onChange = (_: any) => { };


  writeValue(value: any): void {
    this.selectedItem = Object.keys(value).length ? value : null;
    this._changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() { }

  launchList() {
    if (this.displayOnly) {
      return;
    }

    const intent: DialogIntent = {
      title: this.dialogTitle || 'Select an Item',
      component: AgListDialogComponent,
      width: '384px',
      data: {
        successCallback: (item: object) => {
          this.formController.setValue(item);
          this.selectedItem = item;
          this._onChange(item);
          this._changeDetectorRef.markForCheck();
        },
        errorCallback: () => {
          // this._toast.alert('error', 'Subscription failed.');
        },
        closeCallback: () => { },
        listInput: this.listInput,
        displayField: this.displayField || 'name',
        chicletLetter: this.chicletLetter,
        chicletColor: this.chicletColor,
        searchFilter: this.searchFilter
      }
    };

    this._dialog.createDialog(intent);
  }
}