import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ElementRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { Field, Farm, Grower } from 'api/src/public_api';
import { FieldsStateService } from '../fields-state.service';
import { GrowersStateService } from '../../growers/growers-state.service';
import { FarmsStateService } from '../../farms/farms-state.service';
import {
  MapFeaturesService,
  MapService,
  MapInteractionsService
} from 'map/src/public_api';
import { filter, skipWhile, take, tap } from 'rxjs/operators';
import { Params, ActivatedRoute, Router } from '@angular/router';
import {
  AgNeoOnboardService,
  AgOnBoardingMutation,
  AgFeature
} from '../../../shared/services/agneo-onboarding.service';
import { ToasterService } from 'projects/ui/src/public_api';

import { Feature } from 'ol';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';

@Component({
  templateUrl: './create-field.template.html',
  styleUrls: ['./create-field.styles.scss']
})
export class CreateFieldComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLElement>;

  fieldGeoCtrl = new FormControl();
  fieldGeoSub: Subscription;
  fieldNameCtrl = new FormControl('');
  fieldNameSub: Subscription;
  grower: Grower;
  grower$: Observable<Grower>;
  growerSub: Subscription;
  farm: Farm;
  farm$: Observable<Farm>;
  farmSub: Subscription;
  field: Field;
  fieldsForPreview = new BehaviorSubject<Field[]>([]);
  deletedFields: Field[] = [];
  featureClickSub: Subscription;
  featureDoubleClickSub: Subscription;
  fieldsSubscription: Subscription;
  hoveredFeatureId: string;
  importedFileName = new FormControl({});

  fieldRequirementsMet: boolean;
  fieldCompleted: boolean = false;
  growerId: string;
  farmId: string;
  fieldId: string;
  cancelDraw = false;
  fieldsImporting = false;

  createMode: 'notSet' | 'draw' | 'upload' = 'notSet';

  isEditing: string = '';
  showFieldNameSelector = false;

  constructor(
    private _fieldsStateService: FieldsStateService,
    private _growersStateService: GrowersStateService,
    private _farmsStateService: FarmsStateService,
    private _mapFeatureService: MapFeaturesService,
    private _mapService: MapService,
    private _route: ActivatedRoute,
    private _agneoOnboardingService: AgNeoOnboardService,
    private _interactionsService: MapInteractionsService,
    private _toast: ToasterService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.fieldGeoSub = this.fieldGeoCtrl.valueChanges.subscribe(resp => {
      this.checkReqs();
    });

    this.fieldNameSub = this.fieldNameCtrl.valueChanges.subscribe(resp => {
      this.checkReqs();
    });

    this.farmSub = this._route.params
      .pipe(
        filter((params: Params) => {
          return params['farmId'];
        }),
        this._farmsStateService.loadEntityOpr('farmId')
      )
      .subscribe((farm: Farm) => {
        this.farm = farm;
        this._agneoOnboardingService.currentFarmId = farm.id;
      });

    this.growerSub = this._route.params
      .pipe(
        filter((params: Params) => {
          return params['growerId'];
        }),
        this._growersStateService.loadEntityOpr('growerId')
      )
      .subscribe((grower: Grower) => {
        this.grower = grower;
        this._agneoOnboardingService.currentGrowerId = grower.id;
      });

    this.featureClickSub = this._interactionsService.clickedFeature$.subscribe(
      (feature: Feature) => {
        if (feature && feature.getProperties()['class'] !== 'deleted') {
          this._agneoOnboardingService.selectField(
            feature.getProperties()['_uploadId']
          );
        } else {
          this._agneoOnboardingService.deselectAllFields();
        }
      }
    );

    this.featureDoubleClickSub = this._interactionsService.doubleClickedFeature$.subscribe(
      (feature: Feature) => {
        if (feature && feature.getProperties()['class'] === 'deleted') {
          this._agneoOnboardingService.reinstateField(
            feature.getProperties()['_uploadId']
          );
        }
      }
    );

    this._agneoOnboardingService.growerNameCol = 'GROWER_NAM';

    this._agneoOnboardingService.columnNames$
      .pipe(
        filter(resp => resp.length > 0),
        take(1)
      )
      .subscribe((names: any) => {
        this._agneoOnboardingService.fieldNameCol = names.includes('FIELD_NAME')
          ? 'FIELD_NAME'
          : null;
        this.showFieldNameSelector = names.includes('FIELD_NAME')
          ? false
          : true;
      });

    this.fieldsSubscription = this._agneoOnboardingService.newFields$.subscribe(
      (fields: any) => {
        // console.log('newFields: : ', fields);
        this.addFieldsToMap(fields);
      }
    );

    // this._agneoOnboardingService.newGrowers$.subscribe((growers: Grower[]) => {
    //   console.log('newGrowers: : ', growers);
    // });
  }

  checkReqs() {
    this.fieldRequirementsMet =
      this.fieldGeoCtrl.value && this.fieldNameCtrl.value ? true : false;
  }

  clearFieldGeo() {
    this.fieldGeoCtrl.setValue('clear');
    this._agneoOnboardingService.reset();
  }

  startUpload(files: FileList) {
    this._agneoOnboardingService.upload(files);

    this.importedFileName.setValue({
      name: this.fileInput.nativeElement['value'].split('\\').pop()
    });
  }

  importFields() {
    this._toast.alert('success', 'Processing Field Import...');
    this.fieldsImporting = true;
    forkJoin(
      ...this.fieldsForPreview.value.map((field: Field) => {
        return this._fieldsStateService.$create(field).pipe(
          skipWhile(field => !field),
          take(1)
        );
      })
    )
      .pipe(take(1))
      .subscribe((fields: any) => {
        this.addFieldsToMap(fields);
        this.fieldCompleted = true;
      });
  }

  addFieldsToMap(fields: Field[]) {
    this.fieldsForPreview.next(fields);

    if (fields.length) {
      this._mapFeatureService.clearAnnotations();
    }

    const geos = fields.map((field: Field) => {
      if (field.deleted) {
        field.geometry.setStyle(
          new Style({
            fill: new Fill({
              color: 'rgba(255,0,0,0.15)'
            })
          })
        );
        field.geometry.set('class', 'deleted');
      }
      if (field.selected) {
        document.getElementById(field.name).scrollIntoView();
      }
      this._mapFeatureService.addAnnotation(field.geometry);
      return field.geometry.getGeometry();
    });
    if (geos.length) {
      const clusterExtent = new GeometryCollection(geos).getExtent();

      this._mapService.animateToBounds(clusterExtent, [0, 0, 0, 410]);
    }
  }

  editFieldName(id: string, newName: string) {
    this._agneoOnboardingService.renameField(id, newName);
    this.isEditing = '';
  }

  cancelEditing() {
    this.isEditing = '';
  }

  deletePreviewField(fieldUploadId: string) {
    this._agneoOnboardingService.softDeleteField(fieldUploadId);
  }

  launchAddPlanting() {
    this._router.navigate(['../' + this.fieldId + '/plantings/create'], {
      relativeTo: this._route.parent
    });
  }

  resetFileImport(redirect?: boolean) {
    if (this.fileInput) {
      this.fileInput.nativeElement['value'] = null;
    }
    this._agneoOnboardingService.reset();
    this.fieldsForPreview.next([]);
    this._mapFeatureService.clearAnnotations();
    this.importedFileName.setValue({});
    if (redirect) {
      this._router.navigate(['../../'], { relativeTo: this._route.parent });
    }
    this.showFieldNameSelector = false;
  }

  createField() {
    this.fieldCompleted = true;
    const fieldGeo = this.fieldGeoCtrl.value.geometry;

    // need to format to MultiPolygon for ag backend
    fieldGeo.type = 'MultiPolygon';
    fieldGeo.coordinates = [fieldGeo.coordinates];

    const fieldObject = {
      farmId: this.farm.id,
      growerId: this.grower.id,
      name: this.fieldNameCtrl.value,
      geometry: fieldGeo
    };

    this._fieldsStateService
      .$create(new Field(fieldObject))
      .pipe(
        skipWhile(field => !field),
        take(1)
      )
      .subscribe((field: Field) => {
        this.field = field;
        this.fieldId = field.id;
        this._mapFeatureService.addAnnotation(field.geometry);
        this._mapService.animateToBounds(
          (this.field.geometry.getGeometry() as any).getExtent(),
          [0, 0, 0, 410]
        );
      });
  }

  openImportSettings() {
    this.showFieldNameSelector = true;

    // can't get this to work so kickin' this local for now
    /* this._router.navigate(
      [
        // 'create/import-settings',
        {
          outlets: {
            modal: 'import-settings'
          }
        }
      ],
      { relativeTo: this._route.parent }
    ); */
  }

  modalData(data: object) {
    this.showFieldNameSelector = data['showModal'];
    if (data['payload'] && data['payload'] !== 'Select column to map') {
      this._agneoOnboardingService.fieldNameCol = data['payload'];
    } else {
      this.resetFileImport();
    }
  }

  ngOnDestroy() {
    this.fieldGeoSub.unsubscribe();
    this.fieldNameSub.unsubscribe();
    this.fieldsSubscription.unsubscribe();
    if (this.field && this.field.geometry) {
      this._mapFeatureService.removeAnnotation(this.field.geometry);
    }
    if (this.createMode === 'upload') {
      this.resetFileImport();
    }
    if (this.featureClickSub) {
      this.featureClickSub.unsubscribe();
    }
    if (this.featureDoubleClickSub) {
      this.featureDoubleClickSub.unsubscribe();
    }
  }
}
