import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'geotranslate' })
export class GeoTranslatePipe implements PipeTransform {
  transform(text: string): string {
    if (typeof text === 'string' && text.toLowerCase() === 'nadir') {
      return 'Top Down View';
    } else if (typeof text === 'string' && text.toLowerCase() === 'oblique') {
      return 'Side View';
    } else if (typeof text === 'string' && text.toLowerCase() === 'poi') {
      return 'Point of Interest';
    } else {
      return text;
    }
  }
}
