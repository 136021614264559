import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgBaseService } from './agBase.service';
import { Planting } from '../models/planting.model';

@Injectable()
export class PlantingService extends AgBaseService<Planting> {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super('/ag/plantings', httpClient);
  }

  marshall(json: object): Planting {
    return new Planting(json);
  }
}
