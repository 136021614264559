import { Geometry } from '../interfaces/geometry.interface';
import { JobConfig } from '../interfaces/jobConfig.interface';
import { SiteContact } from '../interfaces/siteContact.interface';
import { BaseModel } from './base.model';

export enum MissionStatus {
  Unassigned = 'UNASSIGNED',
  Assigned = 'ASSIGNED',
  VendorRejected = 'VENDOR_REJECTED',
  VendorCancelled = 'VENDOR_CANCELLED',
  InProgress = 'IN_PROGRESS',
  Flown = 'FLOWN',
  Delayed = 'DELAYED',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED'
}

export enum PilotStatus {
  Unassigned = 'UNASSIGNED',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export interface Tag {
  name: string;
}

export class Mission extends BaseModel {
  id: string;
  assignedVendorMissionId: string;
  pilotId: string;
  pilotStatus: string | PilotStatus;
  orderId: string;
  ownerId: string;
  fulfillmentId: string;
  jobType: string;
  jobConfigs: JobConfig;
  notes: string;
  cancellationReason: string;
  status: string | MissionStatus;
  deadline: number;
  aoi: {
    geometries: Geometry[];
  };
  siteContact: SiteContact | null;
  tags: Tag[];
  location: string;
  readonly created: number;
  readonly modified: number;
  vendorId: string | null;
  vendorName?: string;
  workOrderId: string | null;

  get hasPointGeometry(): boolean {
    return this.aoi && this.aoi.geometries[0].type === 'Point';
  }

  get isUnassigned(): boolean {
    return this.status === MissionStatus.Unassigned;
  }

  get isAssigned(): boolean {
    return this.status === MissionStatus.Assigned;
  }

  get isVendorRejected(): boolean {
    return this.status === MissionStatus.VendorRejected;
  }

  get isVendorCancelled(): boolean {
    return this.status === MissionStatus.VendorCancelled;
  }

  get isInProgress(): boolean {
    return this.status === MissionStatus.InProgress;
  }

  get isFlown(): boolean {
    return this.status === MissionStatus.Flown;
  }

  get isDelayed(): boolean {
    return this.status === MissionStatus.Delayed;
  }

  get isComplete(): boolean {
    return this.status === MissionStatus.Complete;
  }

  get isCancelled(): boolean {
    return this.status === MissionStatus.Cancelled;
  }

  get isPilotUnassigned(): boolean {
    return this.pilotStatus === PilotStatus.Unassigned;
  }

  get isPilotPending(): boolean {
    return this.pilotStatus === PilotStatus.Pending;
  }

  get isPilotAccepted(): boolean {
    return this.pilotStatus === PilotStatus.Accepted;
  }

  get isPilotRejected(): boolean {
    return this.pilotStatus === PilotStatus.Rejected;
  }

  get name() {
    if (this._name == null) {
      return this.id;
    }
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  private _name: string;
}
