import { HttpClient } from '@angular/common/http';
import { BaseParams } from '../../interfaces/baseParams.interface';
import { Pagination } from '../../interfaces/pagination.interface';
import {
  SearchBody,
  SearchResponse,
  PagedSearchResponse,
  SortBody,
  MustClause,
  TermQuery
} from '../../interfaces/search/base-search.interface';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class BaseSearchService extends BaseService {
  static readonly DEFAULT_SIZE = 100;
  static readonly ONE_DAY = 1000 * 60 * 60 * 24; // 24 hours in milliseconds

  protected endpointUrl: string;

  constructor(protected _httpClient: HttpClient) {
    super(_httpClient);
  }

  /**
   * Base method to execute an ES search
   *
   * @param search the ES search body. Child classes should know how to build this
   */
  protected search<T>(
    searchObj: SearchBody,
    mapperFn?: (resp: SearchResponse<T>) => T[]
  ): Observable<PagedSearchResponse<T>> {
    return this._httpClient
      .post<SearchResponse<T>>(this.endpointUrl, searchObj)
      .pipe(
        map(response => {
          const pagedResponse: PagedSearchResponse<T> = {
            response,
            pagination: this._buildCurrentPagination(searchObj, response),
            data: mapperFn ? mapperFn(response) : response.hits.hits
          };

          return pagedResponse;
        })
      );
  }

  /**
   * converts regular API BaseParams to ES friendly pagination params
   *
   * @param baseParams a BaseParams (or Partial) object to convert to
   *                   ES friendly pagination params
   * @return returns an object with a from param, the record to start paginging from
   *         and a size param, the number of records to pull into the page
   */
  protected convertPagination(baseParams?: Partial<BaseParams>) {
    const size: number =
      baseParams && baseParams.size
        ? (baseParams.size as number)
        : BaseSearchService.DEFAULT_SIZE;
    const from =
      baseParams && baseParams.page ? (baseParams.page as number) * size : 0;

    return { from, size };
  }

  /**
   * converts regular API BaseParams to ES friendly sort params
   * only covers a very basic case, child classes should handle this for
   * more specific cases
   *
   * @param baseParams a BaseParams (or Partial) object to converto to
   *                   an ES friendly sort object
   * @return returns a simple key value pair sort object for an ES query
   */
  protected convertSort(baseParams?: Partial<BaseParams>): SortBody[] {
    if (!baseParams || !baseParams.sortBy) return undefined;
    return [
      {
        [baseParams.sortBy as string]: {
          order: baseParams.sortOrder || 'asc'
        }
      }
    ];
  }

  protected buildFilterListQuery(
    sourceList: string[],
    field: string
  ): MustClause {
    const should: TermQuery[] = [];
    sourceList.forEach(val =>
      should.push({
        term: {
          [field]: val
        }
      })
    );
    return {
      bool: {
        should,
        minimum_should_match: 1
      }
    };
  }

  /**
   * Helper method to convert from ES pagination back to regular API Pagination
   *
   * @param searchBody the SearchBody to get the input from / size
   * @param resp the SearchResponse to get the total number of hits
   * @return a Pagination object to be used in UI components and further API calls
   */
  private _buildCurrentPagination(
    { from, size }: SearchBody,
    resp: SearchResponse<any>
  ): Pagination {
    return {
      currentPage: from / size,
      pageSize: size,
      totalCount: resp.hits.total,
      totalPages: Math.ceil(resp.hits.total / size)
    };
  }
}
