import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AssetGroup } from '../models/assetGroup.model';

// customer
// /features/assets/groups/customer/{customerid}
// project
// /features/assets/groups/project/{projectid}

@Injectable()
export class AssetGroupService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/features/assets/groups`;
  }

  protected mapJsonToCollection(respBody: AssetGroup[]): AssetGroup[] {
    return respBody.map(json => new AssetGroup(json));
  }

  getAssetGroups(
    baseParams?: BaseParams
  ): Observable<PagedResponse<AssetGroup>> {
    return this.getWithPagedResponse<AssetGroup>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    );
  }

  createAssetGroup(assetGroup: AssetGroup): Observable<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, assetGroup.toJSON());
  }

  getAssetGroupById(id: string): Observable<AssetGroup> {
    return this.httpClient
      .get<AssetGroup>(`${this.endpointUrl}/${id}`)
      .pipe(map(resp => new AssetGroup(resp)));
  }

  getAssetGroupAsCSV(groupId: string): Observable<Blob> {
    return this.httpClient
      .get(`${this.endpointUrl}/${groupId}/export`, {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(map(resp => resp.body));
  }

  updateAssetGroup(asset: AssetGroup): Observable<HttpResponse<AssetGroup>> {
    return this.putWithResponse<AssetGroup>(
      `${this.endpointUrl}/${asset.id}`,
      asset.toJSON()
    );
  }

  patchAssetGroup(
    id: string,
    params: object
  ): Observable<HttpResponse<AssetGroup>> {
    return this.patchWithResponse<AssetGroup>(
      `${this.endpointUrl}/${id}`,
      params
    );
  }

  deleteAssetGroup(id: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${id}`);
  }

  getAssetGroupsByProjectId(
    projectId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Observable<PagedResponse<AssetGroup>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<AssetGroup>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  getAssetGroupsByCustomerId(
    customerId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Observable<PagedResponse<AssetGroup>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<AssetGroup>(
      `${this.endpointUrl}/project/${customerId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }
}
