import { BaseModel } from './base.model';

import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';

export class ManagementZone extends BaseModel {
  public readonly id: string;
  public name: string;
  public dataProductId: string;
  public zones: number;
  public zoneData: Array<object>; // need rate data but not sure what backend will look like
  public status: string;
  public customerId: string;
  public userId: string;
  public plantingId: string;
  public fieldId: string;
  public layerId: string;
  public aoi: string | Feature | any;
  public image: string;
  public classes: Array<any>; // not sure what this will be yet
  public assetId: string; // the uid of derivative

  constructor(opts: Partial<ManagementZone>) {
    let geom1 = opts.aoi;
    delete opts.aoi;
    super(opts);

    let geom = new GeoJSON();
    let feature = geom.readFeature(geom1);
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    feature.setId(opts.id);
    feature.setProperties({
      name: opts.name,
      class: 'asset-feature'
    });

    this.aoi = feature;
  }

  public toJSON(): object {
    let geo = new GeoJSON();
    (this.aoi as Feature).getGeometry().transform('EPSG:3857', 'EPSG:4326');
    const pointJSON = JSON.parse(geo.writeFeature(this.aoi as Feature));

    delete this.aoi;
    this.aoi = pointJSON.geometry;
    return this;
  }
}
