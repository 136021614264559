import { ViewChild } from '@angular/core';
import { ModalOverlayComponent } from './modal-overlay.component';

export abstract class Modal {
  @ViewChild('overlay')
  protected overlay: ModalOverlayComponent;

  public closeModal(modalOutletName = 'modal'): void {
    this.overlay.close(modalOutletName);
  }
}
