import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'contact-group',
  templateUrl: './contact-group.template.html',
  styleUrls: ['./contact-group.styles.scss']
})
export class ContactGroupComponent {
  @Input()
  parent: FormGroup;

  @Input()
  contactGroupName = 'contact';

  @Input()
  showName = false;

  get nameCtrl(): FormControl {
    return this.parent.get(this.contactGroupName).get('name') as FormControl;
  }

  get emailCtrl(): FormControl {
    return this.parent.get(this.contactGroupName).get('email') as FormControl;
  }
}
