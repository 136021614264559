import { BaseModel } from './base.model';

export class Project extends BaseModel {
  id: string;
  name: string;
  deleted: boolean;
  customerId: string;
  userId: string;
  created: number;
  modified: number;
}
