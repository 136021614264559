import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Pipe({
  name: 'secureImage'
})
export class SecureImagePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  transform(url: string, altImageUrl: string) {
    return new Observable<string>((observer) => {
      if (!url) {
        observer.next(null);
        return {
          unsubscribe() {}
        };
      }
      // This is a tiny blank image
      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

      // The next and error callbacks from the observer
      const {next, error} = observer;

      this.http.get(url, {responseType: 'blob'}).subscribe(
        response => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = function() {
            observer.next(reader.result.toString());
          };
        },
        err => observer.next(altImageUrl)
      );

      return {
        unsubscribe() {}
      };
    });
  }
}