import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { SiteContact } from 'projects/api/src/public_api';

export const titleize = (text: string): string => startCase(lowerCase(text));

export const hasSiteContact = (siteContact: SiteContact): boolean => {
  if (siteContact) {
    const { name, email, homePhone, workPhone, mobilePhone } = siteContact;
    return name || email || homePhone || workPhone || mobilePhone
      ? true
      : false;
  }
  return false;
};

// Adds commas every three digits for numbers with lots of digits
// before the decimal.
export const numberWithCommas = (x: number): string => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
