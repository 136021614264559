// NOTE: this is a hardcoded array of tile grid resolutions that represent standard
// resolutions of tiles for each zoom level (the index in the array for each is the zoom level)
// I don't think this is actually needed, and I think openlayers has a default value for this,
// but at the time I didn't know what I was doing
export const WMS_TILEGRID_RESOLUTIONS = [
  156543.03392804099712520838,
  78271.51696402048401068896,
  39135.75848201022745342925,
  19567.87924100512100267224,
  9783.93962050256050133612,
  4891.96981025128025066806,
  2445.98490512564012533403,
  1222.99245256282006266702,
  611.49622628141003133351,
  305.74811314070478829308,
  152.87405657035250783338,
  76.43702828517623970583,
  38.21851414258812695834,
  19.10925707129405992646,
  9.55462853564703173959,
  4.77731426782351586979,
  2.3886571339117579349,
  1.19432856695587897633,
  0.59716428347793950593,
  0.29858214173896975296,
  0.14929107086948487648,
  0.07464553543474243824,
  0.03732276771737121912,
  0.01866138385868560956
];
