import { Pipe, PipeTransform } from '@angular/core';

import timeguy from './timeguy';
import * as dateformat from 'dateformat';

@Pipe({ name: 'timestamp' })
export class TimestampPipe implements PipeTransform {
  transform(timestamp: number | string | Date, format: string): string {
    if (typeof timestamp === 'number' || typeof timestamp === 'string') {
      let date = new Date(
        typeof timestamp === 'string'
          ? parseInt(timestamp as string)
          : timestamp
      );
      return dateformat(date, format || 'm/d/yyyy');
    } else if (timestamp instanceof Date) {
      return dateformat(timestamp, format || 'm/d/yyyy');
    } else if (timestamp == null) {
      return '';
    } else {
      throw 'DateFormatPipe: timestamp input needs to be a number, string, or Date';
    }
  }
}
