import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AerialPassword, ToasterService } from 'projects/ui/src/public_api';

@Component({
  templateUrl: './resetPassword.template.html',
  styleUrls: ['../logIn/logIn.styles.scss']
})
export class ResetPasswordComponent implements OnInit {
  message: string;
  codeInvalid = false;
  resetCode: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _toaster: ToasterService
  ) {}

  resetPasswordForm = new FormGroup(
    {
      pwd: new FormControl('', AerialPassword.standardValidators),
      confirmPwd: new FormControl('', [Validators.required])
    },
    {
      validators: [AerialPassword.matchValidator()]
    }
  );

  resetPassword() {
    const formValue = this.resetPasswordForm.value;
    this._authService.confirmPasswordReset(this.resetCode, formValue.pwd).then(
      () => {
        this._toaster.alert('success', 'Password reset successful!');
        this._router.navigate(['../log_in'], {
          relativeTo: this._route
        });
      },
      error => {
        this.message = error.message;
      }
    );
  }

  ngOnInit() {
    this.resetCode = this._route.snapshot.queryParams['oobCode'];
  }
}
