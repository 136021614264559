import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';

@Component({
  selector: 'aerial-static-map',
  templateUrl: './aerial-static-map.template.html',
  styleUrls: ['../aerial-map.styles.scss', './aerial-static-map.styles.scss']
})
export class AerialStaticMapComponent
  implements OnInit, AfterViewInit, OnDestroy {
  // WIP!!
  // don't assume this static map works like the ADP's static map...
  // right now I'm implementing it how I need it for the DMS
  @Input()
  set feature(feature: Feature) {
    if (this._feature) {
      this._features.remove(this._feature);
    }
    if (feature) {
      this._features.push(feature);
    }
    this._feature = feature;
    if (this._isMapInit) {
      this._zoomToFeatureExtent(feature);
    }
  }

  get feature(): Feature {
    return this._feature;
  }

  @Output()
  mapInit = new EventEmitter<void>();

  readonly mapId: string;

  private _map: Map;
  private _feature: Feature;
  private _features = new Collection<Feature>();
  private _mapInit = new Subject<void>();
  private _isMapInit = false;
  private _sub: Subscription;

  private _featuresLayer = new VectorLayer({
    zIndex: 1000,
    source: new VectorSource({
      features: this._features
    }),
    style: new Style({
      stroke: new Stroke({
        color: '#fe5000', // $orange
        width: 2
      })
    })
  });

  constructor() {
    this.mapId = `static-map-${Math.random().toString(36).substring(2)}`;
  }

  ngOnInit() {
    this._sub = this._mapInit.pipe(take(1)).subscribe(() => {
      this._zoomToFeatureExtent(this.feature);
    });
  }

  ngAfterViewInit() {
    // Had to add this to get the map to display in certain dialog cases.
    // I'm assuming this has something to do with change detection.
    // TODO: figure out zones - https://angular.io/guide/zone
    window.setTimeout(() => {
      this._initMap();
      this._isMapInit = true;
      this.mapInit.emit();
      this._mapInit.next();
    }, 0);
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private _initMap(): void {
    // TODO: replace hard-coded mapbox endpoint with broker endpoint
    const mapboxLayer = new TileLayer({
      source: new XYZ({
        url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2FuamVldmFpcmJ1cyIsImEiOiJjajNscGttbHEwMHp1MndxczZydHZ6c2QxIn0.VZE5FT5lREmfGOt_2i3ERw`,
        crossOrigin: 'anonymous'
      })
    });
    this._map = new Map({
      layers: [mapboxLayer, this._featuresLayer],
      controls: [],
      interactions: [],
      target: this.mapId,
      view: new View({
        center: [-11000000, 4600000],
        zoom: 4
      })
    });
  }

  private _zoomToFeatureExtent(feature: Feature): void {
    if (!feature) {
      return;
    }
    this._map.getView().fit(feature.getGeometry().getExtent(), {
      padding: [8, 10, 8, 10],
      maxZoom: 17,
      duration: 500
    });
  }
}
