import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AerialButtonWithIcon } from '../aerialButtonWithIcon.model';

@Component({
  selector: 'icon-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <aerial-button
      [classes]="classes"
      [color]="color"
      [size]="size"
      [type]="type"
      [disabled]="disabled">
      <i class="btn-icon {{ iconClass }}"></i>
    </aerial-button>
  `
})
export class IconButtonComponent extends AerialButtonWithIcon {
  otherClasses = ['square'];
}
