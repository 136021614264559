export interface UTMAuthorization {
  flightPlanId: string;
  source: string | UTMAuthorizationSource;
  createdAt: number; // assessment created at
  approvedAt: number; // parent auth approved at
  updatedAt: number; // parent auth updated at
  state: string | UTMAuthorizationState | null;
  confirmations: string[];
}

export enum UTMAuthorizationState {
  Approved = 'approved', // 0
  Submitting = 'submitting',
  Cancelled = 'cancelled', // 1
  CancelReq = 'cancelreq', // 1
  Waiting = 'waiting', // 2
  Rejected = 'rejected', // 3
  Rescinded = 'rescinded', // 4
  Error = 'error' // 5
  // the higher value takes priority for parent auth state
}

export enum UTMAuthorizationSource {
  User = 'user',
  UTM = 'utm',
  FAA = 'faa'
}

export interface UTMAuthorizationUrl {
  url: string;
  issued: number;
  expiration: number;
}
