import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';

import { Observable } from 'rxjs';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { ADMAsset } from '../interfaces/adm-asset.interface';

@Injectable()
export class AgSearchService extends BaseService {
  protected endpointUrl: string;

  constructor(
    protected httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    super(httpClient);
    this.endpointUrl = `${this.agRootUrl}`;
  }

  public getAssets(options: {
    [param: string]: string | string[];
  }): Observable<HttpResponse<any>> {
    return this.getWithResponse<ADMAsset>(`${this.endpointUrl}/search/`, {
      params: this.buildParams(options)
    });
  }

  public download(assetUid: string): Observable<SafeResourceUrl> {
    const url = `${this.endpointUrl}/assets/download/`;
    const options = {
      responseType: 'blob' as 'json',
      params: this.buildParams({
        uid: assetUid
      })
    };
    return this.httpClient.get<Blob>(url, options).pipe(
      map((response: Blob) => {
        const urlCreator = window.URL || (window as any).webkitURL;
        const imageUrl = urlCreator.createObjectURL(response);
        return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
      })
    );
  }

  public assetProxy(
    params: {
      subject?: string;
      proxy?: string;
    } = {}
  ): Observable<any> {
    const url = `${this.endpointUrl}/assets/`;

    let body = '';
    if (params.subject) body = `${body}subject=${params.subject}`;
    if (params.proxy) body = `${body}&proxy=${params.proxy}`;

    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob' as 'json'
    };

    return this.httpClient.post<Blob>(url, body, options).pipe(
      map((response: Blob) => {
        const urlCreator = window.URL || (window as any).webkitURL;
        const imageUrl = urlCreator.createObjectURL(response);
        return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
      })
    );
  }

  public assetBlob(assetUid: string): Observable<Blob> {
    const url = `${this.endpointUrl}/assets/download/`;
    const options = {
      responseType: 'blob' as 'json',
      params: this.buildParams({
        uid: assetUid
      })
    };
    return this.httpClient.get<Blob>(url, options);
  }
}
