import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'step-holder',
  templateUrl: './step-holder.template.html',
  styleUrls: ['./step-holder.styles.scss'],
  encapsulation: ViewEncapsulation.None
})

export class StepHolderComponent implements OnInit {
  @Input() stepNumber;
  @Input() curStep;

  constructor() { }

  ngOnInit() { }
}