import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';

import { Observable } from 'rxjs';
import { AgSubscription } from '../interfaces/ag-subscription.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { BaseParams } from '../interfaces/baseParams.interface';

@Injectable()
export class AgSubscriptionService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.agRootUrl}/ag/subscriptions`;
  }

  public list(
    baseParams?: BaseParams
  ): Observable<PagedResponse<AgSubscription>> {
    return this.getWithPagedResponse<AgSubscription>(
      this.endpointUrl,
      (respBody: AgSubscription[]): AgSubscription[] => {
        return respBody.map(json => json as AgSubscription);
      },
      baseParams
    );
  }

  public create(
    item: Partial<AgSubscription>
  ): Observable<HttpResponse<AgSubscription>> {
    return this.createWithResponse<AgSubscription>(
      `${this.endpointUrl}/`,
      item
    );
  }

  public getByPlantingId(
    plantingId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<AgSubscription>> {
    return this.getWithPagedResponse<AgSubscription>(
      `${this.endpointUrl}/planting/${plantingId}/`,
      (respBody: AgSubscription[]): AgSubscription[] => {
        return respBody.map(json => json as AgSubscription);
      },
      baseParams
    );
  }

  public getById(id: string): Observable<HttpResponse<AgSubscription>> {
    return this.getWithResponse<AgSubscription>(`${this.endpointUrl}/${id}/`);
  }
}
