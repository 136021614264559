import { Input, HostBinding } from '@angular/core';
import { DatePickerConfig } from './datePickerConfig.interface';

export abstract class DatePicker {
  @Input()
  abstract config: DatePickerConfig;

  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  get placeholder(): string {
    return this._placeholder ? this._placeholder : this.defaultPlaceholder;
  }

  @HostBinding('class.date-picker')
  hasIdentClass = true;

  get defaultPlaceholder(): string {
    if (this.config.noCalendar) {
      return 'Select a Time';
    }
    if (this.config.mode === 'range') {
      return 'Select a Date Range';
    }
    if (this.config.mode === 'multiple') {
      return 'Select Date(s)';
    }
    if (this.config.mode === 'single' && this.config.enableTime) {
      return 'Select a Date and Time';
    }
    return 'Select a Date';
  }

  private _placeholder: string;
}
