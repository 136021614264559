import { Input } from '@angular/core';
import { AerialButton } from './aerialButton.model';

export abstract class AerialButtonWithIcon extends AerialButton {
  @Input()
  iconClass: string;

  @Input()
  iconPosition: 'left' | 'right' = 'right';

  @Input()
  useSeparator = false;

  @Input()
  set classes(classes: string[] | string) {
    this._classes = this.handleClasses(classes);
  }

  get classes(): string[] | string {
    return this._classes.concat([
      ...this.otherClasses,
      this.positionClass,
      this.separatorClass
    ]);
  }

  get positionClass(): string {
    return `align-icon-${this.iconPosition}`;
  }

  get separatorClass(): string {
    return this.useSeparator ? 'with-separator' : '';
  }

  abstract otherClasses: string[];
  private _classes: string[] = [];
}
