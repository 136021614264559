import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface DataUsage {
  customerId: string;
  name: string;
  aggregateDate: string;
  storageBytes: number;
  nearmapBytes: number;
  pitneyBowesBytes: number;
  oneAtlasBytes: number;
  internalBytes: number;
  externalBytes: number;
  processedDataSize: number;
  processingTime: number;
  totalBytes: number;
  apiCalls: number;
}

@Injectable()
export class DataUsageService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/usagemetrics`;
  }

  getUsageDataForCustomer(customerId: string): Promise<DataUsage> {
    return this.httpClient
      .get<DataUsage>(`${this.endpointUrl}/${customerId}`)
      .pipe(map(resp => resp))
      .toPromise();
  }

  getUsageDataForMonth(
    customerId: string,
    timestamp: number
  ): Promise<DataUsage> {
    return this.httpClient
      .get<DataUsage>(
        `${this.endpointUrl}/${customerId}?start=${timestamp}&end=${timestamp}`
      )
      .pipe(map(resp => resp))
      .toPromise();
  }
}
