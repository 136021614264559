import { DataProduct } from 'projects/api/src/public_api';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Layer from 'ol/layer/Layer';
import { Extent } from 'ol/extent';
import VectorSource from 'ol/source/Vector';

// TODO: this should be in the API package and formalized
// but will work here for now
export class DataProductLayer {
  /**
   * Called "name" but it is the identifier for this layer in
   * the corresponding Capabilities file
   */
  layerName: string;

  /**
   * The display name for this layer that comes from the capabilities file
   */
  layerTitle: string;
}

/**
 * Internal wrapper class around the ol layer class.
 * See [Layer]{@link https://openlayers.org/en/latest/apidoc/Layer.html}
 */
export class MapLayer {
  /**
   * This is the display name for this maplayer
   */
  name: string;

  /**
   * This is the string identifier for this layer, to help make sure
   * duplicates dont get added to the workspace
   */
  key: string;

  /**
   * trying to use an id moving forward instead of the weird key attr
   */
  id: string;

  /**
   * layerTitle is a great user readible layer name for WMS/WMTS layers
   * if this dataProduct has multiple layers in its Capabilities file
   */
  layerTitle: string;

  /**
   * layerName is not a great name, but this is the layer ID inside the WMS/WMTS
   * if this dataProduct has multiple layers in its Capabilities file
   */
  layerName: string;

  private _visible: boolean;

  /**
   * Is the MapLayer visibile on the map?
   */
  get visible(): boolean {
    return this._visible;
  }

  set visible(isVisible: boolean) {
    this._visible = isVisible;
    this.olLayer.setVisible(isVisible);
  }

  /**
   * @param olLayer {Layer} the ol layer object
   * @param dataProduct {DataProduct} the data product that this layer belongs to
   * @param bbox {Extent} the bounding extent of the MapLayer
   * @param _dataProductLayer {DataProductLayer} the layer info if this is one of many
   *                          layers that this data product has in its WMS/WMTS
   */
  constructor(
    public olLayer: Layer,
    public dataProduct: DataProduct,
    public bbox?: Extent,
    private _dataProductLayer?: DataProductLayer
  ) {
    this._visible = this.olLayer.getVisible();
    this.name = dataProduct.name;
    this.key = dataProduct.brokerUrl;
    this.id = dataProduct.id;
    if (this._dataProductLayer) {
      this.name += ` - ${this._dataProductLayer.layerTitle}`;
      this.key += ` - ${this._dataProductLayer.layerName}`;
      this.id += ` - ${this._dataProductLayer.layerName}`;
      this.layerName = this._dataProductLayer.layerName;
      this.layerTitle = this._dataProductLayer.layerTitle;
    }
  }

  /**
   * toggles the MapLayer's visibility on the map
   */
  toggle() {
    this._visible = !this._visible;
    this.olLayer.setVisible(this._visible);
  }

  /**
   * Attempts to remove a feature in the layer from the map
   * will fail if this is a not a vector layer
   *
   * @param featureId the ID of the feature to remove
   */
  deleteFeature(featureId: string) {
    try {
      const source = this.olLayer.getSource() as VectorSource;
      const feature = source.getFeatureById(featureId);
      source.removeFeature(feature);
    } catch (e) {
      console.error(e);
      throw new Error('Cannot remove Feature for a non-Vector MapLayer');
    }
  }

  /**
   * Attempts to add a feature to this layer on the map
   * will fail if this is a not a vector layer
   *
   * @param feature the Feature to add to this layer
   */
  addFeature(feature: Feature) {
    try {
      const source = this.olLayer.getSource() as VectorSource;
      source.addFeature(feature);
    } catch (e) {
      console.error(e);
      throw new Error('Cannot add Feature for a non-Vector MapLayer');
    }
  }

  /**
   * Returns a feature from this layer by ID
   *
   * @param featureId id of the feature to get
   * @returns an Feature from this layer
   */
  getFeature(featureId: string) {
    try {
      return (this.olLayer.getSource() as VectorSource).getFeatureById(
        featureId
      );
    } catch (e) {
      console.error(e);
      throw new Error('Cannot load Feature for a non-Vector MapLayer');
    }
  }

  /**
   * returns all features from this layer
   *
   * @returns an array of all ol.Features in this layer
   */
  getFeatures() {
    try {
      return (this.olLayer.getSource() as VectorSource).getFeatures();
    } catch (e) {
      console.error(e);
      throw new Error('Cannot load Feature for a non-Vector MapLayer');
    }
  }

  /**
   * Returns the entire vector layer as a GeoJSON object
   *
   * @param excludeProperties an array of properties to not include in the GeoJSON object
   * @returns a GeoJSON object representing the layer
   */
  getGeoJSON(excludeProperties?: string[]) {
    const features = this.getFeatures();

    const parser = new GeoJSON();
    const json = JSON.parse(
      parser.writeFeatures(features, {
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:4326'
      })
    );

    if (excludeProperties && excludeProperties.length > 0) {
      json['features'].forEach(
        (feature: { properties: { [key: string]: any } }) => {
          excludeProperties.forEach(prop => delete feature.properties[prop]);
        }
      );
    }

    return json;
  }
}
