import { BaseModel } from './base.model';

export class OrderTemplateSubscription extends BaseModel {
  id: string;
  created: number;
  modified: number;
  name: string;
  customerId: string; // Is this used?
  orderTemplateId: string;
  deleted: boolean;
  active: boolean;

  toJSON(): object {
    return this;
  }
}
