import { BaseModel } from './base.model';
import keys from 'lodash/keys';

import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';

export enum AnnotationEntityType {
  ADM_ASSET = 'ADM_ASSET',
  DATAPRODUCT = 'DATAPRODUCT',
  ASSET = 'ASSET'
}

export class Annotation extends BaseModel {
  // Old API
  dataProductId: string;
  feature: Feature;
  created: number;
  modified: number;

  // Common
  name: string;
  description: string;

  // New API
  id: string;
  orderId: string;
  entityType: AnnotationEntityType;
  entityId: string;
  features: any;
  attributes: any;
  userId: string;
  customerId: string;
  inspectionId: string;
  index: number;

  userName?: string;

  constructor(payload: Partial<Annotation>) {
    super(payload);
  }

  toJSON(): object {
    let geo = new GeoJSON();

    let json = {
      name: this.name,
      description: this.description,
      dataProductId: this.dataProductId,
      geoJson: {
        type: 'FeatureCollection',
        features: [JSON.parse(geo.writeFeature(this.feature))]
      }
    };

    return json;
  }

  toNewJSON(): object {
    let geo = new GeoJSON();
    let thisJson: any; // must be any due to type differences OL and Cesium

    // need to know if this is OL Feature or Cesium Entity
    if (this.feature instanceof Feature) {
      thisJson = JSON.parse(geo.writeFeature(this.feature));
    } else {
      thisJson = this.feature;
    }

    let json = {
      orderId: this.orderId,
      entityType: this.entityType,
      entityId: this.entityId,
      inspectionId: this.inspectionId,
      name: this.name,
      description: this.description,
      attributes: this.attributes
    } as Partial<Annotation>;

    if (this.feature) {
      json.features = {
        type: 'FeatureCollection',
        features: [thisJson]
      };
    }

    return json;
  }

  static fromJSON(jsonAnnotation: any): Annotation {
    let geo = new GeoJSON();

    // TODO: need to deal with feature collections
    let featGeo = jsonAnnotation.geoJson.features[0];
    let feature = geo.readFeature(featGeo);
    feature.setId(jsonAnnotation.id);
    feature.set('name', jsonAnnotation.name);
    feature.set('description', jsonAnnotation.description);

    return new Annotation({
      id: jsonAnnotation.id,
      dataProductId: jsonAnnotation.dataProductId,
      name: jsonAnnotation.name,
      description: jsonAnnotation.description,
      feature: feature,
      created: jsonAnnotation.created,
      modified: jsonAnnotation.modified,
      userId: jsonAnnotation.userId
    } as Partial<Annotation>);
  }
}
