import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'faux-multiselect-option',
  template: `
    <li class="faux-multiselect__option" [hidden]="hidden"
      [ngClass]="ngClass" [class.selected]="selected">
      <label class="option-label">
        <span *ngFor="let label of labels" class="option-column">
          {{ label }}
        </span>
        <input #optionCheckbox type="checkbox" class="option-checkbox"
          [checked]="selected"
          (change)="toggleSelection(optionCheckbox.checked)">
      </label>
    </li>
  `
})

export class FauxMultiSelectOptionComponent {
  @Input() ngClass: object;
  @Input() hidden: boolean;
  @Input() labels: string[];
  @Input() value: string;
  @Input() selected: boolean;

  @Output()
  toggle: EventEmitter<string> = new EventEmitter<string>();

  toggleSelection(checked: boolean) {
    this.selected = checked;
    this.toggle.emit(this.value);
  }
}
