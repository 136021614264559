import { BaseModel } from './base.model';
import { Asset } from './asset.model';

export enum ReportType {
  Triage = 'TRIAGE',
  Inspection = 'INSPECTION_3D',
  SingleAsset = 'SINGLE_ASSET'
}

export enum ReportStatus {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE'
}

export interface SingleAssetReportData {
  workspaceId: string;
  assetId: string;
}

export interface TriageReportData {
  workspaceId: string;
  dataProductId: string;
}

export interface InspectionReportData {
  dataProductId: string;
}

export interface AssetContent extends Asset {
  droneImages: any;
  orthographicAnnotations: any;
  areas: any;
  distances: any;
  overviewAnnotations: any;
  pbAttributes: { [key: string]: string };
}

export class Report extends BaseModel {
  id: string;
  created: number;
  modified: number; // NOTE: modified is the default timestamp for the Report itself
  lastModified: number; // lastModified is to keep track of when any entity displayed in the report is changed.
  name: string;
  type: ReportType;
  status: ReportStatus;
  customerId: string;
  inspectionId: string;
  projectId: string;
  userId: string;
  data:
    | TriageReportData
    | InspectionReportData
    | SingleAssetReportData
    | AssetContent;
}
