import { Pipe, PipeTransform } from '@angular/core';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';

interface GroupByGroup {
  name: string;
  items: Array<any>;
}

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform(collection: Array<any>, groupByAttr: string): any[] {
    if (collection instanceof Array) {
      let grouped = groupBy(collection, groupByAttr);
      return keys(grouped)
        .map(key => {
          return {
            name: key,
            items: grouped[key]
          } as GroupByGroup;
        })
        .reverse(); //TODO: this reverse is temp just make global layers sorted last
    } else throw 'GroupByPipe: collection needs to be an Array';
  }
}
