import { BaseModel } from './base.model';

export abstract class AgBase extends BaseModel {
  public readonly id: string;
  public readonly created: string;
  public readonly lastUpdated: string;
  public readonly lastEditorId: string;
  public readonly creatorId: string;

  public name: string; // min: 1 max: 200
  public description: string; // min: 1

  public _uploadId?: string;
  public deleted?: boolean;
  public selected?: boolean;

  constructor(opts: Partial<AgBase>) {
    super(opts);
  }

  public abstract toJSON(): object;
}
