import { BaseModel } from './base.model';
import { JobConfig } from '../interfaces/jobConfig.interface';
import { titleize } from 'projects/utils/src/public_api';

export enum AcquisitionType {
  RgbNadir = 'RGB_NADIR',
  RgbOblique = 'RGB_OBLIQUE',
  RgbPoi = 'RGB_POI',
  Satellite = 'SATELLITE'
}

export enum TaskStatus {
  Pending = 'PENDING',
  Complete = 'COMPLETE'
}

export class Task extends BaseModel {
  id: string;
  missionId: string;
  fulfillmentId: string;
  description: string;
  notes: string;
  acquisitionType: string | AcquisitionType;
  status: string | TaskStatus;
  created: number;
  modified: number;
  jobConfigs: JobConfig;
  bucketUrl: string;

  get acquisitionTypeText(): string {
    switch (this.acquisitionType) {
      case AcquisitionType.RgbNadir:
        return 'RGB Nadir';
      case AcquisitionType.RgbOblique:
        return 'RGB Oblique';
      case AcquisitionType.RgbPoi:
        return 'RGB POI';
      default:
        return titleize(this.acquisitionType);
    }
  }
}
