import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';

import { DialogIntent } from './dialogIntent.interface';
import { Dialog } from './dialog.interface';
import merge from 'lodash/merge';
import compact from 'lodash/compact';

@Component({
  selector: 'aerial-dialog',
  template: `
    <!-- prettier-ignore -->
    <div [ngClass]="classes" [style.width]="intent.width">
      <header class="dialog-header">
        <h1 class="dialog-title">
          <i *ngIf="intent.titleIcon"
            class="dialog-title-icon {{ intent.titleIcon }}">
          </i>
          {{ intent.title }}
        </h1>
        <i *ngIf="!intent.hideCloseBtn"
          (click)="close()"
          class="dialog-close icon-close"
          title="Close Dialog">
        </i>
      </header>
      <main class="dialog-content">
        <ng-template #dialogHost></ng-template>
      </main>
    </div>
  `
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  @Input()
  intent: DialogIntent;

  @Output()
  onClose = new EventEmitter<DialogIntent>();

  get type(): string {
    return this.intent.type ? this.intent.type : 'default';
  }

  get size(): string {
    return this.intent.size ? this.intent.size : 'medium';
  }

  get classes(): string[] {
    return compact([
      'dialog',
      this.type,
      this.size,
      this.intent.className,
      !this.intent.hideCloseBtn ? 'with-close' : '',
      this.intent.titleIcon ? 'with-title-icon' : ''
    ]);
  }

  @ViewChild('dialogHost', { read: ViewContainerRef })
  private _dlgHost: ViewContainerRef;

  private _dlg: Dialog;
  private _dlgCompRef: ComponentRef<any>;

  constructor(private _resolver: ComponentFactoryResolver) {}

  protected close(): void {
    // beforeCloseCallback
    this.onClose.emit(this.intent);
    // this.dialogHost.viewContainerRef.clear();
  }

  ngAfterViewInit() {
    this._dlgHost.clear();

    // prettier-ignore
    const componentFactory = this._resolver
      .resolveComponentFactory(this.intent.component as any);
    this._dlgCompRef = this._dlgHost.createComponent(componentFactory);

    // Pass data through to dialog component
    this._dlg = this._dlgCompRef.instance as any;
    merge(this._dlg, this.intent.data);
    this._dlg.close = () => this.close();

    // need to  manually force a change detect for some reason
    this._dlgCompRef.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    if (this._dlgCompRef) {
      this._dlgCompRef.destroy();
    }
    this._dlgCompRef = undefined;
  }
}
