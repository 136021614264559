import { BaseModel } from './base.model';

export class AssetGroup extends BaseModel {
  id: string;
  customerId: string;
  projectId: string;
  name: string;
  description: string;
  attributes: any;
  deleted: boolean;
  created: number;
  modified: number;

  constructor(opts: Partial<AssetGroup>) {
    super(opts);
  }

  toJSON(): object {
    return JSON.parse(JSON.stringify(this));
  }
}
