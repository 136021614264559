import { contextTypes } from '../util/helpers';
import compact from 'lodash/compact';

export abstract class Toasty {
  contextType: string;
  message: string;
  customIconClass: string;
  protected hideIcon: boolean;
  protected isVisible: boolean;

  get iconClass(): string {
    if (this.customIconClass) {
      return this.customIconClass;
    }
    switch (this.contextType) {
      case 'success':
        return 'icon-circle_check';
      case 'error':
        return 'icon-circle_x';
      case 'warning':
        return 'icon-warning';
      default:
        return 'icon-info';
    }
  }

  get toastyClasses(): string[] {
    return compact([
      'toasty',
      this.contextType,
      this.isVisible ? 'visible' : ''
    ]);
  }

  abstract classes: string[];

  constructor(
    contextType: string,
    message: string,
    customIconClass?: string,
    hideIcon = false,
    isVisible = false
  ) {
    this.contextType = (contextTypes as any).includes(contextType)
      ? contextType
      : 'info';
    this.message = message;
    this.customIconClass = customIconClass;
    this.hideIcon = hideIcon;
    this.isVisible = isVisible;
  }

  hide(): void {
    this.isVisible = false;
  }

  show(): void {
    this.isVisible = true;
  }
}
