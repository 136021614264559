import { Pipe, PipeTransform } from '@angular/core';
import { titleize } from './helpers';

@Pipe({
  name: 'titleize'
})
export class TitleizePipe implements PipeTransform {
  transform(myString: string): string {
    return titleize(myString);
  }
}
