import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  User,
  UserInvite,
  RoleType,
  UserPreference,
  AuthProviderUser
} from '../models/user.model';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { AnonymousUser } from '../interfaces/anonymousUser.interface';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService extends BaseService {
  static readonly anonExpirationDays = 30;
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.usersUrl}`;
  }

  protected mapJsonToCollection(respBody: User[]): User[] {
    return respBody.map(json => new User(json));
  }

  getUser(): Promise<User> {
    return this.httpClient
      .get<User>(`${this.endpointUrl}/mine`)
      .pipe(
        map(resp => {
          return new User(resp);
        })
      )
      .toPromise();
  }

  createUserPreference(
    preference: Partial<UserPreference>
  ): Observable<Object> {
    return this.httpClient.post(
      `${this.endpointUrl}/mine/preferences`,
      preference
    );
  }

  getUserPreferenceByReferenceId(id: string): Observable<UserPreference> {
    return this.httpClient
      .get<UserPreference>(
        `${this.endpointUrl}/mine/preferences/referenceId/${id}`
      )
      .pipe(map(resp => resp as UserPreference));
  }

  updateUserPreference(
    preference: UserPreference
  ): Observable<HttpResponse<UserPreference>> {
    return this.putWithResponse<UserPreference>(
      `${this.endpointUrl}/mine/preferences/referenceId/${preference.referenceId}`,
      preference
    );
  }

  getAllUsers(size: Number): Promise<any> {
    return this.httpClient
      .get(`${this.endpointUrl}?size=${size}`)
      .pipe(map(resp => resp))
      .toPromise();
  }

  getUserById(userId: string): Promise<User> {
    return this.httpClient
      .get<User>(`${this.endpointUrl}/${userId}`)
      .pipe(map(resp => new User(resp)))
      .toPromise();
  }

  // Leaving for backwards compatibility
  // TODO: update implementations to getUsersByRole
  getUsersForCustomer(
    customerId: string,
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<User>> {
    const params = searchQuery
      ? this.buildParams({ search: searchQuery })
      : undefined;
    return this.getWithPagedResponse<User>(
      `${this.endpointUrl}/customer/${customerId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  getUsersForVendor(
    vendorId: string,
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<User>> {
    const params = searchQuery
      ? this.buildParams({ search: searchQuery })
      : undefined;
    return this.getWithPagedResponse<User>(
      `${this.endpointUrl}/vendor/${vendorId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  // Gets Users by their Role
  // CUSTOMER and VENDOR_ADMIN are restricted to their users
  getUsersByRole(
    role: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<User>> {
    return this.getWithPagedResponse<User>(
      `${this.endpointUrl}/role/${role}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getPilotsForVendor(
    vendorId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<User>> {
    return this.getWithPagedResponse<User>(
      `${this.endpointUrl}/vendor/${vendorId}?search=roles.roleType==${RoleType.Pilot};roles.parentId==${vendorId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  // Get users with the specified role.parentType
  getUsersByParentType(
    parentType: RoleType.Customer | RoleType.Vendor,
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<User>> {
    let queryStr = `roles.parentType==${parentType}`;
    if (searchQuery) {
      queryStr = `(${searchQuery}); ${queryStr}`;
    }
    const params = this.buildParams({ search: queryStr });
    return this.getWithPagedResponse<User>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  getCustomerUsers(
    customerId: string,
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<User>> {
    return customerId
      ? this.getUsersForCustomer(customerId, searchQuery, baseParams)
      : this.getUsersByParentType(RoleType.Customer, searchQuery, baseParams);
  }

  getVendorUsers(
    vendorId: string,
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<User>> {
    return vendorId
      ? this.getUsersForVendor(vendorId, searchQuery, baseParams)
      : this.getUsersByParentType(RoleType.Vendor, searchQuery, baseParams);
  }

  getPilots(baseParams?: BaseParams): Observable<PagedResponse<User>> {
    return this.getWithPagedResponse<User>(
      `${this.endpointUrl}/role/${RoleType.Pilot}`,
      this.mapJsonToCollection,
      baseParams
    );
  }

  updateUser(user: User): Promise<HttpResponse<User>> {
    return this.putWithResponse<User>(
      `${this.endpointUrl}/${user.id}`,
      user
    ).toPromise();
  }

  patchUser(id: string, params: object): Promise<HttpResponse<User>> {
    return this.patchWithResponse<User>(
      `${this.endpointUrl}/${id}`,
      params
    ).toPromise();
  }

  deleteUser(user: User): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/firebase/${user.id}`
    ).toPromise();
  }

  // DEPRECATED! Use `createFirebaseUser()` instead!
  createUser(user: User): Promise<any> {
    return this.httpClient
      .post(`${this.endpointUrl}/firebase`, user)
      .toPromise();
  }

  createFirebaseUser(
    user: AuthProviderUser,
    token?: string
  ): Promise<HttpResponse<User>> {
    const headers = token ? { headers: this.buildAuthHeader(token) } : {};
    return this.createWithResponse<User>(
      `${this.endpointUrl}/firebase`,
      user,
      headers
    ).toPromise();
  }

  inviteUser(userDetail: UserInvite): Promise<any> {
    return this.httpClient
      .post(`${this.endpointUrl}/invites`, userDetail)
      .toPromise();
  }

  inviteUserWithResponse(userDetail: UserInvite): Promise<string> {
    return this.postWithResponseAsUrl(
      `${this.endpointUrl}/invites`,
      userDetail
    ).toPromise();
  }

  resendInvite(userId: string, invite: UserInvite): Promise<string> {
    return this.postWithResponseAsUrl(
      `${this.endpointUrl}/invites/user/${userId}`,
      invite
    ).toPromise();
  }

  // DEPRECATED! Use `getAnonUser()` instead!
  getAnonData(id: string): Promise<AnonymousUser> {
    return this.httpClient
      .get<AnonymousUser>(`${this.endpointUrl}/anonymous/${id}`)
      .toPromise();
  }

  getAnonUser(id: string, token?: string): Promise<AnonymousUser> {
    const headers = token ? { headers: this.buildAuthHeader(token) } : {};
    return this.httpClient
      .get<AnonymousUser>(`${this.endpointUrl}/anonymous/${id}`, headers)
      .toPromise();
  }

  createAnonUser(anonUser: AnonymousUser): Promise<string> {
    return this.createWithResponseAsUrl(
      `${this.endpointUrl}/anonymous`,
      anonUser
    ).toPromise();
  }

  updateUserProjectPermissions(projectsArray: Array<any>, userId: string) {
    return this.createWithResponseAsUrl(
      `${this.endpointUrl}/${userId}/permissions`,
      projectsArray
    ).toPromise();
  }

  getTokenForAnonUser(id: string): Promise<string> {
    return this.httpClient
      .post(`${this.endpointUrl}/anonymous/token/${id}`, {})
      .pipe(map(resp => (resp ? (resp['token'] as string) : null)))
      .toPromise();
  }
}
