import { Pipe, PipeTransform } from '@angular/core';

import { transform } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';

@Pipe({ name: 'lonLat' })
export class LonLatPipe implements PipeTransform {
  transform(coords: Coordinate, projection?: string): string {
    let newCoords = transform(coords, projection || 'EPSG:3857', 'EPSG:4326');

    const posW = (360 - (Math.abs(newCoords[0]) % 360)).toFixed(4);
    const negW = (Math.abs(newCoords[0]) % 360).toFixed(4);
    const posE = negW;
    const negE = posW;
    const isPositive = Math.sign(newCoords[0]) === 1;

    return `
      ${Math.abs(newCoords[1]).toFixed(4)}°
      ${Math.sign(newCoords[1]) === -1 ? 'S' : 'N'},

      ${
        Math.abs(Math.floor(newCoords[0] / 180) % 2) === 1
          ? isPositive
            ? posW
            : negW
          : isPositive
          ? posE
          : negE
      }°
      ${Math.abs(Math.floor(newCoords[0] / 180) % 2) === 1 ? 'W' : 'E'}
    `;
  }
}
