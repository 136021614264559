import { Injectable } from '@angular/core';

import { MapFeaturesService } from './mapFeatures.service';

import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Feature } from 'ol';
import { Collection } from 'ol';
import { MapBrowserEvent } from 'ol';
import { Map } from 'ol';
import Layer from 'ol/layer/Layer';
import * as RenderFeature from 'ol/render/Feature';
import Stroke from 'ol/style/Stroke';
import Draw, { GeometryFunction } from 'ol/interaction/Draw';
import GeometryType from 'ol/geom/GeometryType';
import { DrawEvent } from 'ol/interaction/Draw';
import { unByKey } from 'ol/Observable';
import { Translate, Modify, Select } from 'ol/interaction';
import { pointerMove, shiftKeyOnly, singleClick } from 'ol/events/condition';
import { TranslateEvent } from 'ol/interaction/Translate';
import { Pixel } from 'ol/pixel';

import { environment } from 'environments/environment';

/**
 * Class to manage interactions for the map
 */
@Injectable({
  providedIn: 'root'
})
export class MapInteractionsService {
  private _translate: Translate;
  private _draw: Draw;
  private _modify: Modify;
  private _hover: Select;
  private _map: Map;
  private _clickedFeature$ = new Subject<Feature | RenderFeature.default>();
  private _doubleClickedFeature$ = new Subject<
    Feature | RenderFeature.default
  >();
  private _hoveredFeature$ = new BehaviorSubject<
    Feature | RenderFeature.default
  >(null);
  private _isDrawing: boolean = false;

  /**
   * Returns an observable that will emit a feature when it is clicked on the map,
   * or null if the map itself is clicked
   *
   * @return observable that emits ol.Features
   */
  get clickedFeature$() {
    return this._clickedFeature$;
  }

  /**
   * Returns an observable that will emit a feature when it is double clicked on the map,
   * or null if the map itself is clicked
   *
   * @return observable that emits ol.Features
   */
  get doubleClickedFeature$() {
    return this._doubleClickedFeature$;
  }

  /**
   * Returns an observable that will emit a feature when it is hovered on the map,
   * or null if the mouse is hovering over no feature
   *
   * @return observable that emits ol.Features
   */
  get hoveredFeature$() {
    return this._hoveredFeature$;
  }

  init(map: Map) {
    this._map = map;

    this._hover = new Select({
      condition: pointerMove,
      style: MapFeaturesService.clusterFeatureHoverStyleFunction,
      layers: (layer: Layer) => {
        return layer.get('clusterGuy');
      }
    });

    this._hover.on('select', (e: any) => {
      if (e.selected.length > 0)
        (this._hover.getMap().getTargetElement() as HTMLElement).style.cursor =
          'pointer';
      else
        (this._hover.getMap().getTargetElement() as HTMLElement).style.cursor =
          'default';

      try {
        // This try/catch is in case the feature comes from an MVT layer
        e.selected.forEach((feat: Feature) => feat.set('hover', true));
        e.deselected.forEach((feat: Feature) => feat.set('hover', false));
      } catch (e) {
        console.log('its a render feature');
      }
    });

    this._map.addInteraction(this._hover);

    this._map.on('pointermove', (event: MapBrowserEvent) => {
      if (this._isDrawing) return;
      this._hoveredFeature$.next(this._getMouseOverFeature(event.pixel));
    });

    this._map.on('click', (event: MapBrowserEvent) => {
      if (this._isDrawing) return;
      this._clickedFeature$.next(this._getMouseOverFeature(event.pixel));
    });

    this._map.on('dblclick', (event: MapBrowserEvent) => {
      event.preventDefault();
      if (this._isDrawing) return;
      this._doubleClickedFeature$.next(this._getMouseOverFeature(event.pixel));
    });
  }

  /**
    Activates the draw interaction for the map, and accepts a callback
    which will be called when the drawing is finished.
    @param type The type of geometry to draw.
      [GeometryType]{@link https://openlayers.org/en/latest/apidoc/ol.geom.html#.GeometryType}
    @param drawEndCallback callback function to be called after the drawing is finished.
      Receives an event object with the drawn feature.
    @param [drawStartCallback] callback function to be called when the drawing is started.
      Receives an event object with the drawn feature.
    @param [geometryFunction] function that is called when a geometry's coordinates are updated.
      Useful for drawing shapes. {@link https://openlayers.org/en/latest/examples/draw-shapes.html}
      [ol.DrawGeometryFunctionType()]{@link http://openlayers.org/en/master/apidoc/ol.html#.DrawGeometryFunctionType}
    @param [freehand] operate in freehand mode for lines, polygons, and circles.
  */
  activateDraw(
    type: string,
    geometryFunction?: GeometryFunction,
    freehand?: boolean
  ): Observable<DrawEvent> {
    return new Observable(subscriber => {
      const _teardownFunc = () => {
        this._isDrawing = false;
        this._hover.setActive(true);
        // this.removeDraw();
        this._map.removeInteraction(this._draw);
      };

      let options = {
        type: type as GeometryType,
        style: MapFeaturesService.defaultStyle
      };
      options.style.setStroke(new Stroke({
        color: environment.AOI_DRAWING_COLOR,
        width: 1.5
      }))

      if (geometryFunction) options['geometryFunction'] = geometryFunction;
      if (freehand) options['freehand'] = freehand;

      this._draw = new Draw(options);
      this._map.addInteraction(this._draw);
      this._hover.setActive(false);
      this._isDrawing = true;

      this._draw.once('drawstart', (event: DrawEvent) => {
        subscriber.next(event);
      });

      this._draw.once('drawend', (event: DrawEvent) => {
        subscriber.next(event);

        _teardownFunc();

        subscriber.complete();
      });

      return _teardownFunc;
    });
  }

  deactivateDraw() {
    if (this._draw && this._draw.getActive()) {
      this._draw.dispatchEvent('drawend');
    }
  }

  /**
   * This function was hastily written for the lasso select feature in the triage tool
   * it doesn't actually select anything, just emits a draw event
   *
   * @return observable that emits a drawend event
   */
  activateSelection() {
    return this.activateDraw('Polygon', undefined, true).pipe(
      filter(event => event.type === 'drawend'),
      map((event: DrawEvent) => {
        return event;
      })
    );
  }

  /**
    Activiates the modify interaction for the map.
    Note this still has to mutate the feature directly
    @param feature the feature to modify
  */
  activateModify(feature: Feature) {
    if (this._modify) this.removeModify();

    this._modify = new Modify({
      features: new Collection<Feature>([feature]),
      deleteCondition: function(event: any) {
        return shiftKeyOnly(event) && singleClick(event);
      },
      style: MapFeaturesService.defaultStyle
    });

    this._map.addInteraction(this._modify);
  }

  /**
    Removes the modify interaction from the map.
  */
  removeModify() {
    this._map.removeInteraction(this._modify);
    this._modify = null;
  }

  /**
    Activiates the translate interaction for the map.
    Note this still has to mutate the feature directly
    @param features the features to translate
  */
  activateTranslate(features: Feature[]): Observable<Feature[]> {
    if (this._translate) this.removeTranslate();

    this._translate = new Translate({
      features: new Collection<Feature>(features)
    });

    this._map.addInteraction(this._translate);

    return new Observable(subscriber => {
      const _translateEventKey = this._translate.on(
        'translateend',
        (event: TranslateEvent) => {
          subscriber.next(event.features.getArray());
        }
      );

      // TODO handle error and complete???

      return () => {
        unByKey(_translateEventKey);
        this.removeTranslate();
      };
    });
  }

  /**
    Removes the modify interaction from the map.
  */
  removeTranslate() {
    this._map.removeInteraction(this._translate);
    this._translate = null;
  }

  /**
   * simple method to return the feature at a pixel
   */
  private _getMouseOverFeature(pixel: Pixel): Feature | RenderFeature.default {
    const features = this._map.getFeaturesAtPixel(pixel);

    // we only care about the first feature
    return features ? features[0] : null;
  }
}
