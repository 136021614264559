import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'user-menu-content',
  templateUrl: './user-menu-content.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuContentComponent {
  @Input()
  tosLink =
    `${environment.CDN_ROOT}/terms-of-use/Airbus%20Aerial%20GTCS.pdf`;

  @Output()
  logout = new EventEmitter<void>();
}
