import { Pipe, PipeTransform } from '@angular/core';
import isFinite from 'lodash/isFinite';
import { numberWithCommas } from './helpers';

@Pipe({ name: 'numberWithCommas' })

// Add thousands comma separators to make them easier to read
// 1234 : 1,234
export class NumberWithCommasPipe implements PipeTransform {
  transform(input: number): string {
    if (isFinite(input)) {
      return numberWithCommas(input);
    } else {
      return 'NaN';
    }
  }
}
