import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { Workspace } from '../models/workspace.model';
import { WorkspaceLayer } from '../models/workspaceLayer.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService extends BaseService {
  private readonly _keyName = 'aerialSavedWorkspaces'; // for localstorage
  private _projectId: string;

  protected get endpointUrl() {
    return `${environment.API_ROOT}/projects/${this._projectId}/workspaces`;
  }

  protected mapJsonToCollection(respBody: Workspace[]): Workspace[] {
    return respBody.map(json => new Workspace(json));
  }

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
    localStorage.removeItem(this._keyName); // get rid of any old saved workspaces
  }

  getWorkspacesForProject(
    projectId: string,
    baseParams: BaseParams = {}
  ): Promise<PagedResponse<Workspace>> {
    this._projectId = projectId;

    if (!baseParams.size) {
      baseParams.size = 500;
    }

    return this.getWithPagedResponse<Workspace>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getWorkspace(projectId: string, workspaceId: string): Promise<Workspace> {
    this._projectId = projectId;
    return this._httpClient
      .get<Workspace>(`${this.endpointUrl}/${workspaceId}`)
      .pipe(map(resp => new Workspace(resp)))
      .toPromise();
  }

  createWorkspace(workspace: Workspace): Promise<string> {
    this._projectId = workspace.projectId;
    return this.createWithResponseAsUrl(
      this.endpointUrl,
      workspace
    ).toPromise();
  }

  updateWorkspace(workspace: Workspace): Promise<HttpResponse<Workspace>> {
    this._projectId = workspace.projectId;
    return this.putWithResponse<Workspace>(
      `${this.endpointUrl}/${workspace.id}`,
      workspace
    ).toPromise();
  }

  patchWorkspace(
    projectId: string,
    workspaceId: string,
    params: object
  ): Promise<HttpResponse<Workspace>> {
    this._projectId = projectId;
    return this.patchWithResponse<Workspace>(
      `${this.endpointUrl}/${workspaceId}`,
      params
    ).toPromise();
  }

  deleteWorkspace(
    projectId: string,
    workspaceId: string
  ): Promise<HttpResponse<object>> {
    this._projectId = projectId;
    return this.deleteWithResponse(
      `${this.endpointUrl}/${workspaceId}`
    ).toPromise();
  }
}
