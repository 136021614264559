import { BaseModel } from './base.model';

export enum NotificationReferenceType {
  Attachment = 'ATTACHMENT',
  Order = 'ORDER',
  Airflow = 'AIRFLOW',
  DataProduct = 'DATA_PRODUCT'
}

export enum NotificationPreferenceReferenceType {
  ManagementZone = 'MANAGEMENT_ZONE',
  VRT = 'VRT',
  Grower = 'GROWER'
}

export enum NotificationMessageType {
  Progress = 'PROGRESS',
  Error = 'ERROR',
  ImportStart = 'DATA_IMPORT_START',
  ImportEnd = 'DATA_IMPORT_END',
  DataProductCreated = 'DATA_PRODUCT_CREATED',
  OrderFulfilled = 'ORDER_FULFILLED'
}

export class Notification extends BaseModel {
  id: string;
  userId: string;
  referenceId: string;
  referenceType: NotificationReferenceType | string;
  message: string;
  messageType: NotificationMessageType | string;
  read: boolean;
  created: number;
  modified: number;
  attributes: any;
}

export interface NotificationPreference {
  userId: string;
  referenceId: string;
  referenceType: NotificationPreferenceReferenceType;
}
