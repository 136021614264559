import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'aerial-tab',
  templateUrl: 'tab.template.html',
  styleUrls: ['./tab.styles.scss']
})
export class TabComponent implements OnInit {
  @Input()
  size: string;

  @Input()
  routePath: string | object;

  @Input()
  active = false;

  @Input()
  exact = false;

  constructor() {}

  ngOnInit() {}
}
