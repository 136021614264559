import { ObjectWithFeature } from './objectWithFeature.model';
import { DataProductConfig } from './orderTemplate.model';
import { SiteContact } from '../interfaces/siteContact.interface';

import GeoJSON from 'ol/format/GeoJSON';

export enum OrderStatus {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED'
}

export class Order extends ObjectWithFeature {
  public id: string;
  public projectId: string;
  public customerId: string;
  public userId: string;
  public name: string;
  public serviceType: string;
  public aoiId: string;
  public deadline: number;
  public acquisitionDate: number;
  public completionDate: number;
  public created: number;
  public modified: number;
  public notes: string;
  public dataProductConfigs: DataProductConfig[];
  public status: OrderStatus;
  public siteContact: SiteContact;
  public orderTemplateId: string;
  public description: string;
  public thumbnailUrl: string;
  public outputProduct: string;
  public attributes: {
    downloadFile?: boolean;
  };

  constructor(options?: any) {
    super(options);

    if (!options || !options.siteContact) {
      this.siteContact = {} as SiteContact;
    }
  }

  toJSON(): object {
    let geo = new GeoJSON();

    let json = {
      name: this.name,
      deadline: this.deadline,
      acquisitionDate: this.acquisitionDate,
      aoiId: this.aoiId,
      projectId: this.projectId,
      customerId: this.customerId,
      userId: this.userId,
      serviceType: this.serviceType,
      notes: this.notes,
      dataProductConfigs: this.dataProductConfigs,
      status: this.status,
      siteContact: this.siteContact,
      orderTemplateId: this.orderTemplateId,
      description: this.description,
      thumbnailUrl: this.thumbnailUrl,
      outputProduct: this.outputProduct,
      attributes: this.attributes
    };

    if (this.feature) {
      let jsonFeature;

      try {
        jsonFeature = JSON.parse(geo.writeFeature(this.feature));
      } catch (e) {
        // in case its not an Feature
        jsonFeature = this.feature;
      }

      json['aoi'] = {
        type: 'FeatureCollection',
        features: [jsonFeature]
      };
    }

    return json;
  }

  static fromJSON(jsonOrder: any): Order {
    let geo = new GeoJSON();
    let feature = null;

    // TODO: this try is to catch any old orders with out AOIs on them
    try {
      // TODO: need to deal with feature collections
      let featGeo = jsonOrder.aoi.features[0];
      feature = geo.readFeature(featGeo);
      feature.setId(jsonOrder.id);
      feature.setProperties({
        name: jsonOrder.name,
        class: 'aoi-feature'
      });
    } catch (e) {
      //no aoi
    }

    // TODO: Change this crap to use spread operator
    return new Order({
      id: jsonOrder.id,
      projectId: jsonOrder.projectId,
      customerId: jsonOrder.customerId,
      userId: jsonOrder.userId,
      name: jsonOrder.name,
      serviceType: jsonOrder.serviceType,
      aoiId: jsonOrder.aoiId,
      feature: feature,
      deadline: jsonOrder.deadline,
      acquisitionDate: jsonOrder.acquisitionDate,
      completionDate: jsonOrder.completionDate,
      created: jsonOrder.created,
      modified: jsonOrder.modified,
      dataProductConfigs: jsonOrder.dataProductConfigs,
      status: jsonOrder.status,
      siteContact: jsonOrder.siteContact as SiteContact,
      orderTemplateId: jsonOrder.orderTemplateId,
      description: jsonOrder.description,
      thumbnailUrl: jsonOrder.thumbnailUrl,
      outputProduct: jsonOrder.outputProduct,
      attributes: jsonOrder.attributes
    });
  }
}
