import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface Checkbox {
  label: string;
  controlName: string;
}

export interface CheckboxConfig {
  groupName: string;
  title?: string;
  checkboxes: Array<Checkbox>;
}

@Component({
  selector: 'multi-checkbox',
  template: `
    <div [formGroup]="parent">
      <h3 class="title">{{ config.title }}</h3>
      <div formGroupName="{{ config.groupName }}">
        <div class="checkbox-with-label" *ngFor="let checkbox of config.checkboxes">
          <label>
            <input type="checkbox" formControlName="{{ checkbox.controlName }}" />
            {{ checkbox.label }}
          </label>
        </div>
      </div>
    </div>
  `
})
export class MultiCheckboxComponent {
  @Input() parent: FormGroup;
  @Input() config: CheckboxConfig;
  constructor() {}
}
