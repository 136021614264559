import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialColor } from '../util/helpers';

export interface ProgressBarOptions {
  value?: number;
  percentage?: number | string;
  showPercentage?: boolean;
  warn?: boolean;
  complete?: boolean;
  altStyle?: boolean;
  color?: AerialColor;
}

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.template.html',
  styleUrls: ['./progress-bar.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() // value will be multiplied by 100 to calculate percentage
  set value(value: number) {
    this._percentage = value * 100;
  }

  @Input()
  set percentage(percentage: number | string) {
    if (typeof percentage === 'string') {
      this._percentage = Number(percentage.replace(/%$/, ''));
    } else {
      this._percentage = percentage;
    }
  }

  @Input() // if set to `true`, the bar will turn red at 90%
  set warn(warn: boolean) {
    this._options.warn = warn;
  }

  @Input() // if set to `true`, the bar will turn green at 100% (need a better prop name)
  set complete(complete: boolean) {
    this._options.complete = complete;
  }

  @Input()
  set showPercentage(showPercentage: boolean) {
    this._options.showPercentage = showPercentage;
  }

  @Input()
  set altStyle(altStyle: boolean) {
    this._options.altStyle = altStyle;
  }

  @Input()
  set color(color: AerialColor) {
    this._options.color = color;
  }

  @Input()
  set options(options: ProgressBarOptions) {
    this._options = options;
  }

  get percentage(): number | string {
    let percentage = Math.round(this._percentage);
    if (percentage < 0) {
      percentage = 0;
    } else if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  }

  get warn(): boolean {
    return this._options.warn ? this._options.warn : false;
  }

  get complete(): boolean {
    return this._options.complete ? this._options.complete : false;
  }

  get showPercentage(): boolean {
    return this._options.showPercentage ? this._options.showPercentage : false;
  }

  get altStyle(): boolean {
    return this._options.altStyle ? this._options.altStyle : false;
  }

  get color(): AerialColor {
    return this._options.color ? this._options.color : 'dark-blue';
  }

  get width(): string {
    return `${this.percentage}%`;
  }

  get showWarning(): boolean {
    return this.warn && (this.percentage as number) >= 90;
  }

  get showComplete(): boolean {
    return this.complete && (this.percentage as number) === 100;
  }

  private _percentage: number;
  private _options: ProgressBarOptions = {};
}
