import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AerialAccordion } from '../aerial-accordion/aerial-accordion.model';

@Component({
  selector: 'accordion-heading',
  templateUrl: './accordion-heading.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionHeadingComponent extends AerialAccordion {
  // `text` string must be provided in order for the inline-edit to work!
  @Input()
  editable = false;

  @Input()
  text: string;

  @Output()
  edit = new EventEmitter<string>();
}
