import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'loader-guy',
  template: `
    <!-- prettier-ignore -->
    <div class="loader-guy {{ size }} {{ color }}" [ngClass]="{
      'inline': inline,
      'hide-text': hideText
    }">
      <small class="text">{{ text }}</small>
      <div class="spinner">
        <span class="rect rect1"></span>
        <span class="rect rect2"></span>
        <span class="rect rect3"></span>
        <span class="rect rect4"></span>
        <span class="rect rect5"></span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderGuyComponent {
  @Input() text = 'LOADING';
  @Input() hideText: boolean;
  @Input() size: 'small' | 'medium' | 'large' | 'full' = 'full';
  @Input() color: 'dark' | 'light' | 'neutral' = 'dark';
  // @Input() inTextInput: boolean; // deprecating - only used in commented-out block in ASP
  @Input() inline: boolean;
}
