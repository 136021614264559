import { Injectable } from '@angular/core';
import { BaseDataService } from '../../shared/services/base-data.service';
import { Field, FieldService } from 'api/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class FieldsStateService extends BaseDataService<Field> {
  constructor(private service: FieldService) {
    super({ selected: null, cached: {} }, service);
  }
}
