import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FieldsComponent } from './fields.component';
import { FieldSingleComponent } from './field-single/field-single.component';
import { CreateFieldComponent } from './create-field/create-field.component';
import { NameFieldOverlayComponent } from './create-field/name-field-overlay/name-field-overlay.component';

const routes: Routes = [
  {
    path: '',
    component: FieldsComponent
  },
  // {
  //   path: 'create-field',
  //   component: CreateFieldComponent,
  //   outlet: 'floating-panel'
  // },
  {
    path: 'create',
    children: [
      {
        path: '',
        component: CreateFieldComponent
      }
      // {
      //   path: 'import-settings',
      //   component: NameFieldOverlayComponent,
      //   outlet: 'modal'
      // }
    ]
  },
  {
    path: 'import-settings',
    component: NameFieldOverlayComponent,
    outlet: 'modal'
  },
  {
    path: ':fieldId',
    component: FieldSingleComponent
  },
  {
    path: ':fieldId/plantings',
    loadChildren: '../plantings/plantings.module#PlantingsModule'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FieldsRoutingModule {}
