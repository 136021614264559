import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialButton } from './aerialButton.model';

@Component({
  selector: 'aerial-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <button
      [ngClass]="allClasses"
      [type]="type"
      [disabled]="disabled">
      <ng-content></ng-content>
    </button>
  `
})
export class AerialButtonComponent extends AerialButton {
  @Input()
  classes: string[] | string = [];
}
