import { BaseModel } from './base.model';

export interface AirspaceAdvisory {
  city: string;
  color: string;
  country?: string;
  distance: number;
  id: string;
  last_updated: string;
  latitude: number;
  longitude: number;
  name: string;
  properties: {
    url: string;
    icao: string;
    type: string;
  };
  requirements?: {
    notice: {
      phone: number | string;
      digital: string;
    };
  };
  rule_id: number;
  ruleset_id: string;
  state: string;
  type: string;
}

export interface WeatherAdvisory {
  condition: string;
  dew_point: number;
  humidity: number;
  icon: string;
  mslp: number;
  precipitation: number;
  temperature: number;
  time: string;
  timezone: string;
  visibility: number;
  wind: {
    heading: number;
    speed: number;
    gusting: number;
  };
}

export class AirmapAdvisories extends BaseModel {
  airspaceAdvisories: AirspaceAdvisory[];
  weatherAdvisories: WeatherAdvisory[];
  missionId: string;
}
