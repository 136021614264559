import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { User } from 'projects/api/src/public_api';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent {
  @Input()
  user: User;

  @Output()
  logout = new EventEmitter<void>();
}
