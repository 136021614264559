import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup
} from '@angular/forms';
import uniq from 'lodash/uniq';
import {
  User,
  UserService,
  UserInvite,
  RoleType,
  PortalType,
  Customer,
  CustomerService,
  CustomerCategory,
  GrowerService,
  PagedResponse,
  Grower,
  OperationType
} from 'projects/api/src/public_api';
import { Dialog } from 'projects/ui/src/public_api';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
  templateUrl: './invite-user-dialog.template.html',
  styleUrls: ['./invite-user-dialog.styles.scss']
})
export class InviteUserDialogComponent implements Dialog, OnInit, OnDestroy {
  currentUser: User;
  successCallback: Function;
  errorCallback?: Function;
  customers: Customer[] = [];

  growers$: Observable<Grower[]>;
  selectedGrowers = [];
  showGrowers: boolean = true;
  filteredList = [];

  inviteForm: FormGroup;
  roleTypes = [
    {
      value: RoleType.Customer,
      label: 'User'
    },
    {
      value: RoleType.Grower,
      label: 'Grower'
    },
    {
      value: RoleType.CustomerAdmin,
      label: 'Admin'
    }
  ];

  showResults = false;
  inviting = false;

  get emailCtrl(): FormControl {
    return this.inviteForm.get('email') as FormControl;
  }

  get firstNameCtrl(): FormControl {
    return this.inviteForm.get('firstName') as FormControl;
  }

  get lastNameCtrl(): FormControl {
    return this.inviteForm.get('lastName') as FormControl;
  }

  get customerCtrl(): FormControl {
    return this.inviteForm.get('customerId') as FormControl;
  }

  get roleCtrl(): FormControl {
    return this.inviteForm.get('role') as FormControl;
  }

  constructor(
    private _userService: UserService,
    private _fb: FormBuilder,
    private _customerService: CustomerService,
    private _growerService: GrowerService
  ) {}

  close = () => {};

  inviteUser(): void {
    const invite: UserInvite = {
      id: this.customerCtrl.value,
      roles: [
        {
          parentId: this.customerCtrl.value,
          parentType: RoleType.Customer,
          roleType: this.roleCtrl.value
        }
      ],
      ...(this.roleCtrl.value === 'GROWER' && {
        permissionSet: this.selectedGrowers.map(growerId => {
          return {
            entityType: 'GROWER',
            entityId: growerId,
            operations: [
              OperationType.Update,
              OperationType.Create,
              OperationType.Read
            ]
          }
        })
      }),
      username: this.emailCtrl.value,
      type: PortalType.Ag
    };
    this.inviting = true;
    this._userService
      .inviteUserWithResponse(invite)
      .then((res: string) => {
        this.successCallback(this.emailCtrl.value);
        this.close();
      })
      .catch(err => {
        this.inviting = false;
        try {
          // temp thing
          const errors: string[] = uniq(err.error.errors);
          if (errors.includes('username: The value is already taken.')) {
            this.emailCtrl.setErrors({
              alreadyTaken: true
            });
          } else {
            this.close();
            this.errorCallback(err);
          }
        } catch (e) {
          this.close();
          this.errorCallback(err);
        }
      });
  }

  ngOnInit() {
    this.growers$ = this._growerService.list().pipe(
      map((resp: PagedResponse<Grower>) => {
        this.filteredList = resp.data;
        this.filteredList.map(grower => {
          (grower as any).multiSelectLabels = [grower.name]
        })
        return resp.data;
      })
    );

    this.inviteForm = this._fb.group({
      email: ['', Validators.required],
      firstName: '',
      lastName: '',
      role: [RoleType.Customer, Validators.required],
      customerId: [
        this.currentUser.isAerialAdmin ? '' : this.currentUser.customerParentId,
        Validators.required
      ]
    });

    if (this.currentUser.isAerialAdmin) {
      this._customerService.getCustomers().then(resp => {
        this.customers = resp.data.filter(
          // filter out non-Ag customers
          customer => customer.category === CustomerCategory.Ag
        );
      });
    }
  }

  ngOnDestroy() {
  }
}
