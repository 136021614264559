import { Injectable } from '@angular/core';
import { BaseDataService } from '../../shared/services/base-data.service';
import { Planting, PlantingService } from 'api/src/public_api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlantingsStateService extends BaseDataService<Planting> {
  public includeTimeline = new BehaviorSubject<boolean>(false);
  public timelineOpen = new BehaviorSubject<boolean>(false);

  constructor(private service: PlantingService) {
    super({ selected: null, cached: {} }, service);
  }
}
