import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
  forwardRef,
  OnInit,
  OnDestroy,
  HostBinding,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import flatpickr from 'flatpickr';
import {
  DatePickerConfig,
  defaultDatePickerConfig
} from '../date-picker/datePickerConfig.interface';
import { DatePickerControl } from './date-picker-control.model';

@Component({
  selector: 'date-picker-control',
  templateUrl: './date-picker-control.template.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerControlComponent),
      multi: true
    }
  ]
})
export class DatePickerControlComponent extends DatePickerControl
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  set config(config: DatePickerConfig) {
    const currDate = new Date();
    this._config = {
      ...this._config,
      defaultHour: currDate.getHours(),
      defaultMinute: currDate.getMinutes(),
      minuteIncrement: 1,
      ...config,
      style: config.style === 'alt-dropdown' ? 'dropdown' : config.style
    };
    if (this._config.style === 'dropdown') {
      // make sure the `outlined` class gets added to the flatpickr input
      this._config.altInputClass = `outlined ${config.altInputClass}`;
    }
  }

  get config(): DatePickerConfig {
    return this._config;
  }

  @Input()
  invalid: boolean;

  get configOptions(): object {
    const opts = { ...this.config };
    opts['onChange'] = (selectedDates: Date[], dateStr: string) => {
      this._inputChange(selectedDates, dateStr);
    };
    opts['onValueUpdate'] = (selectedDates: Date[], dateStr: string) => {
      this._inputUpdate(selectedDates, dateStr);
    };
    opts['onClose'] = () => {
      this.isPickerOpen = false;
      this.closePicker.emit();
      // this._changeDetectorRef.markForCheck();
    };
    opts['onOpen'] = () => {
      this._onTouched();
      this.isPickerOpen = true;
      this.openPicker.emit();
    };
    opts['clickOpens'] = this.isIconBtnStyle ? false : true;
    return opts;
  }

  @HostBinding('class.form-control')
  hasFormCtrlClass = true;

  @HostBinding('class.input-control')
  get hasInputCtrlClass(): boolean {
    return this.isInputStyle || this.isDropdownStyle;
  }

  @HostBinding('class.button-control')
  get hasBtnClass(): boolean {
    return this.isBtnStyle || this.isIconBtnStyle;
  }

  protected disabled = false;
  protected isPickerOpen: boolean;

  @ViewChild('input')
  private _inputEl: ElementRef<HTMLInputElement>;

  private _config: DatePickerConfig = defaultDatePickerConfig;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {
    super();
  }

  writeValue(timestamps: number | string | Array<number | string>): void {
    const date = !timestamps
      ? []
      : Array.isArray(timestamps)
      ? timestamps.map(ts => new Date(ts))
      : new Date(timestamps);
    this._flatpickr.setDate(date);
  }

  registerOnChange(
    fn: (_: number | string | Array<number | string>) => void
  ): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (this.isIconBtnStyle) {
      return;
    }
    if (isDisabled) {
      this._flatpickr.set('clickOpens', 'false');
    } else {
      this._flatpickr.set('clickOpens', 'true');
    }
  }

  ngOnInit() {
    this._flatpickr = flatpickr(
      this._inputEl.nativeElement,
      this.configOptions
    );
    if (this.config.altInput) {
      // hacky, but it's a limitation of flatpickr
      const altInput = this._renderer.nextSibling(this._inputEl.nativeElement);
      this._renderer.setAttribute(altInput, 'placeholder', this.placeholder);
    }
  }

  ngOnDestroy() {
    this._flatpickr.destroy();
  }

  private _onChange = (_: number | string | Array<number | string>) => {};
  private _onTouched = () => {};

  private _inputChange(selectedDates: Date[], dateStr: string): void {
    if (selectedDates.length) {
      return;
    }
    this._onChange(this.isSingleMode ? '' : []);
  }

  private _inputUpdate(selectedDates: Date[], dateStr: string): void {
    if (this.isRangeMode && selectedDates.length === 1) {
      // don't emit until a full range (2 dates) is selected
      return;
    }
    const timestamps = selectedDates.map(date => date.getTime());
    if (this.isSingleMode) {
      this._onChange(this.isTimestampFormat ? timestamps[0] : dateStr);
    } else {
      const emitMulti = this.isRangeMode
        ? dateStr.split(' to ')
        : dateStr.split(', ');
      this._onChange(this.isTimestampFormat ? timestamps : emitMulti);
    }
  }
}
