import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Notification,
  NotificationService,
  CoordinatorService,
  AgSearchService,
  ADMAsset,
  User
} from 'api/src/public_api';
import {
  NotificationStreamService,
  NotificationMapByDate
} from '../services/notificationStream.service';
import { Subscription } from 'rxjs';
import { ContextService } from '../services/context.service';
import {
  DialogService,
  DialogIntent,
  ToasterService
} from 'projects/ui/src/public_api';
import { NotificationsSettingsDialogComponent } from '../../dialogs/notifications-settings-dialog/notifications-settings-dialog.component';
import { ADMUtilsService } from '../services/adm-utils.service';
import { HttpResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Component({
  selector: 'notification-panel',
  templateUrl: './notifications-panel.template.html',
  styleUrls: ['./notifications-panel.styles.scss']
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  @Input()
  showNotificationsPanel = false;

  @Output()
  notificationAlert: EventEmitter<NotificationMapByDate[]> = new EventEmitter<
    NotificationMapByDate[]
  >();

  private _notificationStreamSub: Subscription;
  public notifications: NotificationMapByDate[] = [];
  public testingMode: boolean;
  public blockAlert = false;
  isGrower = false;

  constructor(
    private _notificationStreamService: NotificationStreamService,
    private _notificationService: NotificationService,
    private _contextService: ContextService,
    private _coordinatorService: CoordinatorService,
    private _dialogService: DialogService,
    private _toaster: ToasterService,
    private _admUtilsService: ADMUtilsService,
    private _agSearchService: AgSearchService
  ) { }

  ngOnInit() {
    // if wanting to show notifications testing widget during development
    this.testingMode = false;

    this._contextService.user$.pipe(take(1)).subscribe((user: User) => {
      this.isGrower = user.isGrower;
    });

    // filter to Notification types we want (on load only)
    this._notificationStreamService.filterArray = [
      'MANAGEMENT_ZONE',
      'WEAK_ACRES',
      'REPORT'
    ];

    this._notificationStreamSub = this._notificationStreamService.notifications$.subscribe(
      (notifications: NotificationMapByDate[]) => {
        this.notifications = notifications;
        // console.log(this.notifications);
        if (this.blockAlert) {
          this.blockAlert = false;
        } else {
          this.notificationAlert.emit(notifications);
        }
      }
    );

    ///////////////////////////////////////////////////////////////
    // for testing ////////////////////////////////////////////////
    this._notificationStreamService
      .getStream$('New Notification')
      .subscribe((notification: any) => {
        console.log('New Notification: ', notification);
      });
    this._notificationStreamService
      .getStream$('ASSET')
      .subscribe((notification: any) => {
        console.log('ASSET: ', notification);
      });
    this._notificationStreamService
      .getStream$('MANAGEMENT_ZONE')
      .subscribe((notification: any) => {
        console.log('MANAGEMENT_ZONE: ', notification);
      });
    this._notificationStreamService
      .getStream$('VRT')
      .subscribe((notification: any) => {
        console.log('VRT: ', notification);
      });
  }

  ngOnDestroy() {
    this._notificationStreamSub.unsubscribe();
    this._notificationStreamService.destroy();
  }

  launchNotificationSettings() {
    const data = {
      userId: this._contextService.user.id,
      successCallback: () => {
        this._toaster.toast('success', 'Notification settings saved!');
      }
    };

    this._dialogService.createDialog({
      title: 'Notification Settings',
      titleIcon: 'icon-gear',
      component: NotificationsSettingsDialogComponent,
      size: 'small',
      className: 'with-actions',
      hideCloseBtn: true,
      data
    } as DialogIntent);
  }

  downloadReport(notification: Notification) {
    this._agSearchService
      .getAssets({
        plantingId: notification.attributes.plantingId,
        product: 'dashboard report PDF'
      })
      .subscribe((resp: HttpResponse<any>) => {
        const notificationCreationTime = new Date(notification.created);
        let closest = Infinity;
        let target: ADMAsset;

        resp.body.features.forEach((asset: ADMAsset) => {
          const date = new Date(asset.properties.acquisitionDate);

          const diff = Math.abs(
            date.getTime() - notificationCreationTime.getTime()
          );

          if (diff < closest) {
            closest = diff;
            target = asset;
          }
        });

        this._admUtilsService.downloadReport(target);
      });
  }

  markAsRead(notification: Notification) {
    this.blockAlert = true;
    this._notificationStreamService.markAsRead([notification]);
  }

  markAllAsRead() {
    this.blockAlert = true;
    this._notificationStreamService.markAllAsRead();
  }

  ///////////////////////////////////////////////////////////////
  // for testing ////////////////////////////////////////////////
  createBlankNotification(type?: string) {
    this._notificationService
      .create({
        userId: this._contextService.user.id,
        message: `${Math.random()}`
      })
      .subscribe((resp: any) => {
        console.log('blank resp', resp);
      });
  }

  createAssetNotification(id: string) {
    this._notificationService
      .create({
        userId: this._contextService.user.id,
        message: `${Math.random()}`,
        referenceType: 'ASSET',
        referenceId: id
      })
      .subscribe((resp: any) => {
        console.log('asset resp', resp);
      });
  }

  createVRTNotification(id: string) {
    this._notificationService
      .create({
        userId: this._contextService.user.id,
        message: `${Math.random()}`,
        referenceType: 'VRT',
        referenceId: id
      })
      .subscribe((resp: any) => {
        console.log('vrt resp', resp);
      });
  }

  createZoneNotification(id: string) {
    this._notificationService
      .create({
        userId: this._contextService.user.id,
        message: `${Math.random()}`,
        referenceType: 'MANAGEMENT_ZONE',
        referenceId: id
      })
      .subscribe((resp: any) => {
        console.log('zone resp', resp);
      });
  }

  createWeakAcresNotification() {
    this._coordinatorService
      .publishEventToTopic('agneo', {
        messageType: 'AgWeakAcreAlertCreated',
        plantingId: '9a971e1b-e15a-497e-a040-12b1b4b4783a',
        _metadata: {
          messageType: 'AGWEAKACREALERT.CREATED',
          ipAddress: '172.17.0.10',
          timestamp: 1550575311237
        }
      })
      .then((res: any) => {
        console.log('weak acre: ', res);
      });
  }

  createReportNotification(id: string) {
    this._coordinatorService
      .publishEventToTopic('agneo', {
        messageType: 'AgReportCreated',
        plantingId: '9a971e1b-e15a-497e-a040-12b1b4b4783a',
        _metadata: {
          messageType: 'AGREPORT.CREATED',
          ipAddress: '172.17.0.10',
          timestamp: 1550575311237
        }
      })
      .then((res: any) => {
        console.log('agreport rep: ', res);
      });
  }
}
