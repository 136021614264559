import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'number-slider',
  templateUrl: './number-slider.template.html',
  styleUrls: ['./number-slider.styles.scss']
})
export class NumberSliderComponent {
  @Input() control: FormControl;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() placeholder: number;
}
