import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'radio-control',
  template: `
    <!-- prettier-ignore -->
    <div class="radio-control" [class.alt-style]="altStyle">
      <aerial-radio
        class="{{ size }}"
        [size]="size"
        [checked]="control.value === value"
        [disabled]="disabled"
        [altStyle]="altStyle"
        (onChange)="control.setValue(value)">
      </aerial-radio>
      <label [class.disabled]="disabled">
        <input type="radio" [formControl]="control" [value]="value" class="hidden">
        <ng-content></ng-content>
      </label>
    </div>
  `
})
export class RadioControlComponent {
  @Input()
  control: FormControl;

  @Input()
  size: 'small' | 'medium' | 'large' = 'small';

  @Input()
  value: string;

  @Input()
  altStyle = false;

  get disabled(): boolean {
    return this.control.disabled;
  }
}
