import {
  MissionStatus,
  PilotStatus,
  OrderStatus,
  TaskStatus,
  RequirementStatus,
  UserStatus,
  InspectionStatus
} from 'projects/api/src/public_api';
import { titleize } from 'projects/utils/src/public_api';
import kebabCase from 'lodash/kebabCase';

export class AerialStatus {
  private _status: string;

  constructor(status: string) {
    this._status = status;
  }

  get name(): string {
    // example: "UNASSIGNED" or "IN_PROGRESS"
    return this._status;
  }

  get val(): string {
    return this.name;
  }

  get className(): string {
    // example: "unassigned" or "in-progress"
    return kebabCase(this._status);
  }

  get text(): string {
    // example: "Unassigned" or "In Progress"
    return titleize(this._status);
  }

  get contextType(): string {
    switch (this.className) {
      case 'assigned':
      case 'invited':
      case 'pending':
        return 'info';
      case 'ready':
      case 'in-progress':
      case 'submitted':
      case 'flown':
        return 'secondary';
      case 'cancelled':
      case 'vendor-cancelled':
      case 'rejected':
      case 'vendor-rejected':
      case 'failed':
      case 'invalid':
        return 'error';
      case 'delayed':
      case 'pending':
      case 'declined':
        return 'warning';
      case 'complete':
      case 'done':
      case 'active':
      case 'accepted':
      case 'valid':
        return 'success';
      case 'created':
      case 'unassigned':
      case 'awaiting-fulfillment':
      case 'inactive':
        return 'neutral';
      default:
        return 'primary';
    }
  }

  // Mission Status: UNASSIGNED, IN_PROGRESS, FLOWN, DELAYED, CANCELLED, COMPLETE,
  //   VENDOR_REJECTED, VENDOR_CANCELLED, ASSIGNED
  // Pilot Status: UNASSIGNED, PENDING, ACCEPTED, REJECTED
  // OrderStatus: CREATED, CANCELLED, IN_PROGRESS, COMPLETE
  // Task Status: PENDING, COMPLETE
  // InspectionStatus: CREATED, CANCELLED, IN_PROGRESS, COMPLETE
  // RequirementStatus: AWAITING_FULFILLMENT, SUBMITTED, FAILED, DONE
  // UserStatus: INACTIVE, ACTIVE, INVITED
  // AnalyticRun: PENDING, READY, FAILED, ACCEPTED, DECLINED

  get isAssigned(): boolean {
    return this.name === MissionStatus.Assigned;
  }

  get isUnassigned(): boolean {
    return (
      this.name === MissionStatus.Unassigned ||
      this.name === PilotStatus.Unassigned
    );
  }

  get isVendorRejected(): boolean {
    return this.name === MissionStatus.VendorRejected;
  }

  get isVendorCancelled(): boolean {
    return this.name === MissionStatus.VendorCancelled;
  }

  get isInProgress(): boolean {
    // In Progress: Mission, Order, Report
    return (
      this.name === MissionStatus.InProgress ||
      this.name === OrderStatus.InProgress ||
      this.name === InspectionStatus.InProgress
    );
  }

  get isFlown(): boolean {
    return this.name === MissionStatus.Flown;
  }

  get isDelayed(): boolean {
    return this.name === MissionStatus.Delayed;
  }

  get isComplete(): boolean {
    // Complete: Mission, Order, Task, Report
    return (
      this.name === MissionStatus.Complete ||
      this.name === OrderStatus.Complete ||
      this.name === TaskStatus.Complete ||
      this.name === InspectionStatus.Complete
    );
  }

  get isCancelled(): boolean {
    // Cancelled: Mission, Order, Report
    return (
      this.name === MissionStatus.Cancelled ||
      this.name === OrderStatus.Cancelled ||
      this.name === InspectionStatus.Cancelled
    );
  }

  get isPending(): boolean {
    // Pending: Pilot, Task
    return (
      this.name === PilotStatus.Pending || this.name === TaskStatus.Pending
    );
  }

  get isAccepted(): boolean {
    // Accepted: Pilot
    return this.name === PilotStatus.Accepted;
  }

  get isRejected(): boolean {
    // Rejected: Pilot
    return this.name === PilotStatus.Rejected;
  }

  get isCreated(): boolean {
    // Created: Order, Report
    return (
      this.name === OrderStatus.Created ||
      this.name === InspectionStatus.Created
    );
  }

  get isAwaitingFulfillment(): boolean {
    return this.name === RequirementStatus.AwaitingFulfillment;
  }

  get isSubmitted(): boolean {
    return this.name === RequirementStatus.Submitted;
  }

  get isFailed(): boolean {
    return this.name === RequirementStatus.Failed;
  }

  get isDone(): boolean {
    return this.name === RequirementStatus.Done;
  }

  get isActive(): boolean {
    return this.name === UserStatus.Active;
  }

  get isInactive(): boolean {
    return this.name === UserStatus.Inactive;
  }

  get isInvited(): boolean {
    return this.name === UserStatus.Invited;
  }
}
