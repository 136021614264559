import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.template.html',
  styleUrls: ['./search-box.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBoxComponent),
      multi: true
    }
  ]
})
export class SearchBoxComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  get element(): HTMLInputElement {
    return this._input.nativeElement;
  }

  protected searchCtrl = new FormControl('');
  private _changesSub: Subscription;
  private _firstChange = true;
  private _placeholder: string;

  @ViewChild('searchInput')
  private _input: ElementRef<HTMLInputElement>;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  protected onTouched = () => {};
  private _onChange = (_: any) => {};

  writeValue(value: any): void {
    this.searchCtrl.setValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchCtrl.disable();
    }
  }

  ngOnInit() {
    this._changesSub = this.searchCtrl.valueChanges.subscribe(value => {
      if (this._firstChange) {
        this._firstChange = false;
      } else {
        this._onChange(value);
      }
    });
  }

  ngOnDestroy() {
    this._changesSub.unsubscribe();
  }
}
