import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.template.html',
  styleUrls: ['./stepper.styles.scss']
})
export class StepperComponent {
  show = false;
  counter = 1;
  nextStep() {
    // this.stepper.nextStep();
    this.counter++;
  }

  prevStep() {
    // this.stepper.prevStep();
    this.counter--;
  }

  setStep(step) {
    this.counter = step;
  }
}
