import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AuthModule } from 'projects/auth/src/public_api';
import { ApiModule } from 'projects/api/src/public_api';
import { UIModule } from 'projects/ui/src/public_api';
import { UtilsModule } from 'projects/utils/src/public_api';

import { SharedModule } from './shared/shared.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { AppRoutingModule } from './app-routing.module';
import { DialogModule } from './dialog.module';
import { PlantingsModule } from './explorer/plantings/plantings.module';
import { FieldsModule } from './explorer/fields/fields.module';

import { AppComponent } from './app.component';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AuthModule.forRoot(),
    ApiModule.forRoot(),
    UIModule.forRoot(),
    UtilsModule.forRoot(),
    SharedModule,
    DialogsModule,
    AppRoutingModule,
    DialogModule,
    PlantingsModule,
    FieldsModule
  ],
  providers: [{
    provide: APP_BASE_HREF,
    useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
