import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'aerial-radio',
  template: `
    <!-- prettier-ignore -->
    <button type="button"
      class="aerial-radio check-btn square {{ size }}"
      [ngClass]="{
        active: checked,
        'alt-style': altStyle
      }"
      [disabled]="disabled"
      (click)="select()">
      <i class="btn-icon radio-check"></i>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AerialRadioButtonComponent {
  @Input()
  size: 'small' | 'medium' | 'large' = 'small';

  @Input()
  checked = false;

  @Input()
  disabled = false;

  @Input()
  altStyle = false;

  @Output()
  onChange = new EventEmitter<boolean>();

  protected select(): void {
    if (!this.checked) {
      this.checked = true;
      this.onChange.emit(true);
    }
  }
}
