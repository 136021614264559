import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Report, ReportStatus } from '../models/report.model';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map, tap } from 'rxjs/operators';

@Injectable()
export class ReportService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/reports`;
  }

  protected mapJsonToCollection(respBody: Report[]): Report[] {
    return respBody.map(json => new Report(json));
  }

  getReports(baseParams?: BaseParams): Promise<PagedResponse<Report>> {
    return this.getWithPagedResponse<Report>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getReportsForProject(
    projectId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Report>> {
    return this.getWithPagedResponse<Report>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getReportsForCustomer(
    customerId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Report>> {
    return this.getWithPagedResponse<Report>(
      `${this.endpointUrl}/customer/${customerId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getReportsForUser(
    userId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Report>> {
    return this.getWithPagedResponse<Report>(
      `${this.endpointUrl}/user/${userId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getReportsForAsset(
    assetId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Report>> {
    return this.getWithPagedResponse<Report>(
      `${this.endpointUrl}/asset/${assetId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getReportsForInspection(
    inspectionId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Report>> {
    return this.getWithPagedResponse<Report>(
      `${this.endpointUrl}/inspection/${inspectionId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getReport(reportId: string): Promise<Report> {
    return this.httpClient
      .get<Report>(`${this.endpointUrl}/${reportId}`)
      .pipe(map(resp => new Report(resp)))
      .toPromise();
  }

  getMyReports(): Promise<Report> {
    return this.httpClient
      .get<Report>(`${this.endpointUrl}/mine`)
      .pipe(map(resp => new Report(resp)))
      .toPromise();
  }

  createReport(report: Report): Promise<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, report).toPromise();
  }

  updateReport(report: Report): Promise<HttpResponse<Report>> {
    return this.putWithResponse<Report>(
      `${this.endpointUrl}/${report.id}`,
      report
    ).toPromise();
  }

  patchReport(id: string, params: object): Promise<HttpResponse<Report>> {
    return this.patchWithResponse<Report>(
      `${this.endpointUrl}/${id}`,
      params
    ).toPromise();
  }

  unlockReport(id: string): Promise<HttpResponse<Report>> {
    return this.patchReport(id, { status: ReportStatus.InProgress });
  }

  lockReport(id: string): Promise<HttpResponse<Report>> {
    return this.patchReport(id, { status: ReportStatus.Complete });
  }

  deleteReport(id: string): Promise<any> {
    return this.deleteWithResponse(`${this.endpointUrl}/${id}`).toPromise();
  }

  getReportPDF(id: string): Promise<any> {
    return this.postWithResponse(
      `${this.rootUrl}/coordinator/reports/${id}/pdf`,
      null
    )
      .pipe(map(resp => resp.headers.get('Location')))
      .toPromise();
  }
}
