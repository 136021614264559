import { UIModule } from 'projects/ui/src/public_api';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlantingsRoutingModule } from './plantings-routing.module';

import { PlantingsComponent } from './plantings.component';
import { PlantingSingleComponent } from './planting-single/planting-single.component';
import { CreatePlantingComponent } from './create-planting/create-planting.component';
import { StepperComponent } from './create-planting/stepper/stepper.component';
import { StepWrapperComponent } from './create-planting/stepper/step-wrapper/step-wrapper.component';
import { StepComponent } from './create-planting/stepper/step-wrapper/step/step.component';
import { ManagementZoneComponent } from './management-zone/management-zone.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PlantingSubscriptionsComponent } from './planting-subscriptions/planting-subscriptions.component';
import { UtilsModule } from 'utils/src/public_api';
import { LayerOpacityComponent } from 'projects/adp/src/app/map/layerOpacity.component';
import { VrtMapComponent } from './vrt-map/vrt-map.component';
import { EditPlantingComponent } from './edit-planting/edit-planting.component';

@NgModule({
  declarations: [
    PlantingsComponent,
    PlantingSingleComponent,
    CreatePlantingComponent,
    EditPlantingComponent,
    StepperComponent,
    StepWrapperComponent,
    StepComponent,
    ManagementZoneComponent,
    PlantingSubscriptionsComponent,
    LayerOpacityComponent,
    VrtMapComponent
  ],
  imports: [
    CommonModule,
    PlantingsRoutingModule,
    SharedModule,
    UIModule,
    ReactiveFormsModule,
    UtilsModule,
    FormsModule
  ]
})
export class PlantingsModule { }
