import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlantingsStateService } from '../plantings-state.service';
import {
  Subscription,
  Observable,
  BehaviorSubject,
  pipe,
  forkJoin,
  of
} from 'rxjs';
import {
  Planting,
  Field,
  AgSearchService,
  DataProduct,
  AgSubscriptionService,
  AgSubscription,
  PagedResponse,
  Farm,
  Grower,
  ADMAsset,
  ManagementZoneService,
  ManagementZone,
  AttachmentService,
  User
} from 'api/src/public_api';
import { FieldsStateService } from '../../fields/fields-state.service';
import {
  skipWhile,
  switchMap,
  map,
  combineLatest,
  take,
  filter,
  tap
} from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  MapLayersService,
  MapService,
  MapFeaturesService,
  MapLayer
} from 'map/src/public_api';
import {
  ToasterService,
  DialogIntent,
  DialogService
} from 'projects/ui/src/public_api';
import { PlantingSubscriptionsComponent } from '../planting-subscriptions/planting-subscriptions.component';
import { FormControl } from '@angular/forms';
import { FarmsStateService } from '../../farms/farms-state.service';
import { GrowersStateService } from '../../growers/growers-state.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import * as olSphere from 'ol/sphere';

import {
  PlantingDataService,
  HumanReadableProductType,
  AnalyticMap
} from '../../../shared/services/planting-data.service';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ADMUtilsService } from '../../../shared/services/adm-utils.service';
import { ContextService } from '../../../shared/services/context.service';

@Component({
  templateUrl: './planting-single.template.html',
  styleUrls: ['./planting-single.styles.scss']
})
export class PlantingSingleComponent implements OnInit, OnDestroy {
  planting: Planting;
  plantingSub: Subscription;
  plantingArea: number;
  field: Field;
  fieldSub: Subscription;
  farm$: Observable<Farm>;
  grower$: Observable<Grower>;
  field$: Observable<Field>;
  planting$: Observable<Planting>;
  zones = [];
  zoneSub: Subscription;

  agSubscriptionSub: Subscription;
  agSubscription: AgSubscription[] = [];

  routeSub: Subscription;
  selectedDerivativeSub: Subscription;

  currentLayer: MapLayer;

  reports$: Observable<any>;
  derivatives$: Observable<any>;
  dateRange$: Observable<[string, string]>;

  dataSelectCtrl = new FormControl({});
  _dataSelectSub: Subscription;
  private selectedDerivative: any;
  private selectedGeometry: any;
  selectedDate: number;
  selectedDerivativeType: HumanReadableProductType;

  // setDate = [1569038400000, 1569470400000];
  setDate = [];
  firstLoad = true;
  timelineOpen = false;

  subscriptionRequestPending = true;

  isGrower = false;

  constructor(
    private _plantingsStateService: PlantingsStateService,
    private _fieldsStateService: FieldsStateService,
    private _agSearchService: AgSearchService,
    private _mapLayersService: MapLayersService,
    private _toast: ToasterService,
    private _dialog: DialogService,
    private _mapFeatureService: MapFeaturesService,
    private _mapService: MapService,
    private _agSubscriptionService: AgSubscriptionService,
    private _farmsStateService: FarmsStateService,
    private _growersStateService: GrowersStateService,
    private _route: ActivatedRoute,
    private _plantingDataService: PlantingDataService,
    private _router: Router,
    private _managementZoneService: ManagementZoneService,
    private _attachmentService: AttachmentService,
    private _admUtilsService: ADMUtilsService,
    private _contextService: ContextService
  ) {}

  private convertEpochToUTC(epoch: number): string {
    return new Date(epoch).toISOString();
  }

  private responseMap = () =>
    pipe(
      map((res: HttpResponse<any[]>) => {
        return (res.body as any).features.map((feature: any) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              ...(feature.properties.acquisitionDate && {
                acquisitionDate: Date.parse(feature.properties.acquisitionDate)
              })
            }
          };
        });
      })
    );

  ngOnInit() {

    this.farm$ = this._route.params.pipe(
      filter((params: Params) => {
        return params['farmId'];
      }),
      this._farmsStateService.loadEntityOpr('farmId')
    );

    this.grower$ = this._route.params.pipe(
      filter((params: Params) => {
        return params['growerId'];
      }),
      this._growersStateService.loadEntityOpr('growerId')
    );

    this.planting$ = this._route.params.pipe(
      filter((params: Params) => {
        return params['plantingId'];
      }),
      this._plantingsStateService.loadEntityOpr('plantingId'),
    );

    this.field$ = this._route.params.pipe(
      filter((params: Params) => {
        return params['fieldId'];
      }),
      this._fieldsStateService.loadEntityOpr('fieldId')
    );

    this._contextService.user$.pipe(take(1)).subscribe((user: User) => {
      this.isGrower = user.isGrower;
    });

    this.routeSub = this._route.params
      .pipe(
        filter((params: Params) => {
          return params['plantingId'];
        })
      )
      .subscribe((params: Params) => {
        this._plantingDataService.plantingId = params['plantingId'];
      });

    this.plantingSub = this._plantingDataService.planting$.subscribe(
      (planting: Planting) => {
        this.planting = planting;
        this._mapFeatureService.addAnnotation(this.planting.geometry);
        this._mapService.animateToBounds(
          (this.planting.geometry.getGeometry() as any).getExtent(),
          [0, 0, 0, 410]
        );
        this.plantingArea =
          (olSphere as any).getArea(this.planting.geometry.getGeometry()) *
          3.861e-7;
      }
    );

    this.zoneSub = this._plantingDataService.zones$.subscribe( zonearray => {
      this.zones = zonearray;
    // then add this below and see what works
      this.zones.forEach(zone => {
        this._managementZoneService.getVRTByZoneId(zone.id)
         .pipe(take(1))
         .subscribe(resp => {
           zone.vrt = resp.data;
          });
      });
    });

    this.fieldSub = this.field$.subscribe((field: Field) => {
      this.field = field;
    });

    this.agSubscriptionSub = this._plantingDataService.subscriptions$.subscribe(
      (agSubscription: AgSubscription[]) => {
        this.subscriptionRequestPending = false;
        this.agSubscription = agSubscription;
      }
    );

    this._dataSelectSub = this.dataSelectCtrl.valueChanges.subscribe(val => {
      if (val) {
        this._plantingDataService.selectedDerivative = val;
      }
    });

    this._plantingDataService.allDerivatives$.subscribe(
      (derivatives: ADMAsset[]) => {
        if (derivatives.length === 0) {
          this._plantingDataService.selectedDate = 0;
        } else {
          const now = new Date().getTime();
          const closestDate = derivatives
            .map((derivative: ADMAsset) => {
              return derivative.properties.acquisitionDate;
            })
            .reduce((acc: number, curr: number) => {
              return Math.abs(curr - now) < Math.abs(acc - now) ? curr : acc;
            });
          this._plantingDataService.selectedDate = closestDate;
        }
    });

    this._plantingDataService.selectedDate$.subscribe((date: number) => {
      this.selectedDate = date;
    });

    this._plantingDataService.selectedDerivativeType$.subscribe(
      (type: HumanReadableProductType) => {
        this.selectedDerivativeType = type;
      }
    );

    this.selectedDerivativeSub = this._plantingDataService.selectedDerivativeType$
      .pipe(
        combineLatest(
          this._plantingDataService.selectedDate$,
          this._plantingDataService.allMappedDerivatives$
        ),
        map(
          ([selectedDerivativeType, selectedDate, allMapped]: [
            HumanReadableProductType,
            number,
            AnalyticMap
          ]) => {
            return selectedDerivativeType === 'All'
              ? {}
              : allMapped[selectedDerivativeType].color.find(
                  (derivative: ADMAsset) => {
                    return (
                      derivative.properties.acquisitionDate === selectedDate
                    );
                  }
                );
          }
        ),
        tap(_ => {
          if (this.currentLayer) {
            this._mapLayersService.removeLayer(this.currentLayer);
          }
        }),
        filter((derivative: ADMAsset) => {
          return !!derivative;
        })
      )
      .subscribe((derivative: any) => {
        if (Object.keys(derivative).length === 0) {
          return;
        }
        // This notification is overkill AER-6464
        // this._toast.alert('success', 'Loading data to map...');
        this._agSearchService
          .assetProxy({ subject: derivative.id, proxy: 'overlayMercator' })
          .pipe(take(1))
          .subscribe((res: any) => {
            this.selectedDerivative = derivative;
            let geom = new GeoJSON();
            let temptempGeom = this.planting.geometry.clone();
            (temptempGeom as Feature).getGeometry();
            const pointJSON = JSON.parse(
              geom.writeFeature(temptempGeom as Feature)
            );
            this.selectedDerivative.geometry = pointJSON.geometry;

            const dataproduct = new DataProduct({
              dataType: 'STATIC',
              brokerUrl: (res as any).changingThisBreaksApplicationSecurity,
              extent: [
                derivative.properties.geometryMinX,
                derivative.properties.geometryMinY,
                derivative.properties.geometryMaxX,
                derivative.properties.geometryMaxY
              ]
            });
            this._mapLayersService
              .getMapLayers(dataproduct)
              .then((mapLayers: any[]) => {
                if (this.currentLayer) {
                  //https://github.com/openlayers/openlayers/blob/master/changelog/upgrade-notes.md#new-prerender-and-postrender-layer-events-replace-old-precompose-render-and-postcompose-events
                  this.currentLayer.olLayer.un('prerender', this.onPrecompose);
                  this.currentLayer.olLayer.un(
                    'postrender',
                    this.onPostcompose
                  );

                  this._mapLayersService.removeLayer(this.currentLayer);
                }

                this.currentLayer = mapLayers[0];

                this._mapLayersService.addLayer(this.currentLayer);
                this.currentLayer.olLayer.setZIndex(999999);

                this.currentLayer.olLayer.on('prerender', this.onPrecompose);
                this.currentLayer.olLayer.on('postrender', this.onPostcompose);
              });
          });
      });

    /* this._route.data.subscribe(openTL => {
      console.log(openTL);
      this.timelineOpen = openTL.show_timeline;
      this._plantingsStateService.timelineOpen.next(openTL.show_timeline);
    }); */
    this._plantingsStateService.includeTimeline.next(true);
  }

  toggleTimeline(open: boolean) {
    this.timelineOpen = open;
    this._plantingsStateService.timelineOpen.next(open);

    this._mapService.animateToBounds(
      (this.planting.geometry.getGeometry() as any).getExtent(),
      [0, 0, open ? 235 : 0, 410]
    );
  }

  getDataColor(productType: string): string {
    const dataColor = this._plantingDataService.productTypeDisplayColor.find(
      productMatch => {
        return productMatch.type === productType;
      }
    ).color;
    return dataColor;
  }

  ngOnDestroy() {

    if (this.planting.geometry) {
      this._mapFeatureService.removeAnnotation(this.planting.geometry);
    }
    this.plantingSub.unsubscribe();
    this.fieldSub.unsubscribe();
    this._dataSelectSub.unsubscribe();
    this.agSubscriptionSub.unsubscribe();
    this.routeSub.unsubscribe();
    this.selectedDerivativeSub.unsubscribe();
    if (this.currentLayer) {
      this.currentLayer.olLayer.un('prerender', this.onPrecompose);
      this.currentLayer.olLayer.un('postrender', this.onPostcompose);
      this._mapLayersService.removeLayer(this.currentLayer);
    }
    if (this.zoneSub) {
      this.zoneSub.unsubscribe();
    }
    this._plantingsStateService.timelineOpen.next(false);
    this._plantingsStateService.includeTimeline.next(false);
    this._plantingDataService.selectedDerivativeType = 'All';
  }

  private onPrecompose = (event: any) => {
    const ctx = event.context;
    const map = this._mapService.getMap();
    ctx.save();
    const pixelRatio = event.frameState.pixelRatio;
    const size = map.getSize();
    ctx.scale(1 * pixelRatio, 1 * pixelRatio);
    let pix;
    let first = true;
    ctx.beginPath();
    if (this.selectedDerivative.geometry.type === 'MultiPolygon') {
      this.selectedDerivative.geometry.coordinates.forEach((coord: any[]) => {
        coord.forEach((points: any[]) => {
          pix = map.getPixelFromCoordinate(points[0]);
          if (pix) {
            ctx.moveTo(pix[0], pix[1]);
          }
          first = true;
          points.forEach((point: any[]) => {
            if (first) {
              first = false;
              return;
            }
            pix = map.getPixelFromCoordinate(point as [number, number]);
            if (pix) {
              ctx.lineTo(pix[0], pix[1]);
            }
          });
        });
      });
    } else {
      this.selectedDerivative.geometry.coordinates.forEach((coord: any[]) => {
        pix = map.getPixelFromCoordinate(coord[0]);
        if (pix) {
          ctx.moveTo(pix[0], pix[1]);
        }
        first = true;
        coord.forEach((point: any[]) => {
          if (first) {
            first = false;
            return;
          }
          pix = map.getPixelFromCoordinate(point as [number, number]);
          if (pix) {
            ctx.lineTo(pix[0], pix[1]);
          }
        });
      });
    }
    ctx.clip();
    ctx.scale(1 / 1 / pixelRatio, 1 / 1 / pixelRatio);
    ctx.stroke();
  };

  private onPostcompose = (event: any) => {
    event.context.restore();
  };

  downloadDerivative(
    analyticMap: {
      key: HumanReadableProductType;
      value: {
        raw: ADMAsset[];
        color: ADMAsset;
      };
    },
    time: string
  ) {
    const analyticType = analyticMap.key;
    const values = analyticMap.value;
    this._toast.alert('success', 'Initiating Download!');
    let zipFile: JSZip = new JSZip();

    forkJoin(
      ...[]
        .concat(values.raw)
        .concat(values.color)
        .map((derivative: ADMAsset) => {
          return this._agSearchService
            .assetBlob(derivative.properties.uid)
            .pipe(
              tap((blob: Blob) => {
                zipFile.file(
                  `${derivative.properties.product.split(' ').join('_')}-${
                    derivative.properties.acquisitionDate
                  }.${
                    blob.type === 'application/zip'
                      ? 'zip'
                      : derivative.id
                          .split('.')
                          .slice(-1)[0]
                          .toLocaleLowerCase()
                  }`,
                  blob
                );
              })
            );
        })
    )
      .pipe(take(1))
      .subscribe((results: any) => {
        zipFile.generateAsync({ type: 'blob' }).then((blob: Blob) => {
          saveAs(blob, `${analyticType}-${time}.zip`);
        });
      });
  }

  getManagementZones(plantingId: string) {
    this.zoneSub = this._managementZoneService
      .getByPlantingId(plantingId)
      .pipe(
        skipWhile(resp => !resp),
        map((resp: PagedResponse<ManagementZone>) => {
          return resp.data;
        })
      )
      .subscribe(zones => {
        this.zones = zones;

        // may need to loop zones to get VRT...
        this.zones.forEach(zone => {
          this._managementZoneService
            .getVRTByZoneId(zone.id)
            .pipe(take(1))
            .subscribe(resp => {
              zone.vrt = resp.data;
            });
        });
      });
  }

  launchVRT(zoneId: string) {
    this._router.navigate(['./vrt-map/' + zoneId], { relativeTo: this._route });
  }

  deleteZone(id: string) {
    this._dialog.confirm(
      'Are you sure you want to delete this Management Zone?',
      () => {
        this._managementZoneService
          .delete(id)
          .pipe(take(1))
          .subscribe(resp => {
            this.getManagementZones(this.planting.id);
          });
      }
    );
  }

  deleteVRT(zoneId: string, vrtId: string) {
    this._dialog.confirm(
      'Are you sure you want to delete this Prescription Map?',
      () => {
        this._managementZoneService
          .deleteVRT(zoneId, vrtId)
          .pipe(take(1))
          .subscribe(resp => {
            this.getManagementZones(this.planting.id);
          });
      }
    );
  }

  downloadZone(zoneId: string) {
    this._managementZoneService
      .getById(zoneId)
      .pipe(take(1))
      .subscribe(zone => {

        this._managementZoneService
          .download(zoneId)
          .pipe(take(1))
          .subscribe(blob => {
            saveAs(blob, zone.body.name + ".zip");
          });

      });
  }

  downloadVRT(zoneId: string, vrtId: string) {
    this._managementZoneService
      .getVRTById(zoneId, vrtId)
      .pipe(take(1))
      .subscribe(vrt => {

        this._managementZoneService
          .downloadVRT(zoneId, vrtId)
          .pipe(take(1))
          .subscribe(blob => {
            saveAs(blob, vrt.body.name + ".zip");
          });

      });
  }

  editLocation() {
    console.log('will edit location');
  }

  launchSubscriptionsModal() {
    if (this.isGrower) return;
    const intent: DialogIntent = {
      title: 'Manage Subscriptions',
      component: PlantingSubscriptionsComponent,
      width: '384px',
      data: {
        successCallback: (newSubs: AgSubscription[]) => {
          forkJoin(
            newSubs.map((sub: AgSubscription) => {
              return this._agSubscriptionService.create(sub);
            })
          )
            .pipe(take(1))
            .subscribe(
              _ => {
                this._toast.alert('success', 'Subscription updated.');
                this.agSubscription.push(_[0].body);
              },
              (err: HttpErrorResponse) => {
                this._toast.alert(
                  'error',
                  `Subscription failed. ${err.error.detail}`
                );
              }
            );
        },
        errorCallback: () => {
          this._toast.alert('error', 'Subscription failed.');
        },
        closeCallback: () => {},
        subscriptions: this.agSubscription,
        planting: this.planting,
        showSubmitBtn: true
      }
    };

    this._dialog.createDialog(intent);
  }
}
