import { Pipe, PipeTransform } from '@angular/core';
import { PrecisionPipe } from './precision.pipe';
import { lineRadial } from 'd3';

// Used to convert between dimensions.
@Pipe({ name: 'convertunits' })
export class ConvertUnitsPipe implements PipeTransform {
  transform(
    input: string,
    fromUnits: string = 'METER',
    toUnits: string = 'FOOT',
    sigFigs: number = 3
  ): string {
    const value = parseFloat(input);
    return new Unit(value, fromUnits)[`to${toUnits}`](sigFigs);
  }
}

const UNIT_CONVERSIONS = {
  METER_TO_MILLIMETER: 1000.0,
  METER_TO_CENTIMETER: 100.0,
  METER_TO_METER: 1.0,
  METER_TO_KILOMETER: 0.001,
  METER_TO_INCH: 39.37007874,
  METER_TO_FOOT: 3.280839895,
  METER_TO_YARD: 1.093613298,
  METER_TO_MILE: 0.0006213711922,
  METERSQ_TO_MILLIMETERSQ: 1000000.0,
  METERSQ_TO_CENTIIMETERSQ: 10000.0,
  METERSQ_TO_METERSQ: 1.0,
  METERSQ_TO_KILOMETERSQ: 0.000001,
  METERSQ_TO_INCHSQ: 1550.003100006,
  METERSQ_TO_FOOTSQ: 10.76391042,
  METERSQ_TO_YARDSQ: 1.195990046301,
  METERSQ_TO_ACRES: 0.0002471054,
  METERSQ_TO_MILESQ: 0.0000003861003
};

export interface DimensionalUnit {
  name: string;
  abbreviation: string;
  fromMeters: number;
  type: UnitType;
  key: string;
  default?: boolean;
}

export enum UnitType {
  LINEAR,
  AREA
}

export interface DimensionalUnitMap {
  [key: string]: DimensionalUnit;
}

export const SupportedUnits: DimensionalUnitMap = {
  METER: {
    key: 'METER',
    name: 'meter',
    abbreviation: 'm',
    fromMeters: 1.0,
    type: UnitType.LINEAR,
    default: true
  },
  MILLIMETER: {
    key: 'MILLIMETER',
    name: 'millimeter',
    abbreviation: 'mm',
    fromMeters: 1000.0,
    type: UnitType.LINEAR
  },
  CENTIMETER: {
    key: 'CENTIMETER',
    name: 'centimeter',
    abbreviation: 'cm',
    fromMeters: 100.0,
    type: UnitType.LINEAR
  },
  KILOMETER: {
    key: 'KILOMETER',
    name: 'kilometer',
    abbreviation: 'km',
    fromMeters: 0.001,
    type: UnitType.LINEAR
  },
  INCH: {
    key: 'INCH',
    name: 'inch',
    abbreviation: 'in',
    fromMeters: 39.37007874,
    type: UnitType.LINEAR
  },
  FOOT: {
    key: 'FOOT',
    name: 'foot',
    abbreviation: 'ft',
    fromMeters: 3.280839895,
    type: UnitType.LINEAR
  },
  YARD: {
    key: 'YARD',
    name: 'yard',
    abbreviation: 'yd',
    fromMeters: 1.093613298,
    type: UnitType.LINEAR
  },
  MILE: {
    key: 'MILE',
    name: 'mile',
    abbreviation: 'mi',
    fromMeters: 0.0006213711922,
    type: UnitType.LINEAR
  },
  MILLIMETERSQ: {
    key: 'MILLIMETERSQ',
    name: 'millimeter²',
    abbreviation: 'mm²',
    fromMeters: 1000000.0,
    type: UnitType.AREA
  },
  CENTIIMETERSQ: {
    key: 'CENTIIMETERSQ',
    name: 'centimeter²',
    abbreviation: 'cm²',
    fromMeters: 10000.0,
    type: UnitType.AREA
  },
  METERSQ: {
    key: 'METERSQ',
    name: 'meter²',
    abbreviation: 'm²',
    fromMeters: 1.0,
    type: UnitType.AREA,
    default: true
  },
  KILOMETERSQ: {
    key: 'KILOMETERSQ',
    name: 'kilometer²',
    abbreviation: 'km²',
    fromMeters: 0.000001,
    type: UnitType.AREA
  },
  INCHSQ: {
    key: 'INCHSQ',
    name: 'inch²',
    abbreviation: 'in²',
    fromMeters: 1550.003100006,
    type: UnitType.AREA
  },
  FOOTSQ: {
    key: 'FOOTSQ',
    name: 'foot²',
    abbreviation: 'ft²',
    fromMeters: 10.76391042,
    type: UnitType.AREA
  },
  YARDSQ: {
    key: 'YARDSQ',
    name: 'yard²',
    abbreviation: 'yd²',
    fromMeters: 1.195990046301,
    type: UnitType.AREA
  },
  ACRE: {
    key: 'ACRE',
    name: 'acre',
    abbreviation: 'ac',
    fromMeters: 0.0002471054,
    type: UnitType.AREA
  },
  MILESQ: {
    key: 'MILESQ',
    name: 'mile²',
    abbreviation: 'mi²',
    fromMeters: 0.0000003861003,
    type: UnitType.AREA
  }
};

class ExtendableProxy {
  constructor(private handler: object) {
    return new Proxy(this, this.handler);
  }
}

class Unit extends ExtendableProxy {
  constructor(private value: number, private unit: string) {
    super({
      get(target: any, propKey: string, receiver: any) {
        if (propKey in target) return target[propKey];
        const prefix = 'to';
        const assertionName = Object.keys(SupportedUnits).find(
          (assertion: string) => {
            return propKey.substring(2) === assertion;
          }
        );
        if (propKey.startsWith(prefix)) {
          const action = `${unit}_TO_${assertionName}`;
          return (precision: number): string => {
            const precisionPipe = new PrecisionPipe();

            return `${precisionPipe.transform(
              value * SupportedUnits[assertionName].fromMeters,
              precision
            )} ${SupportedUnits[assertionName].abbreviation}`;
          };
        }
      }
    });
  }
}
