import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LonLatPipe } from './lonLat.pipe';
import { MgrsStrPipe } from './mgrsStr.pipe';
import { TimestampPipe } from './timestamp.pipe';
import { ReversePipe } from './reverse.pipe';
import { SortPipe } from './sort.pipe';
import { GroupByPipe } from './groupBy.pipe';
import { CentimetersPipe } from './centimeters.pipe';
import { CentimetersSqrPipe } from './centimetersSqr.pipe';
import { LargeNumbersPipe } from './largeNumbers.pipe';
import { NumberWithCommasPipe } from './numberWithCommas.pipe';
import { PrecisionPipe } from './precision.pipe';
import { ObjectKeysPipe } from './objectKeys.pipe';
import { TitleizePipe } from './titleize.pipe';
import { WindDirectionPipe } from './windDirection.pipe';
import { GeoTranslatePipe } from './geotranslate.pipe';
import { FileSizePipe } from './fileSize.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TimeAgoLivePipe } from './time-ago-live.pipe';
import { ConvertUnitsPipe } from './convertUnits.pipe';
import { DateRangePipe } from './date-range.pipe';
import { SecureImagePipe } from './secure-image.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    LonLatPipe,
    MgrsStrPipe,
    TimestampPipe,
    ReversePipe,
    SortPipe,
    GroupByPipe,
    CentimetersPipe,
    CentimetersSqrPipe,
    LargeNumbersPipe,
    NumberWithCommasPipe,
    PrecisionPipe,
    ObjectKeysPipe,
    TitleizePipe,
    WindDirectionPipe,
    GeoTranslatePipe,
    FileSizePipe,
    TimeAgoPipe,
    TimeAgoLivePipe,
    ConvertUnitsPipe,
    DateRangePipe,
    SecureImagePipe
  ],
  exports: [
    LonLatPipe,
    MgrsStrPipe,
    TimestampPipe,
    ReversePipe,
    SortPipe,
    GroupByPipe,
    CentimetersPipe,
    CentimetersSqrPipe,
    LargeNumbersPipe,
    NumberWithCommasPipe,
    PrecisionPipe,
    ObjectKeysPipe,
    TitleizePipe,
    WindDirectionPipe,
    GeoTranslatePipe,
    FileSizePipe,
    TimeAgoPipe,
    TimeAgoLivePipe,
    ConvertUnitsPipe,
    DateRangePipe,
    SecureImagePipe
  ]
})
export class UtilsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UtilsModule,
      providers: [
        LonLatPipe,
        MgrsStrPipe,
        TimestampPipe,
        ReversePipe,
        SortPipe,
        GroupByPipe,
        CentimetersPipe,
        CentimetersSqrPipe,
        LargeNumbersPipe,
        NumberWithCommasPipe,
        PrecisionPipe,
        ObjectKeysPipe,
        TitleizePipe,
        WindDirectionPipe,
        GeoTranslatePipe,
        FileSizePipe,
        TimeAgoPipe,
        TimeAgoLivePipe,
        ConvertUnitsPipe
      ]
    };
  }
}
