import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { AerialMenu } from 'projects/ui/src/public_api';
import { MapService } from '../../map.service';
import { MapLayersService, BasemapOptions } from '../../mapLayers.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'basemap-selector',
  templateUrl: './basemap-selector.template.html',
  styleUrls: ['./basemap-selector.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasemapSelectorComponent extends AerialMenu implements OnInit {
  protected alternateBasemapOnly = (environment.ALTERNATE_BASEMAP !== '');

  get currentBasemapTitle(): string {
    return this.currentBasemap
      ? this.availableBasemapMapper[this.currentBasemap]
      : 'Loading...';
  }

  get availableBasemaps(): BasemapOptions[] {
    return this.alternateBasemapOnly
      ? BasemapOptions[BasemapOptions.AlternateBasemap]
      : this.basemaps;
  }

  get availableBasemapMapper(): Object {
    return this.alternateBasemapOnly
      ? { [BasemapOptions.AlternateBasemap]: 'Alternate' }
      : this.basemapMapper;
  }

  protected basemapMapper = {
    [BasemapOptions.OneAtlas]: 'OneAtlas',
    [BasemapOptions.OneAtlasMetaData]: 'OneAtlas + Metadata',
    [BasemapOptions.MapboxVector]: 'Streets',
    [BasemapOptions.MapboxSatellite]: 'Satellite Streets'
  };
  protected basemaps: BasemapOptions[] = [
    BasemapOptions.OneAtlas,
    BasemapOptions.OneAtlasMetaData,
    BasemapOptions.MapboxVector,
    BasemapOptions.MapboxSatellite
  ];
  protected currentBasemap: BasemapOptions;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _mapService: MapService,
    private _layersService: MapLayersService
  ) {
    super();
  }

  changeBasemap(basemap: BasemapOptions) {
    try {
      this._layersService.setBasemap(basemap);
      this.currentBasemap = basemap;
    } catch (e) {
      if (basemap !== BasemapOptions.MapboxSatellite) {
        basemap = BasemapOptions.MapboxSatellite;
        this._layersService.setBasemap(basemap);
        this.currentBasemap = basemap;
      } else {
        throw e;
      }
    }
  }

  ngOnInit() {
    this._mapService.onInit(() => {
      this.currentBasemap = this._layersService.currentBasemap;
      this._changeDetectorRef.markForCheck();
    });
  }
}
