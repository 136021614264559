import { Injectable } from '@angular/core';
import { Observable, forkJoin, combineLatest, EMPTY, of } from 'rxjs';
import { ContextService } from './context.service';
import {
  NotificationService,
  User,
  NotificationPreferenceReferenceType,
  UserPreference,
  UserPreferenceReferenceType,
  Customer,
  GrowerService,
  Grower,
  PagedResponse,
  BaseService,
  NotificationPreference,
  UserService
} from 'api/src/public_api';
import { switchMap, map, toArray, tap, defaultIfEmpty } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export enum NotificationPreferenceType {
  AllGrowers = 'All Growers',
  FavoriteGrowers = 'Favorite Growers',
  NoGrowers = 'No Growers'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationPreferencesService extends BaseService {
  /**
   * This is an absolutely disgusting implementation of an absolutely disgusting API
   */
  protected endpointUrl: string;

  constructor(
    protected httpClient: HttpClient,
    private _contextService: ContextService,
    private _notificationService: NotificationService,
    private _growersService: GrowerService,
    private _userService: UserService
  ) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/notifications`;
  }

  public updateGrowerPreference(pref: NotificationPreferenceType) {
    const user = this._contextService.user;

    /**
     * how about this: lets shove a user preference in an attributes json blob
     * instead of in the userPreferences list? This shortcoming will get around
     * a different shortcoming in the API :)
     */
    user.attributes.growerNotificationPreference = pref;

    this._contextService.patchUser(user.id, { attributes: user.attributes });
  }

  /**
   * Yep. Putting what appears to be the requisite amount of thought into this.
   */
  public updateOtherGrowerPreference(
    pref: NotificationPreferenceType
  ): Observable<boolean> {
    return combineLatest(
      this._contextService.user$,
      this._contextService.customer$.pipe(
        switchMap((customer: Customer) => {
          return this._growersService
            .list({
              size: 10000,
              customerId: customer.id
            })
            .pipe(
              map((resp: PagedResponse<Grower>) => {
                return resp.data;
              })
            );
        })
      )
    ).pipe(
      switchMap(([user, growers]: [User, Grower[]]) => {
        return this._notificationService
          .deletePreferenceByReferenceType(
            user.id,
            NotificationPreferenceReferenceType.Grower
          )
          .pipe(
            switchMap(_ => {
              return forkJoin(
                ...[
                  ...(pref === NotificationPreferenceType.NoGrowers
                    ? [] // add nothing
                    : pref === NotificationPreferenceType.FavoriteGrowers
                      ? (user.attributes.favoriteGrowers || []) // favorites
                        .map((id: string) => {
                          return this._notificationService.createPreference({
                            userId: user.id,
                            referenceId: id,
                            referenceType:
                              NotificationPreferenceReferenceType.Grower
                          });
                        })
                      : growers.map((grower: Grower) => {
                        return this._notificationService.createPreference({
                          userId: user.id,
                          referenceId: grower.id,
                          referenceType:
                            NotificationPreferenceReferenceType.Grower
                        });
                      }))
                ]
              ).pipe(defaultIfEmpty(null));
            })
          );
      }),
      map(_ => true)
    );
  }
}
