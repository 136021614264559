import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UIModule } from 'projects/ui/src/public_api';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { StepperControlComponent } from './stepper-control/stepper-control.component';
import { StepHolderComponent } from './stepper-control/step-holder.component';
import { UtilsModule } from 'utils/src/public_api';
import { InputListSelectorComponent } from './input-list-selector/input-list-selector.component';
import { StatisticsTimelineComponent } from './statistics-timeline/statistics-timeline.component';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';

@NgModule({
  declarations: [
    SidePanelComponent,
    StepperControlComponent,
    StepHolderComponent,
    InputListSelectorComponent,
    StatisticsTimelineComponent,
    NotificationsPanelComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, UIModule, UtilsModule, FormsModule],
  exports: [
    SidePanelComponent,
    StepperControlComponent,
    StepHolderComponent,
    InputListSelectorComponent,
    StatisticsTimelineComponent,
    NotificationsPanelComponent
  ]
})
export class SharedModule { }
