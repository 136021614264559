import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { Vendor } from '../models/vendor.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class VendorService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/vendors`;
    this.defaultSize = 750;
  }

  protected mapJsonToCollection(respBody: Vendor[]): Vendor[] {
    return respBody.map(json => new Vendor(json));
  }

  getVendors(): Observable<Vendor[]> {
    return this.httpClient
      .get<Vendor[]>(`${this.endpointUrl}?size=${this.defaultSize}`)
      .pipe(map(data => this.mapJsonToCollection(data)));
  }

  getPagedVendors(
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<Vendor>> {
    const params = searchQuery
      ? this.buildParams({ search: searchQuery })
      : undefined;
    return this.getWithPagedResponse<Vendor>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  getVendor(vendorId: string): Observable<Vendor> {
    return this.httpClient
      .get<Vendor>(`${this.endpointUrl}/${vendorId}`)
      .pipe(map(resp => new Vendor(resp)));
  }

  createVendor(vendor: Vendor | object): Promise<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, vendor).toPromise();
  }

  updateVendor(vendor: Vendor): Promise<HttpResponse<Vendor>> {
    return this.putWithResponse<Vendor>(
      `${this.endpointUrl}/${vendor.id}`,
      vendor
    ).toPromise();
  }

  patchVendor(vendorId: string, body: object): Promise<HttpResponse<Vendor>> {
    return this.patchWithResponse<Vendor>(
      `${this.endpointUrl}/${vendorId}`,
      body
    ).toPromise();
  }

  deleteVendor(vendorId: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${vendorId}`
    ).toPromise();
  }

  // DEPRECATED - DO NOT USE
  // fetchAirport(airportId: string): Observable<Airport> {
  //   return this._airportService.getAirport(airportId);
  // }

  // DEPRECATED - DO NOT USE
  // use `getVendor()` instead
  // getVendorById(id: string): Promise<Vendor> {
  //   return this.getVendor(id).toPromise();
  // }

  // DEPRECATED - DO NOT USE
  // use `getVendor()` instead
  // getVendorDetails(id: string): Observable<Vendor> {
  //   return this.getVendor(id);
  // }

  // DEPRECATED - DO NOT USE
  // use `createVendor()` instead
  // addVendor(vendor: Vendor | object): Promise<string> {
  //   return this.createVendor(vendor);
  // }
}
