import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  Validators
} from '@angular/forms';
import { countries } from '../../util/countries';
import { handleBooleanProperty } from '../../util/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'country-select',
  template: `
    <!-- prettier-ignore -->
    <select #countrySelect
      [formControl]="countrySelectCtrl"
      class="country"
      [class.invalid]="required && control.touched && control.invalid"
      [attr.id]="id"
      (blur)="onTouched()">
      <option ngValue="">Country</option>
      <option *ngFor="let country of countries" [ngValue]="country.code">
        {{country.name}}
      </option>
    </select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true
    }
  ]
})
export class CountrySelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  id: string;

  @Input()
  set required(required: boolean) {
    this._required = handleBooleanProperty(required);
    if (this._required) {
      this.countrySelectCtrl.setValidators(Validators.required);
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set disabled(disabled: boolean) {
    disabled = handleBooleanProperty(disabled);
    this.setDisabledState(disabled);
  }

  get disabled(): boolean {
    return this.countrySelectCtrl.disabled;
  }

  @Input()
  set value(value: string) {
    this.writeValue(value);
  }

  get value(): string {
    return this.countrySelectCtrl.value;
  }

  @Output()
  change = new EventEmitter<string>();

  @HostBinding('class.form-control')
  hasFormCtrlClass = true;

  @HostBinding('class.select-control')
  hasSelectClass = true;

  @HostBinding('class.country-select')
  hasIdentClass = true;

  get control(): FormControl {
    return this.countrySelectCtrl;
  }

  get element(): HTMLSelectElement {
    return this._select.nativeElement;
  }

  @ViewChild('countrySelect')
  private _select: ElementRef<HTMLSelectElement>;

  protected countrySelectCtrl = new FormControl('');
  protected countries = countries;
  private _required = false;
  private _changesSub: Subscription;

  protected onTouched = () => {};
  private _onChange = (state: string) => {};

  constructor(public el: ElementRef) {}

  writeValue(state: string): void {
    this.countrySelectCtrl.setValue(state);
  }

  registerOnChange(fn: (state: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.countrySelectCtrl.disable();
    }
  }

  ngOnInit(): void {
    this._changesSub = this.countrySelectCtrl.valueChanges.subscribe(value => {
      this._onChange(value);
      this.change.emit(value);
    });
  }

  ngOnDestroy(): void {
    this._changesSub.unsubscribe();
  }
}
