import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Project } from '../models/project.model';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class ProjectService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/projects`;
  }

  protected mapJsonToCollection(respBody: Project[]): Project[] {
    return respBody.map(json => new Project(json));
  }

  getProjects(
    baseParams?: BaseParams,
    searchTerm?: string
  ): Promise<PagedResponse<Project>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Project>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getProjectsForCustomer(
    customerId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Promise<PagedResponse<Project>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Project>(
      `${this.endpointUrl}/customer/${customerId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getProjectsForUser(userId: string): Promise<Array<Project>> {
    return this.getWithResponse<Array<Project>>(
      `${this.endpointUrl}/user/${userId}`
    )
      .pipe(map(resp => resp.body))
      .toPromise();
  }

  getProject(projectId: string): Promise<Project> {
    return this.httpClient
      .get<Project>(`${this.endpointUrl}/${projectId}`)
      .pipe(map(resp => new Project(resp)))
      .toPromise();
  }

  createProject(project: Project): Promise<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, project).toPromise();
  }

  updateProject(project: Project): Promise<HttpResponse<Project>> {
    return this.putWithResponse<Project>(
      `${this.endpointUrl}/${project.id}`,
      project
    ).toPromise();
  }

  patchProject(
    projectId: string,
    params: object
  ): Promise<HttpResponse<Project>> {
    return this.patchWithResponse<Project>(
      `${this.endpointUrl}/${projectId}`,
      params
    ).toPromise();
  }

  deleteProject(project: Project): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${project.id}`
    ).toPromise();
  }
}
