import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import {
  NotificationService,
  Notification,
  BaseParams,
  User
} from 'api/src/public_api';
import {
  map,
  tap,
  distinctUntilChanged,
  share,
  filter,
  take
} from 'rxjs/operators';
import { AuthService } from 'auth/src/public_api';
import { NotificationType } from '../../../../../api/src/lib/services/notification.service';
import { JwtHelperService } from '../../../../../api/src/lib/services/jwtHelper.service';
import { ContextService } from './context.service';

type NotificationMap = {
  [key in NotificationType]: Notification[];
};

interface MutatedNotificationMap {
  [key: string]: Notification;
}

export interface NotificationMapByDate {
  [key: string]: Notification[];
}

const defaultState: NotificationMap = {
  'New Notification': [],
  ASSET: [],
  VRT: [],
  MANAGEMENT_ZONE: [],
  WEAK_ACRES: [],
  REPORT: []
};

@Injectable({
  providedIn: 'root'
})
export class NotificationStreamService {
  private source: EventSource;
  private openTime: number = -Infinity;
  private _jwtHelper: JwtHelperService;

  public filterArray = [];

  private historicNotifications: BehaviorSubject<
    Notification[]
  > = new BehaviorSubject<Notification[]>([]);
  private historicNotifications$: Observable<
    Notification[]
  > = this.historicNotifications.asObservable();

  private newNotifications: BehaviorSubject<
    NotificationMap
  > = new BehaviorSubject<NotificationMap>(defaultState);
  private newNotifications$: Observable<
    NotificationMap
  > = this.newNotifications.asObservable();

  private mutatedNotifications: BehaviorSubject<
    MutatedNotificationMap
  > = new BehaviorSubject<MutatedNotificationMap>({});
  private mutatedNotifications$: Observable<
    MutatedNotificationMap
  > = this.mutatedNotifications.asObservable();

  private _baseParams: BehaviorSubject<BaseParams> = new BehaviorSubject<
    BaseParams
  >({ sortBy: 'created', sortOrder: 'desc' });
  private baseParams$: Observable<BaseParams> = this._baseParams.asObservable();

  private _nbUnread: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public nbUnread$: Observable<number> = this._nbUnread.asObservable();

  set baseParams(params: BaseParams) {
    this._baseParams.next(params);
  }

  public destroy(): void {
    this.source.close();
  }

  constructor(
    private _notificationService: NotificationService,
    private _authService: AuthService,
    private _contextService: ContextService
  ) {
    // console.log('constructing stream service');
    this._jwtHelper = new JwtHelperService();

    this._contextService.user$.pipe(take(1)).subscribe((user: User) => {
      this._authService.jwtTokenGetter().then((token: string) => {
        this._notificationService
          .getNotifications(user.id)
          .then((notifications: Notification[]) => {
            this.historicNotifications.next(
              notifications.map((notification: Notification) => {
                return new Notification(notification);
              })
            );
          });
        // console.log('creating source: ', user.id);
        this.source = this._notificationService.getEventSource(user.id, token);

        this.source.onopen = (event: Event) => {
          // console.log('source opened');
          this.openTime = Date.now();
        };
        this.source.onerror = (event: any) => {
          // console.log('source errorid: ', event);
          event.elapsedTime = this.openTime
            ? Date.now() - this.openTime
            : undefined;
          event.isTokenExpired = this._jwtHelper.isTokenExpired(token);
          switch (event.target.readyState) {
            case EventSourcePolyfill.CLOSED:
              this.destroy();
              break;
            default:
              // observer.error(event);
              break;
          }
        };

        Object.keys(this.newNotifications.getValue()).forEach(
          (key: NotificationType) => {
            // console.log('adding event listener: ', key);
            this.source.addEventListener(
              key,
              (event: MessageEvent) => {
                // console.log('getting new notification: ', event);
                const state = this.newNotifications.getValue();
                this.newNotifications.next({
                  ...state,
                  [key]: state[key].concat(
                    new Notification(JSON.parse(event.data))
                  )
                });
              },
              false
            );
          }
        );
        this.source.addEventListener(
          'heartbeat',
          (event: MessageEvent) => {
            // console.log('heartbeat: ', event);
          },
          false
        );
      });
    });
  }

  public getStream$(
    notificationType: NotificationType = 'New Notification'
  ): Observable<Notification> {
    return this.newNotifications$.pipe(
      map((map: NotificationMap) => {
        return map[notificationType].slice(-1).pop();
      }),
      filter((notification: Notification) => {
        return notification !== undefined;
      }),
      distinctUntilChanged((prev: Notification, curr: Notification) => {
        return prev.id === curr.id;
      }),
      share()
    );
  }

  // tslint:disable-next-line:member-ordering
  public notifications$: Observable<NotificationMapByDate[]> = combineLatest(
    this.historicNotifications$,
    this.newNotifications$,
    this.mutatedNotifications$,
    this.baseParams$
  ).pipe(
    map(
      ([
        historicNotifications,
        newNotifications,
        mutatedNotifications,
        baseParams
      ]: [
          Notification[],
          NotificationMap,
          MutatedNotificationMap,
          BaseParams
        ]) => {
        return historicNotifications
          .concat(Object.values(newNotifications).reduce(
            (acc: Notification[], curr: Notification[]) => {
              return acc.concat(curr);
            }
          ) as Notification[])
          .map((notification: Notification) => {
            return mutatedNotifications[notification.id] !== undefined
              ? mutatedNotifications[notification.id]
              : notification;
          })
          .sort((a: Notification, b: Notification) => {
            const attrA =
              baseParams.sortOrder === 'asc'
                ? a[baseParams.sortBy as string]
                : b[baseParams.sortBy as string];
            const attrB =
              baseParams.sortOrder === 'asc'
                ? b[baseParams.sortBy as string]
                : a[baseParams.sortBy as string];

            return attrA < attrB ? -1 : attrA > attrB ? 1 : 0;
          });
      }
    ),
    map((notifications: Notification[]) => {
      return this.filterArray.length
        ? notifications.filter((notification: Notification) => {
          return this.filterArray.some(allowedType => {
            return allowedType === notification.referenceType;
          });
        })
        : notifications;
    }),
    tap((notifications: Notification[]) => {
      this._nbUnread.next(
        notifications
          .map((notification: Notification) => {
            return notification.read === true ? 0 : 1;
          })
          .reduce((acc: number, curr: number) => {
            return acc + curr;
          }, 0)
      );
    }),
    map((notifications: Notification[]) => {
      return Object.entries(
        notifications.reduce(
          (acc: NotificationMapByDate, curr: Notification) => {
            const createdDate = new Date(curr.created);
            const dateKey = new Date(
              `${createdDate.getUTCMonth() +
              1}/${createdDate.getUTCDate()}/${createdDate.getFullYear()}`
            ).getTime();

            return {
              ...acc,
              [dateKey]:
                acc[dateKey] !== undefined
                  ? acc[dateKey].concat([curr])
                  : [curr]
            };
          },
          {} as NotificationMapByDate
        )
      )
        .sort((a: [string, Notification[]], b: [string, Notification[]]) => {
          return a[0] < b[0] ? 1 : a[0] > b[0] ? -1 : 0;
        })
        .reduce(
          (acc: NotificationMapByDate[], curr: [string, Notification[]]) => {
            return acc.concat({ [curr[0]]: curr[1] });
          },
          [] as NotificationMapByDate[]
        );
    })
  );

  private _markAsRead(notifications: Notification[]): void {
    forkJoin(
      ...notifications.map((notification: Notification) => {
        return this._notificationService.markNotificationAsRead(
          notification.id
        );
      })
    )
      .pipe(take(1))
      .subscribe(_ => {
        this.mutatedNotifications.next(
          notifications.reduce(
            (acc: MutatedNotificationMap, curr: Notification) => {
              return {
                ...acc,
                [curr.id]: new Notification({
                  ...curr,
                  read: true
                })
              };
            },
            this.mutatedNotifications.getValue()
          )
        );
      });
  }

  public markAsRead(notifications: Notification[]): void {
    this._markAsRead(notifications);
  }

  public markAllAsRead(): void {
    combineLatest(this.historicNotifications$, this.newNotifications$)
      .pipe(take(1))
      .subscribe(([historicNotifications, newNotifications]) => {
        this._markAsRead(
          historicNotifications.concat(Object.values(newNotifications).reduce(
            (acc: Notification[], curr: Notification[]) => {
              return acc.concat(curr);
            }
          ) as Notification[])
        );
      });
  }
}
