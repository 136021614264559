import { Injectable } from '@angular/core';
import { BaseStateService } from 'utils/src/public_api';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, share, combineLatest, switchMap, tap } from 'rxjs/operators';


export interface SimpleSelectDropdownModel {
  options: string[]; // the list of things from which to choose
  dropdownOpen: boolean; // whether the dropdown is open
  selected: string; // this is what is currently 
  unselectedOption: string;
  mappedOption: string,
}

const defaultState: SimpleSelectDropdownModel = {
  options: [],
  dropdownOpen: false,
  selected: null,
  unselectedOption: null,
  mappedOption: null
}
@Injectable()
export class SimpleSelectDropdownService extends BaseStateService<SimpleSelectDropdownModel> {
  constructor() {
    super(defaultState);
  }

  get options$(): Observable<string[]> {
    return this.state$.pipe(
      combineLatest(this.selected$, this.mappedOption$),
      map(([s, selected, mappedOption]) => {
        return s.options.filter((option: string) => {
          return option !== selected;
        })
      }),
      distinctUntilChanged(),
      share()
    );
  }

  get dropdownOpen$(): Observable<boolean> {
    return this.state$.pipe(
      map(s => s.dropdownOpen),
      distinctUntilChanged(),
      share()
    );
  }

  get selected$(): Observable<string> {
    return this.state$.pipe(
      map(s => s.selected),
      distinctUntilChanged(),
      share()
    );
  }

  get unselectedOption$(): Observable<string> {
    return this.state$.pipe(
      map(s => s.unselectedOption),
      distinctUntilChanged(),
      share()
    );
  }

  get mappedOption$(): Observable<string> {
    return this.state$.pipe(
      map(s => s.mappedOption),
      distinctUntilChanged(),
      share()
    );
  }

  get displayedOption$(): Observable<string> {
    return this.selected$.pipe(
      combineLatest(this.mappedOption$, this.unselectedOption$),
      map(([selected, mappedOption, defaultOption]) => {
        return selected || mappedOption || defaultOption;
      }),
      distinctUntilChanged(),
      share()
    );
  }

  get isPending$(): Observable<boolean> {
    return this.selected$.pipe(
      combineLatest(this.mappedOption$),
      map(([selected, mappedOption]) => {
        if (selected) {
          if (!mappedOption) {
            return true
          } else {
            if (selected !== mappedOption) {
              return true
            }
          }
        }
        return false;
      }),
      distinctUntilChanged(),
      share()
    );
  }

  public $setOptions(options: string[]): void {
    this.patchState({ options: options })
  }

  public $setSelected(option: string): void {
    this.patchState({
      dropdownOpen: false,
      selected: option
    })
  }

  public $setUnselectedOption(value: string): void {
    this.patchState({ unselectedOption: value })
  }

  public $setMappedOption(value: string): void {
    this.patchState({ mappedOption: value })
  }

  public $toggleDropdown(): void {
    this.patchState({ dropdownOpen: !this.getCurrentState().dropdownOpen })
  }

  public $clear(): void {
    this.patchState({
      selected: null,
      dropdownOpen: false
    })
  }

}
