import { Sort } from './sort/sort.interface';
import { Pagination, BaseParams } from 'projects/api/src/public_api';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  share,
  debounceTime,
  switchMap
} from 'rxjs/operators';

export abstract class BaseComponent {
  private _currentPagination: Pagination = {
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
    pageSize: 0
  };
  private _pageSize$: BehaviorSubject<number>;
  private _page$: BehaviorSubject<number>;
  private _sort$: BehaviorSubject<Sort>;

  get sort$() {
    return this._sort$;
  }

  get page$() {
    return this._page$;
  }

  get baseParams$(): Observable<BaseParams> {
    return this._sort$.pipe(
      combineLatest(this._pageSize$),
      distinctUntilChanged(),
      // debounce in case multiple things upstream need to change at same time
      debounceTime(50),
      switchMap(([sort, pageSize]) => {
        // if sort or page size changes, reset page num
        this.resetPage();
        return this.page$.pipe(
          distinctUntilChanged(),
          map(page => {
            return {
              sortBy: sort.by,
              sortOrder: sort.order,
              page: page,
              size: pageSize
            };
          })
        );
      }),
      share()
    );
  }

  get lazyLoadBaseParams$(): Observable<Partial<BaseParams>> {
    return this._sort$.pipe(
      combineLatest(this._pageSize$),
      distinctUntilChanged(),
      // debounce in case multiple things upstream need to change at same time
      debounceTime(50),
      map(([sort, pageSize]) => {
        // if sort or page size changes, reset page num
        this.resetPage();
        return {
          sortBy: sort.by,
          sortOrder: sort.order,
          size: pageSize
        };
      }),
      share()
    );
  }

  get currentPagination() {
    return this._currentPagination;
  }

  set currentPagination(newPagination: Pagination) {
    this._currentPagination = newPagination;
  }

  get hasMorePages() {
    return (
      this.currentPagination.currentPage < this.currentPagination.totalPages - 1
    );
  }

  protected changeSort(sort: Sort) {
    this._sort$.next(sort);
  }

  protected changePage(page: number) {
    this._page$.next(page);
  }

  protected loadMore() {
    if (this.hasMorePages) {
      this.changePage(this._currentPagination.currentPage + 1);
    }
  }

  protected resetPage() {
    this._page$.complete();
    this._page$ = new BehaviorSubject<number>(0);
  }

  protected changePageSize(pageSize: number) {
    this._pageSize$.next(pageSize);
  }

  protected initPagination(
    sortBy: string,
    sortOrder: string,
    pageSize: number
  ) {
    this._sort$ = new BehaviorSubject<Sort>({
      by: sortBy,
      order: sortOrder
    });
    this._page$ = new BehaviorSubject<number>(0);
    this._pageSize$ = new BehaviorSubject<number>(pageSize);
  }
}
