import { Injectable } from '@angular/core';

import Control from 'ol/control/Control';
import { Collection } from 'ol';
import { defaults } from 'ol/control';

/**
 * Service to manage map controls. Doesn't really do much aside from providing
 * default controls for the map to load with.
 */
@Injectable({
  providedIn: 'root'
})
export class MapControlsService {
  controls: Collection<Control>;

  constructor() {
    this.controls = defaults();
    // let rotateLabel = document.createElement('i');
    // rotateLabel.className = 'fa fa-arrow-circle-o-up';
    //
    // // TODO: rotate doesnt work anymore
    // this.controls = ol.control.defaults({
    //   rotateOptions: {
    //     label: rotateLabel
    //   }
    // });

    // this.controls.push(new ol.control.ZoomSlider());

    // .extend([
    //   new ToggleLayerControl()
    // ]);
  }
}
