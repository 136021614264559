import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrokerService } from './broker.service';
import { OneAtlasDataProduct } from '../models/dataProduct.model';
import { map, switchMap } from 'rxjs/operators';

import GeoJSON from 'ol/format/GeoJSON';
import { Extent } from 'ol/extent';
import { getTransform } from 'ol/proj';
import { applyTransform } from 'ol/extent';

@Injectable({ providedIn: 'root' })
export class OneAtlasService {
  constructor(
    private _httpClient: HttpClient,
    private _brokerService: BrokerService
  ) {}
  private _endpointUrl = `${environment['BROKER_ROOT']}/oneatlas/images`;

  getImages(extent: Extent, startDate: Date, endDate: Date): Promise<any> {
    return this._brokerService
      .getApiKey()
      .pipe(
        switchMap(brokerApiKey => {
          return new Promise((resolve, reject) => {
            const transFn = getTransform('EPSG:3857', 'EPSG:4326');
            const bbox = applyTransform(extent, transFn);
            const [minx, miny, maxx, maxy] = bbox;

            // give some padding to account for lag from acquisition to insert
            startDate = new Date(startDate);
            startDate.setDate(startDate.getDate() - 7);

            fetch(
              `${this._endpointUrl}?apiKey=${brokerApiKey}&bbox=${[
                minx,
                miny,
                maxx,
                maxy
              ].join(
                ','
              )}&insertdtstart=${startDate.toISOString()}&insertdtend=${endDate.toISOString()}&size=99`
            )
              .then(resp => resp.json())
              .then((resp: any) => {
                const parser = new GeoJSON();

                const stuff = resp.features;

                if (stuff) {
                  resolve(
                    stuff.map((feature: any) => {
                      return this._parseFeature(feature, parser);
                    })
                  );
                } else {
                  resolve([]);
                }
              });
          });
        })
      )
      .toPromise();
  }

  getImage(imageId: string): Promise<OneAtlasDataProduct> {
    return this._brokerService
      .getApiKey()
      .pipe(
        switchMap(brokerApiKey => {
          return this._httpClient.get(
            `${this._endpointUrl}/${imageId}?apiKey=${brokerApiKey}`
          );
        }),
        map(resp => {
          return this._parseFeature(resp);
        })
      )
      .toPromise();
  }

  private _parseFeature(feature: any, parser?: GeoJSON): OneAtlasDataProduct {
    if (!parser) parser = new GeoJSON();
    const parsedFeature = parser.readFeature(feature);
    parsedFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

    return new OneAtlasDataProduct({
      id: feature.id,
      feature: parsedFeature,
      name: parsedFeature.get('constellation'),
      dataUrl: `${environment['BROKER_ROOT']}/oneatlas/images/${feature.id}/capabilities`,
      brokerUrl: `${environment['BROKER_ROOT']}/oneatlas/images/${feature.id}/capabilities`,
      category: 'ONE_ATLAS',
      dataType: 'WMTS',
      gsd: parsedFeature.get('resolution') * 100,
      productType: 'Satellite RGB',
      acquired: new Date(parsedFeature.get('acquisitionDate')).getTime()
    });
  }
}
