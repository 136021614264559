import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Dialog } from 'projects/ui/src/public_api';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  templateUrl: './ag-list-dialog.template.html',
  styleUrls: ['./ag-list-dialog.styles.scss']
})

export class AgListDialogComponent implements Dialog, OnInit, OnDestroy {
  @Input() listInput = [];
  @Input() displayField: string;

  successCallback: Function;
  filteredList = [];
  _filterSub: Subscription;
  searchCtrl = new FormControl();

  constructor() {
    this.filteredList = this.listInput;
  }

  ngOnInit() {
    this._filterSub = this.searchCtrl.valueChanges
      .pipe(
        debounceTime(350)
      )
      .subscribe(data => {
        this.filteredList = this.listInput.filter(item => {
          return item[this.displayField].indexOf(data) !== -1;
        });
      });
  }

  selectItem(listItem: object) {
    this.successCallback(listItem);
    this.close();
  }

  close = () => { };

  ngOnDestroy() {
    this._filterSub.unsubscribe();
  }
}