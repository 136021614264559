import { Injectable, OnDestroy } from '@angular/core';
import { Tooltip } from './tooltip.model';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class TooltipService implements OnDestroy {
  private _tooltip: Tooltip;

  private _tooltipSubject: BehaviorSubject<Tooltip>;
  tooltip$: Observable<Tooltip>;

  constructor() {
    this._tooltip = new Tooltip();
    this._tooltipSubject = new BehaviorSubject<Tooltip>(this._tooltip);
    this.tooltip$ = this._tooltipSubject.asObservable();

    window.addEventListener('click', () => this.hide());
  }

  load(tooltip: Tooltip): void {
    this._tooltip = tooltip;
    this._tooltipSubject.next(this._tooltip);
  }

  unload(): void {
    this._tooltip = new Tooltip();
    this._tooltipSubject.next(this._tooltip);
  }

  show(): void {
    this._tooltip.visible = true;
    this._tooltipSubject.next(this._tooltip);
  }

  hide(): void {
    this._tooltip.visible = false;
    this._tooltipSubject.next(this._tooltip);
  }

  ngOnDestroy(): void {
    this._tooltipSubject.unsubscribe();
  }
}
