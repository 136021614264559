import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map, tap } from 'rxjs/operators';
import { Inspection, InspectionStatus } from '../models/inspection.model';

@Injectable()
export class InspectionService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/inspections`;
  }

  protected mapJsonToCollection(respBody: Inspection[]): Inspection[] {
    return respBody.map(json => new Inspection(json));
  }

  getInspections(baseParams?: BaseParams): Promise<PagedResponse<Inspection>> {
    return this.getWithPagedResponse<Inspection>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getInspectionsForProject(
    projectId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Inspection>> {
    return this.getWithPagedResponse<Inspection>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getInspection(inspectionId: string): Promise<any> {
    return this.httpClient
      .get<any>(`${this.endpointUrl}/${inspectionId}`)
      .pipe(map(resp => new Inspection(resp)))
      .toPromise();
  }

  createInspection(inspection: Inspection): Promise<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, inspection).toPromise();
  }

  // any will be Inspection type once reportStateService updated
  updateInspection(inspection: any): Promise<HttpResponse<Inspection>> {
    return this.putWithResponse<any>(
      `${this.endpointUrl}/${inspection.id}`,
      inspection
    ).toPromise();
  }

  patchInspection(id: string, params: object): Promise<HttpResponse<Inspection>> {
    return this.patchWithResponse<Inspection>(
      `${this.endpointUrl}/${id}`,
      params
    ).toPromise();
  }

  unlockInspection(id: string): Promise<HttpResponse<Inspection>> {
    return this.patchInspection(id, { status: InspectionStatus.InProgress });
  }

  lockInspection(id: string): Promise<HttpResponse<Inspection>> {
    return this.patchInspection(id, { status: InspectionStatus.Complete });
  }

  deleteInspection(id: string): Promise<any> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${id}`
    ).toPromise();
  }

  /*getReportPDF(id: string): Promise<any> {
   return this.postWithResponse(
     `${this.rootUrl}/coordinator/reports/${id}/pdf`,
     null
   )
     .pipe(map(resp => resp.headers.get('Location')))
     .toPromise();
 } */
}
