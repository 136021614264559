import { BaseModel } from './base.model';

export class VRT extends BaseModel {
  public readonly id: string;
  public name: string;
  // public managementZoneId: string;
  public inputs: Array<object>; // not sure what backend will look like

  constructor(opts: Partial<VRT>) {
    super(opts);
  }
}
