import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { DataProduct, DataProductCategory } from '../models/dataProduct.model';
import { DataProductSubscription } from '../models/dataProductSubscription.model';
import { Aoi } from '../models/aoi.model';

import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { BaseService } from './base.service';

import Geometry from 'ol/geom/Geometry';
import GeoJSON from 'ol/format/GeoJSON';
import { Extent } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import { map } from 'rxjs/operators';
import { toLonLat } from 'ol/proj';

export interface DataProductFilterPayload {
  category?: DataProductCategory;
  projectId?: string;
  customerId?: string;
  searchTerm?: string;
  bbox?: Extent | Geometry;
}

@Injectable()
export class DataProductService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/dataProducts`;
  }

  protected mapJsonToCollection(respBody: DataProduct[]): DataProduct[] {
    return respBody.map(json => new DataProduct(json));
  }

  getDataProducts(
    payload: DataProductFilterPayload,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProduct>> {
    const params = this._buildParams(payload);

    return this.getWithPagedResponse<DataProduct>(
      `${this.endpointUrl}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getDataProductsForCustomer(
    customerId: string,
    category?: DataProductCategory,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProduct>> {
    const params = !isNil(category) ? { category: category } : {};
    return this.getWithPagedResponse<DataProduct>(
      `${this.endpointUrl}/customer/${customerId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getDataProductsForOrder(
    orderId: string,
    category?: DataProductCategory,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProduct>> {
    const params = !isNil(category) ? { category: category } : {};
    return this.getWithPagedResponse<DataProduct>(
      `${this.endpointUrl}/order/${orderId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getDataProductsForProject(
    projectId: string,
    category?: DataProductCategory,
    baseParams?: BaseParams,
    customerId?: string, // NOTE: this is here for a bugfix because aerial-admins can see everything
    bbox?: Extent | Geometry
  ): Promise<PagedResponse<DataProduct>> {
    const params = !isNil(category) ? { category: category } : {};

    let bboxParam = '';
    // TODO: probably not the best way to check the bbox var
    if (bbox instanceof Array) {
      bbox = concat(
        toLonLat([bbox[0], bbox[1]]),
        toLonLat([bbox[2], bbox[3]])
      ) as [number, number, number, number];

      const geo = new GeoJSON();
      bboxParam = geo.writeGeometry(fromExtent(bbox));
    } else if (typeof bbox === 'object') {
      const bboxClone = cloneDeep(bbox);
      const geo = new GeoJSON();
      bboxParam = geo.writeGeometry(
        bboxClone.transform('EPSG:3857', 'EPSG:4326')
      );
    }
    params['bounds'] = bboxParam;

    if (customerId) {
      params['customerId'] = customerId;
    }
    return this.getWithPagedResponse<DataProduct>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  // TODO: this is a stopgap
  // we need to write/find some rsqul query builder thing
  getAnalyticsProductsForProject(
    projectId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProduct>> {
    return this.getWithPagedResponse<DataProduct>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      {
        params: {
          search: `projectId==${projectId};category==PRODUCT;productType=in=('Water Finder','Damage Heatmap','Raster Foundry','Flood Finder')`
        }
      }
    ).toPromise();
  }

  getDataProductsForAoi(
    aoi: Aoi,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProduct>> {
    const bbox = cloneDeep(aoi.geometry);
    const geo = new GeoJSON();
    const json = geo.writeGeometry(bbox.transform('EPSG:3857', 'EPSG:4326'));
    // const bboxParam = `&bounds=${encodeURIComponent(json)}`;
    // const catParam = `&category=${DataProductCategory.Product}`;

    // HttpParams should encode the bboxParam...
    const params = {
      bounds: json,
      category: DataProductCategory.Product
    };

    return this.getWithPagedResponse<DataProduct>(
      `${this.endpointUrl}/project/${aoi.projectId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getDataProduct(dataProductId: string): Promise<DataProduct> {
    return this.httpClient
      .get<DataProduct>(`${this.endpointUrl}/${dataProductId}`)
      .pipe(map(resp => new DataProduct(resp)))
      .toPromise();
  }

  updateDataProduct(
    dataProduct: DataProduct
  ): Promise<HttpResponse<DataProduct>> {
    return this.putWithResponse<DataProduct>(
      `${this.endpointUrl}/${dataProduct.id}`,
      dataProduct
    ).toPromise();
  }

  patchDataProduct(
    dataProductId: string,
    params: object
  ): Promise<HttpResponse<DataProduct>> {
    return this.patchWithResponse<DataProduct>(
      `${this.endpointUrl}/${dataProductId}`,
      params
    ).toPromise();
  }

  createDataProduct(dataProduct: DataProduct): Promise<string> {
    return this.createWithResponseAsUrl(
      this.endpointUrl,
      dataProduct
    ).toPromise();
  }

  deleteDataProduct(dataProductId: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${dataProductId}`
    ).toPromise();
  }

  createSubscription(
    customerId: string,
    dataProductId: string
  ): Promise<string> {
    const subscription = new DataProductSubscription({
      customerId: customerId,
      dataProductId: dataProductId,
      active: true
    });
    return this.createWithResponseAsUrl(
      `${this.endpointUrl}/subscriptions`,
      subscription
    ).toPromise();
  }

  getSubscriptionsForDataProduct(
    dataProductId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProductSubscription>> {
    return this.getWithPagedResponse<DataProductSubscription>(
      `${this.endpointUrl}/subscriptions/dataProduct/${dataProductId}`,
      (respBody: DataProductSubscription[]): DataProductSubscription[] => {
        return respBody.map(json => new DataProductSubscription(json));
      },
      baseParams
    ).toPromise();
  }

  getSubscriptionsForCustomer(
    customerId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<DataProductSubscription>> {
    return this.getWithPagedResponse<DataProductSubscription>(
      `${this.endpointUrl}/subscriptions/customer/${customerId}`,
      (respBody: DataProductSubscription[]): DataProductSubscription[] => {
        return respBody.map(json => new DataProductSubscription(json));
      },
      baseParams
    ).toPromise();
  }

  deleteSubscription(subscriptionId: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/subscriptions/${subscriptionId}`
    ).toPromise();
  }

  getTileLayerInfo(tileLayerId: string): Promise<any> {
    return this.httpClient
      .get(`${this.rootUrl}/tiles/${tileLayerId}`)
      .pipe(map(data => data))
      .toPromise();
  }

  /**
   * Get a uuid Api Key to use for subsequent tile server requests
   */
  getTileApiKey(): Promise<string> {
    return this.httpClient
      .get(`${this.rootUrl}/tiles/apikey`, {
        responseType: 'text'
      })
      .toPromise();
  }

  private _buildParams(
    payload: DataProductFilterPayload
  ): { [param: string]: string | string[] } {
    let { bbox, category, customerId, projectId, searchTerm } = payload;
    let params = {};
    let searchParams = [];

    // TODO: probably not the best way to check the bbox var
    if (bbox instanceof Array) {
      bbox = concat(
        toLonLat([bbox[0], bbox[1]]),
        toLonLat([bbox[2], bbox[3]])
      ) as [number, number, number, number];

      let geo = new GeoJSON();
      let json = geo.writeGeometry(fromExtent(bbox));
      params['bounds'] = json;
    } else if (bbox !== null && typeof bbox === 'object') {
      let bboxClone = cloneDeep(bbox);
      let geo = new GeoJSON();
      let json = geo.writeGeometry(
        bboxClone.transform('EPSG:3857', 'EPSG:4326')
      );
      params['bounds'] = json;
    }
    if (category) {
      params['category'] = category;
    }
    if (searchTerm) {
      searchParams.push(`name=="*${searchTerm}*"`);
    }
    if (projectId) {
      searchParams.push(`projectId==${projectId}`);
    }
    if (customerId) {
      searchParams.push(`customerId==${customerId}`);
    }
    if (searchParams.length > 0) {
      params['search'] = searchParams.join(';');
    }

    return params;
  }
}
