import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'password-group',
  templateUrl: './password-group.template.html'
})
export class PasswordGroupComponent {
  @Input()
  parent: FormGroup;

  @Input()
  showValidation = false;

  get pwdCtrl(): FormControl {
    return this.parent.get('pwd') as FormControl;
  }

  get confirmCtrl(): FormControl {
    return this.parent.get('confirmPwd') as FormControl;
  }
}
