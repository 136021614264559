import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  selector: '[usersnap]'
})
export class UsersnapDirective {
  @Input()
  usersnapEmail: string;

  @Output()
  openUsersnap = new EventEmitter<void>();

  protected usersnapApi: any;

  constructor() {
    if (true || environment.ENV === 'production') {
      // Activate usersnap
      (window as any).onUsersnapLoad = (api: any) => {
        this.usersnapApi = api;
        this.usersnapApi.init({
          button: null,
          fields: {
            email: {
              defaultValue: this.usersnapEmail,
              required: true
            }
          }
        });
      };
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src =
        '//api.usersnap.com/load/301c461b-d120-4431-b6bb-944abcfa31e4.js?onload=onUsersnapLoad';
      const x = document.getElementsByTagName('body')[0];
      x.parentNode.insertBefore(s, x);
    }
  }

  @HostListener('click')
  onClick(): void {
    this.usersnapApi.open();
    this.openUsersnap.emit();
  }
}
