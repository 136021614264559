import { BaseModel } from './base.model';
import {
  FulfillmentSteps,
  FulfillmentStepType
} from './fulfillmentSteps.model';
import { Customer } from './customer.model';
import { Order } from './order.model';
import { OrderTemplate } from './orderTemplate.model';
import { Project } from './project.model';
import { User } from './user.model';
import {
  Requirement,
  RequirementStatus
} from '../interfaces/requirement.interface';
import orderBy from 'lodash/orderBy';

export enum FulfillmentType {
  Order = 'ORDER',
  Mission = 'MISSION'
}

export class Fulfillment extends BaseModel {
  id: string;
  created: number;
  modified: number;
  type: string | FulfillmentType;
  fulfillmentType: FulfillmentType;
  targetId: string;
  requirements: Requirement[];
  deleted: boolean;
  done: boolean;
  started: boolean;
  requirementsReadyForFulfillment: Requirement[];
  fulfillmentStatus: RequirementStatus; // calculated by the backend from the requirements

  _esOrder: object; // raw elasticsearch order object

  set order(order: Order) {
    this._esOrder = order;
    this._order = order ? Order.fromJSON(order) : order;
  }

  get order(): Order {
    return this._order;
  }

  get customer(): Customer {
    return this._esOrder && this._esOrder['customer']
      ? new Customer(this._esOrder['customer'])
      : undefined;
  }

  get orderTemplate(): OrderTemplate {
    return this._esOrder && this._esOrder['orderTemplate']
      ? new OrderTemplate(this._esOrder['orderTemplate'])
      : undefined;
  }

  get project(): Project {
    return this._esOrder && this._esOrder['project']
      ? new Project(this._esOrder['project'])
      : undefined;
  }

  get user(): User {
    return this._esOrder && this._esOrder['user']
      ? new User(this._esOrder['user'])
      : undefined;
  }

  set steps(steps: FulfillmentSteps) {
    this._steps = steps ? new FulfillmentSteps(steps) : steps;
  }

  get steps(): FulfillmentSteps {
    return this._steps;
  }

  get orderedSteps(): FulfillmentStepType[] {
    const ordered: FulfillmentStepType[] = [];
    if (this.steps) {
      this.steps.missions.forEach(step => ordered.push(step));
      this.steps.processes.forEach(step => ordered.push(step));
      this.steps.tasks.forEach(step => ordered.push(step));
    }
    return orderBy(ordered, step => step.reqId, 'asc');
  }

  private _order: Order;
  private _steps: FulfillmentSteps;
}
