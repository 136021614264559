import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { Airport } from '../models/airport.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AirportService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/airports`;
    this.defaultSize = 750;
  }

  protected mapJsonToCollection(respBody: Airport[]): Airport[] {
    return respBody.map(json => new Airport(json));
  }

  getAirports(
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<Airport>> {
    const params = searchQuery
      ? this.buildParams({ search: searchQuery })
      : undefined;
    return this.getWithPagedResponse<Airport>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  getAirport(airportId: string): Observable<Airport> {
    return this.httpClient
      .get<Airport>(`${this.endpointUrl}/${airportId}`)
      .pipe(map(json => new Airport(json)));
  }
}
