import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './baseSearch.service';
import {
  SortBody,
  QueryBody,
  AggsBody,
  PagedSearchResponse,
  AggsBucket
} from '../../interfaces/search/base-search.interface';
import { BaseParams } from '../../interfaces/baseParams.interface';
import { Observable } from 'rxjs';
import { Asset } from '../../models/asset.model';

@Injectable({
  providedIn: 'root'
})
export class AssetSearchService extends BaseSearchService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/search/asset/_search`;
  }

  searchAssetsInGroup(
    groupId: string,
    search?: Array<string>,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<Asset>> {
    const query = this._buildQuery(
      {
        groupId: groupId
      },
      search
    );
    const { from, size } = this.convertPagination(baseParams);
    const sort = this._buildSort(baseParams);

    return this.search<Asset>(
      {
        query,
        from,
        size,
        sort
      },
      resp =>
        resp.hits.hits.map((hit: any) => {
          const asset = hit['_source'];
          return Asset.fromJSON(asset);
        })
    );
  }

  searchAssetsInProject(
    projectId: string,
    search?: Array<string>,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<Asset>> {
    const query = this._buildQuery(
      {
        projectId: projectId
      },
      search
    );
    const { from, size } = this.convertPagination(baseParams);
    const sort = this._buildSort(baseParams);

    return this.search<Asset>(
      {
        query,
        from,
        size,
        sort
      },
      resp =>
        resp.hits.hits.map((hit: any) => {
          const asset = hit['_source'];
          return Asset.fromJSON(asset);
        })
    );
  }

  getColumnNamesByGroupId(
    groupId: string,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<AggsBucket>> {
    const query = this._buildQuery({
      groupId: groupId
    });
    const { from, size } = this.convertPagination(baseParams);
    const aggs = this._buildAggs();

    return this.search<AggsBucket>(
      {
        query,
        from,
        size,
        aggs
      },
      resp => resp.aggregations.attributes.cat_party.buckets
    );
  }

  getColumnNamesByProjectId(
    projectId: string,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<AggsBucket>> {
    const query = this._buildQuery({
      projectId: projectId
    });
    const { from, size } = this.convertPagination(baseParams);
    const aggs = this._buildAggs();

    return this.search<AggsBucket>(
      {
        query,
        from,
        size,
        aggs
      },
      resp => resp.aggregations.attributes.cat_party.buckets
    );
  }

  private _buildQuery(
    match: { [key: string]: string },
    search: Array<string> = []
  ): QueryBody {
    return {
      bool: {
        must: [
          {
            match
          },
          ...search
            .filter((term: string) => {
              return term;
            })
            .map((term: string) => {
              return {
                bool: {
                  should: [
                    {
                      nested: {
                        path: 'tags',
                        query: {
                          multi_match: {
                            query: term,
                            fields: ['tags.*'],
                            lenient: true,
                            fuzziness: 'AUTO',
                            operator: 'AND'
                          }
                        }
                      }
                    },
                    {
                      nested: {
                        path: 'feature.flatAttributes',
                        query: {
                          multi_match: {
                            query: term,
                            fields: ['feature.flatAttributes.*'],
                            lenient: true,
                            fuzziness: 'AUTO',
                            operator: 'AND'
                          }
                        }
                      }
                    },
                    {
                      nested: {
                        path: 'feature.classifications',
                        query: {
                          multi_match: {
                            query: term,
                            fields: ['feature.classifications.*'],
                            lenient: true,
                            fuzziness: 'AUTO:0,2',
                            operator: 'AND'
                          }
                        }
                      }
                    }
                  ]
                }
              };
            })
        ]
      }
    };
  }

  private _buildSort(baseParams?: BaseParams): SortBody[] {
    if (
      baseParams &&
      baseParams.sortBy &&
      baseParams.sortBy.indexOf('flatAttributes') > -1
    ) {
      const sortTerm = (baseParams.sortBy as string).split('.')[1];
      return [
        {
          'feature.flatAttributes.value.keyword': {
            order: baseParams.sortOrder || 'asc',
            nested: {
              path: 'feature.flatAttributes',
              filter: {
                term: {
                  'feature.flatAttributes.key': sortTerm
                }
              }
            }
          }
        }
      ];
    } else if (
      baseParams &&
      baseParams.sortBy &&
      baseParams.sortBy.indexOf('classifications') > -1
    ) {
      return [
        {
          'feature.classifications.class_id': {
            order: baseParams.sortOrder || 'asc',
            nested: {
              path: 'feature.classifications'
            }
          }
        }
      ];
    } else {
      return this.convertSort(baseParams);
    }
  }

  private _buildAggs(): AggsBody {
    return {
      attributes: {
        nested: {
          path: 'feature.flatAttributes'
        },
        aggs: {
          cat_party: {
            terms: {
              field: 'feature.flatAttributes.key',
              // TEMP quick fix, might need to change how this works later
              size: 50
            }
          }
        }
      }
    };
  }
}
