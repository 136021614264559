import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostListener
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MapFeaturesService,
  MapInteractionsService
} from 'projects/map/src/public_api';
import { ToasterService } from 'projects/ui/src/public_api';

import { filter, take } from 'rxjs/operators';

import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Polygon from 'ol/geom/Polygon';
import { DrawEvent } from 'ol/interaction/Draw';
import * as olSphere from 'ol/sphere';
import Circle from 'ol/geom/Circle';

@Component({
  selector: 'draw-polygon-tool',
  templateUrl: './draw-polygon-tool.template.html',
  styleUrls: ['./draw-polygon-tool.styles.scss']
})
export class DrawPolygonToolComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() readOnly: boolean = false;
  @Input() allowPoint: boolean = true;
  @Input() crs: 'EPSG:3857' | 'EPSG:4326' = 'EPSG:4326';
  @Input() geometryOnly: boolean = false;
  @Input() buttonSize: 'small' | 'medium' | 'large' = 'medium';
  @Input() clearGeo: boolean;

  feature: Feature;
  isDrawing: boolean;
  hitCancel = false;

  constructor(
    private _toaster: ToasterService,
    private _featuresService: MapFeaturesService,
    private _interactionsService: MapInteractionsService
  ) {}

  @HostListener('document:keyup', ['$event'])
  handleCancelKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.hitCancel = true;
      this._interactionsService.deactivateDraw();
      this.hitCancel = false;
      this.isDrawing = false;
    }
  }

  ngOnInit() {
    this.control.valueChanges.subscribe(resp => {
      if (resp === 'clear') {
        this.clearAoi();
        this.control.setValue(null);
        this.isDrawing = false;
      } else if (resp) {
        this._featuresService.addAnnotation(this.feature);
      }
    });
  }

  clearAoi() {
    this._featuresService.removeAnnotation(this.feature);
    this.feature = null;
    this.control.setValue(null);
  }

  activateDraw(geomType: 'Polygon' | 'Point' | 'Circle') {
    this.isDrawing = true;
    this._featuresService.clearAnnotations();
    this._interactionsService
      .activateDraw(geomType)
      .pipe(
        filter((event: DrawEvent) => event.type === 'drawend'),
        take(1)
      )
      .subscribe((event: DrawEvent) => {
        if (!this.hitCancel) {
          this.feature = event.feature;

          // if drawing a Circle shape, need to convert to a polygon
          if (this.feature.getGeometry().getType() === 'Circle') {
            const circleGeo = this.feature.getGeometry() as Circle;
            const circlePoints = this.createCirclePointCoords(
              circleGeo.getCenter()[0],
              circleGeo.getCenter()[1],
              circleGeo.getRadius(),
              64 // num of verts
            );

            this.feature.setGeometry(new Polygon([circlePoints]));
          }

          this.isDrawing = false;
          this._updateControl(this.feature);
        }
      });
  }

  createCirclePointCoords(
    circleCenterX,
    circleCenterY,
    circleRadius,
    pointsToFind
  ) {
    const angleToAdd = 360 / pointsToFind;
    const coords = [];
    let angle = 0;
    for (let i = 0; i < pointsToFind; i++) {
      angle = angle + angleToAdd;
      const coordX =
        circleCenterX + circleRadius * Math.cos((angle * Math.PI) / 180);
      const coordY =
        circleCenterY + circleRadius * Math.sin((angle * Math.PI) / 180);
      coords.push([coordX, coordY]);
    }

    coords.push(coords[0]); // last and first coords must be the same

    return coords;
  }

  ngOnDestroy() {
    this.hitCancel = true;
    this._interactionsService.deactivateDraw();
    this.hitCancel = false;
    this._featuresService.clearAnnotations();
    this.isDrawing = false;
    this.clearAoi();
  }

  private _updateControl(feature: Feature) {
    const parser = new GeoJSON();

    // make sure the area of the geometry fits within the specified boundaries
    // Min -> 0.10 acresMax -> 2500 acres
    // TODO: make this reusable somehow
    // TODO: make the min/max values configurable
    const minArea = 0.1,
      maxArea = 2500,
      currArea = (olSphere as any).getArea(feature.getGeometry()) * 3.861e-7,
      currAreaAcres = currArea * 640;
    if (currAreaAcres < minArea || currAreaAcres > maxArea) {
      this._toaster.alert(
        'error',
        `The area of the field should be at least ${minArea} acres and no more than ${maxArea} acres. The area you selected was ${currAreaAcres.toFixed(
          2
        )} acres.`
      );
      this.control.setValue('clear');
      return;
    }

    if (this.geometryOnly) {
      const geom = (feature.getGeometry() as Polygon).clone();

      if (this.crs === 'EPSG:4326') {
        geom.transform('EPSG:3857', 'EPSG:4326');
      }

      this.control.setValue(JSON.parse(parser.writeGeometry(geom)));
    } else {
      const clonedFeat = feature.clone();

      if (this.crs === 'EPSG:4326') {
        clonedFeat.getGeometry().transform('EPSG:3857', 'EPSG:4326');
      }

      this.control.setValue(JSON.parse(parser.writeFeature(clonedFeat)));
    }
  }
}
