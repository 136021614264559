import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

// Local Files
import { BaseService } from './base.service';

@Injectable()
export class AuditService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/features`;
  }

  /**
   *
   *
   * @param featureId
   *
   * @memberof AuditService
   */

  public getAuditsByFeatureId(
    featureId: string,
    baseParams: BaseParams = {},
    options: {
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    } = {}
  ): Observable<PagedResponse<any>> {
    return this.getWithPagedResponse<any>(
      `${this.endpointUrl}/${featureId}/history?size=500`,
      (respBody: any[]): any[] => {
        return respBody;
      },
      baseParams,
      options
    );
  }
}
