import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Badge } from '../badge.model';

@Component({
  selector: 'tag-badge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <span [ngClass]="classes">
      <span class="badge-text combo-text">{{ tagName }}</span>
      <i class="badge-icon combo-icon icon-close"
        (click)="onRemove.emit(tagName)">
      </i>
    </span>
  `
})
export class TagBadgeComponent extends Badge {
  @Input()
  tagName: string;

  @Output()
  onRemove = new EventEmitter<string>();

  otherClasses = ['tag', 'combo-element', 'align-icon-right'];
}
