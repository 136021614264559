import { Customer } from './../../../api/src/lib/models/customer.model';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'projects/api/src/public_api';
import { AuthService } from 'projects/auth/src/public_api';
import { ContextService } from './shared/services/context.service';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-ag',
  templateUrl: './app.template.html',
  styleUrls: ['./app.styles.scss']
})
export class AppComponent {
  protected user$: Observable<User>;
  protected customer$: Observable<Customer>;

  constructor(
    protected router: Router,
    private _context: ContextService,
    private _authService: AuthService
  ) {
    this.user$ = this._context.user$.pipe(shareReplay(1));
    this.customer$ = this._context.customer$.pipe(shareReplay(1));
  }

  protected onLogout(): void {
    this._context.clearAll();
    this._authService
      .logOut()
      .then(_ => this.router.navigate(['/auth/log_in']));
  }

  routeHome() {
    this.router.navigate(['/']);
  }
}
