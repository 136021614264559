import { BaseModel } from './base.model';

export enum PlatformFeature {
  OneAtlasTimeline = 'ONE_ATLAS_TIMELINE',
  TriageTool = 'TRIAGE_TOOL',
  PitneyBowes = 'PITNEY_BOWES',
  FusionAnalytics = 'FUSION_ANALYTICS',
  Inspection2D = 'INSPECTION_2D',
  BYOD = 'DRONE_IMAGERY_IMPORT',
  Nearmap = 'NEARMAP',
  MultiAsset = 'MULTI_ASSET',
  ExternalStreaming = 'EXTERNAL_STREAMING',
  DownloadFile = 'DOWNLOAD_FILE',
  BulkGrowerUpload = 'BULK_GROWER_UPLOAD',
  DmsMgrs = 'DMS_MGRS', // if military grid reference system should be used instead of latlon
  DmsSar = 'DMS_SAR', // if SAR search filters should be shown in DMS UI
  DmsKeyword = 'DMS_KEYWORD', // if keyword filters should be shown in DMS UI
  DmsDocType = 'DMS_DOC_TYPE', // if document type filters should be shown in the DMS UI
  DmsStreaming = 'DMS_STREAMING', // if streaming options should be shown in DMS UI
  DmsDownload = 'DMS_DOWNLOAD', // if download options should be shown in the DMS UI
  ImperialUnits = 'IMPERIAL_UNITS' // use imperial units instead of metric units
}

export interface PlatformFeatureInfo {
  name: PlatformFeature,
  description: string,
  deprecated: boolean
}

// Platform information is stored here because it is not present in the database
export const PlatformFeatureInfos: PlatformFeatureInfo[] = [
  {
    name: PlatformFeature.Inspection2D,
    description: 'Users can annotate an image and create reports on orthomosaic imagery',
    deprecated: false
  },
  {
    name: PlatformFeature.MultiAsset,
    description: 'Users can make assessments of a property, and select images and annotations to be included in reports',
    deprecated: false
  }, 
  {
    name: PlatformFeature.OneAtlasTimeline,
    description: 'Users can access the Living Library archive through the interactive timeline in the browser',
    deprecated: false
  },
  {
    name: PlatformFeature.ExternalStreaming,
    description: 'Users can copy the streaming URL to be used in another application',
    deprecated: false
  },
  {
    name: PlatformFeature.BYOD,
    description: 'Users can import photos to make 2D models, 3D models, or photos only; users can also import assets in bulk',
    deprecated: false
  },
  {
    name: PlatformFeature.DownloadFile,
    description: 'Users can download a copy of processed output data (and uploaded data, if applicable) with certain imagery order services',
    deprecated: false
  },  
  {
    name: PlatformFeature.BulkGrowerUpload,
    description: 'Allows users to bulk upload field enrollments using a shapefile',
    deprecated: false
  },
  {
    name: PlatformFeature.FusionAnalytics,
    description: 'Users can merge an inspection with an analytics layer',
    deprecated: true
  },
  {
    name: PlatformFeature.PitneyBowes,
    description: 'Exposes Pitney Bowes property attributes to users',
    deprecated: true
  },
  {
    name: PlatformFeature.Nearmap,
    description: 'Relationship with Nearmap',
    deprecated: true
  },
  {
    name: PlatformFeature.TriageTool,
    description: 'Deprecated in favor of MULTI_ASSET',
    deprecated: true
  }
];

export enum CustomerCategory {
  Insurance = 'INSURANCE',
  Ag = 'AG',
  Catalog = 'CATALOG'
}

export interface PlatformFeatureFlag {
  featureName: PlatformFeature;
}

export class Customer extends BaseModel {
  id: string;
  name: string;
  created: number;
  modified: number;
  contactId?: string;
  address?: {
    line1: string;
    line2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    country: string;
  };
  category: CustomerCategory;
  platformFeatures: PlatformFeatureFlag[];

  get isInsuranceCustomer(): boolean {
    return this.category === CustomerCategory.Insurance;
  }

  get isAgCustomer(): boolean {
    return this.category === CustomerCategory.Ag;
  }

  get isCatalogCustomer(): boolean {
    return this.category === CustomerCategory.Catalog;
  }

  hasFeature(platformFeature: PlatformFeature): boolean {
    return (
      this.platformFeatures.find(
        featureFlag => featureFlag.featureName === platformFeature
      ) !== undefined
    );
  }
}
