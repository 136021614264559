import { StepWrapperComponent } from './../step-wrapper.component';
import {
  Component,
  OnInit,
  Input,
  Host,
  Inject,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'step',
  templateUrl: './step.template.html',
  styleUrls: ['./step.styles.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent implements OnInit {
  stepNumber = 0;
  isLast = false;
  isFirst = false;
  currentStep = 1;

  constructor() {}

  ngOnInit() {}
}
