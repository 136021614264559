import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './baseSearch.service';
import {
  PagedSearchResponse,
  QueryBody,
  AggsBucket
} from '../../interfaces/search/base-search.interface';
import {
  MissionSearch,
  MissionSearchParams
} from '../../interfaces/search/mission-search.interface';
import { BaseParams } from '../../interfaces/baseParams.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MissionSearchService extends BaseSearchService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/search/mission/_search`;
  }

  searchMissions(
    params: MissionSearchParams,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<MissionSearch>> {
    const query = this._buildQuery(params);
    const { from, size } = this.convertPagination(baseParams);
    const sort = this.convertSort(baseParams);

    return this.search<MissionSearch>(
      {
        query,
        from,
        size,
        sort
      },
      searchResp => searchResp.hits.hits.map((hit: any) => hit['_source'])
    );
  }

  getAllTags(): Observable<PagedSearchResponse<AggsBucket>> {
    const aggs = {
      tags: {
        nested: {
          path: 'tags'
        },
        aggs: {
          tags: {
            terms: {
              field: 'tags.name',
              order: { _key: 'asc' },
              // TEMP quick fix, might need to change how this works later
              size: 500
            }
          }
        }
      }
    };

    return this.search<AggsBucket>({ aggs, size: 0 }, searchResp => {
      return searchResp.aggregations.tags.tags.buckets.map(
        (bucket: AggsBucket) => bucket.key
      );
    });
  }

  getAllTagsForVendor(
    vendorId: string
  ): Observable<PagedSearchResponse<AggsBucket>> {
    const query = this._buildQuery({ vendorId });
    const aggs = {
      tags: {
        nested: {
          path: 'tags'
        },
        aggs: {
          tags: {
            terms: {
              field: 'tags.name',
              order: { _key: 'asc' },
              // TEMP quick fix, might need to change how this works later
              size: 500
            }
          }
        }
      }
    };

    return this.search<AggsBucket>({ query, aggs, size: 0 }, searchResp => {
      return searchResp.aggregations.tags.tags.buckets.map(
        (bucket: AggsBucket) => bucket.key
      );
    });
  }

  getAllJobTypesForVendor(
    vendorId: string
  ): Observable<PagedSearchResponse<AggsBucket>> {
    const query = this._buildQuery({ vendorId });
    const aggs = {
      jobTypes: {
        terms: {
          field: 'jobType',
          order: { _key: 'asc' },
          // TEMP quick fix, might need to change how this works later
          size: 100
        }
      }
    };

    return this.search<AggsBucket>(
      {
        query,
        aggs,
        size: 0
      },
      searchResp => {
        return searchResp.aggregations.jobTypes.buckets.map(
          (bucket: AggsBucket) => bucket.key
        );
      }
    );
  }

  private _buildQuery(params: MissionSearchParams): QueryBody {
    const bool: any = {
      filter: [],
      must: [],
      should: []
    };

    const {
      searchTerm,
      deadlineDates,
      createdDates,
      jobTypes,
      statuses,
      vendorNames,
      pilotIds,
      tags,
      vendorId
    } = params;

    if (searchTerm) {
      bool.filter.push({
        bool: {
          should: [
            {
              wildcard: {
                'name.normalize': {
                  value: `${searchTerm}*`
                }
              }
            },
            {
              nested: {
                path: 'tags',
                query: {
                  wildcard: {
                    'tags.name': {
                      value: `${searchTerm}*`
                    }
                  }
                }
              }
            }
          ]
        }
      });
    }

    if (vendorId) {
      bool.filter.push({
        nested: {
          path: 'vendor',
          query: {
            bool: {
              must: { match: { 'vendor.id': vendorId } }
            }
          }
        }
      } as any);
    }

    if (deadlineDates) {
      bool.filter.push({
        range: {
          deadline: {
            gte: `${deadlineDates[0]}`,
            lte: `${deadlineDates[1] + BaseSearchService.ONE_DAY}`
          }
        }
      });
    }

    if (createdDates) {
      bool.filter.push({
        range: {
          created: {
            gte: `${createdDates[0]}`,
            lte: `${createdDates[1] + BaseSearchService.ONE_DAY}`
          }
        }
      });
    }

    if (statuses && statuses.length > 0) {
      bool.must.push(this.buildFilterListQuery(statuses, 'status'));
    }

    if (jobTypes && jobTypes.length > 0) {
      bool.must.push(this.buildFilterListQuery(jobTypes, 'jobType'));
    }

    if (vendorNames && vendorNames.length > 0) {
      bool.must.push({
        nested: {
          path: 'vendor',
          query: this.buildFilterListQuery(vendorNames, 'vendor.name')
        }
      });
    }

    if (pilotIds && pilotIds.length > 0) {
      bool.must.push({
        nested: {
          path: 'pilot',
          query: this.buildFilterListQuery(pilotIds, 'pilot.id')
        }
      });
    }

    if (tags && tags.length > 0) {
      tags.forEach(tag => {
        bool.must.push({
          nested: {
            path: 'tags',
            query: {
              term: {
                'tags.name': tag
              }
            }
          }
        });
      });
    }

    return {
      bool
    };
  }
}
