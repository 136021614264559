import { Input, Output, EventEmitter } from '@angular/core';

export abstract class AerialAccordion {
  @Input()
  togglerIcon = 'icon-triangle_right';

  @Input() // toggler icon color will be $lightGrey instead of $textColor
  mutedTogglerIcon = false;

  @Input() // TODO: implement!
  togglerLocation = 'left';

  @Input()
  expanded = false;

  @Input()
  disabled = false;

  @Input()
  altStyle = false;

  @Input()
  altStyleMain = false;

  @Input()
  altStyleNested = false;

  @Input()
  animate = false;

  @Input()
  classes = '';

  @Output()
  toggle = new EventEmitter<void>();

  @Output()
  expand = new EventEmitter<void>();

  @Output()
  collapse = new EventEmitter<void>();

  protected onToggle(): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
      this.toggle.emit();
      this[this.expanded ? 'expand' : 'collapse'].emit();
    }
  }
}
