import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MapBrowserEvent } from 'ol';
import { MapEvent } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getPointResolution } from 'ol/proj';
import { MapService } from '../../map.service';

@Component({
  selector: 'map-context',
  templateUrl: './map-context.template.html',
  styleUrls: ['./map-context.styles.scss']
})
export class MapContextComponent implements OnInit, OnDestroy {
  @Input()
  useMgrsCoords = false;

  @Input()
  useImperialUnits = false;

  // TODO: this can be built out to have all the context info
  location: Coordinate;
  scale: string;
  numericScale: number;

  private _mapLoaded = false;

  constructor(private _mapService: MapService) {
    this._getMapCenter();
  }

  ngOnInit() {
    if (this._mapService.map) {
      this._mapService.map.on('pointermove', this._latLongUpdater);
      this._mapService.map.on('postrender', this._scaleUpdater);
      this._mapService.map.on('postrender', this._getMapCenter);
    }
  }

  ngOnDestroy() {
    if (this._mapService.map) {
      this._mapService.map.un('pointermove', this._latLongUpdater);
      this._mapService.map.un('postrender', this._scaleUpdater);
      this._mapService.map.un('postrender', this._getMapCenter);
    }
  }

  private _getMapCenter = () => {
    if (this._mapService.map) {
      this.location = this._mapService.map.getView().getCenter();
    }
  };

  private _latLongUpdater = (event: MapBrowserEvent) => {
    this.location = event.coordinate;
    if (!this._mapLoaded) {
      this._mapLoaded = true;
      this._mapService.map.un('postrender', this._getMapCenter);
    }
  };

  private _scaleUpdater = (event: MapEvent) => {
    if (!event.frameState) {
      return;
    }

    const viewState = event.frameState.viewState;
    let pointResolution =
      (getPointResolution as any)(
        viewState.projection,
        viewState.resolution,
        viewState.center,
        'm'
      ) * 100;
    let suffix: string;

    // NOTE: code removed because this number is not being used. This
    // code gets run every time the mouse moves position.
    //
    // Calculates a scale between 1 and 4 as it relates to pixel scale.
    // 1 means one pixel covers a huge area. 4 means one pixel covers
    // a small area. This is used as a precision factor when displaying
    // the MGRS of the cursor position.
    // this.numericScale =
    //   Math.max(
    //     1,
    //     Math.min(
    //       4,
    //       // Round/floor/log/3 part calculates the order of magnitude of
    //       // the screen pixel size/scale. The -1/-4 part inverts the scale
    //       // so it is between 0 and 4.
    //       -1 * (Math.round(Math.floor(Math.log(pointResolution)) / 3) - 4)));

    if (this.useImperialUnits) {
      // imperial units
      if (pointResolution < 0.9144) {
        suffix = 'in';
        pointResolution /= 0.0254;
      } else if (pointResolution < 1609.344) {
        suffix = 'ft';
        pointResolution /= 0.3048;
      } else {
        suffix = 'mi';
        pointResolution /= 1609.344;
      }
    } else {
      // metric units
      if (pointResolution < 1) {
        suffix = 'mm';
        pointResolution *= 1000;
      } else if (pointResolution < 1000) {
        suffix = 'm';
      } else {
        pointResolution /= 1000;
        suffix = 'km';
      }
    }
    this.scale = `${pointResolution.toFixed()} ${suffix}`;
  };
}
