// NOTE: had to comment this out because of circular dependencies
// import { MapLayer } from 'projects/map/src/public_api';

import {
  DataProduct,
  NearmapDataProduct,
  OneAtlasDataProduct
} from '../models/dataProduct.model';

export enum WorkspaceLayerType {
  OneAtlas = 'ONE_ATLAS',
  Nearmap = 'NEARMAP',
  DataProduct = 'DATA_PRODUCT'
}

export class WorkspaceLayer {
  id: string;
  workspaceId: string;
  entityId: string; // DataProduct Id or OneAtlas image Id
  entityType: WorkspaceLayerType;
  layerName: string;
  layerOrder: number;

  constructor(initLayer: any) {
    if (initLayer.olLayer) {
      this._fromMapLayer(initLayer);
      return;
    }
    Object.keys(initLayer).forEach(key => {
      if (key === 'layerName' && !initLayer[key]) {
        // the api returns `null` for a missing `layerName`. Using `undefined`
        // instead to keep things consistent with how the `MapLayer` model
        // handles a missing `layerName`.
        this[key] = undefined;
      } else {
        this[key] = initLayer[key];
      }
    });
  }

  is(layer: any): boolean {
    return (
      layer.layerName === this.layerName &&
      // layer.olLayer.getZIndex() === this.layerOrder &&
      layer.dataProduct &&
      this._isEntityEqualTo(layer.dataProduct)
    );
  }

  private _fromMapLayer(layer: any): void {
    this.entityId = layer.dataProduct.id;

    switch (layer.dataProduct.constructor) {
      case NearmapDataProduct:
        this.entityType = WorkspaceLayerType.Nearmap;
        break;
      case OneAtlasDataProduct:
        this.entityType = WorkspaceLayerType.OneAtlas;
        break;
      case DataProduct:
      default:
        this.entityType = WorkspaceLayerType.DataProduct;
    }

    this.layerName = layer.layerName ? layer.layerName : undefined;
    this.layerOrder = layer.olLayer.getZIndex();
  }

  private _isEntityEqualTo(entity: DataProduct | OneAtlasDataProduct): boolean {
    if (this.entityId !== entity.id) return false;
    switch (this.entityType) {
      case WorkspaceLayerType.OneAtlas:
        return entity instanceof OneAtlasDataProduct;
      case WorkspaceLayerType.DataProduct:
        return entity instanceof DataProduct;
      default:
        return false;
    }
  }
}
