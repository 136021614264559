import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { Sort } from './sort.interface';

@Component({
  selector: 'sort-icons',
  template: `
    <!-- prettier-ignore -->
    <span class="sort-icons {{ size }}" [ngClass]="{
      'active': isActive,
      'disabled': disabled
    }" [style.display]="display">
      <i class="sort-icon asc fa fa-chevron-up"
        [class.active]="isActive && sortOrder === 'asc'"
        (click)="setSort('asc')"
        [attr.title]="ascTitleAttr">
      </i>
      <i class="sort-icon desc fa fa-chevron-down"
        [class.active]="isActive && sortOrder === 'desc'"
        (click)="setSort('desc')"
        [attr.title]="descTitleAttr">
      </i>
    </span>
  `,
  styleUrls: ['./sorters.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortersComponent {
  private _size = 'medium';
  private _sortBy: string;
  private _sortOrder = 'desc';

  /**
   * Disable the sorters. If set to `false` the arrow icons will have a faded
   * appearance and become unclickable.
   * @prop {boolean} [disabled=false]
   */
  @Input() disabled = false;

  /**
   * The column that _this_ `Sorters` instance is sorting. (e.g. `'name'` or `'status'`)
   * This is used to distinguish between multiple sorters in the same view.
   * @prop {string}
   */
  @Input() readonly sortFor: string;

  /**
   * The size of the arrow icons.
   * 'small' = 5px; 'medium' = 7px; 'large' = 9px
   * @prop {string} [size='medium']
   */
  @Input()
  set size(size: string) {
    size = size.toLowerCase();
    if (size === 'small' || size === 'medium' || size === 'large') {
      this._size = size;
    } else {
      this._size = 'medium';
    }
  }

  get size(): string {
    return this._size;
  }

  /**
   * Style `.sort-icons` with `display: inline-flex;` rather than `display: flex;`
   * @prop {boolean} [inline=false]
   */
  @Input()
  inline = false;

  /**
   * The current sort values.
   * @prop {Sort} currentSort
   * @prop {string} currentSort.by - the column that is currently being
   *   sorted. (e.g. `'name'` or `'status`')
   * @prop {string} currentSort.order - the current sort direction.
   *   (e.g. `'asc'` or `'desc'`). Defaults to `'desc'`.
   */
  @Input()
  set currentSort({ by, order }: Sort) {
    this._sortBy = by;
    if (order.toLowerCase() === 'asc') {
      this._sortOrder = 'asc';
    } else {
      this._sortOrder = 'desc';
    }
  }

  get currentSort(): Sort {
    return { by: this._sortBy, order: this._sortOrder };
  }

  @Output()
  sort: EventEmitter<Sort> = new EventEmitter<Sort>();

  setSort(sortOrder: string): void {
    if (this.disabled || (this.isActive && sortOrder === this.sortOrder)) {
      return;
    }
    this.currentSort = { by: this.sortFor, order: sortOrder };
    this.sort.emit(this.currentSort);
  }

  get isActive(): boolean {
    return this.sortFor === this.sortBy;
  }

  get sortBy(): string {
    return this._sortBy;
  }

  get sortOrder(): string {
    return this._sortOrder;
  }

  get display(): string {
    return this.inline ? 'inline-flex' : undefined;
  }

  get ascTitleAttr(): string {
    return !this.disabled ? 'Sort Ascending' : undefined;
  }

  get descTitleAttr(): string {
    return !this.disabled ? 'Sort Descending' : undefined;
  }
}
