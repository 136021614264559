import { BaseModel } from './base.model';
import { RoleType } from './user.model';

export interface AccessTokenResponse {
  // This matches the backend's APIAccessTokenResponse model
  name: string;
  expirationDate: number;
  token: string;
  userId: string;
  parentId: string;
}

export class AccessToken extends BaseModel {
  // This matches the backend's APIAccessToken model
  id: string;
  name: string;
  prefix: string;
  expirationDate: number;
  valid: boolean;
  userId: string;
  parentId: string;
  role: RoleType;
  created: number;
  modified: number;

  get status(): 'VALID' | 'INVALID' {
    return this.valid ? 'VALID' : 'INVALID';
  }
}
