import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialButtonWithIcon } from '../aerialButtonWithIcon.model';

@Component({
  selector: 'submit-button',
  template: `
    <!-- prettier-ignore -->
    <disclosure-button
      [classes]="classes"
      color="primary"
      [size]="size"
      type="submit"
      [disabled]="disabled">
      <ng-content></ng-content>
    </disclosure-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitButtonComponent extends AerialButtonWithIcon {
  otherClasses: string[] = [];
}
