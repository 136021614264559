import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { UIModule } from 'projects/ui/src/public_api';
import { InviteUserDialogComponent } from './invite-user-dialog/invite-user-dialog.component';
import { AgListDialogComponent } from './ag-list-dialog/ag-list-dialog.component';
import { CreateTokenDialogComponent } from './create-token-dialog/create-token-dialog.component';
import { NotificationsSettingsDialogComponent } from './notifications-settings-dialog/notifications-settings-dialog.component';

@NgModule({
  declarations: [
    InviteUserDialogComponent,
    EditUserDialogComponent,
    AgListDialogComponent,
    CreateTokenDialogComponent,
    NotificationsSettingsDialogComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, UIModule],
  exports: [AgListDialogComponent],
  entryComponents: [
    InviteUserDialogComponent,
    EditUserDialogComponent,
    AgListDialogComponent,
    CreateTokenDialogComponent,
    NotificationsSettingsDialogComponent
  ]
})
export class DialogsModule { }
