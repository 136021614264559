import { Component, AfterViewInit, Renderer2 } from '@angular/core';

import { DialogService } from './dialog.service';
import { DialogIntent } from './dialogIntent.interface';

import pull from 'lodash/pull';

@Component({
  selector: 'dialog-outlet',
  template: `
    <!-- prettier-ignore -->
    <div class="dialog-overlay" [class.hidden]="!displayed">
      <aerial-dialog *ngFor="let dialog of dialogs"
        [intent]="dialog"
        (onClose)="closeDialog($event)">
      </aerial-dialog>
    </div>
  `
})
export class DialogOutletComponent implements AfterViewInit {
  dialogs: DialogIntent[] = [];
  displayed = false;

  constructor(
    private _dialogService: DialogService,
    private _renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this._dialogService.dialogs$.subscribe(data => this.openDialog(data));
  }

  openDialog(intent: DialogIntent) {
    if (intent === null) {
      this.displayed = false;
      this._renderer.removeClass(document.body, 'dialog-open');
      return;
    }
    this.dialogs.push(intent);
    if (!this.displayed) {
      this.displayed = true;
      this._renderer.addClass(document.body, 'dialog-open');
    }
  }

  // closeDialog({
  //   intent,
  //   closeCallback,
  //   closeCallbackParams
  // }: {
  //   intent: DialogIntent;
  //   closeCallback?: Function;
  //   closeCallbackParams?: any[];
  // }) {
  closeDialog(intent: DialogIntent) {
    pull(this.dialogs, intent);
    // TODO @sidney this is just a stop-gap solution
    if (intent.data.closeCallback) {
      intent.data.closeCallback();
    }
    if (this.dialogs.length === 0) {
      this.displayed = false;
      this._renderer.removeClass(document.body, 'dialog-open');
    }
  }
}
