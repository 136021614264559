import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { JwtHelperService, CacheService } from 'projects/api/src/public_api';
import { BaseStateService } from 'projects/utils/src/public_api';

import { Observable, from } from 'rxjs';
import { switchMap, map, take, filter, share } from 'rxjs/operators';

export interface AuthResponse {
  // An Auth ID token generated from the provided authentication provider.
  idToken: string;

  // The email for the user associated with the account
  email: string;

  // The number of seconds in which the ID token expires.
  expiresIn: number;

  // The uid corresponding to the provided ID token.
  localId: string;

  // The refresh token used to create a new idToken
  refreshToken: string;
}

export interface AuthStateModel {
  // The short-lived authentication token
  idToken: string;

  // The long-lived refresh token, to update the auth token when it expires
  refreshToken: string;

  // used by the back end for something
  localId: string;
}

@Injectable()
export class AuthService extends BaseStateService<AuthStateModel> {
  public static readonly tokenKeyName = 'token';
  private readonly _tokenKeyName = AuthService.tokenKeyName;
  private readonly _refreshTokenKeyName = 'refreshToken';
  private readonly _localIdKeyName = 'localId';
  private _jwtHelper = new JwtHelperService();

  get idToken$(): Observable<string> {
    return this.state$.pipe(map(authState => authState.idToken));
  }

  // NOTE: this is only used for the edge case with an anonymous user token
  set token(idToken: string) {
    this._storeAuth({
      idToken: idToken,
      refreshToken: null,
      localId: null
    });
  }

  constructor(protected cache: CacheService) {
    super({
      idToken: null,
      refreshToken: null,
      localId: null
    });

    this._initAuth();
  }

  /**
   * Login with an email and password
   *
   * @param email the log in email
   * @param password the log in password
   */
  logIn(email: string, password: string): Promise<string> {
    // TODO: option to 'stay logged in' on login page?
    return from(
      fetch(`${environment.AUTH_API_ROOT}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password
        })
      })
    )
      .pipe(
        switchMap(resp => {
          if (resp.ok) {
            return resp.json();
          } else {
            const error = new Error('Log in failed. Status ' + resp.status);
            this._clearAuth();
            console.error('authService logIn() FAILED', error);
            return Promise.reject({ error, status: resp.status });
          }
        }),
        map((resp: any) => {
          this._storeAuth(resp);
          return resp.idToken;
        })
      )
      .toPromise();
  }

  /**
   * refresh the idToken with the current refreshToken
   */
  refreshToken(): Promise<string> {
    return this.state$
      .pipe(
        take(1),
        switchMap(authState => {
          if (!authState.refreshToken) return Promise.reject();
          return fetch(`${environment.AUTH_API_ROOT}/refresh-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: authState.refreshToken,
              localId: authState.localId
            })
          });
        }),
        switchMap(resp => {
          if (resp.ok) {
            return resp.json();
          } else {
            const error = new Error(
              'Token refresh failed. Status: ' + resp.status
            );
            this._clearAuth();
            console.error('authService refreshToken() FAILED', error);
            return Promise.reject(error);
          }
        }),
        map((resp: AuthResponse) => {
          this._storeAuth(resp);
          return resp.idToken;
        }),
        share()
      )
      .toPromise();
  }

  /**
   * logs the user out. (just removes the tokens)
   */
  logOut(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this._clearAuth();
        this.state$
          .pipe(
            filter(authState => authState.idToken === null),
            take(1)
          )
          .subscribe(_authState => resolve(true));
      } catch (e) {
        // dunno why this would happen
        // but just in case
        reject(e);
      }
    });
  }

  /**
   * For a user that is already authenticated, can be used to update their password
   *
   * @param newPassword the password to change to
   */
  updatePassword(email: string, newPassword: string): Promise<boolean> {
    return this.state$
      .pipe(
        take(1),
        switchMap(authState =>
          fetch(`${environment.AUTH_API_ROOT}/update-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authState.idToken}`
            },
            body: JSON.stringify({
              email,
              newPassword
            })
          })
        ),
        switchMap(resp => {
          if (resp.ok) {
            return Promise.resolve(true);
          } else {
            const error = new Error(
              'Password update failed. Status ' + resp.status
            );
            console.error('authService updatePassword() FAILED', error);
            return Promise.reject(error);
          }
        }),
        share()
      )
      .toPromise();
  }

  /**
   * "Forgot password" Sends a password reset email for the specified address
   *
   * @param email the email for the account to start a password reset flow for
   */
  sendResetEmail(email: string): Promise<boolean> {
    return fetch(`${environment.AUTH_API_ROOT}/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        requestType: 'PASSWORD_RESET'
      })
    }).then(resp => {
      if (resp.ok) {
        return Promise.resolve(true);
      } else {
        const error = new Error(
          'AuthService send reset email failed. Status: ' + resp.status
        );
        return Promise.reject(error);
      }
    });
  }

  /**
   * After a user clicks through a reset password email, this is used to send along
   * a new password and the generated resetCode to change their password
   *
   * @param resetCode the generated code that should havbe been provided in the email link
   * @param newPassword the new password for the account
   */
  confirmPasswordReset(
    // email: string,
    resetCode: string,
    newPassword: string
  ): Promise<boolean> {
    return fetch(`${environment.AUTH_API_ROOT}/confirm-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        oobCode: resetCode,
        newPassword: newPassword
      })
    }).then(resp => {
      if (resp.ok) {
        return Promise.resolve(true);
      } else {
        const error = new Error(
          'AuthService send reset password confirmation failed. Status: ' +
            resp.status
        );
        return Promise.reject(error);
      }
    });
  }

  /**
   * Returns a promise that attempts to resolve with a valid idToken
   * or resolves with nothing if a valid idToken cannot be gotted
   */
  jwtTokenGetter(): Promise<string> {
    return this.state$
      .pipe(
        take(1),
        switchMap(authState => {
          if (
            authState.idToken &&
            !this._jwtHelper.isTokenExpired(authState.idToken)
          ) {
            // Token is valid, so return it
            return Promise.resolve(authState.idToken);
          } else if (authState.refreshToken) {
            // token is not valid, but we have a refreshToken,
            // so attempt to refresh and return the new token
            return this.refreshToken();
          } else {
            // token is not valid, and we have no refreshToken
            // return null
            return Promise.resolve(null);
          }
        }),
        share()
      )
      .toPromise();
  }

  /**
   * private _storeAuth - sets the new auth state and stores tokens in localStorage
   *
   * @param authResp response that comes back from an auth request
   */
  private _storeAuth(authResp: AuthResponse | Partial<AuthResponse>) {
    const { idToken, refreshToken, localId } = authResp;

    this.setState({
      idToken,
      refreshToken,
      localId
    });

    localStorage.setItem(this._tokenKeyName, idToken);
    localStorage.setItem(this._refreshTokenKeyName, refreshToken);
    localStorage.setItem(this._localIdKeyName, localId);
    this.cache.clearAllCache();
  }

  /**
   * private _clearAuth - clears out the auth state and localStorage
   *
   */
  private _clearAuth() {
    this.setState({
      idToken: null,
      refreshToken: null,
      localId: null
    });

    localStorage.removeItem(this._tokenKeyName);
    localStorage.removeItem(this._refreshTokenKeyName);
    localStorage.removeItem(this._localIdKeyName);
    this.cache.clearAllCache();
  }

  /**
   * private _initAuth - attempts to pull the tokens from localStorage
   *
   */
  private _initAuth() {
    this.setState({
      idToken: localStorage.getItem(this._tokenKeyName),
      refreshToken: localStorage.getItem(this._refreshTokenKeyName),
      localId: localStorage.getItem(this._localIdKeyName)
    });
  }
}
