import { Injectable } from '@angular/core';

import { Feature, Collection } from 'ol';
import Geometry from 'ol/geom/Geometry';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Style, { StyleFunction } from 'ol/style/Style';
import RegularShape from 'ol/style/RegularShape';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import VectorLayer from 'ol/layer/Vector';
import { Extent, extend } from 'ol/extent';
import CircleStyle from 'ol/style/Circle';
import VectorSource from 'ol/source/Vector';
import { fromExtent } from 'ol/geom/Polygon';
import SimpleGeometry from 'ol/geom/SimpleGeometry';

import find from 'lodash/find';
import remove from 'lodash/remove';
import Text from 'ol/style/Text';
import MultiLineString from 'ol/geom/MultiLineString';
import Icon from 'ol/style/Icon';

export enum VectorStyleType {
  Simple = 0,
  FillColorMap = 1,
  StrokeColorMap = 2,
  ColorMap = 3
}

export interface SimpleVectorStyle {
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
}

export interface FillColorMapVectorStyle {
  colorKey: string;
  colorMap: {};
}

export interface StrokeColorMapVectorStyle {
  strokeWidth: number;
  colorKey: string;
  colorMap: {};
}

export interface ColorMapVectorStyle {
  strokeWidth: number;
  colorKey: string;
  colorMap: {
    [key: string]: {
      strokeColor: string;
      fillColor: string;
      strokeWidth: number;
    };
  };
}

export interface VectorStyleDef {
  type: VectorStyleType;
  style:
    | SimpleVectorStyle
    | FillColorMapVectorStyle
    | StrokeColorMapVectorStyle
    | ColorMapVectorStyle;
}

/**
  Service for managing Features that show on the map.

  Manages two collections of features, one `features` that goes into the `featuresSource`
  which gets used by {@link MapLayersService} to render clustered features on the map.
  The other, `annotations` goes into the `annotationsLayer` which allows for adding
  arbitrary features to the map without them being clustered.
 */
@Injectable({
  providedIn: 'root'
})
export class MapFeaturesService {
  /**
   * Default font style
   */
  static defaultFont = `bold 13px "PF DinText Pro", sans-serif`;

   /**
   * Default style object to be reused/extended where needed
   */
  static defaultStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.85)',
      width: 1.5
    }),
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.85)',
        width: 1.5
      })
    })
  });

  /**
    Collection of Feature objects.
    Really just used internally.
    See {@link https://openlayers.org/en/latest/apidoc/Feature.html Feature}
  */
  features: Feature[];

  /**
    The ol.source object. Used in {@link MapLayersService}.
    This source is passed to a clustering algo before being rendered.
  */
  featuresSource: VectorSource;

  /**
    Added in for rudimentary ability to show arbitrary features on the map
    without them going through the clustering.
  */
  annotationsLayer: VectorLayer;

  /**
    Collection of features that go in the annotaionsLayer
  */
  private _annotations: Collection<Feature>;

  constructor() {
    this.features = [];
    this.featuresSource = new VectorSource({ features: this.features });

    this._initAnnotations();
  }

  /**
    Style function used in {@link MapLayersService} to style the clustered
    features layer.
    See {@link http://openlayers.org/en/master/apidoc/ol.html#.StyleFunction StyleFunction}
  */
  static clusteredFeatureStyleFunction(feature: Feature, zoom: number) {
    let featureText: string,
      featureSize = 0,
      fillColor: string,
      strokeColor: string;

    let clusteredFeatures = feature.get('features');
    let numFeatures = clusteredFeatures.length;
    let featureClass = clusteredFeatures[0].get('class');

    if (numFeatures > 1) {
      featureText = numFeatures.toString();
      featureSize += numFeatures;
    }

    switch (featureClass) {
      case 'data-feature':
        fillColor = 'rgba(165,24,144,0.65)';
        strokeColor = numFeatures > 1 ? '#A51890' : '#fff';
        break;
      case 'aoi-feature':
        fillColor = 'rgba(0,32,91,0.65)';
        strokeColor = numFeatures > 1 ? '#00205b' : '#fff';
        break;
      case 'order-feature':
      default:
        fillColor = 'rgba(0,133,173,0.65)';
        strokeColor = numFeatures > 1 ? '#0085AD' : '#fff';
        break;
    }

    return new Style({
      geometry: feature.getGeometry(),
      fill: new Fill({
        color: fillColor
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: 1
      }),
      text: new Text({
        text: featureText,
        fill: new Fill({
          color: '#fff'
        }),
        font: MapFeaturesService.defaultFont
      }),
      image: new CircleStyle({
        radius: 7 + featureSize,
        fill: new Fill({
          color: fillColor
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: 2
        })
      })
    });
  }

  /**
    Styling function used by {@link MapInteractionsService} to style hovered features.
    See {@link http://openlayers.org/en/master/apidoc/ol.html#.StyleFunction StyleFunction}
  */
  static clusterFeatureHoverStyleFunction(feature: Feature, zoom: number) {
    let numFeatures = 0,
      hoverGeometry,
      clusteredFeatures = feature.get('features');

    if (clusteredFeatures) {
      numFeatures = clusteredFeatures.length - 1;

      hoverGeometry = new GeometryCollection(
        clusteredFeatures.map((f: Feature) => {
          if (f.get('layer-extent')) {
            return fromExtent(f.get('layer-extent'));
          } else {
            return f.getGeometry();
          }
        })
      );
    }

    let styles = [
      new Style({
        geometry: feature.getGeometry(),
        fill: new Fill({
          color: 'rgba(0,0,0,0.05)'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 1
        }),
        text: new Text({
          text: numFeatures > 1 ? numFeatures.toString() : '',
          fill: new Fill({
            color: '#fff'
          }),
          font: MapFeaturesService.defaultFont
        }),
        image: new CircleStyle({
          radius: 7 + numFeatures,
          fill: new Fill({
            color: 'rgba(0,0,0,0.05)'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          })
        })
      })
    ];

    if (hoverGeometry) {
      let style = MapFeaturesService.defaultStyle.clone();
      style.setGeometry(hoverGeometry);
      styles.push(style);
    }

    return styles;
  }

  /**
   * Function with logic to generate a style function used for vector layers
   *
   * @param styleDef the vector style definition object to use to generate the function
   * @returns an ol style function to be used when creating the vector layer
   */
  static GenerateMVTStyleFunction(styleDef: VectorStyleDef): StyleFunction {
    const func = (feature: Feature, resolution: number) => {
      // console.log(feature);
      // console.log(feature.get('type'));
      switch (styleDef.type) {
        case VectorStyleType.Simple:
          var {
            fillColor,
            strokeColor,
            strokeWidth
          } = styleDef.style as SimpleVectorStyle;

          if (
            // old demo features
            feature.get('type') === 'oblique' ||
            // new backend generated features
            feature.get('yaw')
          ) {
            // tearDrop style svg
            var svg =
              '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
              '<path fill-rule="evenodd"  fill="' +
              fillColor +
              '" d="M8.000,0.000 L-0.000,17.400 C-0.000,17.400 -0.000,26.000 8.000,26.000 C16.000,26.000 16.000,17.400 16.000,17.400 L8.000,0.000 Z"/></svg>';

            let mysvg = new Image();
            mysvg.src = 'data:image/svg+xml,' + escape(svg);

            const yaw = feature.get('yaw') || feature.get('heading');
            const correctedYaw = yaw < 0 ? yaw + 360 : yaw;
            // console.log('yaw: ' + correctedYaw);

            const icon = new Style({
              fill: new Fill({
                color: fillColor
              }),
              stroke: new Stroke({
                color: strokeColor,
                width: strokeWidth
              }),
              image: new Icon({
                img: mysvg,
                imgSize: [30, 30],
                rotation: correctedYaw * (Math.PI / 180)
              })
            });

            const obliqueArrow = [icon /*, triangle , circle */];

            return obliqueArrow;
          } else {
            // interaction state overrides
            const isSelected = feature.get('selected');
            const isHovered = feature.get('hovered');
            if (isHovered || isSelected) {
              strokeColor = 'rgba(255, 255, 255, 0.85)';
              fillColor = isSelected ? 'rgba(255, 255, 255, 0.4)' : fillColor;
              strokeWidth *= 1.5;
            }
            return [
              new Style({
                fill: new Fill({
                  color: fillColor
                }),
                stroke: new Stroke({
                  color: strokeColor,
                  width: strokeWidth
                }),
                image: new CircleStyle({
                  radius: 7,
                  fill: new Fill({
                    color: fillColor
                  }),
                  stroke: new Stroke({
                    color: strokeColor,
                    width: strokeWidth
                  })
                })
              })
            ];
          }

        case VectorStyleType.FillColorMap:
          var {
            colorKey,
            colorMap
          } = styleDef.style as FillColorMapVectorStyle;

          return [
            new Style({
              fill: new Fill({
                color: colorMap[feature.get(colorKey)]
              }),

              stroke: new Stroke({
                color: '#FFFFFF',
                width: 2
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: colorMap[feature.get(colorKey)]
                }),
                stroke: new Stroke({
                  color: '#FFFFFF',
                  width: 2
                })
              })
            })
          ];
        case VectorStyleType.StrokeColorMap:
          var {
            colorKey,
            colorMap,
            strokeWidth
          } = styleDef.style as StrokeColorMapVectorStyle;

          return [
            new Style({
              stroke: new Stroke({
                color: colorMap[feature.get(colorKey)],
                width: strokeWidth || 2
              })
            })
          ];
        case VectorStyleType.ColorMap:
          let style = styleDef.style as ColorMapVectorStyle;

          var { colorKey, strokeWidth } = style as ColorMapVectorStyle;
          var colorMap = style.colorMap as {}; //NOTE: typescrip was yelling so i had to do this

          let mappedStyle = colorMap[feature.get(colorKey)];
          if (mappedStyle) {
            strokeWidth =
              mappedStyle.strokeWidth != null
                ? mappedStyle.strokeWidth
                : strokeWidth != null
                ? strokeWidth
                : 2;

            // interaction state overrides
            const isSelected = feature.get('selected');
            const isHovered = feature.get('hovered');
            if (isHovered || isSelected) {
              mappedStyle = {
                strokeColor: 'rgba(255, 255, 255, 0.85)',
                fillColor: isSelected
                  ? 'rgba(255, 255, 255, 0.4)'
                  : mappedStyle.fillColor
              };
              strokeWidth *= 1.5;
            }

            return [
              new Style({
                fill: new Fill({
                  color: mappedStyle.fillColor
                }),
                stroke: new Stroke({
                  color: mappedStyle.strokeColor,
                  width: strokeWidth
                }),
                image: new CircleStyle({
                  radius: 7,
                  fill: new Fill({
                    color: mappedStyle.fillColor
                  }),
                  stroke: new Stroke({
                    color: mappedStyle.strokeColor,
                    width: strokeWidth
                  })
                })
              })
            ];
          } else {
            return [
              new Style({
                fill: new Fill({
                  color: 'rgba(212, 47, 18, 0.4)'
                }),
                stroke: new Stroke({
                  color: 'rgba(236, 37, 37, 0.85)',
                  width: 2
                }),
                image: new CircleStyle({
                  radius: 7,
                  fill: new Fill({
                    color: 'rgba(236, 37, 37, 0.85)'
                  })
                })
              })
            ];
          }

        default:
          // console.log(feature.getProperties());
          return [
            new Style({
              fill: new Fill({
                color: 'rgba(212, 47, 18, 0.4)'
              }),
              stroke: new Stroke({
                color: 'rgba(236, 37, 37, 0.85)',
                width: 2
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: 'rgba(236, 37, 37, 0.85)'
                })
              })
            })
          ];
      }
    };
    return func;
  }

  /**
   * A default style function for vector layers
   *
   * @deprecated the GenerateMVTStyleFunction provides a default,
   *             and the switch statements in here are no longer needed
   * See {@link http://openlayers.org/en/master/apidoc/ol.html#.StyleFunction StyleFunction}
   */
  static MVTLayerStyle(feature: Feature, resolution: number) {
    let colorMap = {};
    switch (feature.get('class')) {
      case 'terraloupe-damage':
        colorMap = {
          UP_TO_5: '#256625',
          UP_TO_10: '#5daf5d',
          UP_TO_25: '#edea44',
          UP_TO_75: '#ef7a28',
          ABOVE_75: '#ff0000'
        };
        return [
          new Style({
            stroke: new Stroke({
              color: colorMap[feature.get('damage')],
              width: 2
            })
          })
        ];
      case 'advisory-wind-field':
        colorMap = {
          '34.0': '#edea44',
          '50.0': '#ef7a28',
          '64.0': '#ff0000'
        };
        return [
          new Style({
            fill: new Fill({
              color: colorMap[feature.get('radii')]
            })
          })
        ];
      case 'farmers-policies':
        colorMap = {
          pif: '#44ed55',
          claim: '#ee4b27',
          likely_claim: '#24a9ff',
          'hl1-commercial': '#9a1ee7'
        };
        return [
          new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: colorMap[feature.get('record_type')] || '#24a9ff'
              })
            })
          })
        ];
      case 'dominion-lines':
        return [
          new Style({
            image: new RegularShape({
              fill: new Fill({
                color: 'rgba(41, 246, 61, 0.8)'
              }),
              stroke: new Stroke({
                color: '#fff',
                width: 2
              }),
              points: 3,
              radius: 10
            })
          })
        ];
      default:
        return [
          new Style({
            fill: new Fill({
              color: 'rgba(212, 47, 18, 0.4)'
            }),
            stroke: new Stroke({
              color: 'rgba(236, 37, 37, 0.85)',
              width: 2
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: 'rgba(236, 37, 37, 0.85)'
              })
            })
          })
        ];
    }
  }

  /**
    This is a rudimentary function to create the annotationsLayer
  */
  private _initAnnotations() {
    // TODO: super basic annotations layer stuff

    let fillColor: string;
    let _annotationStyle = (feature: Feature, zoom: number) => {
      let style = MapFeaturesService.defaultStyle.clone();
      style.setText(
        new Text({
          font: MapFeaturesService.defaultFont,
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: '#000',
            width: 2
          }),
          text: feature.get('text')
        })
      );

      if (feature.getProperties().fillColor) {
        style.setFill(
          new Fill({
            color: 'rgba(' + feature.getProperties().fillColor + ', 0.75)'
          })
        );
      }

      return style;
    };

    this._annotations = new Collection<Feature>();

    this.annotationsLayer = new VectorLayer({
      style: _annotationStyle,
      source: new VectorSource({
        features: this._annotations
      })
    });
  }

  /**
    Adds a feature to the annotationsLayer.
    @param annotation Feature to add to the annotations layer.
   */
  addAnnotation(annotation: Feature) {
    this._annotations.push(annotation);
  }

  /**
    Clears all featuers from the annotationsLayer
  */
  clearAnnotations() {
    if (this._annotations) {
      this._annotations.clear();
    }
  }

  /**
    Removes a specific Feature from the annotationsLayer
    @param annotation Feature to remove from the annotationsLayer
  */
  removeAnnotation(annotation: Feature) {
    this._annotations.remove(annotation);
  }

  /**
    Gets an annotation feature by an ID.
    @param featureId ID of the feature to getId
    @returns Feature with matching ID
  */
  getAnnotation(featureId: string): Feature {
    const featArray = this._annotations.getArray();
    return find(featArray, feat => feat.getId() === featureId);
  }

  /**
    Gets all annotation features in collection
    @returns Feature collection (needed for some ol functions)
  */
  getAllAnnotations() {
    return this._annotations;
  }

  /**
   * Gets the extent of all annotations
   * @returns Extent bounding box for all annotations
   */
  getAllAnnotationsExtent(): Extent {
    var extent = this._annotations
      .item(0)
      .getGeometry()
      .getExtent();
    this._annotations.forEach(feature => {
      extend(extent, feature.getGeometry().getExtent());
    });
    return extent;
  }

  /**
   * adds a dotted outline to the annotation layer for the feature
   *
   * @param feature the feature to show a boundary of
   * @param name optional name for the boundary feature
   * @return the styled feature that shows on the annotationsLayer
   */
  addBoundaryAnnotation(feature: Feature, name?: string): Feature {
    let geom = feature.getGeometry() as any;
    let assetBoundary: Feature;

    if (geom.getType() === 'Polygon') {
      assetBoundary = new Feature(new MultiLineString(geom.getCoordinates()));
    } else {
      assetBoundary = feature;
    }
    assetBoundary.set('no-hover', true);
    assetBoundary.setStyle(
      new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [5, 10]
        }),
        image: new CircleStyle({
          radius: 7,
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [5, 10]
          })
        })
      })
    );
    assetBoundary.set('name', name);

    this.addAnnotation(assetBoundary);

    return assetBoundary;
  }

  /**
   * Returns annotations that intersect the provided geometry
   * @param geom the Geometry to intersect with
   * @returms a collection of ol.Features that intersect
   */
  getAnnotationsInGeometry(geom: Geometry): Feature[] {
    const features: Feature[] = [];

    this.annotationsLayer
      .getSource()
      .forEachFeatureIntersectingExtent(
        geom.getExtent(),
        (feature: Feature) => {
          /// NOTE: there is a not a good way to get all features that intersects a geometry
          // at least in openlayers 4.6.5.
          // If lassoing around asset points this will work fine. Things could get weird if lassoing
          // around polygons. And this will break if any features have GeometryCollections
          const coord = (feature.getGeometry() as SimpleGeometry).getFirstCoordinate();
          if (geom.intersectsCoordinate(coord)) {
            features.push(feature);
          }
        }
      );

    return features;
  }

  /**
    Adds an Feature to the featuresSource
    @param feature Feature to add to the featuresSource.
  */
  addFeature(feature: Feature) {
    this.features = this.features.concat([feature]);
    this.featuresSource.addFeature(feature);
  }

  /**
    Adds an array of ol.Features to the featuresSource
    @param features array of ol.Features to add to the featuresSource
  */
  addFeatures(features: Feature[]) {
    this.features = this.features.concat(features);
    this.featuresSource.addFeatures(features);
  }

  /**
    Removes a specific feature from the featuresSource
    @param feature Feature to remove from the features source.
  */
  removeFeature(feature: Feature) {
    remove(this.features, feat => feature.getId() === feat.getId());
    this.featuresSource.removeFeature(feature);
  }

  /**
    Clears all ol.Features from the feature source.
  */
  clearFeatures() {
    this.features = [];
    this.featuresSource.clear();
  }

  /**
    Gets a feature by an ID.
    @param featureId ID of the feature to getId
    @returns Feature with matching ID
  */
  getFeature(featureId: string): Feature {
    return find(this.features, feat => feat.getId() === featureId);
  }

  /**
   * Returns features that interset the provided geometry
   * @param geom the Geometry to intersect with
   * @returms a collection of ol.Features that intersect
   */
  getFeaturesInGeometry(geom: Geometry): Feature[] {
    const features: Feature[] = [];

    this.featuresSource.forEachFeatureIntersectingExtent(
      geom.getExtent(),
      (feature: Feature) => {
        /// NOTE: there is a not a good way to get all features that intersects a geometry
        // at least in openlayers 4.6.5.
        // If lassoing around asset points this will work fine. Things could get weird if lassoing
        // around polygons. And this will break if any features have GeometryCollections
        const coord = (feature.getGeometry() as SimpleGeometry).getFirstCoordinate();
        if (geom.intersectsCoordinate(coord)) {
          features.push(feature);
        }
      }
    );

    return features;
  }
}
