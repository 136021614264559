import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './field-single.template.html',
  styleUrls: ['./field-single.styles.scss']
})
export class FieldSingleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
