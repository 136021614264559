import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'copy-input',
  templateUrl: './copy-input.template.html',
  styleUrls: ['./copy-input.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CopyInputComponent),
      multi: true
    }
  ]
})
export class CopyInputComponent implements ControlValueAccessor {
  // TODO: provide customization options like input size and classes
  @Input()
  set value(value: string) {
    this.writeValue(value);
  }

  get value(): string {
    return this._value;
  }

  @Output()
  copySuccess = new EventEmitter<string>();

  @Output()
  copyError = new EventEmitter<void>();

  @HostBinding('class.copy-input')
  protected hasIdentClass = true;

  protected isCopied = false;

  private _value: string;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  writeValue(value: string): void {
    this._value = value;
    this._onChange(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // we could disable the copy button, but the input is already read-only
    // I can't think of a reason why we'd need to disable it
  }

  onCopy({ content }: { isSuccess: boolean; content?: string }): void {
    this.isCopied = true;
    window.setTimeout(() => {
      this.isCopied = false;
      this._changeDetector.markForCheck();
      this.copySuccess.emit(content);
    }, 2000);
  }

  private _onTouched = () => {};
  private _onChange = (value: string) => {};
}
