import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'login-group',
  templateUrl: './login-group.template.html'
})
export class LoginGroupComponent {
  @Input()
  parent: FormGroup;

  get emailCtrl(): FormControl {
    return this.parent.get('email') as FormControl;
  }

  get passwordCtrl(): FormControl {
    return this.parent.get('password') as FormControl;
  }
}
