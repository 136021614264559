import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from 'projects/api/src/public_api';

@Component({
  selector: 'help-menu',
  templateUrl: './help-menu.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpMenuComponent {
  @Input()
  user: User;

  @Input()
  includeUsersnap = false;

  isMenuVisible: boolean;
}
