import { Annotation } from './annotation.model';
import { ObjectWithFeature } from './objectWithFeature.model';

import GeoJSON from 'ol/format/GeoJSON';
import GeometryCollection from 'ol/geom/GeometryCollection';
import { Extent } from 'ol/extent';

export class Aoi extends ObjectWithFeature {
  public id: string;
  public projectId: string;
  public customerId: string;
  public name: string;
  public category: string;
  public created: number;
  public modified: number;
  public notes: string;
  public annotations: Annotation[];

  toJSON(): object {
    let geo = new GeoJSON();
    let jsonFeature;

    try {
      jsonFeature = JSON.parse(geo.writeFeature(this.feature));
    } catch (e) {
      // in case its not an Feature
      jsonFeature = this.feature;
    }

    let json = {
      name: this.name,
      projectId: this.projectId,
      customerId: this.customerId,
      category: this.category,
      notes: this.notes,
      geoJson: {
        type: 'FeatureCollection',
        features: [jsonFeature]
      },
      annotations: this.annotations
        ? this.annotations.map(annotation => annotation.toJSON())
        : null
    };

    return json;
  }

  static fromJSON(jsonAoi: any): Aoi {
    let geo = new GeoJSON();

    // TODO: need to deal with feature collections
    let featGeo = jsonAoi.geoJson.features[0];
    let feature = geo.readFeature(featGeo);
    feature.setId(jsonAoi.id);
    feature.setProperties({
      name: jsonAoi.name,
      class: 'aoi-feature'
    });

    return new Aoi({
      id: jsonAoi.id,
      projectId: jsonAoi.projectId,
      customerId: jsonAoi.customerId,
      name: jsonAoi.name,
      notes: jsonAoi.notes,
      feature: feature,
      category: jsonAoi.category,
      created: jsonAoi.created,
      modified: jsonAoi.modified,
      annotations: jsonAoi.annotations.map((annotation: any) =>
        Annotation.fromJSON(annotation)
      )
    });
  }

  static getExtentForAois(aois: Aoi[]): Extent {
    let geos = aois.map(aoi => aoi.feature.getGeometry());
    return new GeometryCollection(geos).getExtent();
  }
}
