import { BaseModel } from './base.model';
import { AirportDetails } from '../interfaces/airportDetails.interface';

export enum VendorPlatformFeature {
  LAANC = 'LAANC_AUTHORIZATION'
}

export interface VendorPlatformFeatureFlag {
  featureName: VendorPlatformFeature;
}

export enum Integration {
  DroneBase = 'DRONEBASE'
}

export enum RangeUnits {
  Miles = 'MILES',
  Kilometers = 'KILOMETERS'
}

export interface VendorContact {
  email?: string;
  homePhone?: string;
  workPhone?: string;
  mobilePhone?: string;
}

export interface VendorAddress {
  line1?: string;
  line2?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
}

export class Vendor extends BaseModel {
  id: string | null;
  name: string;
  address: VendorAddress;
  contact: VendorContact;
  manned: boolean;
  unmanned: boolean;
  satellite: boolean;
  range: number;
  integration: Integration;
  rangeUnits: RangeUnits;
  airports: AirportDetails[];
  maxUserLimit: number;
  platformFeatures: VendorPlatformFeatureFlag[];
  primaryContactId: string;
  readonly created: number;
  readonly modified: number;

  hasFeature(platformFeature: VendorPlatformFeature): boolean {
    return (
      this.platformFeatures.find(
        featureFlag => featureFlag.featureName === platformFeature
      ) !== undefined
    );
  }
}
