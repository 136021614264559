import { Component } from '@angular/core';
import { AerialApp, getAerialAppByHostname } from 'projects/ui/src/public_api';
import { environment } from 'environments/environment';

@Component({
  selector: 'auth-auth',
  templateUrl: './auth.template.html',
  styleUrls: ['./auth.styles.scss']
})
export class AuthComponent {
  environment = environment;

  get app(): AerialApp {
    return getAerialAppByHostname();
  }

  get isAgApp(): boolean {
    return this.app === AerialApp.Ag;
  }

  get isCatalogApp(): boolean {
    return this.app === AerialApp.Catalog;
  }

  get siteWelcomeTxt(): string {
    switch (this.app) {
      case AerialApp.Ag:
        return `Welcome to ${this.siteTitle}.`;
      case AerialApp.Catalog:
        return 'Welcome to Airbus DMS.';
      default:
        return 'Welcome to Airbus.';
    }
  }

  get siteShortTitle(): string {
    switch (this.app) {
      case AerialApp.Admin:
        return 'Airbus Admin';
      case AerialApp.Adp:
        return 'ADP'
      case AerialApp.Asp:
        return 'ASP';
      case AerialApp.Ag:
        return 'AgNeo';
      case AerialApp.Catalog:
        return 'Airbus DMS';
      default:
        return 'Airbus';
    }
  }

  get siteTitleArticle(): string {
    switch (this.app) {
      case AerialApp.Admin:
      case AerialApp.Adp:
      case AerialApp.Asp:
        return 'the ';
      default:
        return '';
    }
  }

  get siteTitle(): string {
    switch (this.app) {
      case AerialApp.Admin:
        return 'Airbus Admin Panel';
      case AerialApp.Adp:
        return 'Airbus Data Portal';
      case AerialApp.Asp:
        return 'Airbus Services Portal';
      case AerialApp.Ag:
        return 'AgNeo';
      case AerialApp.Catalog:
        return 'Airbus Data Management Solutions';
      default:
        return 'Airbus';
    }
  }

  get backgroundImage(): string {
    let backgroundImage = environment.CDN_ROOT;
    switch (this.app) {
      case AerialApp.Ag:
        backgroundImage += `/images/agBackground.jpg`;
        break;
      case AerialApp.Catalog:
        backgroundImage += `/images/catalogBackground.jpg`;
        break;
      default:
        backgroundImage += `/images/satbg.jpg`;
        break;
    }
    return `url(${backgroundImage})`;
  }
}
