import { Injectable } from '@angular/core';
import { BaseStateService } from 'utils/src/public_api';
import { Observable, pipe, iif } from 'rxjs';
import {
  map,
  distinctUntilChanged,
  share,
  tap,
  switchMap,
  filter,
  shareReplay
} from 'rxjs/operators';
import { AgBaseService } from 'api/src/lib/services/agBase.service';
import { AgBase } from 'api/src/lib/models/agBase.model';
import { HttpResponse } from '@angular/common/http';
import { PagedResponse, BaseParams } from 'api/src/public_api';
import { Params } from '@angular/router';

export interface BaseDataModel<T> {
  selected: string;
  cached: {
    [key: string]: T;
  };
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseDataService<
  T extends AgBase
> extends BaseStateService<BaseDataModel<T>> {
  constructor(
    protected defaultState: BaseDataModel<T>,
    private _apiService: AgBaseService<T>
  ) {
    super(defaultState);
  }

  get selected(): string {
    return this.getCurrentState().selected;
  }

  get selected$(): Observable<T> {
    return this.state$.pipe(
      map((s: BaseDataModel<T>) => {
        return s.cached[s.selected];
      }),
      distinctUntilChanged(),
      share()
    );
  }

  get all$(): Observable<T[]> {
    return this.state$.pipe(
      map((s: BaseDataModel<T>) => {
        return Object.values(s.cached);
      }),
      distinctUntilChanged(),
      share()
    );
  }

  private patchOperator = () =>
    pipe(
      map((response: HttpResponse<T>) => {
        return this._apiService.marshall(response.body);
      }),
      tap((item: T) => {
        this.patchState({
          cached: {
            ...this.getCurrentState().cached,
            [item.id]: item
          }
        });
      })
    );

  private patchListOperator = () =>
    pipe(
      map((response: PagedResponse<T>) => {
        return response.data;
      }),
      tap((items: T[]) => {
        this.patchState({
          cached: {
            // ...this.getCurrentState().cached, // uncomment this line to merge results
            ...items.reduce((acc: { [key: string]: T }, curr: T) => {
              return {
                ...acc,
                [curr.id]: curr
              };
            }, {})
          }
        });
      })
    );

  public $create(item: Partial<T>): Observable<T> {
    return this._apiService.create(item).pipe(this.patchOperator());
  }

  public $update(item: T): Observable<T> {
    return this._apiService.update(item).pipe(this.patchOperator());
  }

  public $patch(item: Partial<T>): Observable<T> {
    return this._apiService.patch(item.id, item).pipe(this.patchOperator());
  }

  public $delete(id: string) {
    return this._apiService.delete(id).pipe(
      tap(_ => {
        let { [id]: __, ...omit } = this.getCurrentState().cached;
        this.patchState({ cached: { ...omit } });
      })
    );
  }

  public $load(id: string): Observable<T> {
    return iif(
      () => this.getCurrentState().cached[id] === undefined,
      this._apiService.getById(id).pipe(this.patchOperator()),
      this.state$.pipe(
        map((s: BaseDataModel<T>) => {
          return s.cached[id];
        }),
        distinctUntilChanged(),
        share()
      )
    );
  }

  public loadEntityOpr(param: string) {
    return pipe(
      filter((params: Params) => {
        return params[param];
      }),
      switchMap((params: Params) => {
        return this._apiService.getById(params[param]);
      }),
      map((resp: HttpResponse<T>) => {
        return this._apiService.marshall(resp.body);
      }),
      shareReplay(1)
    );
  }

  public $search(baseParams?: BaseParams): Observable<T[]> {
    return this._apiService.list(baseParams).pipe(this.patchListOperator());
  }

  public $select(id: string): void {
    this.patchState({ selected: id });
  }

  public $clear(): void {
    this.setState(this.defaultState);
  }
}
