export enum RequirementType {
  Mission = 'MISSION',
  Task = 'MISSION_TASK',
  Process = 'PROCESSS',
  Semaphore = 'SEMAPHORE'
}

export enum RequirementStatus {
  AwaitingFulfillment = 'AWAITING_FULFILLMENT',
  Submitted = 'SUBMITTED',
  Failed = 'FAILED',
  Done = 'DONE'
}

export interface Requirement {
  id: string;
  created: number;
  modified: number;
  requirementId: string;
  type: string | RequirementType;
  status: string | RequirementStatus;
  upstreamRequirements: string[];
  referenceId: string | null;
}
