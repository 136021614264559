import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';
import range from 'lodash/range';

/**
 * PaginationOptions
 * @prop {boolean} [stretch=false]
 * @prop {boolean} [alwaysShow=true]
 * @prop {boolean} [showTotalPagesText=false]
 * @prop {boolean} [showPages=true]
 * @prop {boolean} [arrowStyle='chevron']
 * @prop {boolean} [showFirstLastCtrls=false]
 * @prop {boolean} [showFirstLastText=false]
 * @prop {string} [firstText='First Page']
 * @prop {string} [lastText='Last Page']
 * @prop {boolean} [showPrevNextText=false]
 * @prop {string} [prevText='Previous Page']
 * @prop {string} [nextText='Next Page']
 * @prop {number} [currentPage=0]
 * @prop {number} [totalPages=1]
 * @prop {number} [maxPages=5]
 */
export interface PaginationOptions {
  /**
   * @prop {boolean} [stretch=false]
   * The paginator should stretch to fill its parent container. If set to `false`,
   * the paginator will be aligned to the start of its parent container and fill
   * only the space needed to display its controls.
   */
  stretch?: boolean;

  /**
   * @prop {boolean} [alwaysShow=true]
   * The paginator should always appear in the view, even if there is only one
   * page. (Page controls will be visible but disabled.)
   */
  alwaysShow?: boolean;

  /**
   * @prop {boolean} [showTotalPagesText=false]
   * Show text that reads "Page `currentPage` of `totalPages`".
   */
  showTotalPagesText?: boolean;

  /**
   * @prop {boolean} [showPages=true]
   * Show a list of `maxPages` page numbers (e.g. 1 2 3 4 5).
   */
  showPages?: boolean;

  /**
   * @prop {string} [arrowStyle='chevron']
   * The type of arrow icon to use: `'chevron'` (>) or `'caret'` (▶︎).
   */
  arrowStyle?: string;

  /**
   * @prop {boolean} [showFirstLastCtrls=false]
   * Show links to first and last pages.
   */
  showFirstLastCtrls?: boolean;

  /**
   * @prop {boolean} [showFirstLastText=false]
   * Show `firstText` and `lastText` along with first page and last page icons.
   */
  showFirstLastText?: boolean;

  /**
   * @prop {string} [firstText='First Page']
   * The text to display to the right of the first page icon, if `showFirstLastText`
   * is set to `true`.
   */
  firstText?: string;

  /**
   * @prop {string} [lastText='Last Page']
   * The text to display to the left of the last page icon, if `showFirstLastText`
   * is set to `true`.
   */
  lastText?: string;

  /**
   * @prop {boolean} [showPrevNextText=false]
   * Show `prevText` and `nextText` along with previous page and next page icons.
   */
  showPrevNextText?: boolean;

  /**
   * @prop {string} [prevText='Previous Page']
   * The text to display to the right of the previous page icon, if
   * `showPrevNextText` is set to `true`.
   */
  prevText?: string;

  /**
   * @prop {string} [nextText='Next Page']
   * The text to display to the left of the next page icon, if `showPrevNextText`
   * is set to `true`.
   */
  nextText?: string;

  /**
   * @prop {number} [maxPages=5]
   * The max number of pages to show, if `showPages` is set to `true`. If an even
   * number is provided, it will be rounded up to the next odd number. If set
   * to `-1` then *all* page numbers will be displayed.
   */
  maxPages?: number;
}

@Component({
  selector: 'pagination',
  templateUrl: './pagination.template.html',
  styleUrls: ['./pagination.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnChanges {
  private _options: PaginationOptions = {};
  private _currentPage = 0;
  private _totalPages = 1;
  protected pageNumbers: number[];

  /**
   * User-supplied customization options for the paginator.
   * @see PaginationOptions
   */
  @Input()
  set options(options: PaginationOptions) {
    Object.keys(options).forEach(optKey => {
      this[optKey] = options[optKey];
    });
  }

  get options(): PaginationOptions {
    return this._options;
  }

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * The paginator should stretch to fill its parent container. If set to `false`,
   * the paginator will be aligned to the start of its parent container and fill
   * only the space needed to display its controls.
   * @prop {boolean} [stretch=false]
   */
  get stretch(): boolean {
    return this.options.stretch === undefined ? false : this.options.stretch;
  }

  set stretch(stretch: boolean) {
    this._options.stretch = stretch;
  }

  /**
   * The paginator should always appear in the view, even if there is only one page.
   * (Page controls will be visible but disabled.)
   * @prop {boolean} [alwaysShow=true]
   */
  get alwaysShow(): boolean {
    return this.options.alwaysShow === undefined
      ? true
      : this.options.alwaysShow;
  }

  set alwaysShow(alwaysShow: boolean) {
    this._options.alwaysShow = alwaysShow;
  }

  /**
   * Show text that reads "Page `currentPage` of `totalPages`".
   * @prop {boolean} [showTotalPagesText=false]
   */
  get showTotalPagesText(): boolean {
    return this.options.showTotalPagesText === undefined
      ? false
      : this.options.showTotalPagesText;
  }

  set showTotalPagesText(showTotalPagesText: boolean) {
    this._options.showTotalPagesText = showTotalPagesText;
  }

  /**
   * Show a list of `maxPages` page numbers (e.g. 1 2 3 4 5).
   * @prop {boolean} [showPages=true]
   */
  get showPages(): boolean {
    return this.options.showPages === undefined ? true : this.options.showPages;
  }

  set showPages(showPages: boolean) {
    this._options.showPages = showPages;
  }

  /**
   * The type of arrow icon to use: `'chevron'` (>) or `'caret'` (▶︎).
   * @prop {string} [arrowStyle='chevron']
   */
  get arrowStyle(): string {
    return this.options.arrowStyle === undefined
      ? 'chevron'
      : this.options.arrowStyle;
  }

  set arrowStyle(arrowStyle: string) {
    arrowStyle = arrowStyle.toLowerCase();
    if (arrowStyle === 'chevron' || arrowStyle === 'caret') {
      this._options.arrowStyle = arrowStyle;
    } else {
      this._options.arrowStyle = 'chevron';
    }
  }

  /**
   * Show links to first and last pages.
   * @prop {boolean} [showFirstLastCtrls=false]
   */
  get showFirstLastCtrls(): boolean {
    return this.options.showFirstLastCtrls === undefined
      ? false
      : this.options.showFirstLastCtrls;
  }

  set showFirstLastCtrls(showFirstLastCtrls: boolean) {
    this._options.showFirstLastCtrls = showFirstLastCtrls;
  }

  /**
   * Show `firstText` and `lastText` along with first page and last page icons.
   * @prop {boolean} [showFirstLastText=false]
   */
  get showFirstLastText(): boolean {
    return this.options.showFirstLastText === undefined
      ? false
      : this.options.showFirstLastText;
  }

  set showFirstLastText(showFirstLastText: boolean) {
    this._options.showFirstLastText = showFirstLastText;
  }

  /**
   * The text to display to the right of the first page icon, if `showFirstLastText`
   * is set to `true`.
   * @prop {string} [firstText="First Page"]
   */
  get firstText(): string {
    return this.options.firstText === undefined
      ? 'First Page'
      : this.options.firstText;
  }

  set firstText(firstText: string) {
    this._options.firstText = firstText;
  }

  /**
   * The text to display to the left of the last page icon, if `showFirstLastText`
   * is set to `true`.
   * @prop {string} [lastText="Last Page"]
   */
  get lastText(): string {
    return this.options.lastText === undefined
      ? 'Last Page'
      : this.options.lastText;
  }

  set lastText(lastText: string) {
    this._options.lastText = lastText;
  }

  /**
   * Show `prevText` and `nextText` along with previous page and next page icons.
   * @prop {boolean} [showPrevNextText=false]
   */
  get showPrevNextText(): boolean {
    return this.options.showPrevNextText === undefined
      ? false
      : this.options.showPrevNextText;
  }

  set showPrevNextText(showPrevNextText: boolean) {
    this._options.showPrevNextText = showPrevNextText;
  }

  /**
   * The text to display to the right of the previous page icon, if
   * `showPrevNextText` is set to `true`.
   * @prop {string} [prevText="Previous Page"]
   */
  get prevText(): string {
    return this.options.prevText === undefined
      ? 'Previous Page'
      : this.options.prevText;
  }

  set prevText(prevText: string) {
    this._options.prevText = prevText;
  }

  /**
   * The text to display to the left of the next page icon, if `showPrevNextText`
   * is set to `true`.
   * @prop {string} [nextText="Next Page"]
   */
  get nextText(): string {
    return this.options.nextText === undefined
      ? 'Next Page'
      : this.options.nextText;
  }

  set nextText(nextText: string) {
    this._options.nextText = nextText;
  }

  /**
   * The max number of pages to show, if `showPages` is set to `true`. If an even
   * number is provided, it will be rounded up to the next odd number. If set
   * to `-1` then *all* page numbers will be displayed.
   * @prop {number} [maxPages=5]
   */
  get maxPages(): number {
    return this.options.maxPages === undefined ? 5 : this.options.maxPages;
  }

  set maxPages(maxPages: number) {
    maxPages = toNumber(maxPages);
    if (isNaN(maxPages)) {
      this._options.maxPages = 5;
    } else if (maxPages !== -1 && maxPages < 1) {
      this._options.maxPages = 1;
    } else if (maxPages % 2 === 0) {
      // easier to enforce odd max pages b/c math is hard
      this._options.maxPages = maxPages + 1;
    } else {
      this._options.maxPages = maxPages;
    }
  }

  /**
   * The current page number (zero-based).
   * @prop {number} [currentPage=0]
   */
  @Input()
  set currentPage(currentPage: number) {
    currentPage = toNumber(currentPage);
    if (isNaN(currentPage) || currentPage < 0) {
      this._currentPage = 0;
    } else {
      this._currentPage = currentPage;
    }
    this.pageNumbers = this._getPageNumbers();
  }

  get currentPage(): number {
    return this._currentPage;
  }

  /**
   * The total number of pages.
   * @prop {number} [totalPages=1]
   */
  @Input()
  set totalPages(totalPages: number) {
    totalPages = toNumber(totalPages);
    if (isNaN(totalPages) || totalPages < 1) {
      this._totalPages = 1;
    } else {
      this._totalPages = totalPages;
    }
  }

  get totalPages(): number {
    return this._totalPages;
  }

  get show(): boolean {
    return this.alwaysShow || this.totalPages > 1;
  }

  get isFirstPage(): boolean {
    return this.currentPage === this.firstPage;
  }

  get showFirst(): boolean {
    return (
      (this.showFirstLastCtrls && this.alwaysShow) ||
      (this.showFirstLastCtrls && !this.isFirstPage)
    );
  }

  get firstPage(): number {
    return 0;
  }

  get firstPageTitleAttr(): string {
    return this.isFirstPage
      ? undefined
      : `Page ${this.pageLabel(this.firstPage)}`;
  }

  get firstIconClass(): string {
    return this.arrowStyle === 'caret'
      ? 'fa fa-fast-backward'
      : 'fa fa-angle-double-left';
  }

  get isLastPage(): boolean {
    return this.currentPage === this.lastPage;
  }

  get showLast(): boolean {
    return (
      (this.showFirstLastCtrls && this.alwaysShow) ||
      (this.showFirstLastCtrls && !this.isLastPage)
    );
  }

  get lastPage(): number {
    return this.totalPages - 1;
  }

  get lastPageTitleAttr(): string {
    return this.isLastPage
      ? undefined
      : `Page ${this.pageLabel(this.lastPage)}`;
  }

  get lastIconClass(): string {
    return this.arrowStyle === 'caret'
      ? 'fa fa-fast-forward'
      : 'fa fa-angle-double-right';
  }

  get hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  get showPrevious(): boolean {
    return this.alwaysShow || this.hasPreviousPage;
  }

  get previousPage(): number {
    return !this.hasPreviousPage ? -1 : this.currentPage - 1;
  }

  get previousPageTitleAttr(): string {
    return this.hasPreviousPage
      ? `Page ${this.pageLabel(this.previousPage)}`
      : undefined;
  }

  get previousIconClass(): string {
    if (this.showFirstLastCtrls) {
      return this.arrowStyle === 'caret'
        ? 'fa fa-step-backward'
        : 'fa fa-angle-left';
    } else {
      return this.arrowStyle === 'caret'
        ? 'icon-triangle_left'
        : 'icon-disclosure_left';
    }
  }

  get hasNextPage(): boolean {
    return this.currentPage + 1 < this.totalPages;
  }

  get showNext(): boolean {
    return this.alwaysShow || this.hasNextPage;
  }

  get nextPage(): number {
    return !this.hasNextPage ? -1 : this.currentPage + 1;
  }

  get nextPageTitleAttr(): string {
    return this.hasNextPage
      ? `Page ${this.pageLabel(this.nextPage)}`
      : undefined;
  }

  get nextIconClass(): string {
    if (this.showFirstLastCtrls) {
      return this.arrowStyle === 'caret'
        ? 'fa fa-step-forward'
        : 'fa fa-angle-right';
    } else {
      return this.arrowStyle === 'caret'
        ? 'icon-triangle_right'
        : 'icon-disclosure_right';
    }
  }

  get currentPageLabel(): string {
    return this.pageLabel(this.currentPage);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pageNumbers = this._getPageNumbers();
  }

  pageLabel(page: number) {
    return toString(page + 1);
  }

  goFirst() {
    this.goToPage(this.firstPage);
  }

  goLast() {
    this.goToPage(this.lastPage);
  }

  goPrevious() {
    if (this.previousPage === -1) {
      return;
    }
    this.goToPage(this.previousPage);
  }

  goNext() {
    if (this.nextPage === -1) {
      return;
    }
    this.goToPage(this.nextPage);
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }

  private _getPageNumbers(): number[] {
    // I'm sure there are way more clever ways of doing this...
    const leftLength = Math.ceil((this.maxPages - 1) / 2),
      rightLength = Math.floor((this.maxPages - 1) / 2);

    let nums: number[];

    if (this.maxPages === -1 || this.totalPages <= this.maxPages) {
      nums = range(0, this.totalPages);
    } else if (this.currentPage <= this.firstPage + leftLength) {
      nums = range(0, this.maxPages);
    } else if (this.currentPage >= this.lastPage - rightLength) {
      nums = range(this.totalPages - this.maxPages, this.totalPages);
    } else {
      const start = this.currentPage - leftLength,
        end = start + this.maxPages;
      nums = range(start, end);
    }
    return nums;
  }
}
