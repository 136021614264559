import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

@Injectable()
export class BrokerService extends BaseService {
  // NOTE: Broker keys are valid for 1 day,
  // setting the expiration to 22 hours just to
  // refresh it early in case.
  static readonly keyDuration = 7.92e7;

  protected endpointUrl: string;

  private _keyExpirationTime: number;
  private _apiKey$: Observable<string>;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.brokerRootUrl}`;
  }

  getApiKey(): Observable<string> {
    // if the apiKey Observable hasn't been created
    // or the key has expired, get a new key and
    // store the new Observavble
    if (this._apiKey$ == null || Date.now() > this._keyExpirationTime) {
      this._apiKey$ = this.httpClient
        .get(`${this.endpointUrl}/apiKey`, {
          responseType: 'text'
        })
        .pipe(
          tap(_key => {
            this._keyExpirationTime = Date.now() + BrokerService.keyDuration;
          }),
          shareReplay(1)
        );
    }
    return this._apiKey$;
  }
}
