import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { titleize } from 'projects/utils/src/public_api';
import { Sort } from '../sort/sort.interface';
import { TooltipPosition } from '../tooltips/tooltip.model';

export interface HeaderColumn {
  propName: string;
  propLabel?: string;
  sortable?: boolean;
}

@Component({
  selector: 'header-column',
  templateUrl: './header-column.template.html',
  styleUrls: ['./header-column.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderColumnComponent {
  @Input()
  set propName(propName: string) {
    this._column.propName = propName;
  }

  @Input()
  set propLabel(propLabel: string) {
    this._column.propLabel = propLabel;
  }

  @Input()
  set sortable(sortable: boolean) {
    this._column.sortable = sortable;
  }

  @Input()
  tipPlacement: TooltipPosition = 'right';

  @Input()
  set currentSort(currentSort: Sort) {
    this.sortBy = currentSort.by;
    this.sortOrder = currentSort.order as 'asc' | 'desc';
  }

  @Input()
  set column(column: HeaderColumn) {
    this._column = column;
  }

  @Output()
  sort = new EventEmitter<Sort>();

  get propName(): string {
    return this._column.propName;
  }

  get propLabel(): string {
    return this._column.propLabel
      ? this._column.propLabel
      : titleize(this.propName);
  }

  get sortable(): boolean {
    return this._column.sortable === undefined ? true : this._column.sortable;
  }

  get isActive(): boolean {
    return this.propName === this.sortBy;
  }

  get titleAttr(): string {
    const labels = {
      asc: 'Ascending',
      desc: 'Descending'
    };
    // When an active column is clicked, the sort order will be reversed, which
    // is why I'm reversing the text ahead of time
    const order = this.isActive
      ? this.sortOrder === 'asc'
        ? labels['desc']
        : labels['asc']
      : labels[this.sortOrder];
    return this.sortable ? `Sort by ${this.propLabel}, ${order}` : '';
  }

  protected sortBy: string;
  protected sortOrder: 'asc' | 'desc';

  /**
   * Watcher for Mouse Hovering/Clicking
   *
   * @remarks
   * If the column has just been clicked, the cursor is still hovering over the
   * column; therefore, the arrow is flipped via CSS, and flipped in the opposite
   * direction than what you expect. i.e., you just sorted ascending, but the
   * arrow is pointing downward instead of upward.
   *
   * Using `hoveringUnclicked`, the CSS flip-on-hover is disabled while `true`.
   * `hoveringUnclicked` is set to `true` when the column name is clicked, and
   * set to `false` when the mouse leaves the element. You can think of this as
   * a way of forcing a blur event.
   */
  protected hoveringUnclicked = false;

  private _column: HeaderColumn = { propName: undefined };

  setSort(): void {
    this.sort.emit({
      by: this.propName,
      order: this.sortOrder === 'asc' ? 'desc' : 'asc'
    });
  }

  @HostListener('mouseenter')
  private _onMouseEnter(): void {
    this.hoveringUnclicked = true;
  }

  @HostListener('mouseleave')
  private _onMouseLeave(): void {
    this.hoveringUnclicked = false;
  }
}
