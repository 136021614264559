import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl
} from '@angular/forms';
import { handleBooleanProperty } from '../../util/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'checkbox-control',
  template: `
    <!-- prettier-ignore -->
    <div class="checkbox-control {{ color }}" [class.alt-style]="altStyle">
      <aerial-checkbox
        class="{{ size }}"
        [size]="size"
        [color]="color"
        [checked]="checkboxCtrl.value"
        [disabled]="checkboxCtrl.disabled"
        [altStyle]="altStyle"
        (onCheck)="checkboxCtrl.setValue(true)"
        (onUncheck)="checkboxCtrl.setValue(false)">
      </aerial-checkbox>
      <label [class.disabled]="checkboxCtrl.disabled">
        <input type="checkbox" [formControl]="checkboxCtrl" class="hidden">
        <ng-content></ng-content>
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxControlComponent),
      multi: true
    }
  ]
})
export class CheckboxControlComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  size: 'small' | 'medium' | 'large' = 'small';

  @Input()
  color: 'dark' | 'light' | 'inverted' = 'dark';

  @Input()
  altStyle = false; // if true, the bgcolor of an unchecked checkbox will be white instead of grey

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = handleBooleanProperty(disabled);
  }

  @Input()
  set checked(checked: boolean) {
    this._checked = handleBooleanProperty(checked);
    if (
      this.checkboxCtrl instanceof FormControl &&
      this.checkboxCtrl.value !== this._checked
    ) {
      this.checkboxCtrl.setValue(this._checked);
    }
  }

  // TODO: @Input() value, @Input() checked

  @Output()
  change = new EventEmitter<any>();

  @Output()
  check = new EventEmitter<void>();

  @Output()
  uncheck = new EventEmitter<void>();

  protected checkboxCtrl: FormControl;
  private _disabled = false;
  private _checked = false;
  private _changesSub: Subscription;
  private _firstChange = true;

  constructor(private _cd: ChangeDetectorRef) {}

  protected onTouched = () => {};
  private _onChange = (_: any) => {};

  writeValue(value: any): void {
    this.checkboxCtrl.setValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.checkboxCtrl.disable();
    }
  }

  ngOnInit(): void {
    this.checkboxCtrl = new FormControl({
      value: this._checked,
      disabled: this._disabled
    });
    this._changesSub = this.checkboxCtrl.valueChanges.subscribe(value => {
      if (this._firstChange) {
        this._firstChange = false;
      } else {
        this._onChange(value);
        this.change.emit(value);
        this[value ? 'check' : 'uncheck'].emit();
        this.onTouched();
        this._cd.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this._changesSub.unsubscribe();
  }
}
