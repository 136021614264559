import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Customer, PlatformFeatureFlag } from '../models/customer.model';
import { DataProductSubscription } from '../models/dataProductSubscription.model';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map, flatMap, mergeMap, combineAll } from 'rxjs/operators';

@Injectable()
export class CustomerService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.customersUrl}`;
  }

  protected mapJsonToCollection(respBody: Customer[]): Customer[] {
    return respBody.map(json => new Customer(json));
  }

  getCustomers(baseParams?: BaseParams): Promise<PagedResponse<Customer>> {
    return this.getWithPagedResponse<Customer>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  searchCustomers(
    searchTerm: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Customer>> {
    const params = { 'search': `name=="*${searchTerm}*"` };

    return this.getWithPagedResponse<Customer>(
      `${this.endpointUrl}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  //TODO: this is temp until a proper endpoint exists
  getCustomersForDataProduct(dataProductId: string): Promise<Customer[]> {
    return this.httpClient
      .get<DataProductSubscription[]>(
        this.rootUrl +
          '/dataProducts/subscriptions/dataProduct/' +
          dataProductId
      )
      .pipe(
        flatMap(subs => subs),
        mergeMap(sub =>
          this.httpClient.get<Customer>(`${this.endpointUrl}/${sub.customerId}`)
        ),
        map(resp => [new Customer(resp)]),
        combineAll()
      )
      .toPromise() as Promise<Customer[]>;
  }

  getCustomer(customerId: string): Promise<Customer> {
    return this.httpClient
      .get<Customer>(`${this.endpointUrl}/${customerId}`)
      .pipe(map(resp => new Customer(resp)))
      .toPromise();
  }

  createCustomer(customer: Customer): Promise<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, customer).toPromise();
  }

  updateCustomer(customer: Customer): Promise<HttpResponse<Customer>> {
    return this.putWithResponse<Customer>(
      `${this.endpointUrl}/${customer.id}`,
      customer
    ).toPromise();
  }

  patchCustomer(
    id: string,
    body: Customer | object
  ): Promise<HttpResponse<Customer>> {
    return this.patchWithResponse<Customer>(
      `${this.endpointUrl}/${id}`,
      body
    ).toPromise();
  }

  updateCustomerPlatformFeatures(
    customerId: string, 
    features: PlatformFeatureFlag[]
  ): Promise<any> {
    return this.createWithResponse(
      `${this.endpointUrl}/${customerId}/platformFeatures`, 
      features
    ).toPromise();
  }

  deleteCustomer(customerId: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${customerId}`
    ).toPromise();
  }
}
