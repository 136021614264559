import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Aoi } from 'projects/api/src/public_api';

import { Feature } from 'ol';

@Component({
  selector: 'feature-display',
  templateUrl: './feature-display.template.html',
  styleUrls: ['./feature-display.styles.scss']
})
export class FeatureDisplayComponent implements OnChanges {
  @Input() feature: Feature;
  @Input() classes: ''; // allow additional styles
  @Input() units: 'sqrMiles' | 'acres' = 'sqrMiles';
  protected aoi: Aoi; // used because of the helper methods on the AOI model

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.feature &&
      changes.feature.currentValue !== changes.feature.previousValue
    ) {
      if (this.feature) {
        this.aoi = new Aoi({ feature: this.feature });
      } else {
        this.aoi = null;
      }
    }
  }
}
