import { BaseModel } from './base.model';
import { AnalyticSpecification } from '../interfaces/analytic-specification.interface';

export enum AnalyticRunStatus {
  Pending = 'PENDING',
  Ready = 'READY',
  Failed = 'FAILED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export class AnalyticRun extends BaseModel {
  id: string;
  name: string;
  projectId: string;
  status: AnalyticRunStatus;
  inputDataProductIds: string[];
  outputDataProductId: string;
  outputAttachmentId: string;
  presets: { [key: string]: any };
  analyticSpecification: AnalyticSpecification;
  aoi: object;
  created: number;
  modified: number;
  completed: number;
}
