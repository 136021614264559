// schema here: https://github.com/AirbusAerial/interstellar-specifications/blob/master/v1/blocks/schema.json
export interface AnalyticSpecification {
  name: string;
  type: 'data' | 'processing';
  tags: string[];
  display_name: string;
  description: string;
  parameters: AnalyticParameters;
  input_capabilities: InputCapabilities;
}

// This will probably change a lot over time
export interface InputCapabilities {
  [input: string]: any;
}

export interface AnalyticParameters {
  required: string[];
  properties: { [propName: string]: AnalyticProperty };
}

export enum AnalyticPropertyType {
  Number = 'number',
  Integer = 'integer',
  String = 'string',
  Boolean = 'boolean'
}

export interface AnalyticProperty {
  type: AnalyticPropertyType;
  title: string;
  default: string | number | boolean;
  maximum: number;
  minimum: number;
  examples: string[] | number[] | boolean[];
}
