import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessToken, AccessTokenResponse } from '../models/access-token.model';
import { BaseParams } from '../interfaces/baseParams.interface';
import { BaseService } from './base.service';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.authUrl}/accessTokens`;
  }

  protected mapJsonToCollection(respBody: AccessToken[]): AccessToken[] {
    return respBody.map(json => new AccessToken(json));
  }

  generateAccessToken(): Observable<AccessTokenResponse> {
    return this.httpClient.post<AccessTokenResponse>(this.endpointUrl, {});
  }

  revokeAccessToken(tokenId: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${tokenId}`);
  }

  getAccessTokens(
    baseParams?: BaseParams
  ): Observable<PagedResponse<AccessToken>> {
    return this.getWithPagedResponse<AccessToken>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getAccessTokensForCustomer(
    customerId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<AccessToken>> {
    return this.getWithPagedResponse<AccessToken>(
      `${this.endpointUrl}/customer/${customerId}`,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getAccessTokensForUser(
    userId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<AccessToken>> {
    return this.getWithPagedResponse<AccessToken>(
      `${this.endpointUrl}/user/${userId}`,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getAccessTokensForMe(
    baseParams?: BaseParams
  ): Observable<PagedResponse<AccessToken>> {
    return this.getWithPagedResponse<AccessToken>(
      `${this.endpointUrl}/mine`,
      this.mapJsonToCollection,
      baseParams
    );
  }
}
