import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'stepper-control',
  templateUrl: './stepper-control.template.html',
  styleUrls: ['./stepper-control.styles.scss']
})

export class StepperControlComponent implements OnInit {
  @Input() numSteps = 0;
  @Input() lockNext = false;
  @Input() blockBack = false;
  @Output() currentStep: EventEmitter<number>;
  @Output() stepperCompleted: EventEmitter<boolean>;

  stepNum: number;

  constructor() {
    this.currentStep = new EventEmitter<1>();
    this.stepperCompleted = new EventEmitter<true>();
  }

  ngOnInit() {
    this.stepNum = 1;
    this.currentStep.emit(this.stepNum);
  }

  prevStep() {
    this.currentStep.emit(--this.stepNum);
  }

  nextStep() {
    if (this.stepNum === this.numSteps) {
      this.completed();
      return;
    }
    this.currentStep.emit(++this.stepNum);
  }

  completed() {
    this.stepperCompleted.emit(true);
  }
}