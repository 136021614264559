import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'projects/auth/src/public_api';
import { ToasterService } from 'projects/ui/src/public_api';
import { ContextService } from './shared/services/context.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {
  constructor(
    private _context: ContextService,
    private _authService: AuthService,
    private _toaster: ToasterService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest(this._context.user$, this._context.customer$).pipe(
      map(([user, customer]) => {
        if (
          user.isAerialAdmin ||
          (user.customerParentId && customer.isAgCustomer)
        ) {
          return true;
        } else {
          this._authService.logOut();
          this._toaster.alert('error', 'Invalid credentials.');
          return false;
        }
      })
    );
  }
}
