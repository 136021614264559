export interface FlightPlan {
  flightPlanId: string;
  missionId: string;
  flightDeparture?: number;
  flightDuration?: number;
  altitudeMax?: number;
}

export enum BriefingState {
  Ready = 'ready',
  Error = 'error',
  Processing = 'processing',
  Success = 'success',
  Fail = 'fail',
  Aborted = 'aborted'
}

export enum BriefingAuthOption {
  NotRequired = 'notRequired',
  NotAvailable = 'notAvailable',
  Auto = 'autoAuthorization',
  FurtherCoordination = 'furtherCoordination'
}

export interface BriefingNotice {
  noticeId?: number;
  message: string;
  regulation: string;
  type: 'error' | 'warning';
}

export interface BriefingDetail {
  // aka DataSuccessModel
  success: boolean;
  data: object;
  notices?: [number, string][];
  geo?: object;
  // only found in uasFacilities object
  further?: boolean;
  laancEnabled?: boolean;
  required?: boolean;
}

export interface FlightPlanBriefing {
  flightPlanId: string;
  state: string | BriefingState;
  authOption: string | BriefingAuthOption | null;
  notices: BriefingNotice[];
  briefing: {
    briefingId: string;
    flightPlanId: string;
    ninetyDay?: BriefingDetail;
    airports?: BriefingDetail;
    daylight?: BriefingDetail;
    under400ft?: BriefingDetail;
    dcSfra?: BriefingDetail;
    vlos?: BriefingDetail;
    tfr?: BriefingDetail;
    sua?: BriefingDetail;
    sportingEvents?: BriefingDetail;
    uasFacilities?: BriefingDetail;
    flightMetadata?: BriefingDetail;
    duplicates?: BriefingDetail;
    airspace?: BriefingDetail;
    concurrency?: BriefingDetail;
    groundspeed?: BriefingDetail;
    nsufr?: BriefingDetail;
    weight?: BriefingDetail;
  };
  modified: number;
}
