import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class FilterService {
  // Setup BehaviorSubject It's basically the Observer AND the Observable (trippy)
  // This is a very basic setup, if you wanna kow more...
  // https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/subjects/behaviorsubject.md
  private _filterText = new BehaviorSubject<string>('');
  observablefilterText = this._filterText.asObservable();
  filterText$ = this.observablefilterText; // alias to `observablefilterText`

  updateText(text: string) {
    this._filterText.next(text);
  }
}
