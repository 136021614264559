import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgBaseService } from './agBase.service';
import { Farm } from '../models/farm.model';

@Injectable()
export class FarmService extends AgBaseService<Farm> {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super('/ag/farms', httpClient);
  }

  marshall(json: object): Farm {
    return new Farm(json);
  }
}
