import { Injectable } from '@angular/core';

import { MapControlsService } from './mapControls.service';
import { MapInteractionsService } from './mapInteractions.service';
import { MapLayersService } from './mapLayers.service';
import { MapFeaturesService } from './mapFeatures.service';

import { MapLayer } from './mapLayer.model';

import { View } from 'ol';
import { easeOut } from 'ol/easing';
import { toLonLat } from 'ol/proj';
import { Map } from 'ol';
import Layer from 'ol/layer/Layer';
import { Extent } from 'ol/extent';
import { EventsKey } from 'ol/events';

import extend from 'lodash/extend';
import LayerGroup from 'ol/layer/Group';
import { defaults as interactionDefaults } from 'ol/interaction';
import { defaults as controlDefaults } from 'ol/control';
import { unByKey } from 'ol/Observable';

import { Subscription } from 'rxjs';

/**
  Interface for dealing with the main Map object.
*/
@Injectable({
  providedIn: 'root'
})
export class MapService {
  /**
   * Main openlayers map object.
   */
  public map: Map;

  /**
   * Layer group to put all user-added layers into
   */
  private _userLayerGroup: LayerGroup;

  /**
   * Array of callbacks that other components can register to be called when the map is initialized.
   */
  private _initCallbacks: Function[] = [];

  /**
   * Whether the service's initMap method has been called yet.
   */
  private _isInit = false;

  private _layerSub: Subscription;

  constructor(
    private _interactionsService: MapInteractionsService,
    private _layersService: MapLayersService,
    private _featuresService: MapFeaturesService
  ) {}

  /**
    Initializes the main map object. Also subscribes to the
    [MapLayersService.onLayerChange]{@link MapLayersService#onLayerChange}
    event to keep map layers in sync.

    @param target The id of the HTML element
    @param center The center of the new map
    @param zoom the zoom level of the new map
    @param options An object with options to configure the map
    @param options.disableOneAtlas Pass true to not add One Atlas layers
    @param options.mouseWheelZoom Pass false to remove mouse/trackpad scroll zoom
  */
  initMap(
    target: string,
    center: [number, number] = [-11000000, 4600000],
    zoom = 4,
    options?: any
  ) {
    const scrollMouseWheel = options ? options.mouseWheelZoom : true; // true is default

    if (this._layerSub) {
      if (this._userLayerGroup) {
        this._userLayerGroup.getLayers().clear();
      }
      this._layerSub.unsubscribe();
    }

    // remove layers from old map to avoid memory leaks and
    // weird things.
    if (this.map) {
      this.map.setTarget(undefined);
      this.map.getControls().clear();
      this.map.getInteractions().clear();
      this.map.getLayerGroup().getLayers().clear();
    }

    // layer group to keep all workspace layers
    this._userLayerGroup = new LayerGroup({
      zIndex: 2
    });

    this.map = new Map({
      layers: [this._userLayerGroup],
      target: target,
      interactions: interactionDefaults({
        mouseWheelZoom: scrollMouseWheel
      }),
      controls: controlDefaults(),
      view: new View({
        center,
        // https://github.com/openlayers/openlayers/blob/master/changelog/upgrade-notes.md#the-view-is-constrained-so-only-one-world-is-visible
        // extent: [-20026376.39, -20048966.1, 20026376.39, 20048966.1], // https://epsg.io/3857
        zoom
        // minZoom: 3
      })
    });

    this._interactionsService.init(this.map);

    this._layersService.init(options && options.disableOneAtlas).then(() => {
      /// add overlay layers
      this.map.addLayer(this._layersService.streetsLayer);
      this.map.addLayer(this._layersService.annotationsLayer);
      this.map.addLayer(this._layersService.featuresLayer);

      // add basemap layers
      this._layersService.baseLayers.forEach((layer: Layer) => {
        this.map.addLayer(layer);
      });

      // subscribe to layer changes
      this._layerSub = this._layersService.onLayerChange.subscribe(
        (layers: MapLayer[]) => {
          // TODO: need something smarter than clear and re-add all layers
          let mapLayers = this._userLayerGroup.getLayers();
          mapLayers.clear();
          layers
            .map(layer => layer.olLayer)
            .forEach(layer => {
              mapLayers.push(layer);
            });
        }
      );

      // run registered callbacks
      this._initCallbacks.forEach(callback => callback());
      this._isInit = true;
      this._initCallbacks = [];
    });
  }

  /**
    Allows for callback functions to be registered to then be called
    after the map object is initialized.
    ```typescript
    this._mapService.onInit(() => {
      // Do stuff here only after the map is created
    })
    ```
  */
  onInit(callback: Function) {
    if (!this._isInit) this._initCallbacks.push(callback);
    else callback();
  }

  /**
    Used to provide the map with the HTML element it needs.
    @param target CSS selector for the target HTML element
  */
  setMapTarget(target: string) {
    this.map.setTarget(target);
  }

  /**
    Gets the bounding extent for the map's viewport
    @param inLonLat Set to true if you want the extent returned in EPSG:4326
    @returns The bounding extent of the map's viewport.
  */
  getMapExtent(inLonLat?: boolean): Extent {
    let mapSize = this.map.getSize();
    let extent = this.map.getView().calculateExtent(mapSize);

    if (inLonLat) {
      return toLonLat([extent[0], extent[1]]).concat(
        toLonLat([extent[2], extent[3]])
      ) as Extent;
    } else {
      return extent;
    }
  }

  /**
    Gets the map that was initialized
    @returns The map object for use in other services.
  */
  getMap(): Map {
    return this.map;
  }

  /**
   * Returns the diagonal length of the extent in pixel size on the map
   * @param extent the Extent to measure
   * @return the length of the extent's diagonal in pixels
   */
  getPixelSizeOfExtent(extent: Extent): number {
    let corner1 = this.map.getPixelFromCoordinate([extent[0], extent[1]]);
    let corner2 = this.map.getPixelFromCoordinate([extent[2], extent[3]]);

    let width = corner1[0] - corner2[0];
    let height = corner1[1] - corner1[1];

    let size = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));

    return size;
  }

  /**
    Removes an ol event by its key that is returned when registering the listener.
  */
  removeListener(eventKey: EventsKey | Array<EventsKey>) {
    unByKey(eventKey);
  }

  /**
    Zooms the map to a feature identified by id.
    @param featureId id of the Feature object.
  */
  zoomToFeature(featureId: string) {
    let feature = this._featuresService.getFeature(featureId);

    this.animateToBounds(feature.getGeometry().getExtent());
  }

  /**
    Animates the map to a bounding box
    @param bounds [minx, miny, maxx, maxy]
  */
  animateToBounds(
    bounds: [number, number, number, number],
    padding?: [number, number, number, number],
    duration: number = 500
  ) {
    this.map.getView().fit(bounds, {
      padding,
      duration,
      easing: easeOut,
      maxZoom: 20
    });
  }

  /**
    Basic view animating function to allow for more custom options passed
    directly to the `View.animate` function.
    See [View.animate]{@link https://openlayers.org/en/latest/apidoc/View.html#animate}
    @param options options to pass to the `.animate` function
  */
  animateTo(options: object) {
    this.map.getView().animate(extend(options, { duration: 500 }));
  }
}
