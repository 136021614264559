import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

export interface ViewSwitcherOptions {
  viewOptions?: Array<'card' | 'list' | 'tile'>;
  activeView?: 'card' | 'list' | 'tile';
  hideLabel?: boolean;
  size?: 'small' | 'medium' | 'large';
  theme?: 'dark' | 'neutral'; // TODO: add 'light' color for dark mode
  cardViewName?: string;
  listViewName?: string;
  tileViewName?: string;
}

@Component({
  selector: 'view-switcher',
  templateUrl: './view-switcher.template.html',
  styleUrls: ['./view-switcher.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewSwitcherComponent {
  @Input()
  set viewOptions(viewOptions: Array<'card' | 'list' | 'tile'>) {
    this._options.viewOptions = viewOptions;
  }

  @Input()
  set activeView(activeView: 'card' | 'list' | 'tile') {
    this._options.activeView = activeView;
  }

  @Input()
  set hideLabel(hideLabel: boolean) {
    this._options.hideLabel = hideLabel;
  }

  @Input()
  set size(size: 'small' | 'medium' | 'large') {
    this._options.size = size;
  }

  @Input() // 'dark' is blue, 'neutral' is grey
  set theme(theme: 'dark' | 'neutral') {
    this._options.theme = theme;
  }

  @Input()
  set cardViewName(cardViewName: string) {
    this._options.cardViewName = cardViewName;
  }

  @Input()
  set listViewName(listViewName: string) {
    this._options.listViewName = listViewName;
  }

  @Input()
  set tileViewName(tileViewName: string) {
    this._options.tileViewName = tileViewName;
  }

  @Input()
  set options(options: ViewSwitcherOptions) {
    this._options = options;
  }

  @Output()
  changeView = new EventEmitter<string>();

  get viewOptions(): Array<'card' | 'list' | 'tile'> {
    return this._options.viewOptions
      ? this._options.viewOptions
      : ['card', 'list', 'tile'];
  }

  get activeView(): 'card' | 'list' | 'tile' {
    return this._options.activeView;
  }

  get hideLabel(): boolean {
    return this._options.hideLabel;
  }

  get size(): 'small' | 'medium' | 'large' {
    return this._options.size ? this._options.size : 'large';
  }

  get theme(): 'dark' | 'neutral' {
    return this._options.theme ? this._options.theme : 'dark';
  }

  get cardViewName(): string {
    return this._options.cardViewName ? this._options.cardViewName : 'Card';
  }

  get listViewName(): string {
    return this._options.listViewName ? this._options.listViewName : 'List';
  }

  get tileViewName(): string {
    return this._options.tileViewName ? this._options.tileViewName : 'Tile';
  }

  get showCardOption(): boolean {
    return this.viewOptions.includes('card');
  }

  get showListOption(): boolean {
    return this.viewOptions.includes('list');
  }

  get showTileOption(): boolean {
    return this.viewOptions.includes('tile');
  }

  get isCardViewActive(): boolean {
    return this.activeView === 'card';
  }

  get isListViewActive(): boolean {
    return this.activeView === 'list';
  }

  get isTileViewActive(): boolean {
    return this.activeView === 'tile';
  }

  private _options: ViewSwitcherOptions = {};
}
