import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Badge } from '../../badges/badge.model';

@Component({
  selector: 'combo-badge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <span [ngClass]="classes">
      <span class="badge-text combo-text">
        <ng-content></ng-content>
      </span>
      <i class="badge-icon combo-icon {{ iconClass }}"></i>
    </span>
  `,
  styleUrls: ['./combo-badge.styles.scss']
})
export class ComboBadgeComponent extends Badge {
  @Input()
  iconClass: string;

  @Input()
  iconPosition: 'left' | 'right' = 'left';

  otherClasses = [
    'combo-badge',
    'combo-element',
    `align-icon-${this.iconPosition}`,
    'with-separator'
  ];
}
