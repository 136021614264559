import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import {
  FlightPlan,
  FlightPlanBriefing
} from '../interfaces/flight-plan.interface';
import {
  UTMAuthorizationUrl,
  UTMAuthorization
} from '../interfaces/utm-authorization.interface';

@Injectable({
  providedIn: 'root'
})
export class AirbusUTMService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/integrations/airbusutm`;
  }

  createFlightPlan(flightPlan: FlightPlan): Observable<FlightPlan> {
    return this.httpClient.post<FlightPlan>(`${this.endpointUrl}/flightPlans`, {
      missionId: flightPlan.missionId,
      flightDeparture: flightPlan.flightDeparture,
      flightDuration: flightPlan.flightDuration,
      altitudeMax: flightPlan.altitudeMax
    });
  }

  getFlightPlan(flightPlanId: string): Observable<FlightPlan> {
    return this.httpClient.get<FlightPlan>(
      `${this.endpointUrl}/flightPlans/${flightPlanId}`
    );
  }

  getFlightPlanForMission(missionId: string): Observable<FlightPlan> {
    return this.httpClient.get<FlightPlan>(
      `${this.endpointUrl}/flightPlans/mission/${missionId}`
    );
  }

  updateFlightPlan(
    flightPlan: FlightPlan
  ): Observable<HttpResponse<FlightPlan>> {
    return this.putWithResponse<FlightPlan>(
      `${this.endpointUrl}/flightPlans/${flightPlan.flightPlanId}`,
      flightPlan
    );
  }

  deleteFlightPlan(flightPlanId: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/flightPlans/${flightPlanId}`
    );
  }

  cancelFlightPlan(flightPlanId: string): Observable<HttpResponse<object>> {
    return this.deleteFlightPlan(flightPlanId);
  }

  getFlightPlanBriefing(flightPlanId: string): Observable<FlightPlanBriefing> {
    return this.httpClient.get<FlightPlanBriefing>(
      `${this.endpointUrl}/flightPlans/${flightPlanId}/briefing`
    );
  }

  updateFlightPlanBriefing(
    flightPlanId: string
  ): Observable<HttpResponse<FlightPlanBriefing>> {
    return this.putWithResponse<FlightPlanBriefing>(
      `${this.endpointUrl}/flightPlans/${flightPlanId}/briefing`,
      {}
    );
  }

  createAuthorizationUrl(
    flightPlanId: string,
    redirectUrl: string,
    appName = 'Aerial Services Portal'
  ): Observable<UTMAuthorizationUrl> {
    return this.httpClient.post<UTMAuthorizationUrl>(
      `${this.endpointUrl}/flightPlans/${flightPlanId}/authorizationUrl`,
      { appName, redirectUrl }
    );
  }

  getAuthorizationData(flightPlanId: string): Observable<UTMAuthorization> {
    return this.httpClient.get<UTMAuthorization>(
      `${this.endpointUrl}/flightPlans/${flightPlanId}/authorization`
    );
  }
}
