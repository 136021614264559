import { NgModule } from '@angular/core';
import { PlantingSubscriptionsComponent } from './explorer/plantings/planting-subscriptions/planting-subscriptions.component';
import { UtilsModule } from 'utils/src/public_api';
import { SharedModule } from './shared/shared.module';
import { UIModule } from 'projects/ui/src/public_api';


@NgModule({
  imports: [
    UIModule,
    UtilsModule,
    SharedModule
  ],
  declarations: [],
  entryComponents: [
    PlantingSubscriptionsComponent
  ]
})
export class DialogModule { }
