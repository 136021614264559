import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  AgSubscription,
  AgSubscriptionTypeService,
  AgSubscriptionType,
  PagedResponse,
  Planting
} from 'api/src/public_api';
import { map, take, switchMap } from 'rxjs/operators';
import { Observable, Subscription, combineLatest } from 'rxjs';

export type SubscriptionTypeNames = 'Core' | 'Verde' | 'PCA' | 'Clipping';

export type SubscriptionMap = {
  [key in SubscriptionTypeNames]?: Partial<AgSubscription>;
};

@Component({
  selector: 'planting-subscriptions',
  templateUrl: './planting-subscriptions.template.html',
  styleUrls: ['./planting-subscriptions.styles.scss']
})
export class PlantingSubscriptionsComponent implements OnInit {
  subscriptionMap: SubscriptionMap = {
    Core: {},
    Verde: {},
    PCA: {},
    Clipping: {}
  };
  availableSubscriptions = [];

  @Output() subChange = new EventEmitter<Partial<AgSubscription>[]>();
  showSubmitBtn = false;
  submitButtonDisabled = false;

  subscriptions: AgSubscription[] = [];
  planting: Partial<Planting> = { id: 'temp' };
  successCallback: (subscriptions: Partial<AgSubscription>[]) => void;
  errorCallback?: Function;
  close: Function;
  planting$: Observable<Planting>;
  plantingSub: Subscription;
  agSubscription: AgSubscription[] = [];

  constructor(private _agSubscriptionTypeService: AgSubscriptionTypeService) {}

  get newSubscriptions(): Partial<AgSubscription>[] {
    return Object.values(this.subscriptionMap)
      .filter((sub: Partial<AgSubscription>) => {
        return (
          !sub.id &&
          sub.checked &&
          (sub.subscriptionTypeId ===
          this.subscriptionMap.Core.subscriptionTypeId
            ? sub.resolution
              ? true
              : false
            : true)
        );
      })
      .map((sub: Partial<AgSubscription>) => {
        const { checked, disabled, ...ret } = sub;
        return ret;
      });
  }

  ngOnInit() {
    Object.keys(this.subscriptionMap).forEach((key: string) => {
      this.subscriptionMap[key] = {
        ...this.subscriptionMap[key],
        plantingId: this.planting.id,
        checked: false,
        disabled: false
      };
    });

    this.subscriptions.forEach((sub: AgSubscription) => {
      this.subscriptionMap[sub.subscriptionTypeName] = {
        ...this.subscriptionMap[sub.subscriptionTypeName],
        ...sub,
        checked: true,
        disabled: true
      };
    });

    this.validate();

    this._agSubscriptionTypeService
      .list()
      .pipe(
        take(1),
        map((resp: PagedResponse<AgSubscriptionType>) => {
          return resp.data;
        })
      )
      .subscribe((types: AgSubscriptionType[]) => {
        types.forEach((type: AgSubscriptionType) => {
          // keep here as reminder- if this fails a subscription type was changed on backend
          // console.log(type);
          this.subscriptionMap[type.name].subscriptionTypeId = type.id;
        });
      });

    // I requested getting this info by cropType to avoid case when there is no plantingId
    if (this.planting.id !== 'temp') {
      this._agSubscriptionTypeService
        .getByPlantingId(this.planting.id)
        .pipe(take(1))
        .subscribe(resp => {
          resp.data
            ? resp.data.forEach(type => {
                this.availableSubscriptions[type.name] = true;
              })
            : (this.availableSubscriptions['none'] = true);
        });
    }
  }

  setResolution(resolution: string) {
    this.subscriptionMap.Core.resolution = parseFloat(resolution);
    this.validate();
  }

  setPCAResolution(resolution: string) {
    this.subscriptionMap.PCA.resolution = parseFloat(resolution);
    this.validate();
  }

  setRowSpacing(spacing: string) {
    this.subscriptionMap.PCA.rowSpacing = parseFloat(spacing);
    this.validate();
  }

  setPlantSpacing(spacing: string) {
    this.subscriptionMap.PCA.plantSpacing = parseFloat(spacing);
    this.validate();
  }

  validate() {
    if (this.subscriptionMap.PCA.checked) {
      this.subscriptionMap.Core.disabled = true;
      if (!this.subscriptionMap.PCA.resolution) {
        this.submitButtonDisabled = true;
      } else {
        this.submitButtonDisabled = false;
      }
    } else {
      this.subscriptionMap.Core.disabled = !!this.subscriptionMap.Core.id;
    }

    if (this.subscriptionMap.Core.checked) {
      this.subscriptionMap.PCA.disabled = true;
      if (!this.subscriptionMap.Core.resolution) {
        this.submitButtonDisabled = true;
      } else {
        this.submitButtonDisabled = false;
      }
    } else {
      this.subscriptionMap.PCA.disabled = !!this.subscriptionMap.PCA.id;
    }
    this.subChange.emit(this.newSubscriptions);
  }

  select(type: string) {
    this.subscriptionMap[type].checked = !this.subscriptionMap[type].checked;
    this.validate();
  }

  onSubmit() {
    this.successCallback(this.newSubscriptions);
    this.close();
  }
}
