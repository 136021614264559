import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './baseSearch.service';
import {
  PagedSearchResponse,
  SearchQuery
} from '../../interfaces/search/base-search.interface';
import {
  FulfillmentSearch,
  FulfillmentSearchParams
} from '../../interfaces/search/fulfillmentSearch.interface';
import { BaseParams } from '../../interfaces/baseParams.interface';
import { Observable } from 'rxjs';

const fulfillmentSortMapping = {
  Customer: 'order.customer.name.keyword',
  Vendor: 'steps.missions.data.vendor.name.keyword',
  'Acquisition Date': 'order.acquisitionDate',
  'Due Date': 'order.deadline',
  'Completion Date': 'order.completionDate',
  'Order Type': 'order.serviceType.keyword',
  Status: 'fulfillmentStatus.keyword',
  'Order Name': 'order.name.keyword'
};

@Injectable({
  providedIn: 'root'
})
export class FulfillmentSearchService extends BaseSearchService {
  static sortOptions = Object.keys(fulfillmentSortMapping);
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/search/fulfillment/_search`;
  }

  searchFulfillments(
    params: FulfillmentSearchParams = {},
    baseParams: BaseParams = { sortBy: 'Acquisition Date', sortOrder: 'desc' }
  ): Observable<PagedSearchResponse<FulfillmentSearch>> {
    const {
      deadlineDates,
      acquisitionDates,
      completionDates,
      searchTerm,
      orderTypes,
      statuses,
      vendorNames,
      customerNames
    } = params;

    const query: SearchQuery = {
      bool: {
        filter: [],
        must: []
      }
    };

    if (deadlineDates) {
      query.bool.filter.push({
        range: {
          'order.deadline': {
            gte: `${deadlineDates[0]}`,
            lte: `${deadlineDates[1] + BaseSearchService.ONE_DAY}`
          }
        }
      });
    }

    if (acquisitionDates) {
      query.bool.filter.push({
        range: {
          'order.acquisitionDate': {
            gte: `${acquisitionDates[0]}`,
            lte: `${acquisitionDates[1] + BaseSearchService.ONE_DAY}`
          }
        }
      });
    }

    if (completionDates) {
      query.bool.filter.push({
        range: {
          'order.completionDate': {
            gte: `${completionDates[0]}`,
            lte: `${completionDates[1] + BaseSearchService.ONE_DAY}`
          }
        }
      });
    }

    if (searchTerm) {
      query.bool.filter.push({
        multi_match: {
          query: searchTerm,
          type: 'phrase_prefix',
          fields: ['id', 'order.id', 'order.name', 'missions.data.workOrderId']
        }
      });
    }

    if (statuses && statuses.length > 0) {
      query.bool.must.push(
        this.buildFilterListQuery(statuses, 'fulfillmentStatus.keyword')
      );
    }
    if (customerNames && customerNames.length > 0) {
      query.bool.must.push(
        this.buildFilterListQuery(customerNames, 'order.customer.name.keyword')
      );
    }
    if (vendorNames && vendorNames.length > 0) {
      query.bool.must.push(
        this.buildFilterListQuery(
          vendorNames,
          'steps.missions.data.vendor.name.keyword'
        )
      );
    }
    if (orderTypes && orderTypes.length > 0) {
      query.bool.must.push(
        this.buildFilterListQuery(orderTypes, 'order.serviceType.keyword')
      );
    }

    const { from, size } = this.convertPagination(baseParams);
    const sort = this._buildSort(baseParams);

    return this.search<FulfillmentSearch>({
      query,
      from,
      size,
      sort
    });
  }

  private _buildSort(baseParams: BaseParams) {
    return this.convertSort({
      ...baseParams,
      sortBy: fulfillmentSortMapping[baseParams.sortBy as string]
    });
  }
}
