import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.template.html',
  styleUrls: ['./date-filter.styles.scss']
})
export class DateFilterComponent {
  @Input() filterTitle: string;
  @Input()
  set selectedRange(range: [number, number]) {
    this.dateRange = range;
  }

  @Output() filtersChange = new EventEmitter<[number, number]>();
  @Output() clearFilters = new EventEmitter<void>();

  dateRange: [number, number];

  protected readonly datePickerCfg = {
    style: 'icon-button',
    mode: 'range',
    enableTime: false
  };

  updateFilter(dateRange?: [number, number]) {
    this.dateRange = dateRange;
    this.filtersChange.emit(this.dateRange);
  }

  clearFilter() {
    this.clearFilters.emit();
  }
}
