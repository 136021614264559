import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Planting,
  ManagementZone,
  AgSearchService,
  DataProduct,
  ManagementZoneService,
  DataProductService,
  VectorDataService,
  User,
  AttachmentService
} from 'api/src/public_api';
import { Subscription, Observable, combineLatest, pipe } from 'rxjs';
import { FormControl } from '@angular/forms';
import { PlantingsStateService } from '../plantings-state.service';
import {
  MapFeaturesService,
  MapService,
  MapLayersService,
  MapLayer
} from 'projects/map/src/public_api';
import {
  skipWhile,
  shareReplay,
  filter,
  take,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { ContextService } from '../../../shared/services/context.service';
import { NotificationStreamService } from 'projects/ag/src/app/shared/services/notificationStream.service';
import { ToasterService } from 'projects/ui/src/public_api';
import { saveAs } from 'file-saver';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';

@Component({
  templateUrl: './vrt-map.template.html',
  styleUrls: ['./vrt-map.styles.scss']
})
export class VrtMapComponent implements OnInit, OnDestroy {
  planting: Planting;
  plantingSub: Subscription;
  planting$: Observable<Planting>;
  derivatives$: Observable<object>;
  dataProduct$: Observable<DataProduct>;

  managementZoneSelectCtrl = new FormControl({});
  mapNameCtrl = new FormControl();
  managementZoneId: string;

  vrtRequirementsMet: boolean;
  selectedManagementZone: Partial<ManagementZone>;
  protected initZoneList = [];
  zoneListForRates = [];
  inputList = [];
  inputDetailsMode = false;
  availableInputs = [];
  availableInputsSelectCtrl = new FormControl({});
  unitOfMeasurementCtrl = new FormControl('');
  inputRequirementsMet: boolean;
  inputId: string;
  currentLayer: MapLayer;
  private selectedDerivative: any;
  private selectedGeometry: any;
  mapLayer: MapLayer;
  layerOpacity = 100;
  notificationSub: Subscription;

  constructor(
    private _plantingStateService: PlantingsStateService,
    private _mapFeatureService: MapFeaturesService,
    private _mapService: MapService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _agSearchService: AgSearchService,
    private _mapLayersService: MapLayersService,
    private _route: ActivatedRoute,
    private _managementZoneService: ManagementZoneService,
    private _dataProductService: DataProductService,
    private _vectorDataService: VectorDataService,
    private _contextService: ContextService,
    private _notificationStreamService: NotificationStreamService,
    private _attachmentService: AttachmentService,
    private _toast: ToasterService
  ) {
    // temp setting these until we get a real list
    this.availableInputs = [
      {
        name: 'Phosphorous',
        id: '111'
      },
      {
        name: 'Nitrogen',
        id: '222'
      },
      {
        name: 'Seeds',
        id: '333'
      },
      {
        name: 'Fertilizer',
        id: '444'
      },
      {
        name: 'Insecticide',
        id: '555'
      },
      {
        name: 'Pot Ash',
        id: '666'
      },
      {
        name: 'Water',
        id: '777'
      },
      {
        name: 'Herbicide',
        id: '888'
      },
      {
        name: 'Pesticide',
        id: '999'
      }
    ];
  }

  ngOnInit() {
    this._route.params
      .pipe(
        filter((params: Params) => {
          this.managementZoneId = params['zoneId'];
          this.getCurrentManagementZone(this.managementZoneId);
          return params['plantingId'];
        }),
        this._plantingStateService.loadEntityOpr('plantingId'),
        take(1)
      )
      .subscribe((planting: Planting) => {
        this.planting = planting;
        this._mapFeatureService.addAnnotation(this.planting.geometry);
        this._mapService.animateToBounds(
          (this.planting.geometry.getGeometry() as any).getExtent(),
          [0, 0, 0, 410]
        );
      });

    this.mapNameCtrl.valueChanges.subscribe(res => {
      this.checkVrtMapReqs();
    });

    this.availableInputsSelectCtrl.valueChanges.subscribe(res => {
      this.checkInputRequirementsMet();
    });

    this.deleteStoredInputs();
  }

  private onPrecompose = (event: any) => {
    const ctx = event.context;
    const map = this._mapService.getMap();
    ctx.save();
    const pixelRatio = event.frameState.pixelRatio;
    const size = map.getSize();
    ctx.scale(1 * pixelRatio, 1 * pixelRatio);
    let pix;
    let first = true;
    ctx.beginPath();
    // console.log(this.selectedDerivative);
    if (this.selectedDerivative.geometry.type === 'MultiPolygon') {
      this.selectedDerivative.geometry.coordinates.forEach((coord: any[]) => {
        coord.forEach((points: any[]) => {
          pix = map.getPixelFromCoordinate(points[0]);
          if (pix) {
            ctx.moveTo(pix[0], pix[1]);
          }
          first = true;
          points.forEach((point: any[]) => {
            if (first) {
              first = false;
              return;
            }
            pix = map.getPixelFromCoordinate(point as [number, number]);
            if (pix) {
              ctx.lineTo(pix[0], pix[1]);
            }
          });
        });
      });
    } else {
      this.selectedDerivative.geometry.coordinates.forEach((coord: any[]) => {
        pix = map.getPixelFromCoordinate(coord[0]);
        if (pix) {
          ctx.moveTo(pix[0], pix[1]);
        }
        first = true;
        coord.forEach((point: any[]) => {
          if (first) {
            first = false;
            return;
          }
          pix = map.getPixelFromCoordinate(point as [number, number]);
          if (pix) {
            ctx.lineTo(pix[0], pix[1]);
          }
        });
      });
    }
    ctx.clip();
    ctx.scale(1 / 1 / pixelRatio, 1 / 1 / pixelRatio);
    ctx.stroke();
  };

  private onPostcompose = (event: any) => {
    event.context.restore();
  };

  getCurrentManagementZone(id: string) {
    this._managementZoneService
      .getById(id)
      .pipe(
        skipWhile(resp => !resp),
        take(1)
      )
      .subscribe(zone => {
        this.selectedManagementZone = Object.assign(
          [],
          this.selectedManagementZone,
          zone.body
        );

        this.selectedManagementZone = zone.body
        this.managementZoneSelectCtrl.setValue(this.selectedManagementZone);
        this.initZoneList = this.selectedManagementZone.zoneData;

        // testing adding derivative to map but not working yet
        this._agSearchService
          .assetProxy({ subject: zone.body.image, proxy: 'overlayMercator' })
          .subscribe((res: any) => {
            this.selectedDerivative = zone.body.aoi;
            const geom = new GeoJSON();
            const temptempGeom = this.planting.geometry.clone();
            (temptempGeom as Feature).getGeometry();
            const pointJSON = JSON.parse(
              geom.writeFeature(temptempGeom as Feature)
            );
            this.selectedDerivative.geometry = pointJSON.geometry;

            const dataproduct = new DataProduct({
              dataType: 'STATIC',
              brokerUrl: (res as any).changingThisBreaksApplicationSecurity,
              extent: (this.planting.geometry.getGeometry() as any).getExtent()
            });

            this._mapLayersService
              .getMapLayers(dataproduct)
              .then((mapLayers: any[]) => {
                if (this.currentLayer) {
                  this.currentLayer.olLayer.un('prerender', this.onPrecompose);
                  this.currentLayer.olLayer.un(
                    'postrender',
                    this.onPostcompose
                  );
                  this._mapLayersService.removeLayer(this.currentLayer);
                }

                this.currentLayer = mapLayers[0];

                this._mapLayersService.addLayer(this.currentLayer);
                this.currentLayer.olLayer.setZIndex(999999);
                this.currentLayer.olLayer.on('prerender', this.onPrecompose);
                this.currentLayer.olLayer.on('postrender', this.onPostcompose);
              });
          });

        // features for selected zone
        this.selectedManagementZone.zoneData.forEach(element => {

          const parser = new GeoJSON();
          const newMapFeature = parser.readFeature(element['geom'], {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          });

          if (newMapFeature) {
            newMapFeature.set('fillColor', element['colorRGB']);
            this._mapFeatureService.addAnnotation(newMapFeature);
          }
        });
      });
  }

  checkIsStringEmpty(str: string) {
    return str !== null || str.match(/^ *$/) === null;
  }

  checkVrtMapReqs() {
    this.vrtRequirementsMet =
      this.mapNameCtrl.value && this.inputList.length > 0 ? true : false;
  }

  checkInputRequirementsMet() {
    this.inputRequirementsMet = this.availableInputsSelectCtrl.value.id
      ? true
      : false;
  }

  zoneRateChange(rate: number, index: number) {
    this.zoneListForRates[index]['rate'] = rate;
  }

  openInputDetails(id?: string) {
    this.inputDetailsMode = true;

    if (id) {
      this.inputId = id;
      const existingInput = this.getInputById(id);

      this.availableInputsSelectCtrl.setValue(existingInput.inputType);
      this.unitOfMeasurementCtrl.setValue(existingInput.unitOfMeasure);
      this.zoneListForRates = existingInput.zones;
    } else {
      const zoneCopy = this.selectedManagementZone.zoneData.map(zone => {
        zone['rate'] = 0;
        return zone;
      });
      this.zoneListForRates = zoneCopy;
    }
  }

  closeInputDetails() {
    this.inputDetailsMode = false;
    this.availableInputsSelectCtrl.setValue({});
    this.unitOfMeasurementCtrl.setValue('');
    this.zoneListForRates = [];
    this.inputId = null;
    this.checkVrtMapReqs();
  }

  saveInput() {
    const randId = new Date().valueOf() * new Date().getUTCMilliseconds();
    // ^^ random for front end use

    const newInput = {
      name: this.availableInputsSelectCtrl.value.name,
      unitOfMeasure: this.unitOfMeasurementCtrl.value || null,
      id: randId,
      inputType: this.availableInputsSelectCtrl.value,
      zones: this.zoneListForRates
    };

    const updatedInputList = !this.inputId
      ? this.inputList.push(newInput)
      : this.inputList.forEach((input, index) => {
        if (input.id === this.inputId) {
          this.inputList[index] = newInput;
        }
      });

    if (this.inputId) {
      this.updateInput(this.inputId, newInput);
    } else {
      this.addStoredInput(randId, newInput);
    }

    this.closeInputDetails();
  }

  addStoredInput(id: number, inputPayload) {
    localStorage.setItem(id + '--input', JSON.stringify(inputPayload));
  }

  getInputById(id: string) {
    return JSON.parse(localStorage.getItem(id + '--input'));
  }

  getStoredInputs() {
    const values = [];
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      if (keys[i].includes('--input')) {
        const inputObject = JSON.parse(localStorage.getItem(keys[i]));
        inputObject['matchKey'] = keys[i];
        values.push(inputObject);
      }
    }

    return values;
  }

  deleteStoredInputs() {
    this.getStoredInputs().forEach(input => {
      localStorage.removeItem(input.matchKey);
    });
  }

  deleteInputById(id: string) {
    localStorage.removeItem(id + '--input');
  }

  updateInput(id: string, input: object) {
    localStorage.setItem(id + '--input', JSON.stringify(input));
  }

  deleteInput() {
    this.inputList = this.inputList.filter(input => {
      return input.id !== this.inputId;
    });

    this.deleteInputById(this.inputId);
    this.closeInputDetails();
  }

  saveVrtMap() {
    const newVrtMap = {
      name: this.mapNameCtrl.value,
      userId: this._contextService.user.id,
      inputs: this.getStoredInputs().map(input => {
        const payload = {
          name: input.name,
          unit: input.unitOfMeasure,
          blend: input.inputType.name,
          zones: input.zones.map(zone => {
            delete zone.healthColor;
            return zone;
          })
        };
        return payload;
      })
    };

    this._managementZoneService
      .createVRT(this.managementZoneId, newVrtMap)
      .pipe(take(1))
      .subscribe(resp => {
        const vrtId = resp.body.id;

        this._managementZoneService
        .downloadVRT(this.managementZoneId, vrtId)
        .pipe(take(1))
        .subscribe(blob => {
          saveAs(blob, resp.body.name + ".zip");
        });

      });
    this._toast.alert('success', 'Initializing VRT Download...');
    this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
  }

  ngOnDestroy() {
    this.deleteStoredInputs();
    if (this.planting.geometry) {
      this._mapFeatureService.removeAnnotation(this.planting.geometry);
    }
    if (this.currentLayer) {
      this.currentLayer.olLayer.un('prerender', this.onPrecompose);
      this.currentLayer.olLayer.un('postrender', this.onPostcompose);
      this._mapLayersService.removeLayer(this.currentLayer);
    }
    this._mapFeatureService.clearAnnotations();
  }
}
