import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Extent } from 'ol/extent';
import { EventsKey } from 'ol/events';
import { MapService } from '../map.service';

@Injectable({
  providedIn: 'root'
})
export class AerialMapService {
  mapInit$: Observable<void>;
  mapDestroy$: Observable<void>;

  get isMapInit(): boolean {
    return this._isMapInit;
  }

  // Note: I'm re-using code from `AoiListComponent`
  viewportChange$: Observable<Extent>;
  private _viewportChange = new BehaviorSubject<Extent>(null);
  private _moveEventKey: EventsKey | Array<EventsKey>;
  private _isViewportChangePaused = false;

  private _onMapInit = new Subject<void>();
  private _onMapDestroy = new Subject<void>();
  private _isMapInit: boolean;

  private _mapInit = new ReplaySubject<void>(1);
  private _mapDestroy = new ReplaySubject<void>(1);

  constructor(private _mapService: MapService) {
    this.viewportChange$ = this._viewportChange
      .asObservable()
      .pipe(filter(_ => !this._isViewportChangePaused));
    this.mapInit$ = this._mapInit.asObservable();
    this.mapDestroy$ = this._mapDestroy.asObservable();
    this._onMapInit.subscribe(() => {
      this._moveEventKey = this._mapService.map.on('moveend', () =>
        this._viewportChange.next(this._mapService.getMapExtent())
      );
      this._isMapInit = true;
      this._mapInit.next();
      this._viewportChange.next(this._mapService.getMapExtent());
    });
    this._onMapDestroy.subscribe(() => {
      if (this._moveEventKey) {
        this._mapService.removeListener(this._moveEventKey);
      }
      this._moveEventKey = undefined;
      this._mapDestroy.next();
      this._isMapInit = false;
    });
  }

  onMapInit(): void {
    this._onMapInit.next();
  }

  onMapDestroy(): void {
    this._onMapDestroy.next();
  }

  resize(): void {
    const intervalId = setInterval(_ => this._mapService.map.updateSize(), 10);
    setTimeout(_ => clearInterval(intervalId), 301);
    // note: the timeout interval comes from: ($mapCtrlDuration * 1000) + 1
    // if the sass var `$mapCtrlDuration` should change, this interval should be updated
  }

  pauseViewportWatcher(): void {
    this._isViewportChangePaused = true;
  }

  resumeViewportWatcher(emitOnResume = false): void {
    this._isViewportChangePaused = false;
    if (emitOnResume) {
      this._viewportChange.next(this._mapService.getMapExtent());
    }
  }
}
