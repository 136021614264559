import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent
} from '@angular/common/http';
import { AuthService } from './auth.service';

import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * HttpInterceptor that injects the auth token into the headers of an HTTPRequest
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  tokenGetter: any;

  constructor(private _authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this._authService.jwtTokenGetter()).pipe(
      mergeMap((asyncToken: string | null) => {
        return this.handleInterception(asyncToken, request, next);
      })
    );
  }

  /**
   * NOTE: this code was copypasted from a library, the commented out code has
   * some logic for handling errors that might need to be implemented in the future
   */
  handleInterception(
    token: string | null,
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    // let tokenIsExpired: boolean = false;
    //
    // if (!token && this.throwNoTokenError) {
    //   throw new Error('Could not get token from tokenGetter function.');
    // }
    //
    // if (this.skipWhenExpired) {
    //   tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;
    // }
    //
    // if (token && tokenIsExpired && this.skipWhenExpired) {
    //   request = request.clone();
    // } else if (
    //   token &&
    //   this.isWhitelistedDomain(request) &&
    //   !this.isBlacklistedRoute(request)
    // ) {
    //   request = request.clone({
    //     setHeaders: {
    //       [this.headerName]: `${this.authScheme}${token}`
    //     }
    //   });
    // }
    // return next.handle(request);
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}
