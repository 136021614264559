import { AgBase } from './agBase.model';
import { Farm } from './farm.model';

export class Grower extends AgBase {
  public readonly customerId: string;
  public farms: Farm[];
  public favorite: boolean;

  constructor(opts: Partial<Grower>) {
    super(opts);
  }

  public toJSON(): object {
    return this;
  }
}
