import { BaseModel } from './base.model';

export class DataProductSubscription extends BaseModel {
  readonly id: string;
  customerId: string;
  dataProductId: string;
  active: boolean;
  readonly created: number;
  readonly modified: number;
}
