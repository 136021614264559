import { Injectable } from '@angular/core';

import { AuthService } from 'projects/auth/src/public_api';
import {
  User,
  UserService,
  Customer,
  CustomerService
} from 'projects/api/src/public_api';

import { Observable, BehaviorSubject } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  public user$: Observable<User>;
  public customer$: Observable<Customer>;

  set user(user: User) {
    this._user = user;
    this._userSubject.next(this._user);
  }

  get user(): User {
    return this._user;
  }

  set customer(customer: Customer) {
    this._customer = customer;
    this._customerSubject.next(this._customer);
  }

  get customer(): Customer {
    return this._customer;
  }

  private _user: User = null;
  private _customer: Customer = null;
  private _userSubject = new BehaviorSubject<User>(this._user);
  private _customerSubject = new BehaviorSubject<Customer>(this._customer);

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _customerService: CustomerService
  ) {
    this.user$ = this._userSubject
      .asObservable()
      .pipe(skipWhile(user => !user));
    this.customer$ = this._customerSubject
      .asObservable()
      .pipe(skipWhile(customer => customer === null));
    this._authService.idToken$.subscribe(token => {
      if (!token) {
        this.clearAll();
      } else {
        this._initUser();
      }
    });
  }

  public patchUser(id: string, update: Partial<User>): void {
    this._userService.patchUser(id, update).then((resp: HttpResponse<User>) => {
      this._userService.getUser().then((user: User) => {
        this.user = new User({ ...user });
      });
    });
  }

  public clearAll(): void {
    this.user = null;
    this.customer = null;
  }

  public toggleFavoriteGrower(id: string) {
    const currUser = this._userSubject.getValue();
    const favGrowers = currUser.attributes.favoriteGrowers || [];
    const ndx = favGrowers.indexOf(id);

    if (ndx === -1) {
      favGrowers.push(id);
    } else {
      favGrowers.splice(ndx, 1);
    }

    this._userService
      .patchUser(currUser.id, {
        attributes: {
          ...currUser.attributes,
          favoriteGrowers: favGrowers
        }
      })
      .then((resp: HttpResponse<User>) => {
        this._userService.getUser().then((user: User) => {
          this.user = user;
        });
      });
  }

  private async _initUser(): Promise<void> {
    try {
      this.user = await this._userService.getUser();
      if (this.user.customerParentId) {
        this._initCustomer();
      } else {
        // this means the user doesn't have a parent customer (i.e. aerial admin)
        this.customer = undefined;
      }
    } catch (err) {
      console.error('Get user error', err);
    }
  }

  private async _initCustomer(): Promise<void> {
    try {
      this.customer = await this._customerService.getCustomer(
        this.user.customerParentId
      );
    } catch (err) {
      console.error('Get customer error', err);
    }
  }
}
