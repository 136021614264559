import { BaseModel } from './base.model';

export class GrowerSummary extends BaseModel {
  public readonly growerId: string;
  public readonly growerName: string;
  public readonly farmId: string;
  public readonly farmName: string;
  public readonly plantingCount: number;

  constructor(opts: Partial<GrowerSummary>) {
    super(opts);
  }
}
