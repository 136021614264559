import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  OnDestroy,
  Renderer2,
  Optional,
  Self
} from '@angular/core';
import {
  FormControlDirective,
  FormControlName,
  FormControl
} from '@angular/forms';
import flatpickr from 'flatpickr';
import { Subscription } from 'rxjs';
import {
  DatePickerConfig,
  defaultDatePickerConfig
} from './datePickerConfig.interface';
import { DatePickerControl } from '../date-picker-control/date-picker-control.model';

@Directive({
  selector: 'input[datePicker]',
  exportAs: 'datePicker'
})
export class DatePickerDirective extends DatePickerControl
  implements OnInit, OnDestroy {
  @Input()
  set config(config: DatePickerConfig) {
    const currDate = new Date();
    this._config = {
      ...this._config,
      defaultHour: currDate.getHours(),
      defaultMinute: currDate.getMinutes(),
      minuteIncrement: 1,
      ...config
    };
  }

  get config(): DatePickerConfig {
    return this._config;
  }

  get configOptions(): object {
    const opts = { ...this.config };
    if (this.inputCtrl.value) {
      opts['defaultDate'] = Array.isArray(this.inputCtrl.value)
        ? this.inputCtrl.value.map(ts => new Date(ts))
        : new Date(this.inputCtrl.value);
    }
    opts['onChange'] = (selectedDates: Date[], dateStr: string) => {
      if (selectedDates.length) {
        return;
      }
      this.inputCtrl.setValue(this.isSingleMode ? '' : []);
    };
    opts['onValueUpdate'] = (selectedDates: Date[], dateStr: string) => {
      if (this.isTimestampFormat) {
        // flatpickr stores invalid timestamp strings
        const timestamps = selectedDates.map(date => date.getTime());
        this.inputCtrl.setValue(this.isSingleMode ? timestamps[0] : timestamps);
      } else {
        this.inputCtrl.setValue(dateStr);
      }
    };
    opts['onClose'] = () => {
      this._isPickerOpen = false;
      this.closePicker.emit();
    };
    opts['onOpen'] = () => {
      this.inputCtrl.markAsTouched();
      this._isPickerOpen = true;
      this.openPicker.emit();
    };
    return opts;
  }

  get inputCtrl(): FormControl {
    return this._formControl
      ? this._formControl.control
      : this._formControlName
      ? this._formControlName.control
      : undefined;
  }

  get inputElement(): HTMLInputElement {
    return this._el.nativeElement;
  }

  get isPickerOpen(): boolean {
    return this._isPickerOpen;
  }

  private _config: DatePickerConfig = defaultDatePickerConfig;
  private _isPickerOpen = false;
  private _sub: Subscription;

  constructor(
    @Optional() @Self() private _formControl: FormControlDirective,
    @Optional() @Self() private _formControlName: FormControlName,
    private _el: ElementRef<HTMLInputElement>,
    private _renderer: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    this._sub = this.inputCtrl.valueChanges.subscribe(val => {
      // this is convoluted but flatpickr always resets the value of the input to
      // a string when sometimes it should be a number or array of numbers
      if (!val || typeof val !== 'string' || !val.match(/^(\d+,?)+$/g)) {
        return;
      }
      val = val.split(',').map(v => parseInt(v));
      this.inputCtrl.setValue(this.isSingleMode ? val[0] : val);
    });
    this._flatpickr = flatpickr(this.inputElement, this.configOptions);
    if (this.config.altInput) {
      // hacky, but it's a limitation of flatpickr
      const altInput = this._renderer.nextSibling(this.inputElement);
      this._renderer.setAttribute(altInput, 'placeholder', this.placeholder);
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
