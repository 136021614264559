import { Input } from '@angular/core';
import { handleClassNamesFromInput as handleClasses } from '../../util/helpers';

export abstract class ComboLink {
  @Input()
  iconClass: string;

  @Input()
  iconPosition: 'left' | 'right' = 'left';

  @Input()
  darkMode = false;

  @Input()
  loading = false;

  @Input()
  disabled = false;

  // TODO: maybe make this reusable across the board? (buttons, badges, etc.)
  @Input()
  set classes(classes: string[] | string) {
    this._classes = handleClasses(classes);
  }

  get classes(): string[] | string {
    return this._classes.concat([
      'combo-link',
      'combo-element',
      `align-icon-${this.iconPosition}`,
      this.darkMode ? 'dark-mode' : '',
      this.loading ? 'loading' : '',
      this.disabled ? 'disabled' : '',
      ...this.otherClasses
    ]);
  }

  abstract otherClasses: string[];
  private _classes: string[] = [];
}
