import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  StacBbox,
  StacCollections,
  StacCollection,
  StacItemCollection,
  StacItem,
  StacSearchParams,
  StacNextLink
} from '../models/stac.model';
import { BaseService } from './base.service';
import { CacheService } from './cache.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StacService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient, protected cache: CacheService) {
    super(httpClient);
    this.endpointUrl = `${environment.DISCOVERY_API_ROOT}/stac`;
  }

  getCollections(): Observable<StacCollections> {
    const url = `${this.endpointUrl}/collections`;
    const cache = this.cache.get(url);
    if (cache) {
      return cache;
    }
    try {
      return this.httpClient.get<StacCollection>(url).pipe(
        map(json => {
          const response = new StacCollections(json);
          this.cache.set(url, response);
          return response;
        })
      );
    } catch (error) {
      this.cache.delete(url);
    }
  }

  getCollection(collectionId: string): Observable<StacCollection> {
    const url = `${this.endpointUrl}/collections/${collectionId}`;
    const cache = this.cache.get(url);
    if (cache) {
      return cache;
    }
    try {
      return this.httpClient.get<StacCollection>(url).pipe(
        map(json => {
          const response = new StacCollection(json);
          this.cache.set(url, response);
          return response;
        })
      );
    } catch (error) {
      this.cache.delete(url);
    }
  }

  getItemsForCollection(collectionId: string): Observable<StacItemCollection> {
    return this.httpClient
      .get<StacItemCollection>(
        `${this.endpointUrl}/collections/${collectionId}/items`
      )
      .pipe(
        // prettier-ignore
        map(json => new StacItemCollection(json))
      );
  }

  getItem(collectionId: string, itemId: string): Observable<StacItem> {
    return this.httpClient
      .get<StacItem>(
        `${this.endpointUrl}/collections/${collectionId}/items/${itemId}`
      )
      .pipe(
        // prettier-ignore
        map(json => new StacItem(json))
      );
  }

  searchByGet(params: StacSearchParams = {}): Observable<StacItemCollection> {
    // Stringify Params (`query` not supported on GET)
    const paramsObj = {};
    const { bbox, datetime, limit, ids, collections, sortby, fields } = params;
    if (bbox) {
      this._wrapBbox(bbox);
      paramsObj['bbox'] = bbox.join();
    }
    if (datetime) {
      paramsObj['datetime'] = datetime;
    }
    if (limit) {
      paramsObj['limit'] = `${limit}`;
    }
    if (ids) {
      paramsObj['ids'] = ids.join();
    }
    if (collections) {
      paramsObj['collections'] = collections.join();
    }
    if (sortby) {
      paramsObj['sortby'] = sortby
        .map(
          ({ field, direction }) => `${direction === 'desc' ? '-' : ''}${field}`
        )
        .join();
    }
    if (fields) {
      paramsObj['fields'] = (fields.include
        ? fields.include.map(field => field)
        : []
      )
        .concat(fields.exclude ? fields.exclude.map(field => `-${field}`) : [])
        .join();
    }
    return this.httpClient
      .get<StacItemCollection>(`${this.endpointUrl}/search`, {
        params: this.buildParams(paramsObj)
      })
      .pipe(
        // prettier-ignore
        map(json => new StacItemCollection(json))
      );
  }

  searchByPost(
    params: StacSearchParams = {},
    next?: StacNextLink
  ): Observable<StacItemCollection> {
    if (params.bbox) {
      this._wrapBbox(params.bbox);
    }
    if (params.intersects) {
      params.bbox = undefined;
    }
    let url = `${this.endpointUrl}/search`,
      body: object = params;
    const options = {};
    // Avoid cache in pagination request
    if (next) {
      // https://github.com/radiantearth/stac-spec/blob/v0.9.0/api-spec/api-spec.md#paging-extension
      url = next.href;
      body = next.merge ? { ...body, ...next.body } : next.body;
      if (next.merge && next.headers) {
        options['headers'] = next.headers;
      }
    } else {
      const cache = this.cache.get(url, body);
      if (cache) {
        return cache;
      }
    }
    try {
      return this.httpClient.post<StacItemCollection>(url, body, options).pipe(
        map(json => {
          const response = new StacItemCollection(json);
          // Expire cache in 30 seconds
          this.cache.set(url, response, body, 30000);
          return response;
        })
      );
    } catch (error) {
      this.cache.delete(url);
    }
  }

  private _wrapBbox(bbox: StacBbox): void {
    // TODO: once airbus discovery-api is fixed, remove this 'if' and always
    //       run the wrap code (that way it will handle the new zealand case)
    if (
      (bbox[0] <= -180 && bbox[2] <= -180) ||
      (bbox[0] >= 180 && bbox[2] >= 180)
    ) {
      // this wraps longitude so it's between -180 and 180
      // https://github.com/radiantearth/stac-spec/blob/master/api-spec/examples.md
      bbox[0] = (((bbox[0] % 360) + 540) % 360) - 180;
      bbox[bbox.length / 2] =
        (((bbox[bbox.length / 2] % 360) + 540) % 360) - 180;
    }
  }
}
