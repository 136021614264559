import { UIModule } from 'projects/ui/src/public_api';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FieldsRoutingModule } from './fields-routing.module';

import { FieldsComponent } from './fields.component';
import { FieldSingleComponent } from './field-single/field-single.component';
import { CreateFieldComponent } from './create-field/create-field.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UtilsModule } from 'utils/src/public_api';
import { FeatureDisplayComponent } from 'projects/adp/src/app/shared/feature-display/feature-display.component';
import { DrawPolygonToolComponent } from '../../shared/draw-polygon-tool/draw-polygon-tool.component';
import { NameFieldOverlayComponent } from './create-field/name-field-overlay/name-field-overlay.component';

@NgModule({
  declarations: [
    FieldsComponent,
    FieldSingleComponent,
    CreateFieldComponent,
    FeatureDisplayComponent,
    DrawPolygonToolComponent,
    NameFieldOverlayComponent
  ],
  imports: [
    CommonModule,
    FieldsRoutingModule,
    SharedModule,
    UIModule,
    ReactiveFormsModule,
    UtilsModule,
    FormsModule
  ]
})
export class FieldsModule { }
