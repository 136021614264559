import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AgBaseService } from './agBase.service';
import { Grower } from '../models/grower.model';
import { BaseParams } from '../interfaces/baseParams.interface';
import { Observable } from 'rxjs';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { GrowerSummary } from '../models/grower-summary.model';

@Injectable()
export class GrowerService extends AgBaseService<Grower> {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super('/ag/growers', httpClient);
    this.defaultSize = 5000;
  }

  marshall(json: object): Grower {
    return new Grower(json);
  }

  public summary(
    baseParams: BaseParams = {},
    options: {
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    } = {}
  ): Observable<PagedResponse<GrowerSummary>> {
    return this.getWithPagedResponse<GrowerSummary>(
      `${this.endpointUrl}/summary/`,
      (respBody: GrowerSummary[]): GrowerSummary[] => {
        return respBody.map(json => new GrowerSummary(json));
      },
      baseParams,
      options
    );
  }
}
