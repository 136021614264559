import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
  forwardRef,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  HostBinding,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import flatpickr from 'flatpickr';
import {
  DatePickerConfig,
  defaultDatePickerConfig
} from './datePickerConfig.interface';
import { DatePicker } from './datePicker.model';

@Component({
  selector: 'date-picker',
  template: `
    <!-- prettier-ignore -->
    <div class="date-picker style-{{ config.style }}"
      [class.active]="isPickerOpen"
      [class.empty]="!hasValue">
      <button *ngIf="isIconBtnStyle; else icon"
        (click)="openPicker()"
        class="date-picker-btn secondary small square"
        [class.active]="isPickerOpen">
        <ng-container *ngTemplateOutlet="icon"></ng-container>
      </button>
      <ng-template #icon>
        <i class="icon-calendar date-picker-icon"></i>
      </ng-template>
      <input #input
        class="date-picker"
        [class.outlined]="isDropdownStyle"
        type="text"
        [placeholder]="placeholder">
      <!-- TODO: combine with 'dropdown' style -->
      <i *ngIf="config.style === 'alt-dropdown'"
        class="icon-triangle_down alt-date-picker-icon">
      </i>
      <!-- only implementing clear functionality for standard input picker right now -->
      <i *ngIf="(isInputStyle || isDropdownStyle) && hasValue"
        class="icon-close date-picker-clear"
        (click)="clearPicker()"
        title="Clear">
      </i>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent extends DatePicker
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() config: DatePickerConfig = {};
  @Output() onChange = new EventEmitter<number | number[]>();
  @ViewChild('input') input: ElementRef;

  @HostBinding('class.form-control')
  hasFormCtrlClass = true;

  @HostBinding('class.input-control')
  get hasInputCtrlClass(): boolean {
    return this.isInputStyle || this.isDropdownStyle;
  }

  @HostBinding('class.button-control')
  get hasBtnClass(): boolean {
    return this.isBtnStyle || this.isIconBtnStyle;
  }

  get isBtnStyle(): boolean {
    return this.config.style === 'button';
  }

  get isIconBtnStyle(): boolean {
    return this.config.style === 'icon-button';
  }

  get isInputStyle(): boolean {
    return this.config.style === 'input';
  }

  get isDropdownStyle(): boolean {
    return this.config.style === 'dropdown';
  }

  get hasValue(): boolean {
    return this._flatpickr.selectedDates.length > 0;
  }

  protected isPickerOpen: boolean;
  private _flatpickr: any;

  private _onChange = (_: number | number[]) => {};
  private _onTouched = () => {};

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {
    super();
  }

  openPicker(): void {
    this._flatpickr.open();
  }

  clearPicker(): void {
    this._flatpickr.close();
    this._flatpickr.clear();
  }

  inputChange(selectedDates: Date[]) {
    const timestamps = selectedDates.map(date => date.getTime());
    const emitValue = timestamps.length === 1 ? timestamps[0] : timestamps;

    // skip emitting the single value if it is not set to mode: 'single'
    if (typeof emitValue === 'number' && this.config.mode !== 'single') {
      return;
    }

    this._onChange(emitValue);
    this.onChange.emit(emitValue);
  }

  writeValue(timestamps: number | number[]): void {
    if (!timestamps) {
      return;
    } else if (timestamps instanceof Array) {
      this._flatpickr.setDate(timestamps.map(ts => new Date(ts)));
    } else {
      this._flatpickr.setDate(new Date(timestamps));
    }
  }

  registerOnChange(fn: (_: number | number[]) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    // TODO: do something here
  }

  ngOnInit() {
    this.config = { ...defaultDatePickerConfig, ...this.config };
    (this.config as any).clickOpens = this.isIconBtnStyle ? false : true;
    (this.config as any).onChange = (selectedDates: Date[]) => {
      this.inputChange(selectedDates);
    };
    (this.config as any).onClose = () => {
      this.isPickerOpen = false;
      this._onTouched();
      this._changeDetectorRef.markForCheck();
    };
    (this.config as any).onOpen = () => {
      this.isPickerOpen = true;
    };
    this._flatpickr = flatpickr(this.input.nativeElement, this.config);
    if (this.config.altInput) {
      const altInput = this._renderer.nextSibling(this.input.nativeElement);
      this._renderer.setAttribute(altInput, 'placeholder', this.placeholder);
      if (this.isDropdownStyle) {
        this._renderer.addClass(altInput, 'outlined');
      }
    }
  }

  ngOnDestroy() {
    this._flatpickr.destroy();
  }
}
