import { BaseModel } from './base.model';

export enum InspectionType {
  Triage = 'TRIAGE',
  Inspection = 'INSPECTION_3D',
  SingleAsset = 'SINGLE_ASSET',
  MultiAsset = 'MULTI_ASSET',
}

export enum InspectionStatus {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE'
}

export interface SingleAssetInspectionData {
  workspaceId: string;
  assetId: string;
}

export interface TriageInspectionData {
  workspaceId: string;
  dataProductId: string;
}

export interface MultiAssetInspectionData {
  workspaceId: string;
  assetGroupId: string;
}

export interface InspectionInspectionData {
  dataProductId: string;
}

export class Inspection extends BaseModel {
  id: string;
  created: number;
  modified: number; // NOTE: modified is the default timestamp for the Inspection itself
  lastModified: number; // lastModified is to keep track of when any entity displayed in the Inspection is changed.
  name: string;
  type: InspectionType;
  status: InspectionStatus;
  customerId: string;
  projectId: string;
  userId: string;
  data: TriageInspectionData | InspectionInspectionData | SingleAssetInspectionData | MultiAssetInspectionData;
  workspaceId?: string;
  assetId?: string;
  dataProductId?: string;
  assetGroupId?: string;
}
