import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlantingsStateService } from '../plantings-state.service';
import {
  Planting,
  Field,
  Crop,
  CropService,
  AgSubscription,
  AgSubscriptionService
} from 'api/src/public_api';
import { FieldsStateService } from '../../fields/fields-state.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { MapFeaturesService, MapService } from 'map/src/public_api';
import { skipWhile } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './create-planting.template.html',
  styleUrls: ['./create-planting.styles.scss']
})
export class CreatePlantingComponent implements OnInit, OnDestroy {
  private planting: Partial<Planting>;
  field: Field;
  fieldSub: Subscription;
  plantingCompleted: boolean = false;

  _plantingNameSub: Subscription;
  plantingNameCtrl = new FormControl('');
  plantingNameChosen: string;

  cropList: any;
  cropTypeCtrl = new FormControl({});

  fieldSelectCtrl = new FormControl({});

  requiredFieldsMet = true;
  plantingNamed: boolean;
  startDate: number;
  endDate: number;
  subData: Partial<AgSubscription>[];

  showEndDateInput = false;

  get minEndDate() {
    // Min growing cycle length: 4 weeks
    if (!this.startDate) {
      return undefined;
    }
    const startDate = new Date(this.startDate);
    return startDate.setDate(startDate.getDate() + 4 * 7);
  }

  get maxEndDate() {
    // Maximum growing cycle length: 52 weeks
    if (!this.startDate) {
      return undefined;
    }
    const startDate = new Date(this.startDate);
    return startDate.setDate(startDate.getDate() + 52 * 7);
  }

  constructor(
    private _plantingsStateService: PlantingsStateService,
    private _fieldsStateService: FieldsStateService,
    private _mapFeatureService: MapFeaturesService,
    private _mapService: MapService,
    private _cropService: CropService,
    private _agSubscriptionService: AgSubscriptionService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fieldSub = this._route.params
      .pipe(
        filter((params: Params) => {
          return params['fieldId'];
        }),
        this._fieldsStateService.loadEntityOpr('fieldId')
      )
      .subscribe((field: Field) => {
        this.field = field;
        this._mapFeatureService.addAnnotation(this.field.geometry);
        this._mapService.animateToBounds(
          (this.field.geometry.getGeometry() as any).getExtent(),
          [0, 0, 0, 410]
        );

        // set incoming Field as selected
        this.setField();
      });

    this._plantingNameSub = this.plantingNameCtrl.valueChanges.subscribe(
      val => {
        this.plantingNameChosen = val;
        this.plantingNamed = val ? true : false;
        this.checkStepOneReqs();
      }
    );

    this._cropService
      .list({
        size: '1000'
      })
      .pipe(
        map((resp: HttpResponse<Crop>) => {
          return resp.body;
        })
      )
      .subscribe(resp => {
        this.cropList = resp;
      });

    this.cropTypeCtrl.valueChanges.subscribe(res => {
      this.checkStepOneReqs();
    });
  }

  setField() {
    this.fieldSelectCtrl.setValue({
      name: this.field.name,
      id: this.field.id
    });
  }

  currentStep(step: number) {
    switch (step) {
      case 1:
        this.checkStepOneReqs();
        break;
    }
  }

  checkStepOneReqs() {
    this.requiredFieldsMet =
      this.startDate &&
      this.endDate &&
      this.fieldSelectCtrl.value.id &&
      this.cropTypeCtrl.value.id
        ? false
        : true;
  }

  stepperCompleted() {
    this.plantingCompleted = true;
    this.createPlanting();
  }

  startDatePicked(date: number) {
    this.showEndDateInput = true;
    this.startDate = date;
    this.checkStepOneReqs();
  }

  endDatePicked(date: number) {
    this.endDate = date;
    this.checkStepOneReqs();
  }

  subscriptionData(data: Partial<AgSubscription>[]) {
    this.subData = data;
  }

  createPlanting() {
    const fieldJSON = this.field.toJSON();
    const plantingObject = {
      ...this.planting,
      fieldId: this.fieldSelectCtrl.value.id,
      name: this.plantingNameChosen || this.cropTypeCtrl.value.name,
      geometry: fieldJSON['geometry'],
      cropId: this.cropTypeCtrl.value.id,
      growingCycleStart: this.startDate,
      growingCycleEnd: this.endDate,
      weakFieldThreshold: 2
    };

    this._plantingsStateService
      .$create(new Planting(plantingObject))
      .pipe(take(1))
      .subscribe((planting: Planting) => {
        const finalSubData = this.subData.map(sub => {
          sub.plantingId = planting.id;
          return sub;
        });

        forkJoin(
          finalSubData.map((sub: AgSubscription) => {
            return this._agSubscriptionService.create(sub);
          })
        )
          .pipe(take(1))
          .subscribe(
            _ => {
              // this._toast.alert('success', 'Subscription updated.');
            },
            (err: HttpErrorResponse) => {
              /* this._toast.alert(
                'error',
                `Subscription failed. ${err.error.detail}`
              ); */
            }
          );
      });
  }

  ngOnDestroy() {
    this.fieldSub.unsubscribe();
    this._plantingNameSub.unsubscribe();
    this._mapFeatureService.clearAnnotations();
  }
}
