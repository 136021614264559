import { BaseModel } from './base.model';

export class Airport extends BaseModel {
  id: string;
  name: string;
  type: string;
  icao: string;
  iata: string;
  state: string;
  city: string;
  county: string;
  latitude: number;
  longitude: number;
  readonly created: number;
  readonly modified: number;
}
