import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { FilterService } from './filter-box.service';
import { Subscription } from 'rxjs';

export interface FilterBoxOptions {
  useNewStyle?: boolean; // deprecated
  isSearchBox?: boolean;
  showToggler?: boolean; // deprecated
  disableToggler?: boolean;
  showInput?: boolean;
}

@Component({
  selector: 'filter-box',
  template: `
    <!-- prettier-ignore -->
    <div class="search-box {{ size }}-box">
      <input #filterInput
        class="input alt-style postfixed"
        type="search"
        [formControl]="filterInputCtrl"
        [placeholder]="placeholder">
      <i class="icon-search input-postfix"></i>
    </div>
  `,
  styleUrls: ['../search-box/search-box.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBoxComponent implements OnInit, OnDestroy {
  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  get placeholder(): string {
    return this.isSearchBox
      ? `Search ${this._placeholder}`
      : `Filter ${this._placeholder}`;
  }

  @Input()
  size: 'small' | 'large' = 'small';

  @Input()
  set options(options: FilterBoxOptions) {
    this._options = options;
  }

  get useNewStyle(): boolean {
    // deprecated; uses new style by default
    return this._options.useNewStyle ? true : false;
  }

  get isSearchBox(): boolean {
    return this._options.isSearchBox ? true : false;
  }

  get showToggler(): boolean {
    // deprecated; shows toggler by default
    return this._options.showToggler ? true : false;
  }

  get disableToggler(): boolean {
    return this._options.disableToggler === undefined
      ? true
      : this._options.disableToggler;
  }

  get showInput(): boolean {
    return this._options.showInput === undefined
      ? true
      : this._options.showInput;
  }

  set showInput(showInput: boolean) {
    this._options.showInput = showInput;
  }

  get togglerTitleAttr(): string {
    if (this.disableToggler) {
      return undefined;
    }
    return this.showInput
      ? `Hide ${this.isSearchBox ? 'Search' : 'Filter'} Box`
      : this.placeholder;
  }

  get control(): FormControl {
    return this.filterInputCtrl;
  }

  get element(): HTMLInputElement {
    return this._input.nativeElement;
  }

  protected filterInputCtrl = new FormControl();

  @ViewChild('filterInput')
  private _input: ElementRef<HTMLInputElement>;

  private _filterSub: Subscription;
  private _placeholder: string;
  private _options: FilterBoxOptions = {};

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _filterService: FilterService
  ) {}

  toggleInput(): void {
    this.showInput = !this.showInput;
    if (this.showInput) {
      window.setTimeout(() => this.element.focus(), 10);
    } else {
      this.filterInputCtrl.setValue('');
    }
    this._changeDetector.markForCheck();
  }

  ngOnInit() {
    // Define subscription and pass to filterService
    // event emitter that fires when formControl value changes
    this._filterSub = this.filterInputCtrl.valueChanges.subscribe(data =>
      this._filterService.updateText(data)
    );
  }

  ngOnDestroy() {
    this._filterService.updateText('');
    this._filterSub.unsubscribe();
  }
}
