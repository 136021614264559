import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { SimpleSelectDropdownService } from './simple-select-dropdown.service';
import { Subscription } from 'rxjs';

const DISPLAYED_OPTION = '--';

@Component({
  selector: 'simple-select-dropdown',
  templateUrl: './simple-select-dropdown.template.html',
  styleUrls: ['./simple-select-dropdown.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleSelectDropdownComponent implements OnChanges, OnInit, OnDestroy {
  @Input() options: string[] = [];
  @Input() disabled = false;
  @Input() _dropdownService: SimpleSelectDropdownService;

  dropdownOpenSubscription: Subscription;
  dropdownOpen: boolean = false;

  dropdownOptionsSubscriptions: Subscription;
  dropdownOptions: string[] = [];

  displatedOptionSubscription: Subscription;
  displayedOption: string;

  selectedSubscription: Subscription;
  selected: string;

  constructor() { }

  ngOnInit() {
    this.dropdownOpenSubscription = this._dropdownService.dropdownOpen$.subscribe((dropdownOpen: boolean) => {
      this.dropdownOpen = dropdownOpen;
    })

    this.dropdownOptionsSubscriptions = this._dropdownService.options$.subscribe((options: string[]) => {
      this.dropdownOptions = options;
    })

    this.displatedOptionSubscription = this._dropdownService.displayedOption$.subscribe((displayedOption: string) => {
      this.displayedOption = displayedOption;
    })

    this.selectedSubscription = this._dropdownService.selected$.subscribe((selected: string) => {
      this.selected = selected;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options && this._dropdownService) {
      this._dropdownService.$setOptions(this.options);
    }
  }

  ngOnDestroy() {

  }

}
