import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'password-checklist',
  styleUrls: ['./passwordChecklist.component.scss'],
  template: `
    <div class="password-checklist">
      <label class="title">Password Requirements</label>
      <div class="checklist-wrapper">
        <div
          class="checklist"
          [ngClass]="{
            error:
              formName.controls.pwd.errors?.minlength &&
              formName.controls.pwd.dirty,
            success:
              !formName.controls.pwd.errors?.minlength &&
              formName.controls.pwd.dirty,
            empty: !formName.controls.pwd.value.length
          }"
        >
          <i
            class="icon icon-disclosure_right"
            *ngIf="!formName.controls.pwd.value.length"
          ></i>
          <i
            class="icon icon-close"
            *ngIf="
              formName.controls.pwd.errors?.minlength &&
              formName.controls.pwd.dirty
            "
          ></i>
          <i
            class="icon icon-check"
            *ngIf="
              !formName.controls.pwd.errors?.minlength &&
              formName.controls.pwd.value.length
            "
          ></i>
          <p class="requirement">At least 8 characters long</p>
        </div>
        <div
          class="checklist"
          [ngClass]="{
            error:
              formName.controls.pwd.errors?.pattern &&
              formName.controls.pwd.dirty,
            success:
              !formName.controls.pwd.errors?.pattern &&
              formName.controls.pwd.dirty,
            empty: !formName.controls.pwd.value.length
          }"
        >
          <i
            class="icon icon-disclosure_right"
            *ngIf="!formName.controls.pwd.value.length"
          ></i>
          <i
            class="icon icon-close"
            *ngIf="
              formName.controls.pwd.errors?.pattern &&
              formName.controls.pwd.dirty
            "
          ></i>
          <i
            class="icon icon-check"
            *ngIf="
              !formName.controls.pwd.errors?.pattern &&
              formName.controls.pwd.value.length
            "
          ></i>
          <p class="requirement">
            At least one number, one upper-case letter, and a special chracter
            are required. The following characters are supported: $@$!%*?&.
          </p>
        </div>
        <div
          class="checklist"
          [ngClass]="{
            error:
              formName.controls.confirmPwd.errors?.passwordMatch &&
              formName.controls.confirmPwd.dirty,
            success:
              !formName.controls.confirmPwd.errors?.passwordMatch &&
              formName.controls.confirmPwd.dirty,
            empty: !formName.controls.confirmPwd.value.length
          }"
        >
          <i
            class="icon icon-disclosure_right"
            *ngIf="!formName.controls.confirmPwd.value.length"
          ></i>
          <i
            class="icon icon-close"
            *ngIf="
              formName.controls.confirmPwd.errors?.passwordMatch &&
              formName.controls.confirmPwd.dirty &&
              formName.controls.confirmPwd.value.length
            "
          ></i>
          <i
            class="icon icon-check"
            *ngIf="
              !formName.controls.confirmPwd.errors?.passwordMatch &&
              formName.controls.confirmPwd.value.length
            "
          ></i>
          <p class="requirement">Passwords must match</p>
        </div>
      </div>
    </div>
  `
})
export class PasswordChecklistComponent {
  @Input() formName: FormGroup;
  constructor() {}
}
