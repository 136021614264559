import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'inline-edit',
  templateUrl: './inline-edit.template.html',
  styleUrls: ['./inline-edit.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditComponent implements OnChanges {
  @Input()
  text: string;
  @Input()
  type = 'text';
  @Input()
  alwaysShowBtn = false;
  @Input()
  neverShowBtn = false;
  @Input()
  canEdit = true;
  @Input()
  theme: 'light' | 'dark' = 'light';
  @Input()
  size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';
  @Output()
  onChange = new EventEmitter<string>();
  @Output()
  onCancel = new EventEmitter<string>();

  @Input()
  isEditing: boolean = false;

  @ViewChild('editInput')
  private _editInput: ElementRef;
  private _originalText: string;
  private _skipBlur: boolean;

  startEdit(event: Event) {
    event.stopPropagation();

    this.isEditing = true;
    this._originalText = this.text;

    // NOTE: timeout is needed here, to account for the brief period before the
    // input field is unhidden
    setTimeout(() => {
      this._editInput.nativeElement.focus();
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isEditing']) {
      if (changes['isEditing'].currentValue === true) {
        this._originalText = this.text;
        setTimeout(() => {
          this._editInput.nativeElement.focus();
        }, 10);
      }
    }
  }
  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  saveEdit(input: HTMLInputElement, event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.isEditing = false;
    this._skipBlur = true;

    if (input.value != this.text) {
      this.text = input.value;
      this.onChange.emit(this.text);
    }
  }

  cancelEdit(input: HTMLInputElement, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    // NOTE: note this is needed to be able to not run the cancel stuff
    // if the blur event gets triggered when the uaer hits enter
    if (this._skipBlur) {
      this._skipBlur = false;
      return;
    }
    this.isEditing = false;
    input.value = this.text = this._originalText;
    this.onCancel.emit(this._originalText);
  }
}
