import { BaseModel } from './base.model';
import { Address } from '../interfaces/address.interface';

export enum RoleType {
  AerialAdmin = 'AERIAL_ADMIN',
  Customer = 'CUSTOMER',
  CustomerAdmin = 'CUSTOMER_ADMIN',
  Vendor = 'VENDOR',
  VendorAdmin = 'VENDOR_ADMIN',
  Pilot = 'PILOT',
  Grower = 'GROWER'
}

export enum OperationType {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE',
  CreateOrder = 'CREATE_ORDER'
}

export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Invited = 'INVITED'
}

export enum PortalType {
  Ag = 'AG',
  Oilgas = 'OILGAS',
  Services = 'SERVICES',
  Insurance = 'INSURANCE',
  Catalog = 'CATALOG'
}

export enum UasType {
  FixedWing = 'FIXED_WING',
  MultiRotor = 'MULTI_ROTOR'
}

export interface Role {
  id?: string;
  created?: number;
  modified?: number;
  parentId: string;
  parentType: string | RoleType;
  roleType: string | RoleType;
}

export interface UserInvite {
  id?: string;
  role?: string;
  roles?: Role[];
  permissionSet?: UserPermission[];
  username: string;
  type?: PortalType;
}

export interface UserInfo {
  homePhone?: string;
  workPhone?: string;
  mobilePhone?: string;
  address?: Address;
}

export interface UserPermission {
  created?: number;
  entityId: string;
  entityType: string;
  id?: string;
  modified?: number;
  operations: OperationType[];
  parentId?: string;
  parentType?: string;
}

export interface PilotInfo {
  pilotCertificationNumber: string;
  pilotCertificationDate: number;
  uasTypes?: UasType[];
}

export enum UserPreferenceReferenceType {
  Project = 'PROJECT',
  Order = 'ORDER',
  Mission = 'MISSION',
  Workspace = 'WORKSPACE',
  Inspection = 'INSPECTION',
  Grower = 'GROWER'
}

export interface UserPreference {
  id: string;
  referenceId: string;
  referenceType: UserPreferenceReferenceType;
  preference: any;
  created: number;
  modified: number;
}

export interface AuthProviderUser {
  email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
  roles: Role[];
  userInfo?: UserInfo;
  pilotInfo?: PilotInfo;
}

export class User extends BaseModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatus;
  userInfo: UserInfo | null;
  pilotInfo: PilotInfo | null;
  roles?: Role[];
  permissions: UserPermission[];
  userPreferences?: UserPreference[];
  attributes: any;
  created: number;
  modified: number;
  failedLoginAttempt: number;
  failedLoginTime: number;

  get fullName(): string {
    return `${this.firstName ? this.firstName : ''} ${
      this.lastName ? this.lastName : ''
    }`.trim();
  }

  get isPilot(): boolean {
    const roles = this.roles.map(role => role.roleType);
    return roles.includes(RoleType.Pilot);
  }

  get isAerialAdmin(): boolean {
    const roles = this.roles.map(role => role.roleType);
    return roles.includes(RoleType.AerialAdmin);
  }

  get isCustomerAdmin(): boolean {
    const roles = this.roles.map(role => role.roleType);
    return roles.includes(RoleType.CustomerAdmin);
  }

  get isVendorAdmin(): boolean {
    const roles = this.roles.map(role => role.roleType);
    return roles.includes(RoleType.VendorAdmin);
  }

  get isGrower(): boolean {
    const roles = this.roles.map(role => role.roleType);
    return roles.includes(RoleType.Grower);
  }

  /**
   * @deprecated
   */
  get isAnyAdmin(): boolean {
    // TODO: this method should be deprecated, in favor of
    // combining the more specific admin checks above
    return this.isAerialAdmin || this.isCustomerAdmin;
  }

  get customerParentId(): string {
    const parentRole = this.roles.find(
      role => role.parentType === RoleType.Customer
    );
    return parentRole ? parentRole.parentId : undefined;
  }

  get vendorParentId(): string {
    const parentRole = this.roles.find(
      role => role.parentType === RoleType.Vendor
    );
    return parentRole ? parentRole.parentId : undefined;
  }

  get customerRoles(): Role[] {
    return this.roles.filter(role => role.parentType === RoleType.Customer);
  }

  get vendorRoles(): Role[] {
    return this.roles.filter(role => role.parentType === RoleType.Vendor);
  }

  get stubbedUserInfo(): UserInfo {
    return {
      homePhone: '',
      workPhone: '',
      mobilePhone: '',
      address: this.stubbedAddressInfo
    };
  }

  get stubbedAddressInfo(): Address {
    return {
      line1: '',
      line2: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: 'US'
    };
  }

  get stubbedPilotInfo(): PilotInfo {
    return {
      pilotCertificationNumber: undefined,
      pilotCertificationDate: undefined,
      uasTypes: []
    };
  }

  hasProjectPermission(projectId: string, operation: OperationType): boolean {
    return (
      this.permissions
        .filter((permission: UserPermission) => {
          return permission.entityId === projectId;
        })
        .map((permission: UserPermission) => {
          return permission.operations;
        })
        .reduce((prev: OperationType[], curr: OperationType[]) => {
          return prev.concat(curr);
        }, [])
        .includes(operation) || this.isAerialAdmin
    );
  }

  canCreateOrderWithinProject(projectId: string): boolean {
    return this.hasProjectPermission(projectId, OperationType.CreateOrder);
  }

  canCreateAoiWithinProject(projectId: string): boolean {
    return this.hasProjectPermission(projectId, OperationType.Create);
  }
}
