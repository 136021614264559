import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  Validators
} from '@angular/forms';
import { handleBooleanProperty } from '../../util/helpers';
import { Subscription } from 'rxjs';
import { User } from 'projects/api/src/public_api';

@Component({
  selector: 'user-select',
  template: `
    <!-- prettier-ignore -->
    <select #userSelect
      [formControl]="userSelectCtrl"
      [class.invalid]="required && control.touched && control.invalid"
      [attr.id]="id"
      (blur)="onTouched()">
      <option *ngFor="let user of users" [ngValue]="user.id">
        {{user.fullName ? user.fullName : user.email}}
      </option>
    </select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true
    }
  ]
})
export class UserSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  users: User[];

  @Input()
  id: string;

  @Input()
  set required(required: boolean) {
    this._required = handleBooleanProperty(required);
    if (this._required) {
      this.userSelectCtrl.setValidators(Validators.required);
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set disabled(disabled: boolean) {
    disabled = handleBooleanProperty(disabled);
    this.setDisabledState(disabled);
  }

  get disabled(): boolean {
    return this.userSelectCtrl.disabled;
  }

  @Input()
  set value(value: string) {
    this.writeValue(value);
  }

  get value(): string {
    return this.userSelectCtrl.value;
  }

  @Output()
  change = new EventEmitter<string>();

  @HostBinding('class.form-control')
  hasFormCtrlClass = true;

  @HostBinding('class.select-control')
  hasSelectClass = true;

  @HostBinding('class.user-select')
  hasIdentClass = true;

  get control(): FormControl {
    return this.userSelectCtrl;
  }

  get element(): HTMLSelectElement {
    return this._select.nativeElement;
  }

  protected userSelectCtrl = new FormControl('');
  private _required = false;
  private _changesSub: Subscription;

  @ViewChild('userSelect')
  private _select: ElementRef<HTMLSelectElement>;

  protected onTouched = () => {};
  private _onChange = (userId: string) => {};

  constructor(public el: ElementRef) {}

  writeValue(userId: string): void {
    this.userSelectCtrl.setValue(userId);
  }

  registerOnChange(fn: (userId: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.userSelectCtrl.disable();
    }
  }

  ngOnInit(): void {
    this._changesSub = this.userSelectCtrl.valueChanges.subscribe(value => {
      this._onChange(value);
      this.change.emit(value);
    });
  }

  ngOnDestroy(): void {
    this._changesSub.unsubscribe();
  }
}
