import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { User } from 'projects/api/src/public_api';

@Component({
  selector: 'help-menu-content',
  templateUrl: './help-menu-content.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpMenuContentComponent {
  @Input()
  user: User;

  @Input()
  includeUsersnap = false;

  @Input()
  supportEmail = 'support@airbus-na.com';

  @Output()
  openUsersnap = new EventEmitter<void>();
}
