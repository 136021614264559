import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { ExternalApiKey } from '../models/external-api-key.model';
import { Observable } from 'rxjs';

@Injectable()
export class ExternalApiKeyService extends BaseService {
  protected endpointUrl: string;
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.authUrl}/keys/external`;
  }

  getExternalApiKey(customerId: string): Observable<ExternalApiKey> {
    return this.httpClient.get<ExternalApiKey>(
      `${this.endpointUrl}/customer/${customerId}`
    );
  }

  generateExternalApiKey(customerId: string): Observable<ExternalApiKey> {
    return this.httpClient.post<ExternalApiKey>(
      `${this.endpointUrl}/customer/${customerId}`,
      {}
    );
  }

  patchExternalApiKey(keyId: string, body: Object) {
    return this.httpClient.patch(`${this.endpointUrl}/${keyId}`, body);
  }
}
