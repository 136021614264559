import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl
} from '@angular/forms';
import { User, UserService, RoleType, Grower, GrowerService, PagedResponse, OperationType } from 'api/src/public_api';
import { Dialog, ToasterService, DialogService } from 'projects/ui/src/public_api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './edit-user-dialog.template.html',
  styleUrls: ['./edit-user-dialog.styles.scss']
})
export class EditUserDialogComponent implements Dialog, OnInit {
  user: User;
  deleteUser: Function;
  successCallback: Function;

  growers$: Observable<Grower[]>;
  selectedGrowers = [];
  showGrowers: boolean = true;
  filteredList = [];

  editForm: FormGroup;
  editing = false;
  roleTypes = [
    {
      value: RoleType.Grower,
      label: 'Grower'
    },
    {
      value: RoleType.Customer,
      label: 'User'
    },
    {
      value: RoleType.CustomerAdmin,
      label: 'Admin'
    }
  ];

  get firstNameCtrl(): FormControl {
    return this.editForm.get('firstName') as FormControl;
  }

  get lastNameCtrl(): FormControl {
    return this.editForm.get('lastName') as FormControl;
  }

  get roleCtrl(): FormControl {
    return this.editForm.get('role') as FormControl;
  }

  constructor(
    private _fb: FormBuilder,
    private _userService: UserService,
    private _toaster: ToasterService,
    private _dialogService: DialogService,
    private _growerService: GrowerService,
  ) {}

  close = () => {};

  delete = () => {
    const confirmMsg = this.user.fullName
    ? `<strong>${this.user.fullName}</strong>`
    : 'this user';
    this._dialogService.confirm(
      `Are you sure you want to delete ${confirmMsg}?`,
      () => this._deleteUser(),
      'Delete User',
      () => {},
      'Yes',
      'No',
      undefined,
      'icon-trash',
      'danger'
    );
  };

  editUser() {
    this.editing = true;

    let customerId = this.user.roles.filter(r => { return r.parentType == 'CUSTOMER' }).map(r => { return r.parentId })[0];

    this._userService
      .patchUser(this.user.id, {
        firstName: this.firstNameCtrl.value,
        lastName: this.lastNameCtrl.value,
        ...(this.roleCtrl.value === 'GROWER' && {
          permissions: this.selectedGrowers.map(growerId => {
            return {
              entityType: 'GROWER',
              entityId: growerId,
              parentType: 'USER',
              parentId: customerId,
              operations: [
                OperationType.Update,
                OperationType.Create,
                OperationType.Read
              ]
            }
          })
        }),
      } as Partial<User>)
      .then(_ => {
        this.user.firstName = this.firstNameCtrl.value;
        this.user.lastName = this.lastNameCtrl.value;
        this.successCallback(this.user);
        this.close();
      })
      .catch(err => {
        this.editing = false;
        console.error('error updating user', err);
        this._toaster.toast('error', `Couldn't update user. Please try again.`);
      });
  }

  private _deleteUser(): void {
    this.close()
    this._userService
      .deleteUser(this.user)
      .then(_ => {
        this._toaster.toast('success', 'User removed!');
      })
      .catch(err => {
        this._toaster.toast(
          'error',
          'Unable to remove user. Please try again.'
        );
        console.error('error deleting user', err, this.user);
      });
  }

  ngOnInit() {
    this.growers$ = this._growerService.list().pipe(
      map((resp: PagedResponse<Grower>) => {
        this.filteredList = resp.data;
        this.filteredList.map(grower => {
          (grower as any).multiSelectLabels = [grower.name]
        })
        return resp.data;
      })
    );

    this.editForm = this._fb.group({
      email: [this.user.email, Validators.required],
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      role: [RoleType.Customer, Validators.required],
    });
    if (this.user.roles && this.user.roles[0].roleType) {
      this.editForm.get('role').setValue(this.user.roles[0].roleType);

      if (this.user.roles[0].roleType == RoleType.Grower) {
        this.selectedGrowers = this.user.permissions.map(permission => { return permission.entityId})
      }
    }
  }
}
