import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ComboLink } from './combo-link.model';

@Component({
  selector: 'combo-link',
  template: `
    <!-- prettier-ignore -->
    <a [ngClass]="classes">
      <span class="combo-text">
        <ng-content></ng-content>
      </span>
      <i class="combo-icon {{ iconClass }}"></i>
    </a>
  `,
  styleUrls: ['./combo-link.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboLinkComponent extends ComboLink {
  otherClasses: string[] = [];
}
