import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit
} from '@angular/core';
import { AerialButton } from '../aerialButton.model';

@Component({
  selector: 'logo-button',
  template: `
    <!-- prettier-ignore -->
    <button [ngClass]="allClasses" [type]="type" [disabled]="disabled">
      <i class="btn-icon icon-airbus_logo"></i>
    </button>
  `,
  styleUrls: ['./logo-button.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoButtonComponent extends AerialButton implements OnInit {
  @Input()
  borderless = false;

  @Input()
  set classes(classes: string[] | string) {
    this._classes = this.handleClasses(classes);
  }

  get classes(): string[] | string {
    return this._classes.concat([
      'logo-btn',
      'square',
      this.borderless ? '' : 'with-border'
    ]);
  }

  private _classes: string[] = [];

  ngOnInit(): void {
    this.color = 'primary';
  }
}
