import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'map-toggler',
  template: `
    <!-- prettier-ignore -->
    <button type="button" [disabled]="disabled"
      class="map-toggler combo-element align-icon-right small">
      <span class="combo-text btn-text">
        <ng-content></ng-content>
      </span>
      <i class="combo-icon btn-icon icon-triangle_{{ expanded ? 'down' : 'up' }}"></i>
    </button>
  `,
  styleUrls: ['./map-toggler.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapTogglerComponent {
  @Input()
  expanded = false;

  @Input()
  disabled = false;
}
