import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  DataProduct,
  dataProductTypes,
  DataProductCategory
} from 'projects/api/src/lib/models/dataProduct.model';
import { DataProductType } from 'projects/api/src/lib/interfaces/dataProductType.interface';
import { Badge } from '../badge.model';

export const dataProdCatTypes: DataProductType[] = [
  {
    name: DataProductCategory.UserProvided,
    displayName: 'Imported Layer',
    iconClass: 'icon-arrow_up'
  },
  {
    name: DataProductCategory.Utility,
    displayName: 'Utility Layer',
    iconClass: 'icon-lightning',
    fillClass: 'fill-yellow'
  }
];

@Component({
  selector: 'product-type-badge',
  template: `
    <!-- prettier-ignore -->
    <i *ngIf="iconOnly"
      [ngClass]="classes"
      class="icon {{ iconClass }}"
      [attr.title]="displayName">
    </i>
    <!-- prettier-ignore -->
    <combo-badge *ngIf="!iconOnly"
      [classes]="classes"
      [iconClass]="iconClass">
      {{ displayName }}
    </combo-badge>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductTypeBadgeComponent extends Badge {
  @Input()
  set productType(productType: string) {
    this._productType = productType;
    this._badge = this._badges.find(badge => badge.name === productType);
  }

  @Input()
  set dataProduct(dataProduct: DataProduct) {
    this._dataProduct = dataProduct;
    if (!this._badge) {
      this._badge = this._badges.find(badge => {
        return (
          badge.name === dataProduct.productType ||
          badge.name === dataProduct.category
        );
      });
    }
  }

  @Input() iconOnly = false;

  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' = 'small';

  get productType(): string {
    return this._productType;
  }

  get dataProduct(): DataProduct {
    return this._dataProduct;
  }

  get displayName(): string {
    if (this._badge) {
      return this._badge.displayName
        ? this._badge.displayName
        : this._badge.name;
    } else if (this.productType) {
      return this.productType;
    } else if (this.dataProduct && this.dataProduct.productTypeDisplay) {
      return this.dataProduct.productTypeDisplay;
    } else {
      return '';
    }
  }

  get fillClass(): string {
    return this._badge && this._badge.fillClass ? this._badge.fillClass : '';
  }

  get iconClass(): string {
    return this._badge ? this._badge.iconClass : this._defaultIcon;
  }

  get otherClasses(): string[] {
    return [this.fillClass, this.size];
  }

  private _productType: string;
  private _dataProduct: DataProduct;

  private _badges = dataProductTypes.concat(dataProdCatTypes);
  private _badge: DataProductType;

  private readonly _defaultIcon = 'icon-data_product';
}
