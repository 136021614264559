import { BaseModel } from './base.model';
import { DataProcess } from './dataProcess.model';
import { Mission } from './mission.model';
import { Task } from './task.model';
import {
  RequirementStatus,
  RequirementType
} from '../interfaces/requirement.interface';

export interface FulfillmentStep {
  data: Mission | Task | DataProcess;
  reqId: string; // numeric string
  type: RequirementType;
  status: RequirementStatus;
}

export class MissionStep extends BaseModel implements FulfillmentStep {
  reqId: string;
  status: RequirementStatus;
  type = RequirementType.Mission;

  set data(data: Mission) {
    this._mission = new Mission(data);
  }

  get data(): Mission {
    return this._mission;
  }

  private _mission: Mission;
}

export class ProcessStep extends BaseModel implements FulfillmentStep {
  reqId: string;
  status: RequirementStatus;
  type = RequirementType.Process;

  set data(data: DataProcess) {
    this._dataProcess = new DataProcess(data);
  }

  get data(): DataProcess {
    return this._dataProcess;
  }

  private _dataProcess: DataProcess;
}

export class TaskStep extends BaseModel implements FulfillmentStep {
  reqId: string;
  status: RequirementStatus;
  type = RequirementType.Task;

  set data(data: Task) {
    this._task = new Task(data);
  }

  get data(): Task {
    return this._task;
  }

  private _task: Task;
}

export type FulfillmentStepType = MissionStep | ProcessStep | TaskStep;

export class FulfillmentSteps extends BaseModel {
  get missions(): MissionStep[] {
    return this._missions;
  }

  set missions(missions: MissionStep[]) {
    this._missions = missions ? missions.map(m => new MissionStep(m)) : [];
  }

  get processes(): ProcessStep[] {
    return this._processes;
  }

  set processes(processes: ProcessStep[]) {
    this._processes = processes ? processes.map(p => new ProcessStep(p)) : [];
  }

  get tasks(): TaskStep[] {
    return this._tasks;
  }

  set tasks(tasks: TaskStep[]) {
    this._tasks = tasks ? tasks.map(t => new TaskStep(t)) : [];
  }

  private _missions: MissionStep[];
  private _processes: ProcessStep[];
  private _tasks: TaskStep[];
}
