import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import {
  AnonymousUser,
  AnonymousUserScope
} from '../interfaces/anonymousUser.interface';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { MissionHistory } from '../interfaces/missionHistory.interface';
import { VendorNote } from '../interfaces/vendorNote.interface';
import { Task } from '../models/task.model';
import { Mission, Tag } from '../models/mission.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MissionService extends BaseService {
  readonly anonExpirationDays = UserService.anonExpirationDays;
  protected endpointUrl: string;

  constructor(
    protected httpClient: HttpClient,
    private _userService: UserService
  ) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/missions`;
    this.defaultSize = 750;
  }

  protected mapJsonToCollection(respBody: Mission[]): Mission[] {
    return respBody.map(json => new Mission(json));
  }

  getMissions(): Observable<Mission[]> {
    return this.httpClient
      .get<Mission[]>(`${this.endpointUrl}?size=${this.defaultSize}`)
      .pipe(map(respBody => this.mapJsonToCollection(respBody)));
  }

  getPagedMissions(
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<Mission>> {
    const searchUrl = searchQuery
      ? `${this.endpointUrl}?search=${searchQuery}`
      : this.endpointUrl;
    return this.getWithPagedResponse<Mission>(
      searchUrl,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getMission(missionId: string): Observable<Mission> {
    return this.httpClient
      .get<Mission>(`${this.endpointUrl}/${missionId}`)
      .pipe(map(resp => new Mission(resp)));
  }

  patchMission(
    missionId: string,
    params: object
  ): Observable<HttpResponse<Mission>> {
    return this.patchWithResponse<Mission>(
      `${this.endpointUrl}/${missionId}`,
      params
    );
  }

  getMissionsForVendor(vendorId: string): Observable<Mission[]> {
    return this.httpClient
      .get<Mission[]>(`${this.endpointUrl}/vendor/${vendorId}`)
      .pipe(map(data => this.mapJsonToCollection(data)));
  }

  getPagedMissionsForVendor(
    vendorId: string,
    searchQuery = '',
    baseParams?: BaseParams
  ): Observable<PagedResponse<Mission>> {
    let url = `${this.endpointUrl}/vendor/${vendorId}`;
    if (searchQuery) {
      url = `${url}?search=${searchQuery}`;
    }
    return this.getWithPagedResponse<Mission>(
      url,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getMissionsForPilot(pilotId: string): Observable<Mission[]> {
    return this.httpClient
      .get<Mission[]>(`${this.endpointUrl}/pilot/${pilotId}`)
      .pipe(map(respBody => this.mapJsonToCollection(respBody)));
  }

  getPagedMissionsForPilot(
    pilotId: string,
    baseParams?: BaseParams
  ): Observable<PagedResponse<Mission>> {
    return this.getWithPagedResponse<Mission>(
      `${this.endpointUrl}/pilot/${pilotId}`,
      this.mapJsonToCollection,
      baseParams
    );
  }

  getTasksForMission(missionId: string): Promise<Task[]> {
    // prettier-ignore
    return this.httpClient
      .get<Task[]>(`${this.endpointUrl}/${missionId}/tasks`)
      .pipe(
        map(
          // prettier-ignore
          respBody => respBody.map(json => new Task(json))
        )
      )
      .toPromise();
  }

  getPagedTasksForMission(
    missionId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Task>> {
    return this.getWithPagedResponse<Task>(
      `${this.endpointUrl}/${missionId}/tasks`,
      (respBody: Task[]) => {
        return respBody.map(json => new Task(json));
      },
      baseParams
    ).toPromise();
  }

  getMissionHistory(missionId: string): Promise<MissionHistory[]> {
    // prettier-ignore
    return this.httpClient
      .get<MissionHistory[]>(
        `${
          this.endpointUrl
        }/${missionId}/history?page=0&size=200&sort=created,desc`
      ).pipe(
        map(respBody => respBody.map(json => json as MissionHistory))
      )
      .toPromise();
  }

  getPagedMissionHistory(
    missionId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<MissionHistory>> {
    return this.getWithPagedResponse<MissionHistory>(
      `${this.endpointUrl}/${missionId}/history`,
      (respBody: MissionHistory[]) => {
        return respBody.map(json => json as MissionHistory);
      },
      baseParams
    ).toPromise();
  }

  addTagToMission(missionId: string, tag: Tag): Promise<string> {
    return this.createWithResponseAsUrl(
      `${this.endpointUrl}/${missionId}/tags`,
      tag
    ).toPromise();
  }

  removeTagFromMission(
    missionId: string,
    tag: Tag
  ): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${missionId}/tags/${tag.name}`
    ).toPromise();
  }

  getVendorNotes(missionId: string, token?: string): Observable<VendorNote[]> {
    const headers = token ? { headers: this.buildAuthHeader(token) } : {};
    return this.httpClient
      .get<VendorNote[]>(
        `${this.endpointUrl}/${missionId}/vendorNotes`,
        headers
      )
      .pipe(
        // prettier-ignore
        map(resp => resp.map(json => json as VendorNote))
      );
  }

  addVendorNote(
    missionId: string,
    content: string,
    token?: string
  ): Promise<HttpResponse<VendorNote>> {
    const headers = token ? { headers: this.buildAuthHeader(token) } : {};
    return this.httpClient
      .post<VendorNote>(
        `${this.endpointUrl}/${missionId}/vendorNotes`,
        { content },
        { ...headers, observe: 'response' }
      )
      .toPromise();
  }

  createAnonymousUser(mission: Mission, taskIds: string[]): Promise<string> {
    const missionScope: AnonymousUserScope = {
      name: 'uploadMissionAssets',
      params: { missionId: mission.id, ownerId: mission.ownerId }
    };
    const tasksScope: AnonymousUserScope = {
      name: 'uploadTaskAssets',
      params: { taskIds }
    };
    const anonUserItem: AnonymousUser = {
      expirationDays: this.anonExpirationDays,
      scopes: [missionScope, tasksScope]
    };
    return this._userService.createAnonUser(anonUserItem);
  }

  getPublicMission(token: string): Observable<Mission> {
    const headers = this.buildAuthHeader(token);
    return this.httpClient.get<Mission[]>(this.endpointUrl, { headers }).pipe(
      map(data => this.mapJsonToCollection(data)),
      map(missions => missions[0])
    );
  }

  getTasksForPublicMission(token: string): Promise<Task[]> {
    const headers = this.buildAuthHeader(token);
    return this.httpClient
      .get<Task[]>(`${this.endpointUrl}/tasks`, { headers })
      .pipe(
        map(
          // prettier-ignore
          resp => resp.map(json => new Task(json))
        )
      )
      .toPromise();
  }

  // OLD ASP METHODS - UNCOMMENT TO BUILD OLD ASP
  // DEPRECATED!
  // searchEntriesByStatus(term: string): Observable<Mission[]> {
  //   return this.httpClient
  //     .get<Mission[]>(`${this.endpointUrl}?size=500&search=status==${term}`)
  //     .pipe(map(resp => this.mapJsonToCollection(resp)));
  // }

  // searchMissionsByStatus(
  //   status: MissionStatus,
  //   baseParams?: BaseParams
  // ): Observable<PagedResponse<Mission>> {
  //   return this.getPagedMissions(baseParams, status, 'status');
  // }

  // searchEntriesByStatusAndType(
  //   term: string,
  //   type: string,
  //   id: string
  // ): Observable<Mission[]> {
  //   return this.httpClient
  //     .get<Mission[]>(
  //       `${this.endpointUrl}?search=status==${term};${type}==${id}`
  //     )
  //     .pipe(map(resp => this.mapJsonToCollection(resp)));
  // }

  // searchMissionsByStatusAndType(
  //   status: MissionStatus,
  //   type: string,
  //   typeId: string,
  //   baseParams?: BaseParams
  // ): Observable<PagedResponse<Mission>> {
  //   return this.getWithPagedResponse<Mission>(
  //     `${this.endpointUrl}?search=status==${status};${type}==${typeId}`,
  //     this.mapJsonToCollection,
  //     baseParams
  //   );
  // }

  // updateMissionNotes(
  //   notes: string,
  //   missionId: string
  // ): Promise<HttpResponse<Mission>> {
  //   return this.patchMission(missionId, { notes }).toPromise();
  // }

  // updateMissionStatus(
  //   status: string | MissionStatus,
  //   missionId: string
  // ): Promise<HttpResponse<Mission>> {
  //   return this.patchMission(missionId, { status }).toPromise();
  // }

  // updatePilotMissionStatus(
  //   pilotStatus: string | PilotStatus,
  //   missionId: string
  // ): Promise<HttpResponse<Mission>> {
  //   return this.patchMission(missionId, { pilotStatus }).toPromise();
  // }

  // addSiteContact(
  //   siteContact: SiteContact,
  //   missionId: string
  // ): Promise<HttpResponse<Mission>> {
  //   return this.patchMission(missionId, { siteContact }).toPromise();
  // }

  // assignToMission(
  //   missionId: string,
  //   id: string,
  //   isVendor: boolean
  // ): Promise<HttpResponse<Mission>> {
  //   const params = isVendor
  //     ? {
  //         vendorId: id,
  //         status: MissionStatus.InProgress
  //       }
  //     : { pilotId: id };
  //   return this.patchMission(missionId, params).toPromise();
  // }

  // assignVendorToMission(
  //   missionId: string,
  //   vendorId: string
  // ): Promise<HttpResponse<Mission>> {
  //   return this.assignToMission(missionId, vendorId, true);
  // }

  // assignPilotToMission(
  //   missionId: string,
  //   pilotId: string
  // ): Promise<HttpResponse<Mission>> {
  //   return this.assignToMission(missionId, pilotId, false);
  // }

  // getPilotMissions(pilotId: string): Observable<Mission[]> {
  //   return this.httpClient
  //     .get<Mission[]>(`${this.endpointUrl}/pilot/${pilotId}`)
  //     .pipe(map(respBody => this.mapJsonToCollection(respBody)));
  // }

  // getFilesForTask(referenceId: string): Promise<Attachment[]> {
  //   return this._attachmentService.getAttachmentsByReferenceId(referenceId);
  // }

  // https://api.airbusaerial.com/attachments/${attachment_id}?signed=true
  // fetchSignedImage(id: string): Promise<Attachment> {
  //   return this._attachmentService.getAttachment(id, true);
  // }

  // getUUIDForTokenExchange(uuid: string): Promise<object> {
  //   return this._userService.getTokenForAnonUser(uuid);
  // }

  // getPagedMissions(
  //   baseParams?: BaseParams,
  //   searchTerm?: string,
  //   searchColumn?: string
  // ): Observable<PagedResponse<Mission>> {
  //   const params = searchTerm
  //     ? this.buildWildcardSearchParam(searchTerm, searchColumn)
  //     : undefined;
  //   return this.getWithPagedResponse<Mission>(
  //     this.endpointUrl,
  //     this.mapJsonToCollection,
  //     baseParams,
  //     { params }
  //   );
  // }
}
