import { Pipe, PipeTransform } from '@angular/core';

import { transform } from 'ol/proj';
import isFinite from 'lodash/isFinite';
import { Coordinate } from 'ol/coordinate';
import { forward } from 'mgrs';

// Specify aoiSize in centimeters squared and the precision will
// be automatically adjusted to best match the aoi size.

@Pipe({ name: 'mgrsStr' })
export class MgrsStrPipe implements PipeTransform {
  transform(coords: Coordinate, projection?: string, precision?: number, aoiSize?: number): string {
    if (isFinite(aoiSize)) {
      if (aoiSize > 1e10) {
        precision = 1;
      } else if (aoiSize > 1e4) {
        precision = 2;
      } else if (aoiSize < 1) {
        precision = 4;
      } else {
        precision = 3;
      }
    }
    const newCoords = transform(coords, projection || 'EPSG:3857', 'EPSG:4326');
    while (newCoords[0] > 180) { newCoords[0] -= 360; }
    while (newCoords[0] < -180) { newCoords[0] += 360; }
    while (newCoords[1] > 90) { newCoords[1] -= 180; }
    while (newCoords[1] < -90) { newCoords[1] += 180; }
    const re = new RegExp(`(.*[A-Za-z]{3})([0-9]{${precision || 4}})([0-9]{${precision || 4}})`, 'g');
    return forward(newCoords, precision || 4).replace(re, '$1 $2 $3');
  }
}
