import { DataUsageService } from './services/data-usage.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminService } from './services/admin.service';
import { AirmapService } from './services/airmap.service';
import { AirportService } from './services/airport.service';
import { AoiService } from './services/aoi.service';
import { AttachmentService } from './services/attachment.service';
import { CustomerService } from './services/customer.service';
import { DataProductService } from './services/dataProduct.service';
import { OrderService } from './services/order.service';
import { UserService } from './services/user.service';
import { MissionService } from './services/mission.service';
import { VectorTileService } from './services/vectorTile.service';
import { ProjectService } from './services/project.service';
import { VendorService } from './services/vendor.service';
import { NotificationService } from './services/notification.service';
import { AnnotationService } from './services/annotation.service';
import { VectorDataService } from './services/vectorData.service';
import { CoordinatorService } from './services/coordinator.service';
import { AuditService } from './services/auditService.service';
import { ReportService } from './services/report.service';
import { JwtHelperService } from './services/jwtHelper.service';
import { NearmapService } from './services/nearmap.service';

import { OrderTemplateService } from './services/orderTemplate.service';
import { BrokerService } from './services/broker.service';
import { AssetService } from './services/asset.service';
import { InspectionService } from './services/inspection.service';
import { AssetGroupService } from './services/assetGroup.service';
import { ExternalApiKeyService } from './services/external-api-key.service';
import { GrowerService } from './services/grower.service';
import { FarmService } from './services/farm.service';
import { FieldService } from './services/field.service';
import { PlantingService } from './services/planting.service';
import { ManagementZoneService } from './services/management-zone.service';
import { AgSearchService } from './services/agSearch.service';
import { AgSubscriptionService } from './services/ag-subscription.service';
import { AgSubscriptionTypeService } from './services/ag-subscription-type.service';
import { CropService } from './services/crop.service';
import { CacheService } from './services/cache.service';

@NgModule({
  imports: [CommonModule],
  declarations: []
})
export class ApiModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        AdminService,
        AirportService,
        AirmapService,
        AoiService,
        AssetService,
        BrokerService,
        AttachmentService,
        CoordinatorService,
        CustomerService,
        DataProductService,
        MissionService,
        OrderService,
        OrderTemplateService,
        ProjectService,
        UserService,
        VectorTileService,
        VendorService,
        NotificationService,
        AnnotationService,
        VectorDataService,
        AuditService,
        ReportService,
        JwtHelperService,
        NearmapService,
        InspectionService,
        AssetGroupService,
        ExternalApiKeyService,
        DataUsageService,
        GrowerService,
        FarmService,
        FieldService,
        PlantingService,
        ManagementZoneService,
        AgSearchService,
        AgSubscriptionService,
        AgSubscriptionTypeService,
        CropService,
        CacheService
      ]
    };
  }
}
