import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { FilterService } from '../filter-box/filter-box.service';

import remove from 'lodash/remove';

@Component({
  selector: 'faux-multiselect',
  template: `
    <div class="faux-multiselect">
      <filter-box [placeholder]="placeholder"></filter-box>
      <ul class="faux-multiselect__select">
        <faux-multiselect-option
          *ngFor="
            let option of options;
            let odd = odd;
            let even = even;
            let first = first;
            let last = last
          "
          [ngClass]="{
            odd: odd,
            even: even,
            first: first,
            last: last
          }"
          [hidden]="option.hide"
          [labels]="option.multiSelectLabels"
          [value]="option.id"
          [selected]="selectedIds.includes(option.id)"
          (toggle)="toggleOptionSelection($event)"
        >
        </faux-multiselect-option>
      </ul>
    </div>
  `
})
export class FauxMultiSelectComponent implements OnInit {
  private _filterText: string;

  @Input() options: Array<any>;
  @Input() selectedIds: string[];
  @Input() placeholder: string;

  constructor(private _filterService: FilterService) {}

  ngOnInit() {
    // subscribe to the filter box
    this._filterService.observablefilterText.subscribe(text => {
      this._filterText = text ? text.toLowerCase() : null;

      let textMatch: boolean = true;
      this.options.forEach(option => {
        if (this._filterText) {
          textMatch = option.multiSelectLabels.some((label: string) => {
            return label.toLowerCase().includes(this._filterText);
          });
        }
        (option as any).hide = !textMatch;
      });
    });
  }

  toggleOptionSelection(optionId: string) {
    if ((this.selectedIds as any).includes(optionId))
      remove(this.selectedIds, id => id === optionId);
    else this.selectedIds.push(optionId);
  }
}
