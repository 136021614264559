import { Injectable } from '@angular/core';
import { BaseDataService } from '../../shared/services/base-data.service';
import { Grower, GrowerService } from 'api/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class GrowersStateService extends BaseDataService<Grower> {
  constructor(private service: GrowerService) {
    super({ selected: null, cached: {} }, service);
  }
}
