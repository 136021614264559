import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';

import { AuthRoutingModule } from './auth-routing.module';

import { UIModule } from 'projects/ui/src/public_api';
import { TextMaskModule } from 'angular2-text-mask';

// providers
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

// components
import { AuthComponent } from './auth.component';
import { SignUpComponent } from './signUp/signUp.component';
import { LogInComponent } from './logIn/logIn.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    HttpClientModule,
    TextMaskModule
  ],
  declarations: [
    AuthComponent,
    SignUpComponent,
    LogInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [AuthService, AuthGuard]
    };
  }
}
