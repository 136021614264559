import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class VectorTileService {
  private _rootUrl: string;

  constructor(private _httpClient: HttpClient) {
    this._rootUrl = `${environment.API_ROOT}/tiles`;
  }

  getLayerInfo(layerId: string): Promise<any> {
    return this._httpClient
      .get(`${this._rootUrl}/${layerId}`)
      .pipe(map(resp => resp))
      .toPromise();
  }

  getFeatureInfo(layerId: string, featureId: string): Promise<any> {
    return this._httpClient
      .get(`${this._rootUrl}/${layerId}/attributes/${featureId}`)
      .pipe(map(resp => resp))
      .toPromise();
  }
}
