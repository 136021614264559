import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';

import { Tooltip } from './tooltip.model';
import { TooltipService } from './tooltip.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'tooltip-outlet',
  template: `
    <!-- prettier-ignore -->
    <div #tooltip
      class="tooltip {{ (tooltip$ | async)?.classes }}"
      [ngClass]="{
        'visible': (tooltip$ | async)?.visible && (tooltip$ | async)?.text,
        'disabled': (tooltip$ | async)?.disabled
      }"
      [ngStyle]="(tooltip$ | async)?.style">
      <span #tooltipText
        class="tooltip-text"
        [ngStyle]="(tooltip$ | async)?.textStyle">
        {{ (tooltip$ | async)?.text }}
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipOutletComponent implements OnInit, OnDestroy {
  @ViewChild('tooltip') tooltipElement: ElementRef;
  @ViewChild('tooltipText') tooltipTextElement: ElementRef;
  protected tooltip$: Observable<Tooltip>;
  protected hide = (): void => this._tooltipService.hide();

  constructor(
    private _el: ElementRef,
    private _tooltipService: TooltipService
  ) {
    this.tooltip$ = this._tooltipService.tooltip$;
  }

  // https://stackoverflow.com/questions/44516017/how-to-handle-window-scroll-event-in-angular-4
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
  ngOnInit(): void {
    window.addEventListener('scroll', this.hide, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.hide, true);
  }
}
