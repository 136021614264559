import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { ADMAsset, AgSearchService } from 'api/src/public_api';
import { ToasterService } from 'projects/ui/src/public_api';
import { SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ADMUtilsService {
  constructor(
    private _toast: ToasterService,
    private _agSearchService: AgSearchService
  ) {}

  downloadReport(report: ADMAsset) {
    this._toast.alert('success', 'Initiating Download!');
    const title = report.properties.title;
    const uid = report.properties.uid;
    const time = report.properties.acquisitionDate;

    this._agSearchService
      .download(uid)
      .pipe(take(1))
      .subscribe((res: SafeResourceUrl) => {
        const url = (res as any).changingThisBreaksApplicationSecurity;
        const date = new Date(time);
        const acqTime = `${title.split(' ').join('-')}-${date.getMonth() +
          1}-${date.getDate()}-${date.getFullYear()}`;
        this._downloadFile(url, acqTime);
      });
  }

  private _downloadFile(url: string, fileName?: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
