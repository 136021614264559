import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AerialStatus } from '../../util/aerialStatus';
import { getAerialStatus } from '../../util/helpers';
import { Badge } from '../badge.model';

@Component({
  selector: 'status-badge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <span [ngClass]="classes">{{ statusText }}</span>
  `
})
export class StatusBadgeComponent extends Badge {
  @Input()
  set status(status: string) {
    this._status = getAerialStatus(status);
  }

  @Input()
  altStyle = false;

  get contextType(): string {
    return this._status.contextType;
  }

  get statusText(): string {
    return this._status.text;
  }

  get otherClasses(): string[] {
    return [
      'status',
      this.contextType,
      this._status.className,
      this.altStyle ? 'alt-style' : ''
    ];
  }

  private _status: AerialStatus;
}
