import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth.component';
import { LogInComponent } from './logIn/logIn.component';
import { SignUpComponent } from './signUp/signUp.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';

const authRoutes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'log_in',
        pathMatch: 'full'
      },
      {
        path: 'log_in',
        component: LogInComponent
      },
      {
        path: 'forgot_password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset_password',
        component: ResetPasswordComponent
      }
    ]
  },
  {
    path: 'auth/sign_up/:id',
    component: SignUpComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
