import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class VectorDataService extends BaseService {
  protected endpointUrl: string;

  constructor(private _http: HttpClient) {
    super(_http);

    this.endpointUrl = `${this.rootUrl}/features`;
  }

  getFeaturesForLayer(
    layerId: string,
    baseParams?: BaseParams,
    search?: string
  ): Promise<PagedResponse<any>> {
    const params = {};

    if (search) {
      params['attributes'] = search;
    }

    return this.getWithPagedResponse<any>(
      `${this.endpointUrl}/layer/${layerId}`,
      json => json,
      baseParams,
      {
        params
      }
    ).toPromise();
  }

  getFeature(featureId: string): Promise<any> {
    return this.getWithResponse<any>(`${this.endpointUrl}/${featureId}`)
      .pipe(map(resp => resp.body))
      .toPromise();
  }

  patchFeature(featureId: string, params: any): Promise<any> {
    return this.patchWithResponse<any>(
      `${this.endpointUrl}/${featureId}`,
      params
    ).toPromise();
  }

  updateFeature(feature: any): Promise<any> {
    return this.putWithResponse<any>(
      `${this.endpointUrl}/${feature.id}`,
      feature
    ).toPromise();
  }

  getLayersForDataProduct(dataProductId: string): Promise<any> {
    return this.getWithResponse(
      `${this.endpointUrl}/layers/dataproduct/${dataProductId}`
    )
      .pipe(map(resp => resp.body))
      .toPromise();
  }

  getClassificationLayersForLayer(layerId: string): Promise<any> {
    return this.getWithResponse(
      `${this.endpointUrl}/layers/${layerId}/classificationLayers`
    )
      .pipe(map(resp => resp.body))
      .toPromise();
  }

  classifyLayer(
    baseLayerId: string,
    classificationLayerId: string,
    autoFillTriage?: boolean
  ): Promise<any> {
    return this.postWithResponse(
      `${this.endpointUrl}/layer/${baseLayerId}/classify`,
      {
        layerId: classificationLayerId,
        autoTriage: autoFillTriage
      }
    ).toPromise();
  }

  getPropertyNamesForLayer(layerId: string): Promise<any> {
    return this.getWithResponse(
      `${this.endpointUrl}/layers/${layerId}/propertyNames`
    )
      .pipe(map(resp => resp.body))
      .toPromise();
  }

  getLayerAsCSV(layerId: string): Promise<Blob> {
    return this._http
      .get(`${this.endpointUrl}/layer/${layerId}/export`, {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(map(resp => resp.body))
      .toPromise();
  }
}
