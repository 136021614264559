import { AerialStatus } from './aerialStatus';
import {
  MissionStatus,
  PilotStatus,
  OrderStatus,
  UserStatus,
  RequirementStatus,
  PortalType
} from 'projects/api/src/public_api';
import compact from 'lodash/compact';
import snakeCase from 'lodash/snakeCase';

export enum AerialApp {
  Adp = 'adp',
  Asp = 'asp',
  Admin = 'admin',
  Ag = 'ag',
  Catalog = 'catalog'
}

// these should match the `.color-` and `.fill-color-` classes in utilities.scss
export type AerialColor =
  | 'red'
  | 'pink'
  | 'orange'
  | 'golden-orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'sky-blue'
  | 'blue'
  | 'dark-blue'
  | 'darkest-blue'
  | 'light-blue'
  | 'purple'
  | 'magenta'
  | 'grey'
  | 'dark-grey'
  | 'black';

export const aerialColors = [
  'red',
  'pink',
  'orange',
  'golden-orange',
  'yellow',
  'green',
  'teal',
  'sky-blue',
  'blue',
  'dark-blue',
  'darkest-blue',
  'light-blue',
  'purple',
  'magenta',
  'grey',
  'dark-grey',
  'black'
];

export const contextTypes = [
  'primary',
  'secondary',
  'info',
  'error',
  'warning',
  'success',
  'neutral'
];

export const statuses: AerialStatus[] = [
  new AerialStatus(MissionStatus.Assigned), // ASSIGNED
  new AerialStatus(MissionStatus.Unassigned), // UNASSIGNED
  new AerialStatus(MissionStatus.InProgress), // IN_PROGRESS
  new AerialStatus(MissionStatus.Flown), // FLOWN
  new AerialStatus(MissionStatus.Cancelled), // CANCELLED
  new AerialStatus(MissionStatus.VendorCancelled), // VENDOR_CANCELLED
  new AerialStatus(PilotStatus.Accepted), // ACCEPTED
  new AerialStatus(PilotStatus.Rejected), // REJECTED
  new AerialStatus(MissionStatus.VendorRejected), // VENDOR_REJECTED
  new AerialStatus(RequirementStatus.Failed), // FAILED
  new AerialStatus(MissionStatus.Delayed), // DELAYED
  new AerialStatus(PilotStatus.Pending), // PENDING
  new AerialStatus(MissionStatus.Complete), // COMPLETE
  new AerialStatus(OrderStatus.Created), // CREATED
  new AerialStatus(RequirementStatus.AwaitingFulfillment), // AWAITING_FULFILLMENT
  new AerialStatus(RequirementStatus.Submitted), // SUBMITTED
  new AerialStatus(RequirementStatus.Done), // DONE
  new AerialStatus(UserStatus.Active), // ACTIVE
  new AerialStatus(UserStatus.Inactive), // INACTIVE
  new AerialStatus(UserStatus.Invited), // INVITED
  // 'VALID' and 'INVALID' are frontend-only token statuses for the view
  // this is why they don't belong to an enum like our other statuses
  new AerialStatus('VALID'),
  new AerialStatus('INVALID')
];

export const getAerialStatus = (status: string): AerialStatus => {
  const aerialStatus = statuses.find(st => st.name === status);
  return aerialStatus ? aerialStatus : new AerialStatus(status);
};

export const getAerialStatusByText = (statusText: string): AerialStatus => {
  // this assumes all status values follow the upper snake case naming convention
  const aerialStatus = statuses.find(
    st => st.name === snakeCase(statusText).toUpperCase()
  );
  return aerialStatus ? aerialStatus : undefined;
};

export const getAerialAppByHostname = (): AerialApp => {
  let hostname = window.location.hostname.split('.')[0].split('-')[0];
  if (hostname === 'localhost') {
    hostname = `localhost:${window.location.port}`;
  }
  switch (hostname) {
    case 'admin':
    case 'localhost:7070':
      return AerialApp.Admin;
    case 'asp':
    case 'localhost:9091':
      return AerialApp.Asp;
    case 'agportal':
    case 'ag-portal':
    case 'agneo':
    case 'localhost:5050':
      return AerialApp.Ag;
    case 'omnibus':
    case 'dms':
    case 'localhost:4040':
      return AerialApp.Catalog;
    case 'portal':
    case 'localhost:8080':
      return AerialApp.Adp;
    default:
      return null;
  }
};

export const getAerialAppByPortalType = (portalType: PortalType): AerialApp => {
  switch (portalType) {
    case PortalType.Ag:
      return AerialApp.Ag;
    case PortalType.Oilgas:
      return AerialApp.Catalog;
    case PortalType.Services:
      return AerialApp.Asp;
    case PortalType.Insurance:
      return AerialApp.Adp;
    case PortalType.Catalog:
      return AerialApp.Catalog;
    default:
      return null;
  }
};

export const getAerialApp = (portalType?: PortalType): AerialApp => {
  const byPortal = getAerialAppByPortalType(portalType);
  return byPortal ? byPortal : getAerialAppByHostname();
};

export const hex2rgb = (hex: string): string => {
  hex = hex[0] === '#' ? hex.substr(1) : hex;

  let rgb: string[];
  if (hex.length === 6) {
    rgb = [hex.substring(0, 2), hex.substring(2, 4), hex.substring(4, 6)];
  } else if (hex.length === 3) {
    rgb = hex.split('');
  }

  let rgbStr = '';
  for (const x of rgb) {
    const rgbInt = parseInt(x, 16);
    if (isNaN(rgbInt)) {
      rgbStr = undefined;
      break;
    }
    rgbStr += `${rgbInt},`;
  }

  return rgbStr === undefined
    ? undefined
    : rgbStr.substring(0, rgbStr.length - 1);
};

export const handleClassNamesFromInput = (
  classes: string[] | string
): string[] => {
  return classes
    ? compact(typeof classes === 'string' ? classes.split(' ') : classes)
    : [];
};

export const handleBooleanProperty = (value: any): boolean => {
  return value != null && `${value}` !== 'false';
};

export const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
