import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AerialAccordion } from './aerial-accordion.model';

@Component({
  selector: 'aerial-accordion',
  templateUrl: './aerial-accordion.template.html',
  styleUrls: ['./aerial-accordion.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AerialAccordionComponent extends AerialAccordion {}
