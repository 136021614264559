import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AerialButtonWithIcon } from '../aerialButtonWithIcon.model';

@Component({
  selector: 'texticon-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- prettier-ignore -->
    <aerial-button
      class="texticon-btn {{ positionClass }} {{ separatorClass }}"
      [ngClass]="defaultClasses"
      [classes]="classes"
      [color]="color"
      [size]="size"
      [type]="type"
      [disabled]="disabled">
      <span class="combo-text btn-text">
        <ng-content></ng-content>
      </span>
      <i class="combo-icon btn-icon {{ iconClass }}"></i>
    </aerial-button>
  `,
  styleUrls: ['./texticon-button.styles.scss']
})
export class TexticonButtonComponent extends AerialButtonWithIcon {
  otherClasses = ['combo-element'];
}
