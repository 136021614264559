import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Observable, pipe, BehaviorSubject } from 'rxjs';
import {
  ManagementZoneService,
  ManagementZone,
  Planting,
  Field,
  AgSearchService,
  DataProduct,
  User,
  VectorDataService
} from 'api/src/public_api';
import { PlantingsStateService } from '../plantings-state.service';
import { FieldsStateService } from '../../fields/fields-state.service';
import { ContextService } from '../../../shared/services/context.service';
import { HttpResponse } from '@angular/common/http';
import { skipWhile, switchMap, combineLatest, map } from 'rxjs/operators';
import {
  MapFeaturesService,
  MapService,
  MapLayer,
  MapLayersService
} from 'map/src/public_api';
import { ToasterService } from 'projects/ui/src/public_api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take, filter } from 'rxjs/operators';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { NotificationStreamService } from '../../../shared/services/notificationStream.service';

@Component({
  selector: 'management-zone',
  templateUrl: './management-zone.template.html',
  styleUrls: ['./management-zone.styles.scss']
})
export class ManagementZoneComponent implements OnInit, OnDestroy {
  zoneNameCtrl = new FormControl('');
  _zoneNameSub: Subscription;
  dataSelectCtrl = new FormControl({});
  _dataSelectSub: Subscription;

  requiredFieldsMet = true;
  zoneCompleted: boolean = false;
  zoneNameChosen: string;
  zoneNamed: boolean;
  zoneLevelsChosen: number = 2; // defaults to two
  classificationMethod: string = 'kmeans';
  sieveAcres: number = 0.3;
  dataSourceSelected: boolean;
  dataSource = {};

  planting: Planting;
  field: Field;
  plantingSub: Subscription;
  planting$: Observable<Planting>;
  derivatives$: Observable<object>;
  private _dateRange$: BehaviorSubject<[number, number]>;
  dateRange$: Observable<[string, string]>;
  currentLayer: MapLayer;
  selectedManagementZone: Partial<ManagementZone>;
  layerOpacity = 100;
  private selectedDerivative: any;
  private selectedGeometry: any;
  zoneId: string;
  tempZoneId: string;

  notificationSub: Subscription;
  derivativeSub: Subscription;

  constructor(
    private _managementZoneService: ManagementZoneService,
    private _plantingStateService: PlantingsStateService,
    private _fieldStateService: FieldsStateService,
    private _contextService: ContextService,
    private _agSearchService: AgSearchService,
    private _mapFeatureService: MapFeaturesService,
    private _mapService: MapService,
    private _mapLayersService: MapLayersService,
    private _toast: ToasterService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _plantingsStateService: PlantingsStateService,
    private _vectorDataService: VectorDataService,
    private _notificationStreamService: NotificationStreamService
  ) {
    this._dateRange$ = new BehaviorSubject<[number, number]>([0, 0]);
  }

  private convertEpochToUTC(epoch: number): string {
    return new Date(epoch).toISOString();
  }

  private responseMap = () =>
    pipe(
      map((res: HttpResponse<any[]>) => {
        return (res.body as any).features.map((feature: any) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              ...(feature.properties.acquisitionDate && {
                acquisitionDate: Date.parse(feature.properties.acquisitionDate)
              })
            }
          };
        });
      })
    );

  ngOnInit() {
    this._route.params
      .pipe(
        filter((params: Params) => {
          return params['fieldId'];
        }),
        this._fieldStateService.loadEntityOpr('fieldId'),
        take(1)
      )
      .subscribe((field: Field) => {
        this.field = field;
      });

    this._route.params
      .pipe(
        filter((params: Params) => {
          return params['plantingId'];
        }),
        this._plantingStateService.loadEntityOpr('plantingId'),
        take(1)
      )
      .subscribe((planting: Planting) => {
        this.planting = planting;
        this._mapFeatureService.addAnnotation(this.planting.geometry);
        this._mapService.animateToBounds(
          (this.planting.geometry.getGeometry() as any).getExtent(),
          [0, 0, 0, 410]
        );
      });

    this._zoneNameSub = this.zoneNameCtrl.valueChanges.subscribe(val => {
      this.zoneNameChosen = val;
      this.zoneNamed = val ? true : false;
      this.checkStepOneReqs();
    });

    this._dataSelectSub = this.dataSelectCtrl.valueChanges.subscribe(val => {
      this.dataSource = val;
      this.dataSourceSelected = val ? true : false;
      this.checkStepOneReqs();
      if (val) {
        this.clickedDerivative(val);
      }
    });

    this.dateRange$ = this._dateRange$.asObservable().pipe(
      skipWhile((range: [number, number]) => {
        return !range;
      }),
      map((range: [number, number]) => {
        return <[string, string]>[
          this.convertEpochToUTC(range[0]),
          this.convertEpochToUTC(range[1])
        ];
      })
    );

    this.planting$ = this._route.params.pipe(
      filter((params: Params) => {
        return params['plantingId'];
      }),
      this._plantingsStateService.loadEntityOpr('plantingId')
    );

    this.derivatives$ = this.planting$.pipe(
      combineLatest(this.dateRange$),
      switchMap(([planting, dateRange]: [Planting, [string, string]]) => {
        return this._agSearchService.getAssets({
          plantingId: planting.id,
          count: '1000',
          product:
            // tslint:disable-next-line:max-line-length
            '{Verde CHL TIF, Verde FCOVER TIF, Verde FNPV TIF, Verde FSOIL TIF, Verde LAI TIF, Verde NDVI TIF, raw msavi TIF}',
          ...(dateRange[0] !== '1970-01-01T00:00:00.000Z' && {
            acquisitionDate: `[${dateRange[0]},${dateRange[1]}]`
          })
        });
      }),
      this.responseMap()
    );

    this._contextService.user$.pipe(take(1)).subscribe((user: User) => {
      this.notificationSub = this._notificationStreamService
        .getStream$('MANAGEMENT_ZONE')
        .subscribe(notification => {
          if (
            notification &&
            this.tempZoneId === notification.referenceId &&
            notification.referenceId
          ) {
            this.fetchNewManagementZone(notification.referenceId);
            if (this.notificationSub) {
              this.notificationSub.unsubscribe();
            }
          }
        });
    });
  }

  clickedDerivative(derivative: any) {
    this._toast.alert('success', 'Loading data to map...');
    this.derivativeSub = this._agSearchService
      .assetProxy({ subject: derivative.id, proxy: 'overlayMercator' })
      .subscribe((res: any) => {
        this.selectedDerivative = derivative;
        const geom = new GeoJSON();
        const temptempGeom = this.planting.geometry.clone();
        (temptempGeom as Feature).getGeometry();
        const pointJSON = JSON.parse(
          geom.writeFeature(temptempGeom as Feature)
        );
        this.selectedDerivative.geometry = pointJSON.geometry;

        const dataproduct = new DataProduct({
          dataType: 'STATIC',
          brokerUrl: (res as any).changingThisBreaksApplicationSecurity,
          extent: [
            derivative.properties.geometryMinX,
            derivative.properties.geometryMinY,
            derivative.properties.geometryMaxX,
            derivative.properties.geometryMaxY
          ]
        });
        this._mapLayersService
          .getMapLayers(dataproduct)
          .then((mapLayers: any[]) => {
            if (this.currentLayer) {
              this.currentLayer.olLayer.un('prerender', this.onPrecompose);
              this.currentLayer.olLayer.un('postrender', this.onPostcompose);

              this._mapLayersService.removeLayer(this.currentLayer);
            }

            this.currentLayer = mapLayers[0];

            this._mapLayersService.addLayer(this.currentLayer);
            this.currentLayer.olLayer.setZIndex(999999);

            this.currentLayer.olLayer.on('prerender', this.onPrecompose);
            this.currentLayer.olLayer.on('postrender', this.onPostcompose);
          });
      });
  }

  private onPrecompose = (event: any) => {
    const ctx = event.context;
    const map = this._mapService.getMap();
    ctx.save();
    const pixelRatio = event.frameState.pixelRatio;
    const size = map.getSize();
    ctx.scale(1 * pixelRatio, 1 * pixelRatio);
    let pix;
    let first = true;
    ctx.beginPath();
    if (this.selectedDerivative.geometry.type === 'MultiPolygon') {
      this.selectedDerivative.geometry.coordinates.forEach((coord: any[]) => {
        coord.forEach((points: any[]) => {
          pix = map.getPixelFromCoordinate(points[0]);
          if (pix) {
            ctx.moveTo(pix[0], pix[1]);
          }
          first = true;
          points.forEach((point: any[]) => {
            if (first) {
              first = false;
              return;
            }
            pix = map.getPixelFromCoordinate(point as [number, number]);
            if (pix) {
              ctx.lineTo(pix[0], pix[1]);
            }
          });
        });
      });
    } else {
      this.selectedDerivative.geometry.coordinates.forEach((coord: any[]) => {
        pix = map.getPixelFromCoordinate(coord[0]);
        if (pix) {
          ctx.moveTo(pix[0], pix[1]);
        }
        first = true;
        coord.forEach((point: any[]) => {
          if (first) {
            first = false;
            return;
          }
          pix = map.getPixelFromCoordinate(point as [number, number]);
          if (pix) {
            ctx.lineTo(pix[0], pix[1]);
          }
        });
      });
    }
    ctx.clip();
    ctx.scale(1 / 1 / pixelRatio, 1 / 1 / pixelRatio);
    ctx.stroke();
  };

  private onPostcompose = (event: any) => {
    event.context.restore();
  };

  currentStep(step: number) {
    switch (step) {
      case 1:
        this.checkStepOneReqs();
        break;

      case 2:
        this.getManagementZone();
        break;
    }
  }

  checkStepOneReqs() {
    this.requiredFieldsMet =
      this.dataSourceSelected && this.zoneNamed ? false : true;
  }

  zoneLevelChange(zoneLevels: number) {
    this.zoneLevelsChosen = zoneLevels;
  }

  classificationMethodChange(classificationMethod: string) {
    this.classificationMethod = classificationMethod;
  }

  sieveAcresChange(sieveAcres: string) {
    this.sieveAcres = parseFloat(sieveAcres);
  }

  getManagementZone() {
    const fieldJSON = this.planting.toJSON();

    const newMgmtZoneData = {
      name: this.zoneNameChosen,
      image: this.dataSource['id'],
      zones: this.zoneLevelsChosen,
      classificationMethod: this.classificationMethod,
      sieveAcres: this.sieveAcres,
      status: 'READY',
      customerId: this._contextService.customer.id,
      userId: this._contextService.user.id,
      plantingId: this.planting.id,
      fieldId: this.field.id,
      layerId: null,
      aoi: fieldJSON['geometry'],
      classes: [],
      assetId: this.dataSource['properties'].uid
    };

    this._managementZoneService
      .create(new ManagementZone(newMgmtZoneData))
      .pipe(take(1))
      .subscribe(initResp => {
        this.tempZoneId = initResp.body.id;
        //this.fetchNewManagementZone(this.tempZoneId);

        this.layerOpacity = 0; // set derivative to 0 opacity
        this.selectedManagementZone = initResp.body;
        //this.selectedManagementZone.zoneData = [];

        initResp.body.zoneData.forEach(element => {

          // this.selectedManagementZone.zoneData.push({
          //   range: element['min'] + ' to ' + element['max'],
          //   healthColor: element['color'],
          //   rate: 0
          // });

          const parser = new GeoJSON();
          const newMapFeature = parser.readFeature(element['geom'], {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          });

          if (newMapFeature) {
            newMapFeature.set(
              'fillColor',
              element['colorRGB']
            );
            newMapFeature.set('class', 'zoneLayer');
            this._mapFeatureService.addAnnotation(newMapFeature);
          }
        });
      });
  }

  stepperCompleted() {
    // if zone hasn't finished, just send back to planting detail
    if (!this.zoneId) {
      this._router.navigate(['../'], { relativeTo: this._route });
    }
    this.zoneCompleted = true;
    // remove derivative at this point for aesthetics
    if (this.currentLayer) {
      this.currentLayer.olLayer.un('prerender', this.onPrecompose);
      this.currentLayer.olLayer.un('postrender', this.onPostcompose);
      this._mapLayersService.removeLayer(this.currentLayer);
    }
  }

  fetchNewManagementZone(id: string) {
    this.zoneId = id;
    this._managementZoneService
      .getById(id)
      .pipe(take(1))
      .subscribe(zone => {
        this.layerOpacity = 0; // set derivative to 0 opacity
        this.selectedManagementZone = zone.body;
        this.selectedManagementZone.zoneData = [];
        for (const [key, value] of Object.entries(
          this.selectedManagementZone['colorMap']
        )) {
          // this is dirty AF but the only way to truncate values for display
          const numValues = key.split(' to ');
          const cappedRange =
            parseFloat(numValues[0]).toFixed(2) +
            ' to ' +
            parseFloat(numValues[1]).toFixed(2);

          this.selectedManagementZone.zoneData.push({
            range: cappedRange,
            healthColor: value,
            rate: 0
          });
        }

        this._vectorDataService
          .getLayersForDataProduct(zone.body['outDataProductId'])
          .then(resp => {
            this._vectorDataService
              .getFeaturesForLayer(resp[0].id)
              .then(feature => {
                feature.data.forEach(element => {
                  const parser = new GeoJSON();
                  const newMapFeature = parser.readFeature(element['geom'], {
                    featureProjection: 'EPSG:3857',
                    dataProjection: 'EPSG:4326'
                  });

                  if (newMapFeature) {
                    newMapFeature.set(
                      'fillColor',
                      element.attributes['CLASS_CLRS']
                    );
                    newMapFeature.set('class', 'zoneLayer');
                    this._mapFeatureService.addAnnotation(newMapFeature);
                  }
                });
              });
          });
      });
  }

  launchVRT() {
    this._router.navigate(['../vrt-map/' + this.zoneId], {
      relativeTo: this._route
    });
  }

  ngOnDestroy() {
    this._zoneNameSub.unsubscribe();
    this._dataSelectSub.unsubscribe();
    if (this.planting.geometry) {
      this._mapFeatureService.removeAnnotation(this.planting.geometry);
    }
    if (this.currentLayer) {
      this.currentLayer.olLayer.un('prerender', this.onPrecompose);
      this.currentLayer.olLayer.un('postrender', this.onPostcompose);
      this._mapLayersService.removeLayer(this.currentLayer);
    }
    if (this.notificationSub) {
      this.notificationSub.unsubscribe();
    }
    this._mapFeatureService.clearAnnotations();
    if (this.derivativeSub) {
      this.derivativeSub.unsubscribe();
    }
  }
}
