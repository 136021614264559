import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'address-group',
  templateUrl: './address-group.template.html',
  styleUrls: ['./address-group.styles.scss']
})
export class AddressGroupComponent {
  @Input()
  parent: FormGroup;

  get addressGrp(): FormGroup {
    return this.parent.get('address') as FormGroup;
  }

  get line1Ctrl(): FormControl {
    return this.addressGrp.get('line1') as FormControl;
  }

  get cityCtrl(): FormControl {
    return this.addressGrp.get('city') as FormControl;
  }

  get stateCtrl(): FormControl {
    return this.addressGrp.get('stateProvince') as FormControl;
  }

  get zipCtrl(): FormControl {
    return this.addressGrp.get('postalCode') as FormControl;
  }

  get countryCtrl(): FormControl {
    return this.addressGrp.get('country') as FormControl;
  }
}
