import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ComboLink } from './combo-link.model';

@Component({
  selector: 'disclosure-link',
  template: `
    <!-- prettier-ignore -->
    <combo-link
      iconClass="icon-disclosure_{{ arrowDirection }}"
      [iconPosition]="iconPosition"
      [darkMode]="darkMode"
      [classes]="classes">
      <ng-content></ng-content>
    </combo-link>
  `,
  styleUrls: ['./combo-link.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclosureLinkComponent extends ComboLink {
  @Input()
  arrowDirection: 'up' | 'down' | 'left' | 'right' = 'right';

  otherClasses: string[] = ['disclosure-link'];
}
