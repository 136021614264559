import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Toasty } from './toasty.model';

@Component({
  selector: 'toasty',
  template: `
    <!-- prettier-ignore -->
    <div [ngClass]="toasty.classes">
      <i *ngIf="!toasty.hideIcon"
        class="toasty-icon {{ toasty.iconClass }} small square">
      </i>
      <span class="toasty-message">
        {{toasty.message}}
      </span>
      <button class="toasty-close invisible small square"
        (click)="onClose.emit(toasty)">
        <i class="icon-close"></i>
      </button>
    </div>
  `,
  styleUrls: ['./toasty.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastyComponent {
  @Input()
  toasty: Toasty;

  @Output()
  onClose = new EventEmitter<Toasty>();
}
