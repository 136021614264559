import { Component, OnInit } from '@angular/core';
import { Dialog } from 'projects/ui/src/public_api';
import { FormGroup, FormControl } from '@angular/forms';
import {
  NotificationPreferencesService,
  NotificationPreferenceType
} from '../../shared/services/notificationPreferences.services';
import { ContextService } from '../../shared/services/context.service';
import { User } from 'api/src/public_api';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './notifications-settings-dialog.template.html',
  styleUrls: ['./notifications-settings-dialog.styles.scss']
})
export class NotificationsSettingsDialogComponent implements Dialog, OnInit {
  successCallback: Function;
  notificationsSettingsForm: FormGroup;
  userId: string;

  public notificationOptions = [];
  public usersSavedPreference: NotificationPreferenceType;
  public updateInProgress = false;

  constructor(
    private _notificationPreferencesService: NotificationPreferencesService,
    private _contextService: ContextService
  ) {}

  ngOnInit() {
    this.notificationOptions = Object.values(NotificationPreferenceType);

    this._contextService.user$.subscribe((user: User) => {
      console.log('user: ', user);
      this.usersSavedPreference =
        user.attributes.growerNotificationPreference ||
        NotificationPreferenceType.AllGrowers;
    });

    this.notificationsSettingsForm = new FormGroup({
      notificationPref: new FormControl(this.usersSavedPreference, [])
    });
  }

  save() {
    this.updateInProgress = true;
    const newPref = this.notificationsSettingsForm.value.notificationPref;

    // Make this call in the modal
    this._notificationPreferencesService
      .updateOtherGrowerPreference(newPref)
      .pipe(take(1))
      .subscribe((res: any) => {
        this._notificationPreferencesService.updateGrowerPreference(newPref);
        this.updateInProgress = false;
        this.successCallback();
      });

    this.close();
  }

  close = () => {};
}
