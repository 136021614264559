import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialButton } from '../aerialButton.model';

@Component({
  selector: 'cancel-button',
  template: `
    <!-- prettier-ignore -->
    <aerial-button
      [classes]="classes"
      color="tertiary"
      [size]="size"
      [type]="type"
      [disabled]="disabled">
      Cancel
    </aerial-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelButtonComponent extends AerialButton {
  @Input()
  classes: string[] | string = [];
}
