import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef
} from '@angular/core';

import { SwatchOptions } from './swatchOptions.interface';
import toNumber from 'lodash/toNumber';

@Component({
  selector: 'color-swatch',
  template: `
    <!-- prettier-ignore -->
    <button #swatch
      type="button"
      class="color-swatch small"
      [ngClass]="{
        'context-btn': editable,
        'disabled': disabled,
        'picker-active': openPicker
      }"
      [ngStyle]="{
        'background-color': color,
        'border-width': strokeWidth,
        'border-color': strokeColor
      }"
      [disabled]="!editable || disabled"
      [colorPicker]="color"
      [cpToggle]="openPicker"
      [cpOutputFormat]="'rgba'"
      [cpFallbackColor]="defaultColor"
      [cpPosition]="pickerPosition"
      [cpPresetLabel]="'Presets'"
      [cpPresetColors]="presets"
      [cpOKButton]="true"
      [cpCancelButton]="true"
      [cpSaveClickOutside]="false"
      (cpToggleChange)="togglePicker($event)"
      (colorPickerSelect)="selectColor($event)">
    </button>
  `,
  styleUrls: ['./color-swatch.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorSwatchComponent {
  static readonly presetColors = [
    '#00AEC7', // $teal
    '#A51890', // $magenta
    '#FE5000', // $orange
    '#E4002B', // $red
    '#E1E000', // $yellow
    '#84BD00', // $green
    '#DA1884', // $pink
    '#009ffd', // $skyBlue
    '#7768ae' // $purple
  ];
  readonly presets = ColorSwatchComponent.presetColors;
  readonly defaultColor = '#00AEC7';

  @ViewChild('swatch') swatchElement: ElementRef;

  private _color: string;
  private _options: SwatchOptions = {};

  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * The hex/rgba color of the swatch. Defaults to `this.defaultColor`.
   * @prop {string} [color='#00AEC7']
   */
  @Input()
  set color(color: string) {
    this._color = color;
  }

  get color(): string {
    return !this._color ? this.defaultColor : this._color;
  }

  /**
   * User-supplied customization options for the color swatch.
   * @see SwatchOptions
   */
  @Input()
  set options(options: SwatchOptions) {
    Object.keys(options).forEach(optKey => {
      this[optKey] = options[optKey];
    });
  }

  get options(): SwatchOptions {
    return this._options;
  }

  /**
   * Allow the swatch's fill color to be changed via a color picker that opens
   * when the swatch is clicked. If set to `true` then the swatch's stroke/border
   * properties will be ignored to avoid confusion over which color (fill or stroke)
   * is being edited.
   * @prop {boolean} [editable=true]
   */
  get editable(): boolean {
    return this.options.editable === undefined ? true : this.options.editable;
  }

  set editable(editable: boolean) {
    this._options.editable = editable;
  }

  /**
   * The hex/rgba color of the swatch's border, set via `ngStyle`. Defaults to
   * `'transparent'`. Note: `strokeColor` will be ignored if `editable` is `true`
   * or `showStroke` is `false`.
   * @prop {string} [strokeColor='transparent']
   */
  get strokeColor(): string {
    return this.options.strokeColor === undefined ||
      this.editable ||
      !this.showStroke
      ? 'transparent'
      : this.options.strokeColor;
  }

  set strokeColor(strokeColor: string) {
    this._options.strokeColor = strokeColor;
  }

  /**
   * The unitless width of the swatch's border, set via `ngStyle`. Max width is
   * 5 (for display purposes only). Note: `strokeWidth` will be ignored if
   * `editable` is `true` or `showStroke` is `false`.
   * @prop {number|string} [strokeWidth=0]
   */
  get strokeWidth(): number | string {
    return this.options.strokeWidth === undefined ||
      this.editable ||
      !this.showStroke
      ? 0
      : `${this.options.strokeWidth}px`;
  }

  set strokeWidth(strokeWidth: number | string) {
    strokeWidth = toNumber(strokeWidth);
    if (isNaN(strokeWidth) || strokeWidth < 0) {
      this._options.strokeWidth = 0;
    } else if (strokeWidth > 5) {
      this._options.strokeWidth = 5;
    } else {
      this._options.strokeWidth = strokeWidth;
    }
  }

  /**
   * Show the `strokeWidth` size border around the swatch.
   * @prop {boolean} [showStroke=false]
   */
  get showStroke(): boolean {
    return this.options.showStroke === undefined
      ? false
      : this.options.showStroke;
  }

  set showStroke(showStroke: boolean) {
    this._options.showStroke = showStroke;
  }

  /**
   * Open the swatch's color picker by default.
   * @prop {boolean} [openPicker=false]
   */
  get openPicker(): boolean {
    return this.options.openPicker === undefined
      ? false
      : this.options.openPicker;
  }

  set openPicker(openPicker: boolean) {
    this._options.openPicker = openPicker;
  }

  /**
   * Position of the swatch's color picker, relative to the swatch. Valid values
   * are: 'right', 'left', 'top', and 'bottom'.
   * @prop {string} [pickerPosition='right']
   */
  get pickerPosition(): string {
    return this.options.pickerPosition === undefined
      ? 'right'
      : this.options.pickerPosition;
  }

  set pickerPosition(pickerPosition: string) {
    this._options.pickerPosition = ['right', 'left', 'top', 'bottom'].includes(
      pickerPosition
    )
      ? pickerPosition
      : 'right';
  }

  /**
   * Disable the swatch's color picker and give it the 'disabled' appearance.
   * @prop {boolean} [disabled=false]
   */
  get disabled(): boolean {
    return this.options.disabled === undefined ? false : this.options.disabled;
  }

  set disabled(disabled: boolean) {
    this._options.disabled = disabled;
  }

  togglePicker(open: boolean): void {
    this.openPicker = open;
  }

  selectColor(color: string): void {
    if (color !== this.color) {
      this.color = color;
      this.colorChange.emit(this.color);
    }
  }
}
