export interface DownloadJobDetails {
  jobId: string;
  jobName: string;
  jobType: DownloadJobType;
  params: {
    [key: string]: string;
  };
  assetIds: string[];
  jobStart: string;
  jobEnd: string;
  jobStatus: DownloadJobStatus;
  errorMessage: string;
  processTimeSecs: number;
  percentComplete: number;
  outputs: DownloadOutputDetails[];
}

export enum DownloadJobType {
  DLZip = 'dlzip'
}

export enum DownloadJobStatus {
  NotStarted = 'not started',
  InProgress = 'in progress',
  Successful = 'successful',
  Failed = 'failed'
}

export interface DownloadOutputDetails {
  outputId: string;
  geometryWkt: string;
  sourceAssetIds: string[];
}
