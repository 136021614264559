import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseSearchService } from './baseSearch.service';
import { PagedSearchResponse } from '../../interfaces/search/base-search.interface';
import { FeatureSearch } from '../../interfaces/search/featureSearch.interface';
import { BaseParams } from '../../interfaces/baseParams.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureSearchService extends BaseSearchService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/search/feature/_search`;
  }

  searchFeatures(
    search: string,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<FeatureSearch>> {
    const query = {
      multi_match: {
        query: search,
        fields: ['attributes.*'],
        lenient: true,
        fuzziness: 'AUTO',
        operator: 'AND'
      }
    };
    const { from, size } = this.convertPagination(baseParams);
    const sort = this._buildSort(baseParams);

    return this.search<FeatureSearch>(
      {
        query,
        from,
        size,
        sort
      },
      searchResp => searchResp.hits.hits.map((hit: any) => hit['_source'])
    );
  }

  searchFeaturesForLayer(
    layerId: string,
    search?: string,
    baseParams?: BaseParams
  ): Observable<PagedSearchResponse<FeatureSearch>> {
    const query = {
      bool: {
        must: [
          {
            match: {
              layerId: layerId
            }
          }
        ]
      }
    };

    if (search) {
      query.bool.must.push({
        nested: {
          path: 'flatAttributes',
          query: {
            multi_match: {
              query: search,
              fields: ['flatAttributes.*'],
              lenient: true,
              fuzziness: 'AUTO',
              operator: 'AND'
            }
          }
        }
      } as any);
    }

    const { from, size } = this.convertPagination(baseParams);
    const sort = this._buildSort(baseParams);

    return this.search<FeatureSearch>(
      {
        query,
        from,
        size,
        sort
      },
      searchResp => searchResp.hits.hits.map((hit: any) => hit['_source'])
    );
  }

  private _buildSort(baseParams: Partial<BaseParams>) {
    if (
      baseParams &&
      baseParams.sortBy &&
      baseParams.sortBy.indexOf('flatAttributes') > -1
    ) {
      // NOTE: this is some old crap
      const sortTerm = (baseParams.sortBy as string).split('.')[1];

      return [
        {
          'flatAttributes.value.keyword': {
            order: baseParams.sortOrder || 'asc',
            nested: {
              path: 'flatAttributes',
              filter: {
                term: {
                  'flatAttributes.key': sortTerm
                }
              }
            }
          }
        }
      ];
    } else {
      return this.convertSort(baseParams);
    }
  }
}
