import {
  Directive,
  ElementRef,
  Input,
  Optional,
  Self,
  HostBinding
} from '@angular/core';
import {
  NgControl,
  NgModel,
  FormControl,
  FormControlName,
  FormControlDirective
} from '@angular/forms';

// button, reset, submit - use <aerial-button>
// checkbox - use <aerial-checkbox>
// radio - use <aerial-radio>
// date, datetime-local, month, time, week - use <date-picker>
// color - use <color-swatch>
// number - use <number-picker>
// file, hidden, range - use native input

@Directive({
  selector: 'input[aerialInput]',
  exportAs: 'aerialInput'
})
export class AerialInputDirective {
  readonly validTypes = ['email', 'password', 'search', 'tel', 'text', 'url'];

  @Input()
  placeholder: string;

  @Input()
  id: string;

  @Input()
  set type(type: string) {
    this._type = type;
  }

  get type(): string {
    return (this.validTypes as any).includes(this._type) ? this._type : 'text';
  }

  get value(): string {
    const val = !this.control
      ? this.element.value
      : this.control instanceof NgModel
      ? this.control.model
      : this.control.value;
    return val ? val : '';
  }

  get required(): boolean {
    return this.element.required;
  }

  get element(): HTMLInputElement {
    return this._el.nativeElement;
  }

  get formControl(): FormControl {
    return this.control instanceof FormControlDirective ||
      this.control instanceof FormControlName
      ? this.control.control
      : undefined;
  }

  @HostBinding('class.aerial-input')
  hasClass = true;

  private _type: string;

  constructor(
    private _el: ElementRef<HTMLInputElement>,
    @Optional() @Self() public control: NgControl
  ) {}
}
