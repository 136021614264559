import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'click-tabs',
  templateUrl: './click-tabs.template.html',
  styleUrls: ['./click-tabs.styles.scss']
})
export class ClickTabsComponent implements OnChanges {
  @Input() tabs: { name: string; startActive?: boolean }[];
  @Input() size: 'small' | 'large' = 'large';
  @Output() onTabClick = new EventEmitter<string>();
  activeTab: string;

  handleTabClick(tabName: string) {
    this.activeTab = tabName;
    this.onTabClick.emit(tabName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.tabs &&
      changes.tabs.previousValue !== changes.tabs.currentValue
    ) {
      const startActiveTab = this.tabs.find(tab => tab.startActive);
      if (startActiveTab) {
        this.activeTab = startActiveTab.name;
      }
    }
  }
}
