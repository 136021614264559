import { Component, Input, ContentChild } from '@angular/core';
import { AerialInputDirective } from './aerial-input.directive';

@Component({
  selector: 'input-control',
  template: `
    <!-- prettier-ignore -->
    <label [ngClass]="labelClasses" [attr.for]="input.id">
      {{ label }}
    </label>
    <ng-content></ng-content>
  `,
  styleUrls: ['./input-control.styles.scss']
})
export class InputControlComponent {
  @Input()
  set label(label: string) {
    this._label = label;
  }

  /**
   * @prop {boolean} [useHidingLabel=true]
   * If true, the label hides behind the input field while the input's value is
   * empty (the `placeholder` attribute will be used to identify the input field.)
   * When the placeholder disappears the label pops up out of hiding. It's cute.
   */
  @Input()
  useHidingLabel = true;

  get label(): string {
    return this.useHidingLabel ? this.input.placeholder : this._label;
  }

  get isLabelHiding(): boolean {
    return this.input.value === '';
  }

  get labelClasses(): string[] {
    return [
      this.useHidingLabel ? 'hiding-label' : '',
      this.useHidingLabel && !this.isLabelHiding ? 'visible' : ''
    ];
  }

  @ContentChild(AerialInputDirective)
  input: AerialInputDirective;

  private _label: string;
}
