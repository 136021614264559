import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { JwtHelperService } from './jwtHelper.service';

import {
  Notification,
  NotificationPreference,
  NotificationPreferenceReferenceType
} from '../models/notification.model';
import { NotificationTemplate } from '../interfaces/notificationTemplate.interface';
import { BaseService } from './base.service';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

declare var EventSourcePolyfill: any;

export type NotificationType =
  | 'New Notification'
  | 'ASSET'
  | 'VRT'
  | 'WEAK_ACRES'
  | 'REPORT'
  | 'MANAGEMENT_ZONE';

@Injectable()
export class NotificationService extends BaseService {
  protected endpointUrl: string;
  private _jwtHelper: JwtHelperService;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/notifications`;
    this._jwtHelper = new JwtHelperService();
  }

  create(notification: Partial<Notification>): Observable<object> {
    return this.httpClient.post(`${this.endpointUrl}/`, notification);
  }

  send(notification: NotificationTemplate): Promise<object> {
    return this.httpClient
      .post(`${this.endpointUrl}/send/`, notification)
      .pipe(map(resp => resp))
      .toPromise();
  }

  deleteNotification(id: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${id}`).toPromise();
  }

  deleteAllNotificationsForUser(userId: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${userId}/clear`
    ).toPromise();
  }

  getNotifications(userId: string): Promise<Notification[]> {
    return this.httpClient
      .get<Notification[]>(`${this.endpointUrl}/${userId}`)
      .toPromise();
  }

  getEventSource(userId: string, jwtToken: string, timeout = 3): EventSource {
    const esHeaders = {
      Accept: 'text/event-stream',
      Authorization: `Bearer ${jwtToken}`
    };
    return new EventSourcePolyfill(
      `${this.endpointUrl}/${userId}/subscribe?timeout=${timeout}`,
      { headers: esHeaders }
    );
  }

  /**
   * ** WARNING **
   *
   * Calling this function will overwrite backend state which could affect other
   * parts of this application
   *
   * @param userId
   * @param jwtToken
   * @param notificationType
   * @param timeout
   */
  streamNotifications(
    userId: string,
    jwtToken: string,
    notificationType = 'New Notification',
    timeout = 3
  ): Observable<Notification> {
    return new Observable<Notification>(observer => {
      const esHeaders = {
        Accept: 'text/event-stream',
        Authorization: `Bearer ${jwtToken}`
      };
      const es = new EventSourcePolyfill(
        `${this.endpointUrl}/${userId}/subscribe?timeout=${timeout}`,
        { headers: esHeaders }
      );
      let openTime: number;

      es.onopen = (event: Event) => {
        openTime = Date.now();
      };
      es.onerror = (event: any) => {
        event.elapsedTime = openTime ? Date.now() - openTime : undefined;
        event.isTokenExpired = this._jwtHelper.isTokenExpired(jwtToken);
        switch (event.target.readyState) {
          case EventSourcePolyfill.CLOSED:
            observer.complete();
            break;
          default:
            observer.error(event);
            break;
        }
      };
      es.addEventListener(
        notificationType,
        (event: MessageEvent) => {
          const notification = new Notification(JSON.parse(event.data));
          observer.next(notification);
        },
        false
      );
      es.addEventListener(
        'heartbeat',
        (event: MessageEvent) => observer.next(undefined),
        false
      );
      return () => es.close();
    });
  }

  markNotificationAsRead(id: string): Promise<HttpResponse<Notification>> {
    return this.postWithResponse<Notification>(
      `${this.endpointUrl}/${id}/read`,
      null
    ).toPromise();
  }

  createPreference(
    preference: Partial<NotificationPreference>
  ): Observable<object> {
    return this.httpClient.post(`${this.endpointUrl}/preferences`, preference);
  }

  getPreferenceById(id: string): Observable<NotificationPreference> {
    return this.httpClient.get<NotificationPreference>(
      `${this.endpointUrl}/preferences/${id}`
    );
  }

  deletePreferenceById(id: string): Observable<object> {
    return this.httpClient.delete<NotificationPreference>(
      `${this.endpointUrl}/preferences/${id}`
    );
  }

  getPreferenceByUserId(id: string): Observable<NotificationPreference> {
    return this.httpClient.get<NotificationPreference>(
      `${this.endpointUrl}/preferences/user/${id}`
    );
  }

  deletePreferenceByUserId(id: string): Observable<object> {
    return this.httpClient.delete<NotificationPreference>(
      `${this.endpointUrl}/preferences/user/${id}`
    );
  }

  getPreferenceByReferenceType(
    userId: string,
    type: NotificationPreferenceReferenceType
  ): Observable<NotificationPreference> {
    return this.httpClient.get<NotificationPreference>(
      `${this.endpointUrl}/preferences/user/${userId}`,
      {
        params: this.buildParams({ type: type })
      }
    );
  }

  deletePreferenceByReferenceType(
    userId: string,
    type: NotificationPreferenceReferenceType
  ): Observable<object> {
    return this.httpClient.delete<NotificationPreference>(
      `${this.endpointUrl}/preferences/user/${userId}`,
      {
        params: this.buildParams({ type: type })
      }
    );
  }
}
