import {
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';

export abstract class AerialMenu {
  @Input()
  hideOnClick = true;

  @Input()
  showCaret = true;

  @Input()
  animateCaret = false;

  @Input() // optional width of menu, set via `[style.width]`
  menuWidth: string;

  @Input()
  isMenuVisible = false;

  @Output()
  menuShow = new EventEmitter<void>();

  @Output()
  menuHide = new EventEmitter<void>();

  @Output()
  menuToggle = new EventEmitter<void>();

  @ViewChild('toggler')
  protected menuToggler: ElementRef;

  @HostListener('document:click', ['$event'])
  protected onClickout(event: Event) {
    if (
      this.hideOnClick &&
      !this.menuToggler.nativeElement.contains(event.target)
    ) {
      this.isMenuVisible = false;
    }
  }

  protected onToggle(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.menuToggle.emit();
    this[this.isMenuVisible ? 'menuShow' : 'menuHide'].emit();
  }
}
