import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  AfterContentInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import * as d3 from 'd3';
import {
  PlantingDataService,
  AnalyticMap,
  HumanReadableProductType
} from '../services/planting-data.service';
import { ADMAsset } from 'api/src/public_api';
import { concatMap, tap, map, combineLatest } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { PlantingsStateService } from '../../explorer/plantings/plantings-state.service';
import { DatePipe } from '@angular/common';

export interface GraphNode {
  date: number;
  value: number;
  stddev: number;
}

@Component({
  selector: 'statistics-timeline',
  templateUrl: './statistics-timeline.template.html',
  styleUrls: ['./statistics-timeline.styles.scss'],
  encapsulation: ViewEncapsulation.None // needed to be able to style d3
})
export class StatisticsTimelineComponent
  implements AfterContentInit, OnDestroy {
  @ViewChild('theSvg') private _svg: ElementRef;
  private svg: any;
  brushBehavior: any;
  zoomBehavior: any;
  @Output() toggleTimeline = new EventEmitter<boolean>();
  timelineOpen = false; // starting position
  selectedDate: number;
  selectedType: HumanReadableProductType;
  prevDayAvailable: boolean = false;
  nextDayAvailable: boolean = false;
  filteredMappedDerivativeSub: Subscription;
  timelineOpenSub: Subscription;
  prevDayAvailableSub: Subscription;
  nextDayAvailableSub: Subscription;
  isDrawing = false;
  dotClicked = false;

  viewStartDate: number;
  viewEndDate: number;

  constructor(
    private _plantingDataService: PlantingDataService,
    private _plantingsStateService: PlantingsStateService
  ) {}

  ngAfterContentInit() {
    let metric:
      | 'mean'
      | 'median'
      | 'maximum'
      | 'minimum'
      | '25thPercentile'
      | '50thPercentile'
      | '5thPercentile'
      | '75thPercentile'
      | '95thPercentile' = 'mean';

    let filteredDerivatives: ADMAsset[] = [];

    this.svg = d3.select(this._svg.nativeElement);

    const desiredGraphHeight = 178;
    const margin = { top: 0, right: 0, bottom: 55, left: 25 };
    const margin2 = {
      top: desiredGraphHeight - 30,
      right: 0,
      bottom: 0,
      left: 25
    };

    const width = document.getElementById('chart-bg').offsetWidth;

    const height = desiredGraphHeight - (margin.bottom + margin.top);
    const height2 = 25;

    const x = d3.scaleTime().range([0, width]);
    const x2 = d3.scaleTime().range([0, width]);

    const y = d3.scaleLinear().range([height, 0]);
    const y2 = d3.scaleLinear().range([height2, 0]);

    const xAxis = d3.axisBottom(x);
    const xAxis2 = d3.axisBottom(x2);
    const yAxis = d3.axisLeft(y).ticks(3);

    this.svg.attr('width', width + margin.left + 1);
    this.svg.attr('height', desiredGraphHeight);
    const div = d3.select('#tooltip-container').style('opacity', 0);

    const getX = (d: GraphNode) => x(d.date);
    const getY = (d: GraphNode) =>
      this.selectedType === 'All' ? height / 2 : y(d.value);
    const getRadius = (d: GraphNode) => (d.date === this.selectedDate ? 8 : 4);
    const dataMap = (derivatives: ADMAsset[]) =>
      derivatives.map((asset: ADMAsset) => {
        return {
          date: asset.properties.acquisitionDate,
          value: <number>asset.properties[metric],
          stddev: <number>asset.properties.stdDev
        } as GraphNode;
      });

    d3.select(window).on('resize', () => {
      const newWidth = document.getElementById('chart-bg').offsetWidth;
      const s = d3.event.selection || x2.range();
      x.domain(s.map(x2.invert, x2));
      const data = dataMap(filteredDerivatives);

      this.svg.attr('width', newWidth + margin.left + 1);
      this.svg.select('#clip').attr('width', newWidth);
      this.svg.select('.clip-rect').attr('width', newWidth);
      focus.attr('width', newWidth);
      focus.select('.line').attr('d', area(data));
      focus.select('.envelope').attr('d', envelopArea(data));
      context.select('.overlay').attr('width', newWidth);
      focus
        .selectAll('.dot')
        .data(data)
        .attr('x', getX)
        .attr('y', getY)
        .attr('width', newWidth);
      this.brushBehavior.extent([[0, 0], [newWidth, height2]]);

      daymarker.attr('x', x(this.selectedDate || 0) + margin.left);

      this.svg.select('.zoom').attr('width', newWidth);

      this.svg
        .select('.zoom')
        .call(
          this.zoomBehavior.transform,
          d3.zoomIdentity.scale(newWidth / (s[1] - s[0])).translate(-s[0], 0)
        );
    });

    const brushed = () => {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') return; // ignore brush-by-zoom
      const s = d3.event.selection || x2.range();
      x.domain(s.map(x2.invert, x2));
      this.updateViewDates(x.domain());
      const data = dataMap(filteredDerivatives);

      focus.select('.axis--x').call(xAxis);
      this.svg
        .select('.zoom')
        .call(
          this.zoomBehavior.transform,
          d3.zoomIdentity.scale(width / (s[1] - s[0])).translate(-s[0], 0)
        );

      daymarker.attr('x', x(this.selectedDate || 0) + margin.left);

      // if not currently drawing, update points/line
      if (this.isDrawing) {
        return;
      }
      focus.classed('animate', false);

      focus
        .selectAll('.dot')
        .data(data)
        .attr('x', getX)
        .attr('y', getY)
        .style('opacity', 1.0);

      focus.select('.line').attr('d', area(data));
      focus.select('.envelope').attr('d', envelopArea(data));
    };

    const zoomed = () => {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') return; // ignore zoom-by-brush
      const t = d3.event.transform;
      x.domain(t.rescaleX(x2).domain());
      this.updateViewDates(x.domain());
      const data = dataMap(filteredDerivatives);

      focus.select('.axis--x').call(xAxis);
      context
        .select('.brush')
        .call(this.brushBehavior.move, x.range().map(t.invertX, t));

      daymarker.attr('x', x(this.selectedDate || 0) + margin.left);

      // if not currently drawing, update points/line
      if (this.isDrawing) {
        return;
      }
      focus.classed('animate', false);

      focus
        .selectAll('.dot')
        .data(data)
        .attr('x', getX)
        .attr('y', getY)
        .style('opacity', 1.0);

      focus.select('.line').attr('d', area(data));
      focus.select('.envelope').attr('d', envelopArea(data));
    };

    this.brushBehavior = d3
      .brushX()
      .extent([[0, 0], [width, height2]])
      .on('brush end', brushed);

    this.zoomBehavior = d3
      .zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      .on('zoom', zoomed);

    const area = d3
      .line<GraphNode>()
      .curve(d3.curveMonotoneX)
      .x(getX) // x is scaled to xScale2
      .y(getY); // x is scaled to xScale2

    const envelopArea = d3
      .area<GraphNode>()
      .x((d: GraphNode) => {
        return x(d.date);
      })
      .y0((d: GraphNode) => {
        return this.selectedType === 'All'
          ? height / 2
          : y(d.value + d.stddev * 1.96);
      })
      .y1((d: GraphNode) => {
        return this.selectedType === 'All'
          ? height / 2
          : y(d.value - d.stddev * 1.96);
      });

    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('class', 'clip-rect')
      .attr('width', width + margin.left)
      .attr('height', height);

    const daymarker = this.svg
      .append('g')
      .append('rect')
      .attr('class', 'day-marker')
      .attr('width', 1)
      .attr('height', height)
      .attr('fill', 'red')
      .attr('x', margin2.left);

    const focus = this.svg
      .append('g')
      .attr('class', 'focus')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const context = this.svg
      .append('g')
      .attr('class', 'context')
      .attr('transform', 'translate(' + margin2.left + ',' + margin2.top + ')');

    ///////////////////////////////////////////////////////////////////////////

    const draw = (derivatives: ADMAsset[]) => {
      this.isDrawing = this.dotClicked ? false : true;
      focus.classed('animate', this.isDrawing);
      this.dotClicked = false;
      focus.select('.deriv-dot').remove(); // cleanup

      const maxValTopEnd = d3.max(
        this.selectedType === 'All' ? [] : derivatives,
        (d: ADMAsset) => {
          return <number>d.properties[metric];
        }
      );

      x.domain(
        d3.extent(derivatives, (d: ADMAsset) => {
          return new Date(d.properties.acquisitionDate);
        })
      );

      y.domain([0, maxValTopEnd * 1.25]); // adding buffer to top range
      // also adding buffer of 2 days before/after start and end dates
      const startDateBuffer = x.domain()[0].getTime() - 2 * 24 * 60 * 60 * 1000;
      const endDateBuffer = x.domain()[1].getTime() + 2 * 24 * 60 * 60 * 1000;
      x2.domain([new Date(startDateBuffer), new Date(endDateBuffer)]);
      y2.domain(y.domain());
      this.updateViewDates(x.domain());

      focus
        .select('.axis--y')
        .transition()
        .call(yAxis);

      const dataStart_dot = dataMap(derivatives).map(der => {
        der[1] = height / 2;
        return der;
      });
      const dataStart_line = dataMap(derivatives).map(der => {
        der[1] = 0;
        return der;
      });
      const data = dataMap(derivatives);

      // Show confidence interval
      focus
        .select('.envelope')
        .attr('fill', this._plantingDataService.selectedDerivativeTypeColor)
        .attr('d', envelopArea(data));

      focus
        .select('.line')
        .attr('stroke', this._plantingDataService.selectedDerivativeTypeColor)
        .attr('clip-path', 'url(#clip)');

      focus.select('.axis--x').call(xAxis);
      context.select('.axis--x').call(xAxis2);

      const datePipe = new DatePipe('en-US');

      // tslint:disable-next-line:max-line-length
      const svgImage =
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="15px"><path fill-rule="evenodd" fill="' +
        this._plantingDataService.selectedDerivativeTypeColor +
        '" d="M7.000,-0.000 L14.000,7.857 L7.000,15.000 L-0.000,7.857 L7.000,-0.000 Z"/></svg>';

      focus
        .append('g')
        .attr('class', 'deriv-dot')
        .attr('clip-path', 'url(#clip)')
        .selectAll('dot')
        .data(dataStart_dot)
        .enter()
        // .append('circle')
        .append('g')
        .attr('class', 'inner-translate')
        // .classed('selected', data[0][0] === this.selectedDate)
        .append('svg')
        .attr('class', 'dot')
        .attr('width', 14)
        .attr('height', 15)
        .attr('x', getX)
        .attr('y', height / 2)
        .style('opacity', 0.0)
        .html(svgImage)
        .on('click', (thisDot: GraphNode) => {
          this.dotClicked = true;
          this._plantingDataService.selectedDate = thisDot.date;
          daymarker.attr('x', x(this.selectedDate || 0) + margin.left);
        })
        .on('mouseover', (d: GraphNode) => {
          let tooltipData =
            '' +
            '<div class="date">' +
            datePipe.transform(d.date, 'mediumDate') +
            '</div>';
          if (this.selectedType !== 'All') {
            tooltipData +=
              '<div>' +
              ' <span>' +
              this.selectedType +
              '</span>' +
              ' <span>' +
              d.value.toFixed(3) +
              '</span>' +
              '</div>';
          }

          div
            .transition()
            .duration(200)
            .style('opacity', 0.9);
          div
            .html(tooltipData)
            .style('left', d3.event.pageX + 'px')
            .style('top', d3.event.pageY - 40 + 'px');
        })
        .on('mouseout', (d: ADMAsset) => {
          div
            .transition()
            .duration(500)
            .style('opacity', 0);
        });

      context.select('.line').datum(dataStart_line);
      focus.select('.line').attr('d', area(dataStart_line));
      focus.select('.envelope').attr('d', envelopArea(dataStart_line));
      daymarker.attr('x', x(this.selectedDate || 0) + margin.left);

      setTimeout(() => {
        context.select('.line').datum(data);
        focus.select('.line').attr('d', area(data));
        focus.select('.envelope').attr('d', envelopArea(data));
        focus
          .selectAll('.dot')
          .data(data)
          .attr('x', getX)
          .attr('y', getY)
          .style('opacity', 1.0);
      }, 10);

      context
        .select('.brush')
        .call(this.brushBehavior)
        .call(this.brushBehavior.move, x.range());

      this.svg.select('.zoom').call(this.zoomBehavior);

      this.isDrawing = false;
    };

    /* this._plantingDataService.selectedDate$.subscribe((date: number) => {
      // daymarker.attr('transform', 'translate(' + margin2.left + ',' + 0 + ')');
      console.log('selectedDate: ', date);
    }); */

    this.filteredMappedDerivativeSub = this._plantingDataService.filteredMappedDerivatives$
      .pipe(
        map((analyticMap: AnalyticMap) => {
          return analyticMap[Object.keys(analyticMap)[0]].raw as ADMAsset[];
        }),
        concatMap((value, index) => {
          return index === 0
            ? of(value).pipe(
                tap((derivatives: ADMAsset[]) => {
                  x.domain(
                    d3.extent(derivatives, d => {
                      return new Date(d.properties.acquisitionDate);
                    })
                  );
                  y.domain([
                    0,
                    d3.max(derivatives, d => {
                      return <number>d.properties[metric];
                    })
                  ]);
                  x2.domain(x.domain());
                  y2.domain(y.domain());

                  const data = dataMap(derivatives);
                  // Show confidence interval
                  focus
                    .append('path')
                    .datum(data)
                    .attr('class', 'envelope')
                    .attr('clip-path', 'url(#clip)')
                    .attr('fill', '#cce5df')
                    .attr('stroke', 'none')
                    .style('opacity', 0.2)
                    .attr('d', envelopArea(data));

                  focus
                    .append('path')
                    .datum(data)
                    .attr('class', 'line')
                    .attr('d', area(data))
                    .attr('stroke', 'blue')
                    .attr('stroke-width', 2)
                    .attr('fill', 'none');

                  focus
                    .append('g')
                    .attr('class', 'axis axis--x')
                    .attr('transform', 'translate(0,' + height + ')')
                    .call(xAxis);

                  focus
                    .append('g')
                    .attr('class', 'axis axis--y')
                    .call(yAxis);

                  context
                    .append('path')
                    .datum(data)
                    .attr('class', 'line');
                  // .attr('d', area2);
                  context
                    .append('g')
                    .attr('class', 'axis axis--x')
                    .attr('transform', 'translate(0,' + height2 + ')')
                    .call(xAxis2);

                  context
                    .append('g')
                    .attr('class', 'brush')
                    .call(this.brushBehavior)
                    .call(this.brushBehavior.move, x.range());

                  this.svg
                    .append('rect')
                    .attr('class', 'zoom')
                    .attr('width', width)
                    .attr('height', 25)
                    .attr('fill', 'none')
                    .attr(
                      'transform',
                      'translate(' + margin.left + ',' + height + ')'
                    )
                    .call(this.zoomBehavior);
                  draw(derivatives);
                })
              )
            : of(value);
        }),
        combineLatest(
          this._plantingDataService.selectedDate$,
          this._plantingDataService.selectedDerivativeType$
        )
      )
      .subscribe(
        ([derivatives, newDate, newType]: [
          ADMAsset[],
          number,
          HumanReadableProductType
        ]) => {
          this.selectedDate = newDate;
          this.selectedType = newType;

          filteredDerivatives.splice(
            0,
            filteredDerivatives.length,
            ...derivatives
          );
          draw(derivatives);
        }
      );

    this.timelineOpenSub = this._plantingsStateService.timelineOpen.subscribe(
      tlOpen => {
        this.timelineOpen = tlOpen;
      }
    );

    this.prevDayAvailableSub = this._plantingDataService.prevDayAvailable$.subscribe(
      (available: boolean) => {
        this.prevDayAvailable = available;
      }
    );
    this.nextDayAvailableSub = this._plantingDataService.nextDayAvailable$.subscribe(
      (available: boolean) => {
        this.nextDayAvailable = available;
      }
    );
  }

  updateViewDates(range: Date[]) {
    this.viewStartDate = range[0].getTime();
    this.viewEndDate = range[1].getTime();
  }

  toggleOpen() {
    this.toggleTimeline.emit((this.timelineOpen = !this.timelineOpen));
  }

  convertDate = (curr: ADMAsset): ADMAsset => {
    return {
      ...curr,
      properties: {
        ...curr.properties,
        date: new Date(curr.properties.acquisitionDate)
      }
    };
  };

  ngOnDestroy() {
    d3.select(window).on('resize', null);
    this.brushBehavior.on('brush end', null);
    this.zoomBehavior.on('zoom', null);
    this.svg.selectAll('.dot').on('click', null);
    this.svg.selectAll('.dot').on('mouseover', null);
    this.svg.selectAll('.dot').on('mouseout', null);
    this.svg.selectAll('*').remove();
    this.filteredMappedDerivativeSub.unsubscribe();
    this.timelineOpenSub.unsubscribe();
    this.prevDayAvailableSub.unsubscribe();
    this.nextDayAvailableSub.unsubscribe();
  }
}
