import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Annotation, AnnotationEntityType } from '../models/annotation.model';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map } from 'rxjs/operators';

@Injectable()
export class AnnotationService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/annotations`;
  }

  protected mapJsonToCollection(respBody: Annotation[]): Annotation[] {
    return respBody.map(json => new Annotation(json));
  }

  createAnnotation(annotation: Annotation): Promise<string> {
    return this.createWithResponseAsUrl(
      this.endpointUrl,
      annotation.toNewJSON()
    ).toPromise();
  }

  saveAnnotation(annotation: Annotation): Promise<string> {
    return this.createAnnotation(annotation);
  }

  getAnnotationByUrl(url: string): Promise<Annotation> {
    return this.httpClient
      .get<Annotation>(url)
      .pipe(map(resp => new Annotation(resp)))
      .toPromise();
  }

  getAnnotationById(id: string): Promise<Annotation> {
    return this.httpClient
      .get<Annotation>(`${this.endpointUrl}/${id}`)
      .pipe(map(resp => new Annotation(resp)))
      .toPromise();
  }

  updateAnnotation(annotation: Annotation): Promise<HttpResponse<Annotation>> {
    return this.putWithResponse<Annotation>(
      `${this.endpointUrl}/${annotation.id}`,
      annotation.toNewJSON()
    ).toPromise();
  }

  deleteAnnotation(annotation: Annotation): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${annotation.id}`
    ).toPromise();
  }

  getAnnotationsByOrderIdAndEntityId(
    orderId: string,
    entityType: AnnotationEntityType,
    entityId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Annotation>> {
    return this.getWithPagedResponse<Annotation>(
      `${this.endpointUrl}/order/${orderId}/${entityType}/${entityId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getAnnotationsByEntityTypeAndEntityId(
    entityType: AnnotationEntityType,
    entityId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Annotation>> {
    return this.getWithPagedResponse<Annotation>(
      `${this.endpointUrl}/${entityType}/${entityId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getAnnotationsByInspectionId(
    inspectionId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Annotation>> {
    if (!baseParams) {
      baseParams = { size: 2500 };
    }
    return this.getWithPagedResponse<Annotation>(
      `${this.endpointUrl}/inspection/${inspectionId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }
  getAnnotationsByEntityTypeEntityIdAndInspectionId(
    entityType: AnnotationEntityType,
    entityId: string,
    inspectionId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Annotation>> {
    return this.getWithPagedResponse<Annotation>(
      `${this.endpointUrl}/inspection/${inspectionId}/${entityType}/${entityId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }
}
