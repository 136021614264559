import { envPicker } from './envPicker';

export const environment = {
  ENV: envPicker('ENV', 'production'),
  CDN_ROOT: envPicker('CDN_ROOT', 'https://storage.googleapis.com/datadoors-qual-cdn'),
  API_ROOT: envPicker('API_ROOT', 'https://agneo.oneatlas.airbus.com/api'),
  AUTH_API_ROOT: envPicker(
    'AUTH_API_ROOT',
    'https://agneo.oneatlas.airbus.com/api/auth'
  ),
  USERS_API_ROOT: envPicker(
    'USERS_API_ROOT',
    'https://agneo.oneatlas.airbus.com/api/users'
  ),
  CUSTOMERS_API_ROOT: envPicker(
    'CUSTOMERS_API_ROOT',
    'https://agneo.oneatlas.airbus.com/api/customers'
  ),
  AG_API_ROOT: envPicker(
    'AG_API_ROOT',
    'https://agneo.oneatlas.airbus.com/agapi'
  ),
  DISCOVERY_API_ROOT: envPicker(
    'DISCOVERY_API_ROOT',
    'https://www.datadoors.net/discovery-api'
  ),
  BROKER_ROOT: envPicker('BROKER_ROOT', 'https://agneo.oneatlas.airbus.com/api/broker'),
  CESIUM_BASE_URL: envPicker('CESIUM_BASE_URL', '/assets/cesium'),
  AMAP_APP_ID: envPicker(
    'AMAP_APP_ID',
    '824ebd9efaa41549d0f736230ab1fa5f6b949946'
  ),
  // links to other apps
  ADP_ROOT: envPicker('ADP_ROOT', 'https://portal.airbusaerial.com'),
  ASP_ROOT: envPicker('ASP_ROOT', 'https://asp.airbusaerial.com'),
  ADMIN_ROOT: envPicker('ADMIN_ROOT', 'https://agneo.oneatlas.airbus.com/admin'),
  AG_ROOT: envPicker('AG_ROOT', 'https://agclient.ddns.net/'),
  DMS_ROOT: envPicker('DMS_ROOT', 'https://dms.airbus-intelligence.com'),
  // old ASP stuff,  do we still need?
  AIRMAP_KEY: envPicker(
    'AIRMAP_KEY',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVkZW50aWFsX2lkIjoiY3JlZGVudGlhbHxBenlBV0FFSHlHendlcWZvTlBLbm1IRTQ2ZTc0IiwiYXBwbGljYXRpb25faWQiOiJhcHBsaWNhdGlvbnx4YkFhZHg2c1FYbTY2WVNQMDR5NFBTT1lYNDlEIiwib3JnYW5pemF0aW9uX2lkIjoiZGV2ZWxvcGVyfGVPMkQ3enRQenZlYjVobGtQUFB3VU5OUkV4ayIsImlhdCI6MTUwMjEyNzYyOX0.wM8oYwQijG5eeIpfU-ZdAZT2XRhoTtkzi35Zr2hbOiM'
  ),
  // if not null, all the apps will try to load this, and only this basemap
  // only xyz is supported. Setting this BREAKS ADP!
  ALTERNATE_BASEMAP: envPicker('ALTERNATE_BASEMAP', 'https://agneo.oneatlas.airbus.com/api/broker/oneatlasv3?x={x}&y={y}&z={z}'),
  ALTERNATE_OVERLAY: envPicker('ALTERNATE_OVERLAY', ''),
  // if true, the app will be configured for Motac Kiosk mode, which means:
  // - auto-login will be enabled (backend auth should be disabled)
  // - the geocoder search in the DMS will be removed
  // - the <aerial-header> in the DMS will be removed
  IS_MOTAC: envPicker('IS_MOTAC', 'false'),

  AOI_DRAWING_COLOR: 'rgba(255, 255, 255, 0.85)'
};
