import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'section-title',
  template: `
    <section class="section-title-block alt-font">
      <h2>{{ sectionTitle }}</h2>
      <small>Section {{ sectionNumber }}</small>
    </section>
  `,
  styleUrls: ['./section-title.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTitleComponent {
  @Input()
  sectionTitle: string;

  @Input()
  sectionNumber: string;
}
