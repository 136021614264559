import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  Validators
} from '@angular/forms';
import { usStates } from '../../util/usStates';
import { handleBooleanProperty } from '../../util/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'state-select',
  template: `
    <!-- prettier-ignore -->
    <select #stateSelect
      [formControl]="stateSelectCtrl"
      class="state"
      [class.invalid]="required && control.touched && control.invalid"
      [attr.id]="id"
      (blur)="onTouched()">
      <option ngValue="">&mdash;</option>
      <option *ngFor="let state of states" [ngValue]="state.abbreviation">
        {{state.abbreviation}}
      </option>
    </select>
  `,
  styleUrls: ['./state-select.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateSelectComponent),
      multi: true
    }
  ]
})
export class StateSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  id: string;

  @Input()
  set required(required: boolean) {
    this._required = handleBooleanProperty(required);
    if (this._required) {
      this.stateSelectCtrl.setValidators(Validators.required);
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set disabled(disabled: boolean) {
    disabled = handleBooleanProperty(disabled);
    this.setDisabledState(disabled);
  }

  get disabled(): boolean {
    return this.stateSelectCtrl.disabled;
  }

  @Input()
  set value(value: string) {
    this.writeValue(value);
  }

  get value(): string {
    return this.stateSelectCtrl.value;
  }

  @Output()
  change = new EventEmitter<string>();

  @HostBinding('class.form-control')
  hasFormCtrlClass = true;

  @HostBinding('class.select-control')
  hasSelectClass = true;

  @HostBinding('class.state-select')
  hasIdentClass = true;

  get control(): FormControl {
    return this.stateSelectCtrl;
  }

  get element(): HTMLSelectElement {
    return this._select.nativeElement;
  }

  protected stateSelectCtrl = new FormControl('');
  protected states = usStates;
  private _required = false;
  private _changesSub: Subscription;

  @ViewChild('stateSelect')
  private _select: ElementRef<HTMLSelectElement>;

  protected onTouched = () => {};
  private _onChange = (state: string) => {};

  constructor(public el: ElementRef) {}

  writeValue(state: string): void {
    this.stateSelectCtrl.setValue(state);
  }

  registerOnChange(fn: (state: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.stateSelectCtrl.disable();
    }
  }

  ngOnInit(): void {
    this._changesSub = this.stateSelectCtrl.valueChanges.subscribe(value => {
      this._onChange(value);
      this.change.emit(value);
    });
  }

  ngOnDestroy(): void {
    this._changesSub.unsubscribe();
  }
}
