import { ElementRef } from '@angular/core';

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

export class Tooltip {
  text = '';
  placement: TooltipPosition = 'right';
  tooltipClass = '';
  disabled = false;
  visible = false;
  actorElement: ElementRef;

  // TODO: take into account viewport boundaries

  get classes(): string {
    return `place-${this.placement} ${this.tooltipClass}`;
  }

  get actorRect(): DOMRect {
    return this.actorElement.nativeElement.getBoundingClientRect();
  }

  get style(): object {
    if (!this.actorElement) return undefined;
    const style = {
      'top': `${this.actorRect.top}px`,
      'left': `${this.actorRect.left}px`,
      'min-height': `${this.actorRect.height}px`
    };
    switch (this.placement) {
      case 'top':
      case 'bottom':
        style['transform'] = `translateX(${this.actorRect.width / 2}px)`;
        return style;
      default:
        return style;
    }
  }

  get textStyle(): object {
    if (!this.actorElement) return undefined;
    switch (this.placement) {
      case 'bottom':
        return {
          'top': `${this.actorRect.height}px`,
        };
      case 'right':
        return {
          'left': `${this.actorRect.width}px`,
        };
    }
  }

  constructor(tooltip?: object) {
    if (tooltip) {
      Object.keys(tooltip).forEach(key => {
        if (tooltip[key]) this[key] = tooltip[key];
      });
    }
  }
}
