import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ToasterService,
  AerialPassword,
  AerialValidation,
  AerialApp,
  getAerialApp
} from 'projects/ui/src/public_api';
import {
  UserService,
  RoleType,
  Role,
  PortalType,
  AnonymousUser,
  AuthProviderUser
} from 'projects/api/src/public_api';

enum SignUpStep {
  UserInfo = 1,
  PilotInfo = 2,
  Password = 3,
  Complete = 4
}

@Component({
  selector: 'sign-up',
  templateUrl: './signUp.template.html',
  styleUrls: ['./signUp.styles.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  loading = true;
  submitting = false;

  isInvalidUrl: boolean;
  invitedBy: string;
  portalType: PortalType;
  isPilotForm: boolean;
  signUpForm: FormGroup;

  readonly mask = AerialValidation.phoneMask;
  readonly samplePhoneFormat = AerialValidation.samplePhoneFormat;
  readonly minlength = AerialPassword.minlength;
  readonly validSpecialChars = AerialPassword.validSpecialChars;

  get isUserInfoStep(): boolean {
    return this._currentStep === SignUpStep.UserInfo;
  }

  get isPilotInfoStep(): boolean {
    return this._currentStep === SignUpStep.PilotInfo;
  }

  get isPasswordStep(): boolean {
    return this._currentStep === SignUpStep.Password;
  }

  get isCompleteStep(): boolean {
    return this._currentStep === SignUpStep.Complete;
  }

  get app(): AerialApp {
    return getAerialApp(this.portalType);
  }

  get portalName(): string {
    switch (this.app) {
      case AerialApp.Ag:
        return 'AgNeo Portal';
      case AerialApp.Catalog:
        return 'Airbus DMS';
      case AerialApp.Asp:
        return 'Aerial Services Portal';
      default:
        return 'Aerial Data Portal';
    }
  }

  get userInfoGrp(): FormGroup {
    return this.signUpForm.get('userInfo') as FormGroup;
  }

  get pilotInfoGrp(): FormGroup {
    return this.signUpForm.get('pilotInfo') as FormGroup;
  }

  get passwordGrp(): FormGroup {
    return this.signUpForm.get('password') as FormGroup;
  }

  get firstNameCtrl(): FormControl {
    return this.userInfoGrp.get('firstName') as FormControl;
  }

  get lastNameCtrl(): FormControl {
    return this.userInfoGrp.get('lastName') as FormControl;
  }

  get emailCtrl(): FormControl {
    return this.userInfoGrp.get('email') as FormControl;
  }

  get pwdCtrl(): FormControl {
    return this.passwordGrp.get('pwd') as FormControl;
  }

  get confirmPwdCtrl(): FormControl {
    return this.passwordGrp.get('confirmPwd') as FormControl;
  }

  get mobilePhoneCtrl(): FormControl {
    return this.pilotInfoGrp.get('mobilePhone') as FormControl;
  }

  get certNumberCtrl(): FormControl {
    return this.pilotInfoGrp.get('pilotCertificationNumber') as FormControl;
  }

  get certDateCtrl(): FormControl {
    return this.pilotInfoGrp.get('pilotCertificationDate') as FormControl;
  }

  get currDate(): Date {
    return new Date();
  }

  get isMobilePhoneInvalid(): boolean {
    return (
      (this.mobilePhoneCtrl.touched || this.mobilePhoneCtrl.dirty) &&
      this.mobilePhoneCtrl.invalid
    );
  }

  get isPasswordInvalid(): boolean {
    return (this.pwdCtrl.touched || this.pwdCtrl.dirty) && this.pwdCtrl.invalid;
  }

  get isConfirmPasswordInvalid(): boolean {
    return (
      (this.passwordGrp.touched || this.passwordGrp.dirty) &&
      this.passwordGrp.hasError('passwordMismatch')
    );
  }

  // The password validation fields are messy... probably a better way to do it
  // TODO: update <password-group> to allow other types of input styles then use it here
  get showPwdRequiredError(): boolean {
    return this.pwdCtrl.touched && this.pwdCtrl.hasError('required');
  }

  get showPwdMinlengthError(): boolean {
    return !this.showPwdRequiredError && this.pwdCtrl.hasError('minlength');
  }

  get showPwdPatternError(): boolean {
    return (
      !this.showPwdRequiredError &&
      !this.showPwdMinlengthError &&
      this.pwdCtrl.hasError('pattern')
    );
  }

  private _currentStep = SignUpStep.UserInfo;
  private _token: string;
  private _newUser: AuthProviderUser;
  private _sub: Subscription;

  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _toaster: ToasterService,
    private _router: Router,
    private _fb: FormBuilder
  ) {}

  // note: no "invalid step" error checking in `nextStep()` and `prevStep()`
  nextStep(): void {
    if (this.isUserInfoStep && !this.isPilotForm) {
      this._currentStep = SignUpStep.Password;
    } else {
      this._currentStep++;
    }
  }

  prevStep(): void {
    if (this.isPasswordStep && !this.isPilotForm) {
      this._currentStep = SignUpStep.UserInfo;
    } else {
      this._currentStep--;
    }
  }

  submitForm(): void {
    this.submitting = true;
    const { firstName, lastName } = this.userInfoGrp.value;
    this._newUser = {
      ...this._newUser,
      firstName,
      lastName,
      password: this.pwdCtrl.value
    };
    if (this.isPilotForm) {
      const {
        mobilePhone,
        pilotCertificationNumber,
        pilotCertificationDate
      } = this.pilotInfoGrp.value;
      this._newUser.userInfo = { mobilePhone };
      this._newUser.pilotInfo = {
        pilotCertificationNumber,
        pilotCertificationDate
      };
    }
    this._userService
      .createFirebaseUser(this._newUser, this._token)
      .then(_ => {
        this.submitting = false;
        this.nextStep();
      })
      .catch(err => {
        this.submitting = false;
        this._toaster.alert('error', 'Sign up failed. Please try again.');
        console.error('error creating firebase user', err, this._newUser);
      });
  }

  goToLogin(): void {
    this._router.navigate(['auth/log_in']);
  }

  ngOnInit() {
    this._sub = this._route.params
      .pipe(map(params => params['id'] as string))
      .subscribe(async anonUserId => {
        await this._setupUser(anonUserId);
        this._initForm();
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private async _setupUser(anonUserId: string): Promise<void> {
    this._token = await this._getToken(anonUserId);
    if (!this._token) {
      this.isInvalidUrl = true;
      return;
    }
    const anonUser = await this._getAnonUser(anonUserId);
    if (!anonUser) {
      this.isInvalidUrl = true;
      return;
    }
    const anonScope = anonUser.scopes[0].params;
    this._newUser = {
      email: anonScope['username'],
      roles: anonScope['roles'] as Role[]
    };
    this.invitedBy = anonScope['invitedBy'];
    this.portalType = anonScope['category'] as PortalType;
    this.isPilotForm = this._newUser.roles.some(
      role => role.roleType === RoleType.Pilot
    );
  }

  private async _getToken(anonUserId: string): Promise<string> {
    try {
      return await this._userService.getTokenForAnonUser(anonUserId);
    } catch (err) {
      console.error('error fetching token for anon user', err);
      return null;
    }
  }

  private async _getAnonUser(anonUserId: string): Promise<AnonymousUser> {
    try {
      return await this._userService.getAnonUser(anonUserId, this._token);
    } catch (err) {
      console.error('error fetching anon user', err);
      return null;
    }
  }

  private _initForm(): void {
    this.signUpForm = this._fb.group({
      userInfo: this._fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: [
          this._newUser ? this._newUser.email : '',
          [Validators.required, Validators.email]
        ]
      }),
      pilotInfo: this._fb.group({}),
      password: this._fb.group(
        {
          pwd: ['', AerialPassword.standardValidators],
          confirmPwd: ['', Validators.required]
        },
        {
          validator: [AerialPassword.matchValidator()]
        }
      )
    });
    if (this.isPilotForm) {
      this.pilotInfoGrp.addControl(
        'mobilePhone',
        this._fb.control('', AerialValidation.mobilePhoneValidators)
      );
      this.pilotInfoGrp.addControl(
        'pilotCertificationNumber',
        this._fb.control('', Validators.required)
      );
      this.pilotInfoGrp.addControl(
        'pilotCertificationDate',
        this._fb.control('', Validators.required)
      );
    }
    if (this.isInvalidUrl) {
      this.signUpForm.disable();
    }
  }
}
