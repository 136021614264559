import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnChanges
} from '@angular/core';
import { MapLayer } from 'projects/map/src/public_api';

@Component({
  selector: 'layer-opacity',
  template: `
    <label
      class="layer-opacity {{ class }}"
      (click)="stopClickPropagation($event)"
      [class.visible]="isInputVisible"
    >
      <a
        href="#"
        class="opacity-toggler"
        title="Adjust Layer Opacity"
        placement="bottom"
        (click)="showInput($event)"
      >
        {{ currOpacity }}%
      </a>
      <input
        type="number"
        min="1"
        max="100"
        class="opacity-input"
        #opacityInput
        [value]="currOpacity"
        (change)="setLayerOpacity($event.target.value)"
        (keyup.enter)="hideInput($event)"
        (keyup.esc)="hideInput($event)"
      />
      <strong class="opacity-percent">%</strong>
      <span class="opacity-slider">
        <input
          type="range"
          min="1"
          #opacitySlider
          [value]="currOpacity"
          (input)="setLayerOpacity($event.target.value)"
        />
      </span>
    </label>
  `,
  styleUrls: ['./layerOpacity.styles.scss'],
  host: {
    '(document:click)': 'onClickout($event)'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerOpacityComponent implements OnDestroy, OnChanges {
  @Input() layer: MapLayer;
  @ViewChild('opacityInput') opacityInput: ElementRef;
  isInputVisible: boolean = false;
  @Input() currOpacity: number = 100;
  @Input() class: string;

  ngOnDestroy() {
    if (this.layer) {
      this.layer.olLayer.setOpacity(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setLayerOpacity(this.currOpacity);
  }

  onClickout(event: Event) {
    if (this.isInputVisible) {
      this.isInputVisible = false;
    }
  }

  stopClickPropagation(event: Event) {
    event.stopPropagation();
  }

  setLayerOpacity(value: number) {
    this.currOpacity = value;
    this.layer.olLayer.setOpacity(this.currOpacity / 100.0);
  }

  hideInput(event: Event) {
    event.stopPropagation();
    this.isInputVisible = false;
  }

  showInput(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isInputVisible = true;
    this.opacityInput.nativeElement.focus();
  }

  toggleInput(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isInputVisible = !this.isInputVisible;
    if (this.isInputVisible) {
      this.opacityInput.nativeElement.focus();
    }
  }
}
