import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataProduct, NearmapDataProduct } from '../models/dataProduct.model';
import { BaseService } from './base.service';
import { BrokerService } from './broker.service';
import { switchMap, map } from 'rxjs/operators';

import GeoJSON from 'ol/format/GeoJSON';
import { getCenter } from 'ol/extent';
import Polygon from 'ol/geom/Polygon';
import { Extent } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

@Injectable()
export class NearmapService extends BaseService {
  protected endpointUrl: string;

  constructor(
    protected httpClient: HttpClient,
    private _brokerService: BrokerService
  ) {
    super(httpClient);
    this.endpointUrl = `${this.brokerRootUrl}/maps/manned`;
  }

  searchForDataInGeometry(geometry: Polygon): Promise<any | DataProduct[]> {
    const geom = geometry.clone();
    geom.transform('EPSG:3857', 'EPSG:4326');
    const [lon, lat] = getCenter(geom.getExtent());

    return this._brokerService
      .getApiKey()
      .pipe(
        switchMap(brokerKey => {
          return this.getWithResponse(
            `${this.endpointUrl}?ll=${lat},${lon}&apiKey=${brokerKey}`
          );
        }),
        map(resp => resp.body),
        map((body: [{ acquired: number; dataUrl: string }]) => {
          const parser = new GeoJSON();
          return body.map(({ acquired, dataUrl }) => {
            const dp = new NearmapDataProduct({
              name: 'Airborne Archive Imagery',
              // orderId: dataUrl,
              // projectId: this.order.projectId,
              acquired: acquired,
              productType: 'Manned RGB',
              gsd: 7.4,
              dataUrl: dataUrl,
              brokerUrl: dataUrl,
              dataType: 'XYZ',
              category: 'NEARMAP',
              extent: parser.writeGeometry(geom),
              sourceKey: `NEARMAP_${dataUrl}`
            });

            // This generates an entityId for the workspace to be able
            // to recreate a NearmapDataProduct when loading
            dp.id = NearmapService.generateEntityIdForNearmapDataProduct(
              dp,
              geom
            );

            return dp;
          });
        })
      )
      .toPromise();
  }

  static generateEntityIdForNearmapDataProduct(
    product: NearmapDataProduct,
    extent: Polygon
  ): string {
    return `${product.dataUrl}::${product.acquired}::${extent.getExtent()}`;
  }

  // NOTE: this is a shite method to allow for saving/loading
  // nearmap data for a workspace
  static createNearmapDataProductForSavedWorkspace(
    entityIdString: string
  ): Promise<NearmapDataProduct> {
    return new Promise(resolve => {
      const [dataUrl, acquired, bbox] = entityIdString.split('::');
      const geom = fromExtent(bbox
        .split(',')
        .map(num => parseFloat(num)) as Extent);
      const parser = new GeoJSON();

      const nearmapDP = new NearmapDataProduct({
        id: entityIdString,
        name: 'Airborne Archive Imagery',
        // orderId: dataUrl,
        // projectId: this.order.projectId,
        acquired: parseInt(acquired),
        productType: 'Manned RGB',
        gsd: 7.4,
        dataUrl: dataUrl,
        brokerUrl: dataUrl,
        dataType: 'XYZ',
        category: 'NEARMAP',
        extent: parser.writeGeometry(geom),
        sourceKey: `NEARMAP_${dataUrl}`
      });

      resolve(nearmapDP);
    });
  }
}
