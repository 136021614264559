import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aerial-hero',
  template: `
    <header class="hero columnar-container alt-font">
      <h1 class="display-heading">{{ heading }}</h1>
      <div class="h4"><ng-content></ng-content></div>
    </header>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./aerial-hero.styles.scss']
})
export class AerialHeroComponent {
  @Input()
  heading: string;
}
