import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reverse' })

// Used to reverse the contents of an Array
export class ReversePipe implements PipeTransform {
  transform(input: Array<any>): Array<any> {
    if (input instanceof Array) return input.reverse();
    else throw 'ReversePipe: input needs to be an Array';
  }
}
