import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Guard that can be used to gate routes by making sure the user is logged in with a token
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(): Promise<boolean> {
    if (environment.IS_MOTAC) {
      return of(this._authService.logIn('MOTAC@MOTAC.com', 'password'))
        .pipe(map(_ => true))
        .toPromise();
    } else {
      return this._checkToken();
    }
  }

  private _checkToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._authService.jwtTokenGetter().then(
        token => {
          if (token) {
            resolve(true);
          } else {
            this._accessDenied();
            resolve(false);
          }
        },
        _error => {
          this._accessDenied();
          reject(false);
        }
      );
    });
  }

  canActivateChild = this.canActivate;

  /**
   * This method will redirect the user to the log in page,
   * and will store thge path they tried to access
   * to the 'deniedPath' localStorage var
   */
  private _accessDenied() {
    if (window.location.pathname.indexOf('/auth') == -1)
      localStorage.setItem('deniedPath', window.location.pathname);
    else localStorage.removeItem('deniedPath');

    this._router.navigate(['/auth/log_in']);
  }
}
