import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map } from 'rxjs/operators';
import { Asset } from '../models/asset.model';
import { Observable } from 'rxjs';

export interface BatchUpdateAction {
  type: 'TRIAGE_STATUS' | 'CREATE_TAG' | 'DELETE_TAG';
  params: {
    tags?: string[];
    value?: string;
  };
}
export interface BatchUpdate {
  assetIds: string[];
  projectId: string;
  actions: BatchUpdateAction[];
}

@Injectable()
export class AssetService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/features/assets`;
  }

  protected mapJsonToCollection(respBody: Asset[]): Asset[] {
    return respBody.map(json => Asset.fromJSON(json));
  }

  getAssets(baseParams?: BaseParams): Observable<PagedResponse<Asset>> {
    return this.getWithPagedResponse<Asset>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams
    );
  }

  createAsset(asset: Asset): Observable<string> {
    return this.createWithResponseAsUrl(this.endpointUrl, asset.toJSON());
  }

  getAssetById(id: string): Observable<Asset> {
    return this.httpClient
      .get<Asset>(`${this.endpointUrl}/${id}`)
      .pipe(map(resp => Asset.fromJSON(resp)));
  }

  updateAsset(asset: Asset): Observable<HttpResponse<Asset>> {
    return this.putWithResponse<Asset>(
      `${this.endpointUrl}/${asset.id}`,
      asset.toJSON()
    );
  }

  patchAsset(id: string, params: object): Observable<HttpResponse<Asset>> {
    return this.patchWithResponse<Asset>(`${this.endpointUrl}/${id}`, params);
  }

  batchPatchAsset(params: BatchUpdate): Observable<HttpResponse<Asset>> {
    return this.patchWithResponse<Asset>(`${this.endpointUrl}/batch`, params);
  }

  deleteAsset(assetId: string): Observable<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${assetId}`);
  }

  getAssetsByProjectId(
    projectId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Observable<PagedResponse<Asset>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Asset>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  getAssetsByGroupId(
    groupId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Observable<PagedResponse<Asset>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Asset>(
      `${this.endpointUrl}/group/${groupId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    );
  }

  classifyAssetsByProjectId(
    projectId: string,
    classificationLayerId: string
  ): Observable<any> {
    return this.postWithResponse(
      `${this.endpointUrl}/project/${projectId}/classify`,
      {
        layerId: classificationLayerId,
        autoTriage: false,
        bufferDistance: 35
      }
    );
  }

  classifyAssetsByGroupId(
    groupId: string,
    classificationLayerId: string
  ): Observable<any> {
    return this.postWithResponse(
      `${this.endpointUrl}/group/${groupId}/classify`,
      {
        layerId: classificationLayerId,
        autoTriage: false,
        bufferDistance: 35
      }
    );
  }
}
