import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Customer } from '../models/customer.model';
import { User } from '../models/user.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// TODO: deprecate this guy

@Injectable()
export class AdminService {
  private _rootUrl: string = environment.API_ROOT;

  constructor(private _httpClient: HttpClient) {}

  // Services for admin call
  getCustomers(): Observable<Customer[]> {
    return this._httpClient
      .get<Customer[]>(`${this._rootUrl}/customers?size=500`)
      .pipe(map(data => data));
  }

  getAssetsFromCustomerID(id: string): Observable<any> {
    return this._httpClient
      .get(`${this._rootUrl}/assets/customer/${id}?size=500`)
      .pipe(map(data => data));
  }

  addLayerData(id: string, data: any): Promise<any> {
    return this._httpClient
      .patch(`${this._rootUrl}/orders/${id}`, data)
      .pipe(map(data => data))
      .toPromise();
  }

  getUsersForCustomer(id: number): Observable<User[]> {
    return this._httpClient.get<User[]>(
      `${this._rootUrl}/users/customer/${id}?size=500`
    );
  }

  createUser(userObj: any) {
    return this._httpClient
      .post(`${this._rootUrl}/users/firebase`, userObj)
      .pipe(map(data => data));
  }

  createCustomer(customerName: Object): Promise<any> {
    return this._httpClient
      .post(`${this._rootUrl}/customers`, customerName)
      .pipe(map(data => data))
      .toPromise();
  }

  deleteUser(id: number): Observable<any> {
    return this._httpClient
      .delete(`${this._rootUrl}/users/${id}`)
      .pipe(map(data => data));
  }
}
