import { AgBase } from './agBase.model';

export class Farm extends AgBase {
  public readonly plantingCount: number;
  public growerId: string;

  constructor(opts: Partial<Farm>) {
    super(opts);
  }

  public toJSON(): object {
    return this;
  }
}
