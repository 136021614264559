import { Input } from '@angular/core';
import { handleClassNamesFromInput as handleClasses } from '../util/helpers';

export abstract class Badge {
  @Input()
  set classes(classes: string[] | string) {
    this._classes = handleClasses(classes);
  }

  get classes(): string[] | string {
    return this._classes.concat(['badge', ...this.otherClasses]);
  }

  abstract otherClasses: string[];
  private _classes: string[] = [];
}
