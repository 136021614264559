import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AerialMenu } from '../util/aerial-menu.model';

@Component({
  selector: 'overflow-menu',
  templateUrl: './overflow-menu.template.html',
  styleUrls: ['./overflow-menu.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverflowMenuComponent extends AerialMenu {
  @Input()
  menuSize: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  disabled = false;

  @Input()
  additionalClasses = '';
}
