import {
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';

export class AerialPassword {
  static readonly regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%*&])[A-Za-z\d!?@#$%*&]/;
  static readonly validSpecialChars = '!?@#$%*&';

  static readonly minlength = 8;

  static readonly standardValidators = [
    Validators.required,
    Validators.minLength(AerialPassword.minlength),
    Validators.pattern(AerialPassword.regex)
  ];

  // Removes any errors on `confirmPwd` control, adds `passwordMatch` error to `confirmPwd` control
  static passwordMatch(group: FormGroup): any {
    const pwd = group.get('pwd').value; // to get value in input tag
    const confirmPwd = group.get('confirmPwd').value; // to get value in input tag
    if (pwd !== confirmPwd) {
      group.get('confirmPwd').setErrors({ passwordMatch: true });
    } else {
      return null;
    }
  }

  // Adds `passwordMismatch` error to the password form group
  // prettier-ignore
  static readonly matchValidator = (
    pwdCtrlName = 'pwd',
    confirmCtrlName = 'confirmPwd'
  ): ValidatorFn => (group: FormGroup): ValidationErrors | null => {
    const pwdVal = group.get(pwdCtrlName).value,
      confirmVal = group.get(confirmCtrlName).value;
    const passwordMismatch = {
      passwordValue: pwdVal,
      confirmPasswordValue: confirmVal
    };
    return pwdVal !== confirmVal ? { passwordMismatch } : null;
  }
}
