import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Order } from '../models/order.model';
import { OrderTemplate } from '../models/orderTemplate.model';
import { BaseService } from './base.service';
import { BaseParams } from '../interfaces/baseParams.interface';
import { PagedResponse } from '../interfaces/pagedResponse.interface';

import { map } from 'rxjs/operators';

@Injectable()
export class OrderService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/orders`;
  }

  protected mapJsonToCollection(respBody: Order[]): Order[] {
    return respBody.map(json => Order.fromJSON(json));
  }

  getOrders(
    baseParams?: BaseParams,
    searchTerm?: string
  ): Promise<PagedResponse<Order>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Order>(
      this.endpointUrl,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getOrdersForAoi(
    aoiId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Order>> {
    return this.getWithPagedResponse<Order>(
      `${this.endpointUrl}/aoi/${aoiId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getOrdersForProject(
    projectId: string,
    baseParams?: BaseParams,
    searchTerm?: string
  ): Promise<PagedResponse<Order>> {
    const params = searchTerm
      ? this.buildWildcardSearchParam(searchTerm)
      : undefined;
    return this.getWithPagedResponse<Order>(
      `${this.endpointUrl}/project/${projectId}`,
      this.mapJsonToCollection,
      baseParams,
      { params }
    ).toPromise();
  }

  getOrdersForCustomer(
    customerId: string,
    baseParams?: BaseParams
  ): Promise<PagedResponse<Order>> {
    return this.getWithPagedResponse<Order>(
      `${this.endpointUrl}/customer/${customerId}`,
      this.mapJsonToCollection,
      baseParams
    ).toPromise();
  }

  getOrder(orderId: string): Promise<Order> {
    return this.httpClient
      .get<Order>(`${this.endpointUrl}/${orderId}`)
      .pipe(map(resp => Order.fromJSON(resp)))
      .toPromise();
  }

  createOrder(order: Order, skipFulfillment?: boolean): Promise<string> {
    return this.createWithResponseAsUrl(
      `${this.endpointUrl}${skipFulfillment ? '?fulfillment=false' : ''}`,
      order.toJSON()
    ).toPromise();
  }

  updateOrder(order: Order): Promise<HttpResponse<Order>> {
    return this.putWithResponse<Order>(
      `${this.endpointUrl}/${order.id}`,
      order
    ).toPromise();
  }

  patchOrder(orderId: string, params: object): Promise<HttpResponse<Order>> {
    return this.patchWithResponse<Order>(
      `${this.endpointUrl}/${orderId}`,
      params
    ).toPromise();
  }

  // TODO: get rid of this method
  saveOrder(order: Order, skipFulfillment?: boolean): Promise<string> {
    return this.createOrder(order, skipFulfillment);
  }

  deleteOrder(order: Order): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(
      `${this.endpointUrl}/${order.id}`
    ).toPromise();
  }

  cloneOrderToCustomer(
    orderId: string,
    projectId: string,
    customerId: string
  ): Promise<HttpResponse<object>> {
    return this.postWithResponse<object>(
      `${this.endpointUrl}/${orderId}/cloneToCustomer`,
      {
        projectId: projectId,
        customerId: customerId
      },
      { params: this.buildParams({ fulfillment: 'false' }) }
    ).toPromise();
  }

  //  NOTE: this is overlapping with our newish OrderTemplateService
  getOrderTemplates(
    baseParams?: BaseParams
  ): Promise<PagedResponse<OrderTemplate>> {
    return this.getWithPagedResponse<OrderTemplate>(
      `${this.rootUrl}/orderTemplates`,
      (respBody: OrderTemplate[]) => {
        return respBody.map(template => new OrderTemplate(template));
      },
      baseParams,
      {
        params: {
          search: 'active==true;templateType==ORDER'
        }
      }
    ).toPromise();
  }

  getOrderTemplate(orderTemplateId: string): Promise<OrderTemplate> {
    return this.httpClient
      .get<OrderTemplate>(`${this.rootUrl}/orderTemplates/${orderTemplateId}`)
      .pipe(map(resp => new OrderTemplate(resp)))
      .toPromise();
  }

  getServiceTypes(): Promise<string[]> {
    return this.httpClient
      .get<string[]>(`${this.rootUrl}/orders/serviceTypes`)
      .toPromise();
  }
}
