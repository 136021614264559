import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from '@angular/core';

@Component({
  selector: 'aerial-code',
  template: `
    <!-- prettier-ignore -->
    <div class="aerial-code">
      <button ngxClipboard
        type="button"
        [cbContent]="code"
        (cbOnSuccess)="copySuccess($event)"
        class="copy-btn invisible">
        <i class="fa fa-clipboard"></i>
      </button>
      <small class="copy-success" [class.visible]="isCopied">
        Copied to Clipboard!
      </small>
      <pre><code highlight [code]="code"></code></pre>
    </div>
  `,
  styleUrls: ['./aerial-code.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AerialCodeComponent {
  @Input()
  code: string;

  protected isCopied = false;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  protected copySuccess(event: any): void {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
      this._changeDetectorRef.markForCheck();
    }, 2000);
  }
}
