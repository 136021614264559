import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { DownloadJobDetails, DownloadJobType } from '../models/download.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${environment.DISCOVERY_API_ROOT}/download`;
  }

  getInfoForJobIds(jobIds: string[]): Observable<DownloadJobDetails[]> {
    return this.httpClient.get<DownloadJobDetails[]>(
      `${this.endpointUrl}/info`,
      {
        params: {
          jobIds: jobIds.join(',')
        }
      }
    );
  }

  getDownloadFile(outputId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.endpointUrl}/retrieve`, {
      params: {
        outputId
      },
      responseType: 'blob',
      observe: 'response'
    });
  }

  submitJobForProcessing(
    jobName: string,
    assetIds: string[],
    jobType: DownloadJobType = DownloadJobType.DLZip,
    params?: {
      [key: string]: string;
    }
  ): Observable<string> {
    return this.httpClient
      .post<{ jobId: string }>(`${this.endpointUrl}/submit`, {
        jobName,
        jobType,
        assetIds,
        params
      })
      .pipe(map(({ jobId }) => jobId));
  }
}
