import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccessTokenResponse } from 'projects/api/src/public_api';
import { Dialog } from 'projects/ui/src/public_api';

@Component({
  selector: 'create-token-dialog',
  templateUrl: 'create-token-dialog.template.html'
})
export class CreateTokenDialogComponent implements Dialog, OnInit {
  successCallback: Function;
  tokenResp: AccessTokenResponse;
  tokenCtrl: FormControl;

  constructor() {}

  close = () => {};

  ngOnInit() {
    this.tokenCtrl = new FormControl(this.tokenResp.token);
  }
}
