import { Pipe, PipeTransform } from '@angular/core';
import isFinite from 'lodash/isFinite';
import { numberWithCommas } from './helpers';

@Pipe({ name: 'largeNumbers' })

// Convert a large number to quickly human readable
// Not for use with very small or very negative number.
// less than 1000 : limit precision to 2
// 1000 : 1K
// 1001 : >1K
// 1000000 : 1M
// 1000000 : >1M
// 1000000000 : 1B
// 1000000000 : >1B
// 1000000000000 : 1T
// 1000000000000 : >1T
// 1000000000000000 : >1,000T (comma added)
// If you want to add negative number support, find something here
// https://stackoverflow.com/questions/10599933/
export class LargeNumbersPipe implements PipeTransform {
  transform(input: number): string {
    if (isFinite(input)) {
      if (input < 1e3) {
        return parseFloat((input).toFixed(2)).toString();
      } else if (input === 1e3) {
        return '1K';
      } else if (input < 1e6) {
        return '>' + parseFloat(Math.floor((input / 1000)).toFixed(0)) + 'K';
      } else if (input === 1e6) {
        return '1M';
      } else if (input < 1e9) {
        return '>' + parseFloat(Math.floor((input / 1e6)).toFixed(0)) + 'M';
      } else if (input === 1e9) {
        return '1B';
      } else if (input < 1e12) {
        return '>' + parseFloat(Math.floor((input / 1e9)).toFixed(0)) + 'B';
      } else if (input === 1e12) {
        return '1T';
      } else if (input < 1e15) {
        return '>' + parseFloat(Math.floor((input / 1e12)).toFixed(0)) + 'T';
      } else {
        return '>' +
          numberWithCommas(
            parseFloat(Math.floor((input / 1e12)).toFixed(0))
          ) + 'T';
      }
    } else {
      return 'NaN';
    }
  }
}
