import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  forwardRef
} from '@angular/core';
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import { Tag } from 'projects/api/src/public_api';

@Component({
  selector: 'tag-list',
  templateUrl: './tag-list.template.html',
  styleUrls: ['./tag-list.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagListComponent),
      multi: true
    }
  ]
})
export class TagListComponent implements ControlValueAccessor {
  tags: Tag[];

  protected tagInput = new FormControl('');

  constructor(private _changeDetectorRef: ChangeDetectorRef) { }

  protected onTouched = () => { };
  private _onChange = (_: any) => { };

  writeValue(value: any): void {
    this.tags = value;
    this._changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    this._onChange(this.tags);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  keyCheck(e: KeyboardEvent) {
    switch (e.keyCode) {
      case 188: // comma
        this.addTags();
        break;

      case 13: // enter
        this.addTags();
    }
  }

  protected addTags(): void {
    const newTags = uniq(
      compact(
        (this.tagInput.value as string)
          .split(',')
          .map(t => t.toLowerCase().trim())
      )
    ).map(name => {
      const tag = { name };
      return tag;
    });
    this.tags = uniqBy(this.tags.concat(newTags), 'name');
    this._onChange(this.tags);
    this.tagInput.setValue('');
    this._changeDetectorRef.markForCheck();
  }

  protected removeTag(tagName: string): void {
    this.tags = this.tags.filter(tag => tag.name !== tagName);
    this._onChange(this.tags);
    this._changeDetectorRef.markForCheck();
  }

  protected clearTags(): void {
    this.tags = [];
    this._onChange(this.tags);
    this._changeDetectorRef.markForCheck();
  }
}
