import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpEvent
} from '@angular/common/http';

import { Attachment, SignedUrlResponse } from '../models/attachment.model';
import { BaseService } from './base.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentService extends BaseService {
  protected endpointUrl: string;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpointUrl = `${this.rootUrl}/attachments`;
    this.defaultSize = 1000;
  }

  getAttachment(
    id: string,
    signed = false,
    upload = false
  ): Promise<Attachment> {
    return this.httpClient
      .get<Attachment>(
        `${this.endpointUrl}/${id}?signed=${signed}&upload=${upload}`
      )
      .pipe(map(resp => new Attachment(resp)))
      .toPromise();
  }

  getAttachmentByUrl(url: string, upload: boolean): Promise<Attachment> {
    return this.httpClient
      .get<Attachment>(`${url}?upload=${upload}`)
      .pipe(map(resp => new Attachment(resp)))
      .toPromise();
  }

  /**
   * downloads an attachments content as a Blob
   *
   * @param id the attachment id
   * @param reportProgress passes a report progress param to the http request
   * @return a Blob with the file's content
   */
  getAttachmentContent(
    id: string,
    reportProgress: boolean = false
  ): Observable<HttpResponse<any> | HttpEvent<any>> {
    const options = {
      responseType: 'blob' as any
    };

    if (reportProgress) {
      options['reportProgress'] = true;
      options['observe'] = 'events';
    }

    // TODO: figure out how to properly type this
    return this.httpClient.get<any>(`${this.endpointUrl}/${id}/content`, {
      ...options
    });
  }

  getSignedUrl(storageUrl: string): Promise<SignedUrlResponse> {
    const body = {
      urls: [
        {
          gsUrl: storageUrl.replace('https://storage.googleapis.com/', 'gs://')
        }
      ]
    };

    return this.httpClient
      .post(`${this.endpointUrl}/signedUrls`, body)
      .pipe(map(data => data as SignedUrlResponse))
      .toPromise();
  }

  createAttachment(attachment: Attachment | object): Promise<string> {
    return this.createWithResponseAsUrl(
      this.endpointUrl,
      attachment
    ).toPromise();
  }

  decodeImage(attachment: Attachment, base64: string): Promise<string> {
    return this.postWithResponseAsUrl(
      `${this.endpointUrl}/${attachment.id}/decodeImage`,
      { imageString: base64 }
    ).toPromise();
  }

  /**
   * @deprecated should use the new addFileContent method for uploading
   */
  uploadFile(uploadUrl: string, file: File): Promise<HttpResponse<File>> {
    const fileBytes = file.size;
    return this.putWithResponse<File>(
      `${this.brokerRootUrl}/proxy/${uploadUrl}`,
      file,
      {
        headers: new HttpHeaders({
          'Content-Range': `bytes 0-${fileBytes - 1}/${fileBytes}`
        })
      }
    ).toPromise();
  }

  addFileContent(id: string, file: File): Promise<HttpResponse<File>> {
    let options = {};

    if (!file.type) {
      options['headers'] = new HttpHeaders({
        'Content-Type': 'text/plain'
      });
    }

    return this.putWithResponse<File>(
      `${this.endpointUrl}/${id}/content`,
      file,
      options
    ).toPromise();
  }

  checkUploadStatus(uploadUrl: string): Promise<HttpResponse<File>> {
    return this.putWithResponse<File>(
      `${this.brokerRootUrl}/proxy/${uploadUrl}`,
      undefined,
      {
        headers: new HttpHeaders({
          'Content-Range': '*/*'
        })
      }
    ).toPromise();
  }

  updateAttachmentStatus(
    id: string,
    status: string
  ): Promise<HttpResponse<Attachment>> {
    return this.patchWithResponse<Attachment>(`${this.endpointUrl}/${id}`, {
      status: status
    }).toPromise();
  }

  updateAttachment(attachment: Attachment): Promise<HttpResponse<Attachment>> {
    return this.putWithResponse<Attachment>(
      `${this.endpointUrl}/${attachment.id}`,
      attachment
    ).toPromise();
  }

  deleteAttachment(id: string): Promise<HttpResponse<object>> {
    return this.deleteWithResponse(`${this.endpointUrl}/${id}`).toPromise();
  }

  /**
   * @param referenceId The reference id of the attachments to load
   * @param downloadFile if set to true, will only return the special "downloadable" attachments for orders
   */
  getAttachmentsByReferenceId(
    referenceId: string,
    downloadFile?: boolean
  ): Promise<Attachment[]> {
    const params = {};

    if (downloadFile) {
      params['downloadFile'] = downloadFile.toString();
    }

    return this.httpClient
      .get<Attachment[]>(`${this.endpointUrl}/referenceId/${referenceId}`, {
        params
      })
      .pipe(
        map(attachments =>
          attachments.map(attachment => new Attachment(attachment))
        )
      )
      .toPromise();
  }
}
