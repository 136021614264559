import { Input, HostBinding } from '@angular/core';
import { handleClassNamesFromInput } from '../util/helpers';
import compact from 'lodash/compact';

export type ButtonType = 'submit' | 'reset' | 'button';

export abstract class AerialButton {
  @Input()
  color: 'primary' | 'secondary' | 'tertiary' | 'subordinate' = 'primary';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  set type(type: string) {
    this._type = type as ButtonType;
  }

  @Input()
  disabled = false;

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class.block')
  get hasBlockClass(): boolean {
    return this.allClasses.includes('block');
  }

  get type(): string {
    return this._type ? this._type : 'button';
  }

  get defaultClasses(): string[] {
    return compact([this.size, this.color]);
  }

  get allClasses(): string[] {
    return this.handleClasses(this.classes).concat([
      'aerial-btn',
      ...this.defaultClasses
    ]);
  }

  abstract classes: string[] | string;
  private _type: ButtonType;

  protected handleClasses = handleClassNamesFromInput;
}
