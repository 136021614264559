import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AerialApp } from '../util/helpers';

@Component({
  selector: 'aerial-header',
  templateUrl: './aerial-header.template.html',
  styleUrls: ['./aerial-header.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AerialHeaderComponent {
  @Input()
  app: AerialApp;

  @Input()
  pageTitle: string;

  @Input()
  homeTooltip: string;

  @Input()
  // If `true` then only the Airbus logo will be displayed on the left side.
  // `pageTitle`, `homeTooltip` and `homeClick` will be ignored.
  logoOnly = false;

  @Output()
  homeClick = new EventEmitter<void>();

  get title(): string {
    if (this.pageTitle) {
      return this.pageTitle;
    }
    switch (this.app) {
      case AerialApp.Adp:
        return 'Aerial Data Portal';
      case AerialApp.Asp:
        return 'Aerial Services';
      case AerialApp.Admin:
        return 'Aerial Admin';
      case AerialApp.Ag:
        return 'AgNeo';
      case AerialApp.Catalog:
        return 'Airbus DMS';
    }
  }
}
