export interface DatePickerConfig {
  enableTime?: boolean;
  defaultHour?: number;
  defaultMinute?: number;
  minuteIncrement?: number;
  minDate?: string | Date;
  maxDate?: string | Date;
  noCalendar?: boolean;
  dateFormat?: string;
  altFormat?: string;
  altInput?: boolean;
  altInputClass?: string;
  mode?: 'single' | 'multiple' | 'range';
  style?: 'input' | 'button' | 'icon-button' | 'dropdown' | 'alt-dropdown';
  nextArrow?: string;
  prevArrow?: string;
}

export const defaultDatePickerConfig: DatePickerConfig = {
  enableTime: true,
  dateFormat: 'U',
  altInput: true,
  mode: 'single',
  style: 'input',
  nextArrow: `<i class="icon-triangle_right"></i>`,
  prevArrow: `<i class="icon-triangle_left"></i>`
};
